import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  InfoWindow,
} from "@react-google-maps/api";

const calculateCenter = (data, latField, lngField) => {
  if (data.length === 0) return { lat: 39.1667, lng: 35.6667 };

  const total = data.reduce(
    (acc, item) => {
      const lat = parseFloat(item[latField].replace(",", "."));
      const lng = parseFloat(item[lngField].replace(",", "."));
      acc.lat += lat;
      acc.lng += lng;
      return acc;
    },
    { lat: 0, lng: 0 }
  );

  const count = data.length;
  return {
    lat: total.lat / count,
    lng: total.lng / count,
  };
};

export default function DynamicMap({
  data,
  tableColumn,
  selectedColumnValue,
  selectedColumnLabel,
}) {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [options, setOptions] = useState({
    center: { lat: 39.1667, lng: 35.6667 },
    zoom: 5.8,
    styles: [
      {
        featureType: "all",
        elementType: "all",
        stylers: [
          { invert_lightness: true },
          { saturation: -50 },
          { lightness: 40 },
          { gamma: 0.5 },
          { hue: "#3cd2a5" },
        ],
      },
    ],
  });

  useEffect(() => {
    if (
      data &&
      data.length !== 0 &&
      selectedColumnValue &&
      selectedColumnLabel
    ) {
      const newCenter = calculateCenter(
        data,
        selectedColumnValue.value,
        selectedColumnLabel.value
      );
      // `options`'un sadece `center` kısmını `newCenter` ile güncelle
      setOptions((prevOptions) => ({
        ...prevOptions,
        center: newCenter,
      }));
    }
  }, [data, selectedColumnValue, selectedColumnLabel]);

  const handleMarkerClick = (item) => {
    setSelectedMarker(item);
  };

  return (
    selectedColumnValue &&
    selectedColumnLabel && (
      <div>
        {data ? (
          data.length !== 0 ? (
            <GoogleMap
              mapContainerStyle={{
                height: 500,
                width: "100%",
              }}
              options={options} // Use options with state values here
            >
              <MarkerClusterer
                options={{
                  maxZoom: 20,
                  styles: [
                    {
                      textColor: "white",
                      url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
                      height: 50,
                      width: 50,
                    },
                    {
                      textColor: "white",
                      url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png",
                      height: 56,
                      width: 56,
                    },
                    {
                      textColor: "white",
                      url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png",
                      height: 66,
                      width: 66,
                    },
                  ],
                }}
              >
                {(clusterer) =>
                  data.map((item, index) => {
                    const position = {
                      lat: parseFloat(
                        item[selectedColumnValue.value].replace(",", ".")
                      ),
                      lng: parseFloat(
                        item[selectedColumnLabel.value].replace(",", ".")
                      ),
                    };

                    return (
                      <Marker
                        key={index}
                        position={position}
                        clusterer={clusterer}
                        onClick={() => handleMarkerClick(item)}
                      />
                    );
                  })
                }
              </MarkerClusterer>

              {selectedMarker && (
                <InfoWindow
                  position={{
                    lat: parseFloat(
                      selectedMarker[selectedColumnValue.value].replace(
                        ",",
                        "."
                      )
                    ),
                    lng: parseFloat(
                      selectedMarker[selectedColumnLabel.value].replace(
                        ",",
                        "."
                      )
                    ),
                  }}
                  onCloseClick={() => setSelectedMarker(null)}
                >
                  <div>
                    {Object.entries(selectedMarker).map(([key, value]) => (
                      <p key={key}>
                        <strong>{key}:</strong> {value}
                      </p>
                    ))}
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          ) : (
            <span>Konumlar Bulunamadı</span>
          )
        ) : (
          <div className="spinner-border text-theme"></div>
        )}
      </div>
    )
  );
}
