import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

import { CSVLink } from "react-csv";
import { ToastContainer } from "react-toastify";
import toastMessage from "../../components/ToastMessage";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

import AddModal from "./addModal";
import UpdateModal from "./updateModal";
import Fancybox from "../../components/Fancybox";

import { Card, CardBody } from "../../components/card/card";
import GroupList from "./groupList";

function Kullanicilar() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  // Get Data
  const searchRef = useRef();
  const tableRef = useRef();
  const tableHead = [
    {
      key: "id",
      label: "#",
    },
    {
      key: "profil",
      label: context?.t?.profile,
    },
    {
      key: "customer",
      label: context?.t?.customer,
    },
    {
      key: "customer_name",
      label: context?.t?.customer_representative,
    },
    {
      key: "role",
      label: context?.t?.user_role,
    },
    {
      key: "gender",
      label: context?.t?.gender,
    },
    {
      key: "username",
      label: context?.t?.username,
    },
    {
      key: "unvan",
      label: context?.t?.user_title,
    },
    {
      key: "name",
      label: context?.t?.user_representative,
    },
    {
      key: "phone",
      label: context?.t?.user_phone,
    },
    {
      key: "email",
      label: context?.t?.user_email,
    },
    {
      key: "address",
      label: context?.t?.user_address,
    },
  ];

  const [data, setData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [customerId, setCustomerId] = useState(null);

  const [limitPage, setLimitPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchCol, setSearchCol] = useState("username");
  const [sortBy, setSortBy] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");

  useEffect(() => {
    if (context && context.me) {
      if (context?.me?.role?.id !== 1) {
        setCustomerId({
          value: context.me.customer.id,
          label: context.me.customer.customer.name,
        });
      }
    }
  }, [context]);

  const getData = useCallback(async () => {
    if (customerId) {
      setData(null);

      try {
        const response = await apiClient.get(
          `/user?customer_id=${
            customerId.value
          }&paginate=true&limit=${limitPage}${
            sortDirection ? `&sort=${sortBy}&direction=${sortDirection}` : ""
          }&page=${currentPage}&query=${search}&query_field=${searchCol}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error("Users Error", error);
        setData(null);

        if (error.response.status === 500) {
          setErrorMessage("500 | Sunucu Hatası!");
        } else if (error.response.status === 404) {
          navigate("/404");
        } else if (error.response.status === 403) {
          setErrorMessage("Çok fazla istek atıldı.");
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        } else {
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    }
  }, [
    context,
    currentPage,
    customerId,
    limitPage,
    navigate,
    search,
    searchCol,
    sortBy,
    sortDirection,
  ]);

  useEffect(() => {
    if (
      context?.me?.role?.id === 1 ||
      context?.me?.role?.id === 2 ||
      context?.me?.role?.id === 4
    ) {
      getData();
    }
    // eslint-disable-next-line
  }, [
    context,
    customerId,
    limitPage,
    currentPage,
    search,
    sortBy,
    sortDirection,
  ]);

  // Sort
  const handleSort = (key) => {
    if (sortBy === key) {
      setSortDirection((prevSortDirection) => {
        if (prevSortDirection === "asc") return "desc";
        if (prevSortDirection === "desc") return "asc";
        // if (prevSortDirection === "desc") return null;
        // return "asc";
      });
    } else {
      setSortBy(key);
      setSortDirection("asc");
    }
  };

  // Limit Change
  function limitChange(e) {
    setCurrentPage(1);
    setLimitPage(e.target.value);
  }

  // Pagination
  function handlePagination(value) {
    setCurrentPage(value);
  }

  // Search
  function handleSearchCol(e) {
    setSearchCol(e.target.value);
  }

  function handleSearch() {
    setCurrentPage(1);
    setSearch(searchRef.current.value);
  }

  function handleReset() {
    setLimitPage(10);
    setCurrentPage(1);
    setSearch("");
    setSearchCol("username");
    setSortBy("id");
    setSortDirection("desc");
  }

  // Update
  const [updateItem, setUpdateItem] = useState(null);

  // Delete
  const [deleteItem, setDeleteItem] = useState(null);

  const handleDelete = async () => {
    if (deleteItem) {
      try {
        const response = await apiClient.delete(`/user/${deleteItem.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.status === 200) {
          toastMessage(response.data.message, "success");
          document.getElementById("deleteConfirmModalClose").click();
          getData();
        }
      } catch (error) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }

        if (error.response.status !== 404 && error.response.status !== 500) {
          setDeleteItem(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    }
  };

  //--

  const [customerSelect, setCustomerSelect] = useState(null);

  async function handleCustomerSelect(e) {
    setCustomerSelect(e);
    setCustomerId(e);
  }

  // Birimler
  const [birimlerList, setBirimlerList] = useState(null);
  const [showUsersBirimler, setShowUsersBirimler] = useState(false);
  const [birimLoad, setBirimLoad] = useState(false);

  const [birimlerUpdateItem, setBirimlerUpdateItem] = useState(null);

  const [birimlerDeleteItem, setBirimlerDeleteItem] = useState(null);

  const [birimAdiValue, setBirimAdiValue] = useState("");
  const [birimSelectValue, setBirimSelectValue] = useState(null);
  const [birimUserValue, setBirimUserValue] = useState(null);

  const [allUsersSelect, setAllUsersSelect] = useState(null);

  async function fetchAllUsersSelect() {
    setAllUsersSelect(null);

    try {
      const response = await apiClient.get(
        `/user?customer_id=${
          customerSelect ? customerSelect.value : context?.me?.customer?.id
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setAllUsersSelect(response.data);
      }
    } catch (error) {
      console.error("Users Error", error);
      setAllUsersSelect([]);
    }
  }

  const flattenBirimlerList = (nodes) => {
    let flattened = [];
    nodes.forEach((node) => {
      // Ana öğeyi ekle
      flattened.push({
        value: node.id,
        label: node.name,
      });

      // Eğer çocuklar varsa, onları da mapleyip ekle
      if (node.children && node.children.length > 0) {
        flattened = flattened.concat(flattenBirimlerList(node.children));
      }
    });
    return flattened;
  };

  async function fetchBirimler() {
    setBirimlerList(null);

    try {
      const response = await apiClient.get(`/groups`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (customerSelect) {
        setBirimlerList(
          response.data.filter(
            (item) => item.customer_id === customerSelect.value
          )
        );
      } else {
        setBirimlerList(response.data);
      }
    } catch (error) {
      setBirimlerList([]);
      console.error("Birimler", error);
    }
  }

  useEffect(() => {
    fetchBirimler();
    fetchAllUsersSelect();
  }, [customerSelect]);

  useEffect(() => {
    if (birimlerUpdateItem) {
      if (birimlerUpdateItem.parent_id) {
        setBirimSelectValue(
          flattenBirimlerList(birimlerList).find(
            (item) => item.value === birimlerUpdateItem.parent_id
          )
        );
      } else {
        setBirimSelectValue(null);
      }

      setBirimAdiValue(birimlerUpdateItem.name);
      setBirimUserValue(
        birimlerUpdateItem.users.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    } else {
      setBirimAdiValue("");
      setBirimUserValue(null);
      setBirimSelectValue(null);
    }
  }, [birimlerUpdateItem]);

  async function handleBirimEkle() {
    if (birimAdiValue === "") {
      toastMessage(context?.t?.department_name_required, "error");
      return;
    }

    const payload = {
      name: birimAdiValue,
      parent_id: birimSelectValue ? birimSelectValue.value : null,
      customer_id: customerSelect
        ? customerSelect.value
        : context?.me?.customer?.id,
    };

    setBirimLoad(true);

    try {
      const response = birimlerUpdateItem
        ? await apiClient.put(
            "/groups/" + birimlerUpdateItem.id,
            {
              name: birimAdiValue,
              users: birimUserValue
                ? birimUserValue.map((item) => {
                    return item.value;
                  })
                : [],
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
        : await apiClient.customPost("/groups", payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

      if (response) {
        fetchBirimler();

        toastMessage(
          birimlerUpdateItem
            ? context?.t?.updated_department
            : context?.t?.added_department,
          "success"
        );
        document.getElementById("birimEkleModalCloseButton").click();
        setBirimLoad(false);
      }
    } catch (error) {
      setBirimLoad(false);
      console.error("Error", error);
    }
  }

  async function handleBirimlerDelete(params) {
    if (birimlerDeleteItem) {
      try {
        const response = await apiClient.delete(
          `/groups/${birimlerDeleteItem.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          fetchBirimler();

          toastMessage(context?.t?.deleted_department, "success");
          document.getElementById("birimlerDeleteConfirmCloseButton").click();
        }
      } catch (error) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }

        if (error.response.status !== 404 && error.response.status !== 500) {
          setDeleteItem(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    }
  }

  return (
    (context?.me?.role?.id === 1 ||
      context?.me?.role?.id === 2 ||
      context?.me?.role?.id === 4) && (
      <>
        <div className="zoom-item mb-5 pb-5">
          {context?.me?.role.id === 1 && (
            <div className="border-bottom mb-4 pb-4">
              <div className="d-flex">
                <div className="w-100" style={{ maxWidth: 200 }}>
                  {context.allCustomerSelect ? (
                    <Select
                      options={context.allCustomerSelect}
                      placeholder={context?.t?.select_customer}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      value={customerSelect}
                      onChange={(e) => handleCustomerSelect(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="mb-4 d-flex align-items-center gap-3">
            <h4 className="mb-0">{context?.t?.users}</h4>
            <button
              type="button"
              className="btn btn-outline-theme"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              {context?.t?.add_user}
            </button>
          </div>

          {errorMessage ? (
            errorMessage
          ) : customerId ? (
            data ? (
              <div className="dataTables_wrapper dt-bootstrap5">
                <div className="row mb-3">
                  <div className="col-md-4 mb-3 mb-md-0">
                    <div className="dataTables_length">
                      <label>
                        <select
                          className="form-select form-select-sm ms-0"
                          onChange={limitChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                        </select>
                      </label>
                      <span className="ms-3">{context?.t?.view}</span>
                    </div>
                  </div>

                  <div className="col-md-8 text-md-end">
                    <div className="d-flex justify-content-center justify-content-md-end">
                      <div className="dataTables_filter d-flex border-end pe-2">
                        <select
                          className="form-select form-select-sm"
                          onChange={handleSearchCol}
                          defaultValue={searchCol}
                          style={{ width: 130 }}
                        >
                          <option value="username">
                            {context?.t?.user_name}
                          </option>
                          <option value="name">
                            {context?.t?.person_name_surname}
                          </option>
                          <option value="phone">
                            {context?.t?.user_phone}
                          </option>
                          <option value="email">
                            {context?.t?.user_email}
                          </option>
                        </select>

                        <input
                          type="search"
                          className="form-control form-control-sm border-end-0 rounded-end-0"
                          placeholder={context?.t?.search}
                          ref={searchRef}
                        />

                        <button
                          type="button"
                          className="btn buttons-print btn-outline-default btn-sm rounded-start-0"
                          onClick={() => handleSearch()}
                        >
                          {context?.t?.search}
                        </button>
                      </div>

                      <CSVLink
                        data={data.data}
                        headers={tableHead}
                        filename="stratejimerkezi_users.csv"
                        className="btn buttons-print btn-outline-default btn-sm ms-2"
                      >
                        CSV
                      </CSVLink>

                      <div className="border-start ms-2 ps-2">
                        <button
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => handleReset()}
                        >
                          {context?.t?.reset}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {data.data.length === 0 ? (
                  context?.t?.user_not_found
                ) : (
                  <>
                    <div className="dataTables_scroll">
                      <div className="dataTables_scrollBody table-responsive">
                        <table
                          className="table text-nowrap w-100 dataTable"
                          ref={tableRef}
                        >
                          <thead>
                            <tr>
                              <th className="text-start ps-2">
                                {context?.t?.transactions}
                              </th>
                              {tableHead.map((item, index) => (
                                <th
                                  key={index}
                                  onClick={() =>
                                    item.key !== "customer_name" &&
                                    handleSort(item.key)
                                  }
                                  className={
                                    item.key !== "customer_name"
                                      ? `sorting ${
                                          sortBy === item.key
                                            ? `sorting_${sortDirection}`
                                            : ""
                                        }`
                                      : ""
                                  }
                                >
                                  {item.label}
                                </th>
                              ))}
                            </tr>
                          </thead>

                          <tbody>
                            {data.data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-end">
                                  <button
                                    type="button"
                                    className="btn btn-outline-theme btn-sm me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#updateModal"
                                    onClick={() =>
                                      setUpdateItem({
                                        id: item.id,
                                        customer: {
                                          value: item?.customer?.id || "",
                                          label:
                                            item?.customer?.customer?.name ||
                                            "",
                                        },
                                        role: {
                                          value: item?.role?.id || "",
                                          label: item?.role?.name || "",
                                        },
                                        username: item?.username || "",
                                        name: item?.name || "",
                                        phone: item?.phone || "",
                                        email: item?.email || "",
                                        address: item?.address || "",
                                        settings: {
                                          gender: item?.settings?.gender || "",
                                          title: item?.settings?.title || "",
                                          image: item?.settings?.image || null,
                                          kvkk_confirm_date:
                                            item?.settings?.kvkk_confirm_date ||
                                            null,
                                        },
                                      })
                                    }
                                  >
                                    {context?.t?.edit}
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-outline-danger btn-sm"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteConfirmModal"
                                    onClick={() =>
                                      setDeleteItem({
                                        id: item.id,
                                        name: item.username,
                                      })
                                    }
                                  >
                                    {context?.t?.delete}
                                  </button>
                                </td>
                                <td>{item?.id || "-"}</td>
                                <td>
                                  {item?.settings?.image ? (
                                    <Fancybox
                                      options={{
                                        Carousel: {
                                          infinite: false,
                                        },
                                      }}
                                    >
                                      <a
                                        className="d-block"
                                        href={item?.settings?.image}
                                        data-fancybox
                                      >
                                        <img
                                          src={item?.settings?.image}
                                          alt={item.name}
                                          width={100}
                                          height={100}
                                          style={{
                                            objectFit: "cover",
                                            borderRadius: "100%",
                                          }}
                                        />
                                      </a>
                                    </Fancybox>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>{item?.customer?.customer?.name || "-"}</td>
                                <td>
                                  {item?.customer?.customer?.competent || "-"}
                                </td>
                                <td>{item?.role?.name}</td>
                                <td>{item?.settings?.gender?.label || "-"}</td>
                                <td>{item?.username || "-"}</td>
                                <td>{item?.settings?.title || "-"}</td>
                                <td>{item?.name || "-"}</td>
                                <td>
                                  <a href={`tel:${item.phone}`}>
                                    {item?.phone || "-"}
                                  </a>
                                </td>
                                <td>
                                  <a href={`mailto:${item.email}`}>
                                    {item?.email || "-"}
                                  </a>
                                </td>
                                <td style={{ whiteSpace: "wrap" }}>
                                  <p className="mb-0" style={{ width: 200 }}>
                                    {item?.address || "-"}
                                  </p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="mb-0 col-md-12">
                        <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center">
                          <ul className="pagination">
                            <li
                              className={`paginate_button page-item ${
                                !data.prev_page_url && "disabled"
                              }`}
                            >
                              <Link
                                to="#"
                                onClick={() =>
                                  data.prev_page_url &&
                                  handlePagination(
                                    data.prev_page_url.split("?page=")[1]
                                  )
                                }
                                className="page-link"
                              >
                                {context?.t?.prev}
                              </Link>
                            </li>

                            {data.links.map(
                              (item, index) =>
                                !isNaN(parseInt(item.label)) && (
                                  <li
                                    key={index}
                                    className={`paginate_button page-item ${
                                      item.active ? "active" : ""
                                    }`}
                                  >
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        handlePagination(item.label)
                                      }
                                      className="page-link"
                                    >
                                      {item.label}
                                    </Link>
                                  </li>
                                )
                            )}

                            <li
                              className={`paginate_button page-item ${
                                !data.next_page_url && "disabled"
                              }`}
                            >
                              <Link
                                to={"#"}
                                onClick={() =>
                                  data.next_page_url &&
                                  handlePagination(
                                    data.next_page_url.split("?page=")[1]
                                  )
                                }
                                className="page-link"
                              >
                                {context?.t?.next}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="spinner-border text-theme"></div>
            )
          ) : (
            <span>{context?.t?.choose_customer}</span>
          )}

          <hr />

          <div className="pt-3 mb-4 d-flex align-items-center gap-3">
            <h4 className="mb-0">{context?.t?.departments}</h4>
            <button
              type="button"
              className="btn btn-outline-theme me-3"
              data-bs-toggle="modal"
              data-bs-target="#birimEkleModal"
              onClick={() => setBirimlerUpdateItem(null)}
            >
              {context?.t?.add_group}
            </button>
          </div>

          {customerId ? (
            birimlerList ? (
              birimlerList.length !== 0 ? (
                <GroupList
                  data={birimlerList}
                  setBirimlerUpdateItem={setBirimlerUpdateItem}
                  setBirimlerDeleteItem={setBirimlerDeleteItem}
                />
              ) : (
                // <div className="row g-3">
                //   {birimlerList.map((item, index) => (
                //     <div key={index} className="col-12">
                //       <Card>
                //         <CardBody>
                //           <div className="d-flex align-items-center justify-content-between">
                //             <span className="flex-1 d-block h6 mb-0 me-3">
                //               {item.name}
                //             </span>

                //             <div className="d-flex gap-2">
                //               {item.users.length !== 0 && (
                //                 <button
                //                   type="button"
                //                   className="btn btn-outline-theme"
                //                   onClick={() =>
                //                     setShowUsersBirimler((prevIndex) =>
                //                       prevIndex === index ? null : index
                //                     )
                //                   }
                //                 >
                //                   {showUsersBirimler === index
                //                     ? context?.t?.hide_users
                //                     : context?.t?.show_users}
                //                 </button>
                //               )}

                //               <button
                //                 type="button"
                //                 className="btn btn-outline-theme"
                //                 data-bs-toggle="modal"
                //                 data-bs-target="#birimEkleModal"
                //                 onClick={() => setBirimlerUpdateItem(item)}
                //               >
                //                 {context?.t?.edit}
                //               </button>

                //               <button
                //                 type="button"
                //                 className="btn btn-danger"
                //                 data-bs-toggle="modal"
                //                 data-bs-target="#birimlerDeleteConfirm"
                //                 onClick={() => setBirimlerDeleteItem(item)}
                //               >
                //                 {context?.t?.remove}
                //               </button>
                //             </div>
                //           </div>

                //           {showUsersBirimler === index &&
                //             item.users.length !== 0 && (
                //               <div className="table-responsive mt-3">
                //                 <table className="table border mb-0 align-middle">
                //                   <thead>
                //                     <th className="border-bottom p-2">
                //                       {context?.t?.name_surname}
                //                     </th>
                //                     <th className="border-bottom border-start p-2">
                //                       {context?.t?.username}
                //                     </th>
                //                     <th className="border-bottom border-start p-2">
                //                       {context?.t?.role}
                //                     </th>
                //                     <th className="border-bottom border-start p-2">
                //                       {context?.t?.email}
                //                     </th>
                //                     <th className="border-bottom border-start p-2">
                //                       {context?.t?.phone}
                //                     </th>
                //                   </thead>
                //                   <tbody>
                //                     {item.users.map(
                //                       (itemUser, itemUserIndex) => (
                //                         <tr key={itemUserIndex}>
                //                           <td>{itemUser.name}</td>
                //                           <td className="border-start">
                //                             {itemUser.username}
                //                           </td>
                //                           <td className="border-start">
                //                             {itemUser.role.name}
                //                           </td>
                //                           <td className="border-start">
                //                             {itemUser.email}
                //                           </td>
                //                           <td className="border-start">
                //                             {itemUser.phone}
                //                           </td>
                //                         </tr>
                //                       )
                //                     )}
                //                   </tbody>
                //                 </table>
                //               </div>
                //             )}
                //         </CardBody>
                //       </Card>
                //     </div>
                //   ))}
                // </div>
                <span>{context?.t?.department_not_added}</span>
              )
            ) : (
              <div className="spinner-border text-theme"></div>
            )
          ) : (
            <span>{context?.t?.choose_customer}</span>
          )}
        </div>

        <div className="modal modal-cover fade" id="birimEkleModal">
          <div className="modal-dialog">
            <div
              className="modal-content rounded"
              style={{ background: "#141b24" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">
                  {birimlerUpdateItem
                    ? context?.t?.department_edit
                    : context?.t?.department_add}
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="birimEkleModalCloseButton"
                ></button>
              </div>

              <div className="modal-body">
                <div className="mb-4">
                  <span className="mb-1 d-block">
                    {context?.t?.department_name}
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    value={birimAdiValue}
                    onChange={(e) => setBirimAdiValue(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <span className="mb-1 d-block">
                    {context?.t?.departments}
                  </span>
                  {birimlerList ? (
                    <Select
                      options={flattenBirimlerList(birimlerList)}
                      placeholder=""
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      value={birimSelectValue}
                      isClearable
                      onChange={(e) => setBirimSelectValue(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>

                {birimlerUpdateItem && (
                  <div className="mb-4">
                    <span className="mb-1 d-block">{context?.t?.users}</span>
                    {allUsersSelect ? (
                      <Select
                        options={allUsersSelect.map((item) => {
                          return {
                            value: item.id,
                            label: item.name,
                          };
                        })}
                        placeholder=""
                        classNamePrefix="react-select"
                        noOptionsMessage={() => context?.t?.no_option}
                        isMulti
                        value={birimUserValue}
                        onChange={(e) => setBirimUserValue(e)}
                      />
                    ) : (
                      <div className="spinner-border spinner-border-sm text-theme"></div>
                    )}
                  </div>
                )}

                <button
                  type="submit"
                  className="btn btn-outline-theme w-100"
                  onClick={() => handleBirimEkle()}
                  disabled={birimLoad}
                >
                  {birimlerUpdateItem ? context?.t?.update : context?.t?.save}
                  {birimLoad && (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <AddModal getData={getData} />

        <UpdateModal getData={getData} updateItem={updateItem} />

        <div className="modal modal-cover fade" id="deleteConfirmModal">
          <div className="modal-dialog">
            <div
              className="modal-content rounded"
              style={{ background: "#141b24" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">
                  {context?.t?.are_you_sure}
                  <br />
                  <span className="h5">
                    {context?.t?.username}: {deleteItem && deleteItem.name}
                  </span>
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="deleteConfirmModalClose"
                ></button>
              </div>

              <div className="modal-body">
                <div className="d-flex gap-3">
                  <button
                    type="button"
                    className="btn btn-outline-danger w-100"
                    onClick={handleDelete}
                  >
                    {context?.t?.delete}
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-theme w-100"
                    data-bs-dismiss="modal"
                  >
                    {context?.t?.cancel}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal modal-cover fade" id="birimlerDeleteConfirm">
          <div className="modal-dialog">
            <div
              className="modal-content rounded"
              style={{ background: "#141b24" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">
                  {context?.t?.are_you_sure}
                  <br />
                  <span className="h5">
                    {context?.t?.department}: {birimlerDeleteItem?.name}
                  </span>
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="birimlerDeleteConfirmCloseButton"
                ></button>
              </div>

              {birimlerDeleteItem && (
                <div className="modal-body">
                  <div className="d-flex gap-3">
                    <button
                      type="button"
                      className="btn btn-outline-danger w-100"
                      onClick={handleBirimlerDelete}
                    >
                      {context?.t?.delete}
                    </button>

                    <button
                      type="button"
                      className="btn btn-outline-theme w-100"
                      data-bs-dismiss="modal"
                    >
                      {context?.t?.cancel}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <ToastContainer pauseOnFocusLoss={false} />
      </>
    )
  );
}

export default Kullanicilar;
