import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";

import Select from "react-select";

import apiClient from "../../ApiClient.js";
import { AppSettings } from "../../config/app-settings.js";
import AddReport from "../strateji-haritasi/addReport.jsx";
import toastMessage from "../../components/ToastMessage.js";
import LocationButton from "./LocationButton.jsx";

export default function ArastirmaRaporu({ show = true, card = false }) {
  const context = useContext(AppSettings);

  // Map
  const mapRef = useRef(null);
  const markerRefReport = useRef(null);
  const [mapModalDataReport, setMapModalDataReport] = useState(null);

  const [removeMarkerReport, setRemoveMarkerReport] = useState(false);
  const [reportModalMapCity, setReportModalMapCity] = useState(false);

  function handleRemoveMarkerReport() {
    setRemoveMarkerReport(true);
    setMapModalDataReport(null);
  }

  // Atanmış Anketleri Getir
  const [assigmentSelect, setAssigmentSelect] = useState(null);

  const [assigmentData, setAssigmentData] = useState([]);

  const handleGetReports = useCallback(async () => {
    setAssigmentData(null);

    try {
      const response = await apiClient.get(
        `/street-report-assigment/${context?.me?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.length !== 0) {
        const formattedData = response.data.map((item) => ({
          value: item.report.id,
          label: item.report.name,
          ...item,
        }));

        setAssigmentData(formattedData);
      } else {
        setAssigmentData([]);
      }
    } catch (error) {
      setAssigmentData(null);

      if (error.response?.data?.errors) {
        Object.keys(error.response.data.errors).forEach((key) =>
          error.response.data.errors[key].forEach((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(
          error.response?.data?.message || "An error occurred",
          "error"
        );
      }
    }
  }, [context?.me?.id]);

  useEffect(() => {
    if (context?.me) {
      handleGetReports();
    }
  }, [handleGetReports, context?.me]);

  // Anket Show
  const [surveyData, setSurveyData] = useState(null);

  async function handleGetShowReport(id) {
    try {
      const response = await apiClient.get(`/street-report/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setSurveyData(response.data);
    } catch (error) {
      setSurveyData(null);
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (assigmentSelect) {
      handleGetShowReport(assigmentSelect.value);
    }
  }, [assigmentSelect]);

  return (
    show && (
      <div className={`position-relative ${card ? "h-100" : "mb-4"}`}>
        <div className="d-flex align-items-end gap-2 mb-4">
          <div style={{ width: 300 }}>
            <span className="d-block mb-2">{context?.t?.atanmis_anketler}</span>
            {assigmentData ? (
              <Select
                options={assigmentData}
                placeholder=""
                classNamePrefix="react-select"
                noOptionsMessage={() => context?.t?.no_option}
                value={assigmentSelect}
                onChange={(e) => setAssigmentSelect(e)}
              />
            ) : (
              <div className="spinner-border spinner-border-sm text-theme"></div>
            )}
          </div>

          {assigmentSelect && (
            <button
              type="button"
              className="btn btn-outline-theme btn-lg"
              data-bs-toggle="modal"
              data-bs-target="#addReportModal"
            >
              <i className="fas fa-lg me-2 fa-plus-circle"></i>
              {context?.t?.gozlem_olustur}
            </button>
          )}
        </div>

        <AddReport
          assigmentSelect={assigmentSelect}
          surveyData={surveyData}
          mapModalData={mapModalDataReport}
          handleRemoveMarker={handleRemoveMarkerReport}
          setModalMapCity={setReportModalMapCity}
        />

        <button
          type="button"
          className="d-none"
          id="report-detail-modal-button"
          data-bs-toggle="modal"
          data-bs-target="#reportDetailModal"
        >
          Report Detail
        </button>
      </div>
    )
  );
}
