import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, useRoutes, useLocation, useNavigate } from 'react-router-dom';
import AppRoute from './config/app-route.jsx';
import { slideToggle } from './composables/slideToggle.js';

// Bootstrap and styles
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './scss/styles.scss';
import apiClient from './ApiClient.js';
import PageLoader from './components/PageLoader.jsx';

const container = document.getElementById('root');
const root = createRoot(container);

function App() {
	const [me, setMe] = useState(null); // State to store the user data
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();
	let element = useRoutes(AppRoute); // Moved to the top to avoid conditional hook calls

	// Fetch `me` data
	const getMe = async () => {
		if (localStorage.getItem('token')) {
			try {
				const response = await apiClient.get('/me', {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				});

				if (response) {
					setMe(response.data);
					localStorage.setItem('user', JSON.stringify(response.data));
					setTimeout(() => setLoading(false), 1000);
				}
			} catch (error) {
				console.error(error);
				handleUnauthorizedAccess();
			}
		} else {
			handleUnauthorizedAccess();
			setTimeout(() => setLoading(false), 1000);
		}
	};

	// Handle unauthorized access
	const handleUnauthorizedAccess = () => {
		setMe(null);
		localStorage.removeItem('token');
		localStorage.removeItem('user');
		navigate('/giris-yap');
	};

	// Fetch me data on component mount
	useEffect(() => {
		getMe();
	}, []);

	// Extract allowed IDs from `me`
	const allowedIds = me?.customer?.settings?.pages ? me?.customer?.settings?.pages.map(page => page.id) || [] : [];

	// Filter pages based on roles and allowed IDs
	const filterPagesByRoleAndId = (page) => {
		const roleId = me?.role?.id;

		if (page.removed) {
			if (roleId === 3) {
				return (
					(page.path === 'strateji-haritasi' || page.path === 'kentmetre-haritasi' || page.path === 'profil') &&
					(page.id ? allowedIds.includes(page.id) : true)
				);
			} else if (roleId === 4) {
				return (
					(page.path === 'hane-ve-birey-analizi' || page.path === 'halkla-iletisim-yonetimi' || page.path === 'hazir-raporlar' || page.path === 'kullanicilar' || page.path === 'profil') &&
					(page.id ? allowedIds.includes(page.id) : true)
				);
			} else if (roleId === 5) {
				return (page.path === 'arastirma-raporu') && (page.id ? allowedIds.includes(page.id) : true);
			} else if (roleId === 6) {
				return (page.path === 'halkla-iletisim-yonetimi' || page.path === 'profil') && (page.id ? allowedIds.includes(page.id) : true);
			} else {
				return page.id ? allowedIds.includes(page.id) : true;
			}
		} else {
			// Allow pages without IDs and those with IDs that are in the allowed list
			return page.id ? allowedIds.includes(page.id) : true;
		}
	};

	// Apply the filtering logic only when `me` is available
	useEffect(() => {
		if (me) {
			const filteredAppRoute = AppRoute[0].children.filter(filterPagesByRoleAndId);
			AppRoute[0].children = filteredAppRoute;
		}
	}, [me]);

	// Add a 404 NotFound route to handle inaccessible pages
	AppRoute[0].children.push({
		path: '*',
		element: <NotFound />,
	});

	// Side effects for location changes
	useEffect(() => {
		window.scrollTo(0, 0);
		window.speechSynthesis.cancel();

		const elm = document.querySelector('.app');
		if (elm) {
			elm.classList.remove('app-sidebar-mobile-toggled');
		}

		const elm2 = document.querySelector('.app-top-nav');
		if (elm2 && elm2.style.display === 'block') {
			slideToggle(document.querySelector('.app-top-nav'));
		}
	}, [location]);

	return loading ? (
		<PageLoader loading={true} /> // Render loading state
	) : (
		element // Render routes once loading is complete
	);
}

// 404 NotFound Component
const NotFound = () => (
	<div style={{ textAlign: 'center', marginTop: '50px' }}>
		<h1>404 - Page Not Found</h1>
		<p>The page you are looking for does not exist or is not accessible.</p>
	</div>
);

root.render(
	<Router>
		<App />
	</Router>
);
