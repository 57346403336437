import React, { useCallback, useContext, useEffect, useState } from "react";

import Select from "react-select";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";

import { Card, CardBody } from "./card/card";
import { AppSettings } from "../config/app-settings";
import apiClient from "../ApiClient";
import moment from "moment";
import DynamicCard from "./DynamicCard";

const center = { lat: 39.1667, lng: 35.6667 };
const defaultZoom = 5.8;
const options = {
  center,
  zoom: defaultZoom,
  styles: [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        { invert_lightness: true },
        { saturation: -50 },
        { lightness: 40 },
        { gamma: 0.5 },
        { hue: "#3cd2a5" },
      ],
    },
  ],
};

const getMarkerSize = (mag, depth) => {
  // İki örneği baz alarak bir çarpan belirleyelim
  // Oranı belirlemek için
  const scalingFactor = 2; // Deneme çarpanı, uygun olanı belirlemek için ayarlayın
  const size = scalingFactor * mag;

  // Minimum ve maksimum boyutlar
  return Math.max(3, Math.min(size, 100)); // Boyutları 3 ile 15 arasında sınırlayın
};

const getMarkerStyle = (mag, depth) => {
  let color = "red";
  const size = getMarkerSize(mag, depth);

  if (depth >= 0 && depth <= 5) {
    color = "#FF0000"; // Kırmızı
  } else if (depth > 5 && depth <= 10) {
    color = "#FF6666"; // Açık kırmızı
  } else if (depth > 10 && depth <= 20) {
    color = "#FFA500"; // Turuncu
  } else if (depth > 20 && depth <= 40) {
    color = "#FFFF00"; // Sarı
  } else if (depth > 40 && depth <= 80) {
    color = "#008000"; // Yeşil
  } else if (depth > 80 && depth <= 150) {
    color = "#0000FF"; // Mavi
  }

  return {
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: color,
    fillOpacity: 1,
    scale: size,
    strokeColor: color,
    strokeWeight: 1,
  };
};

export default function AfetYonetimi() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  function handleBack() {
    if (context?.me?.role?.id === 4) {
      navigate("/hane-ve-birey-analizi");
    } else if (context?.me?.role?.id === 1) {
      navigate("/durum-analizi", {
        state: { menu: { id: 51 } },
      });
    }
  }

  useEffect(() => {
    // context.setThemePanel(false);
    // context.setAppHeaderNone(true);
    // context.setAppScrollButton(false);
    // context.setAppWeather(false);
    // context.setAppSidebarNone(true);
    // context.setAppFooterFull(true);
    context.setAppContentClass("disaster-page");

    return function cleanUp() {
      // context.setThemePanel(true);
      // context.setAppHeaderNone(false);
      // context.setAppScrollButton(true);
      // context.setAppWeather(true);
      // context.setAppFooterFull(false);
      // context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };
    // eslint-disable-next-line
  }, [context]);

  // Filter
  const [city, setCity] = useState(null);
  const [cityValue, setCityValue] = useState({
    value: null,
    label: context?.t?.all,
  });

  const [district, setDistrict] = useState(null);
  const [districtLoader, setDistrictLoader] = useState(true);
  const [districtValue, setDistrictValue] = useState(null);
  const [allDistrict, setAllDistrict] = useState([]);

  const [neighborhoodValue, setNeighborhoodValue] = useState(null);
  const [neighborhoodLoader, setNeighborhoodLoader] = useState(true);
  const [allNeighborhood, setAllNeighborhood] = useState([]);

  const [streetValue, setStreetValue] = useState(null);
  const [streetLoader, setStreetLoader] = useState(true);
  const [allStreet, setAllStreet] = useState([]);

  const [allBuilding, setAllBuilding] = useState([]);
  const [buildingValue, setBuildingValue] = useState(null);
  const [buildingLoader, setBuildingLoader] = useState(true);

  const [allApartment, setAllApartment] = useState([]);
  const [apartmentValue, setApartmentValue] = useState(null);
  const [apartmentLoader, setApartmentLoader] = useState(true);

  // Filter Reset
  function resetNeighborhood() {
    setNeighborhoodValue(null);
    setNeighborhoodLoader(false);
    setTimeout(() => {
      setNeighborhoodLoader(true);
    }, 500);
  }

  function resetStreet() {
    setStreetValue(null);
    setStreetLoader(false);

    setTimeout(() => {
      setStreetLoader(true);
    }, 500);
  }

  // Filter Select
  function handleCity(e) {
    setCityValue(e);

    setDistrictLoader(false);
    setTimeout(() => {
      setDistrictLoader(true);
    }, 500);
    setDistrictValue(null);
    fetchDistrict(e.value);

    resetNeighborhood();
    fetchNeighborhood(null);

    resetStreet();
    fetchStreet(null);
    fetchBuilding(null);
    fetchApartment(null);
  }

  function handleDistrict(e) {
    setDistrictValue(e);

    resetNeighborhood();
    fetchNeighborhood(e.value && e.value !== "" ? e.value : null);

    resetStreet();
    fetchStreet(null);
    fetchBuilding(null);
    fetchApartment(null);
  }

  function handleNeighborhood(e) {
    setNeighborhoodValue(e);

    resetStreet();
    fetchStreet(e.value && e.value !== "" ? e.label : null);
    fetchBuilding(null);
    fetchApartment(null);
  }

  function handleStreet(e) {
    setStreetValue(e);

    fetchBuilding(e.value && e.value !== "" ? e.label : null);
    fetchApartment(null);
  }

  function handleBuilding(e) {
    setBuildingValue(e);

    fetchApartment(e.value && e.value !== "" ? e.label : null);
  }

  function handleApartment(e) {
    setApartmentValue(e);
  }

  // Fetchs
  async function fetchDistrict(value, name) {
    if (value) {
      const data = await context.getAllDistrict(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: context?.t?.choose_district,
        });

        setAllDistrict(newData);
      }
    }
  }

  async function fetchNeighborhood(value) {
    if (value) {
      const data = await context.getAllNeighborhood(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: context?.t?.choose_neighborhood,
        });

        setAllNeighborhood(newData);
      }
    } else {
      setAllNeighborhood([]);
    }
  }

  async function fetchStreet(value) {
    // if (value) {
    //   try {
    //     const response = await apiClient.customPost(
    //       `/query/preview`,
    //       {
    //         chart: 1,
    //         page_id: 23,
    //         title: "Bina Hane Sayısı",
    //         type: `bireysel-data-customer-${context?.me?.customer?.id}`,
    //         description: "",
    //         customer: null,
    //         status: true,
    //         order: 1,
    //         sql: {
    //           column: [{ key: "Adres Sokak", value: "select" }],
    //           condition: [
    //             {
    //               key: "Adres Il",
    //               value: context
    //                 .removeTurkishCharacters(cityValue?.label)
    //                 .toUpperCase(),
    //               operator: "=",
    //               type: "and",
    //             },
    //             {
    //               key: "Adres Ilce",
    //               value: context
    //                 .removeTurkishCharacters(districtValue?.label)
    //                 .toUpperCase(),
    //               operator: "=",
    //               type: "and",
    //             },
    //             {
    //               key: "Adres Mahalle",
    //               value: context.removeTurkishCharacters(value).toUpperCase(),
    //               operator: "=",
    //               type: "and",
    //             },
    //           ],
    //           group: [],
    //         },
    //       },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${localStorage.getItem("token")}`,
    //         },
    //       }
    //     );
    //     if (response.length !== 0) {
    //       const data = response
    //         .map((item) => {
    //           return {
    //             value: context
    //               .removeTurkishCharacters(item["adres sokak"])
    //               .toUpperCase(),
    //             label: context
    //               .removeTurkishCharacters(item["adres sokak"])
    //               .toUpperCase(),
    //           };
    //         })
    //         .sort((a, b) => a.label.localeCompare(b.label));
    //       const newData = data.slice();
    //       newData.unshift({
    //         value: "",
    //         label: context?.t?.choose_street,
    //       });
    //       setAllStreet(newData);
    //     } else {
    //       setAllStreet([]);
    //     }
    //   } catch (error) {
    //     setAllStreet(null);
    //   }
    // } else {
    //   setAllStreet([]);
    // }
  }

  async function fetchBuilding(value) {
    // setAllBuilding([]);
    // setBuildingValue(null);
    // setBuildingLoader(false);
    // setTimeout(async () => {
    //   setBuildingLoader(true);
    //   if (value) {
    //     try {
    //       const response = await apiClient.customPost(
    //         `/query/preview`,
    //         {
    //           chart: 1,
    //           page_id: 23,
    //           title: "Bina Hane Sayısı",
    //           type: `bireysel-data-customer-${context?.me?.customer?.id}`,
    //           description: "",
    //           customer: null,
    //           status: true,
    //           order: 1,
    //           sql: {
    //             column: [{ key: "Bina NO", value: "select" }],
    //             condition: [
    //               {
    //                 key: "Adres Il",
    //                 value: context
    //                   .removeTurkishCharacters(cityValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Adres Ilce",
    //                 value: context
    //                   .removeTurkishCharacters(districtValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Adres Mahalle",
    //                 value: context
    //                   .removeTurkishCharacters(neighborhoodValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Adres Sokak",
    //                 value: context.removeTurkishCharacters(value).toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //             ],
    //             group: [],
    //           },
    //         },
    //         {
    //           headers: {
    //             Authorization: `Bearer ${localStorage.getItem("token")}`,
    //           },
    //         }
    //       );
    //       if (response.length !== 0) {
    //         const data = response
    //           .map((item) => {
    //             return {
    //               value: context
    //                 .removeTurkishCharacters(item["bina no"])
    //                 .toUpperCase(),
    //               label: context
    //                 .removeTurkishCharacters(item["bina no"])
    //                 .toUpperCase(),
    //             };
    //           })
    //           .sort((a, b) => a.label.localeCompare(b.label));
    //         const newData = data.slice();
    //         newData.unshift({
    //           value: "",
    //           label: context?.t?.choose_building,
    //         });
    //         setAllBuilding(newData);
    //       } else {
    //         setAllBuilding([]);
    //       }
    //     } catch (error) {
    //       setAllBuilding(null);
    //     }
    //   } else {
    //     setAllBuilding([]);
    //   }
    // }, 500);
  }

  async function fetchApartment(value) {
    // setAllApartment([]);
    // setApartmentValue(null);
    // setApartmentLoader(false);
    // setTimeout(async () => {
    //   setApartmentLoader(true);
    //   if (value) {
    //     try {
    //       const response = await apiClient.customPost(
    //         `/query/preview`,
    //         {
    //           chart: 1,
    //           page_id: 23,
    //           title: "Bina Hane Sayısı",
    //           type: `bireysel-data-customer-${context?.me?.customer?.id}`,
    //           description: "",
    //           customer: null,
    //           status: true,
    //           order: 1,
    //           sql: {
    //             column: [{ key: "Daire", value: "select" }],
    //             condition: [
    //               {
    //                 key: "Adres Il",
    //                 value: context
    //                   .removeTurkishCharacters(cityValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Adres Ilce",
    //                 value: context
    //                   .removeTurkishCharacters(districtValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Adres Mahalle",
    //                 value: context
    //                   .removeTurkishCharacters(neighborhoodValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Adres Sokak",
    //                 value: context
    //                   .removeTurkishCharacters(streetValue?.label)
    //                   .toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //               {
    //                 key: "Bina NO",
    //                 value: context.removeTurkishCharacters(value).toUpperCase(),
    //                 operator: "=",
    //                 type: "and",
    //               },
    //             ],
    //             group: [],
    //           },
    //         },
    //         {
    //           headers: {
    //             Authorization: `Bearer ${localStorage.getItem("token")}`,
    //           },
    //         }
    //       );
    //       if (response.length !== 0) {
    //         const data = response
    //           .map((item) => {
    //             return {
    //               value: context
    //                 .removeTurkishCharacters(item["daire"])
    //                 .toUpperCase(),
    //               label: context
    //                 .removeTurkishCharacters(item["daire"])
    //                 .toUpperCase(),
    //             };
    //           })
    //           .sort((a, b) => a.label.localeCompare(b.label));
    //         const newData = data.slice();
    //         newData.unshift({
    //           value: "",
    //           label: context?.t?.choose_apartment,
    //         });
    //         setAllApartment(newData);
    //       } else {
    //         setAllApartment([]);
    //       }
    //     } catch (error) {
    //       setAllApartment(null);
    //     }
    //   } else {
    //     setAllApartment([]);
    //   }
    // }, 500);
  }

  // GET MAP
  const [showTable, setShowTable] = useState(true);

  const [earthquakeData, setEarthquakeData] = useState(null);
  const [selectedEarthquake, setSelectedEarthquake] = useState(null);
  const [filter, setFilter] = useState({
    mag_0_2_9: true,
    mag_3_3_9: true,
    mag_4_4_9: true,
    mag_5_5_9: true,
    mag_6_6_9: true,
    mag_7_7_9: true,
  });

  useEffect(() => {
    fetch("https://api.orhanaydogdu.com.tr/deprem/kandilli/live")
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.result.reduce((acc, current) => {
          // const existing = acc.find((item) => item.title === current.title);
          // if (!existing) {
          acc.push(current);
          // } else {
          //   const existingDate = new Date(existing.date);
          //   const currentDate = new Date(current.date);
          //   if (currentDate > existingDate) {
          //     acc = acc.filter((item) => item.title !== current.title);
          //     acc.push(current);
          //   }
          // }
          return acc;
        }, []);

        setEarthquakeData({ ...data, result: filteredData });
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleFilterChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.checked,
    });
  };

  const filterEarthquakes = () => {
    if (!earthquakeData) return [];

    const firstEarthquake = earthquakeData.result[0];

    const filteredEarthquakes = earthquakeData.result
      .slice(1)
      .filter((earthquake) => {
        const mag = earthquake.mag;

        if (filter.mag_0_2_9 && mag >= 0 && mag <= 2.9) return true;
        if (filter.mag_3_3_9 && mag >= 3 && mag <= 3.9) return true;
        if (filter.mag_4_4_9 && mag >= 4 && mag <= 4.9) return true;
        if (filter.mag_5_5_9 && mag >= 5 && mag <= 5.9) return true;
        if (filter.mag_6_6_9 && mag >= 6 && mag <= 6.9) return true;
        if (filter.mag_7_7_9 && mag >= 7 && mag <= 7.9) return true;

        return false;
      });

    return [firstEarthquake, ...filteredEarthquakes];
  };

  // Tesisler Data
  const [tesislerSelectData, setTesislerSelectData] = useState([]);
  const [tesislerSelectValue, setTesislerSelectValue] = useState({
    value: null,
    label: context?.t?.all,
  });

  const [tesislerData, setTesislerData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [tesisMarker, setTesisMarker] = useState(null);
  const [tesisMarkerSelected, setTesisMarkerSelected] = useState(null);
  const [allTesisMarkers, setAllTesisMarkers] = useState(null);

  const [filterCity, setFilterCity] = useState(null);
  const [filterDistrict, setFilterDistrict] = useState(null);
  const [filterNeighborhood, setFilterNeighborhood] = useState(null);

  async function handleLocationAllTesis() {
    setAllTesisMarkers(null);
    setTesisMarker(null);
    setTesisMarkerSelected(null);

    const payload = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `saglik-kurumlari`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      sql: {
        column: [
          {
            key: "Şehir",
            value: "select",
          },
          {
            key: "İlçe",
            value: "select",
          },
          {
            key: "Kurum Adı",
            value: "select",
          },
          {
            key: "Kategori",
            value: "select",
          },
          {
            key: "Adres",
            value: "select",
          },
          {
            key: "Enlem",
            value: "select",
          },
          {
            key: "Boylam",
            value: "select",
          },
          {
            key: "Telefon",
            value: "select",
          },
          {
            key: "Web Sitesi",
            value: "select",
          },
          {
            key: "Bölümler",
            value: "select",
          },
        ],
        condition: [
          filterCity
            ? {
                key: "Şehir",
                value: context
                  .removeTurkishCharacters(filterCity.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          filterDistrict
            ? {
                key: "İlçe",
                value: context
                  .removeTurkishCharacters(filterDistrict.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          tesislerSelectValue && tesislerSelectValue.value
            ? {
                key: "Kategori",
                value: tesislerSelectValue.value,
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(`/query/preview`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setAllTesisMarkers(response);
    } catch (error) {
      setAllTesisMarkers([]);
      console.error(error);
    }
  }

  async function fetchTesislerSelect(city, district, neighborhood) {
    setTesislerSelectData([]);

    const payload = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `saglik-kurumlari`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      sql: {
        column: [
          {
            key: "Kategori",
            value: "select",
          },
        ],
        condition: [
          city
            ? {
                key: "Şehir",
                value: context
                  .removeTurkishCharacters(city.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          district
            ? {
                key: "İlçe",
                value: context
                  .removeTurkishCharacters(district.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(`/query/preview`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setTesislerSelectData(
        response.length !== 0
          ? [
              {
                value: null,
                label: context?.t?.all,
              },
              ...response.map((item) => {
                return {
                  value: item["kategori"],
                  label: item["kategori"],
                };
              }),
            ]
          : []
      );
    } catch (error) {
      setTesislerSelectData([]);
      console.error(error);
    }
  }

  // Afet Dosyası
  const [afetGraphic1, setAfetGraphic1] = useState(null);
  const [afetGraphic2, setAfetGraphic2] = useState(null);
  const [afetGraphic3, setAfetGraphic3] = useState(null);
  const [afetGraphic4, setAfetGraphic4] = useState(null);
  const [afetGraphic5, setAfetGraphic5] = useState(null);
  const [afetGraphic6, setAfetGraphic6] = useState(null);

  const fetchAfetFile = useCallback(
    async (city, district, neighborhood) => {
      setAfetGraphic1(null);
      setAfetGraphic2(null);
      setAfetGraphic3(null);
      setAfetGraphic4(null);
      setAfetGraphic5(null);
      setAfetGraphic6(null);

      async function fetchAfetItem(category_1, category_2, graphicNumber) {
        try {
          const response = await apiClient.customPost(
            `/query/preview`,
            {
              chart: 1,
              page_id: 23,
              title: "-",
              type: `afet`,
              description: "",
              customer: null,
              status: true,
              order: 1,
              sql: {
                column: [
                  {
                    key: "Parametre",
                    value: "select",
                  },
                  {
                    key: "Deger",
                    value: "sum",
                  },
                ],
                condition: [
                  {
                    key: "Alt-Kategori-1",
                    value: category_1,
                    operator: "=",
                    type: "and",
                  },
                  {
                    key: "Alt-Kategori-2",
                    value: category_2,
                    operator: "=",
                    type: "and",
                  },
                  city
                    ? {
                        key: "Sehir",
                        value: city,
                        operator: "=",
                        type: "and",
                      }
                    : null,
                  district
                    ? {
                        key: "Ilce",
                        value: district,
                        operator: "=",
                        type: "and",
                      }
                    : null,
                  neighborhood
                    ? {
                        key: "Mahalle",
                        value: neighborhood,
                        operator: "=",
                        type: "and",
                      }
                    : null,
                ].filter((item) => item !== null),
                group: [],
              },
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.length !== 0) {
            const sortedResponse = response.sort(
              (a, b) => a.deger_sum - b.deger_sum
            );

            const chartOptionX = sortedResponse.map((item) => {
              return item.parametre;
            });
            const chartOptionY = sortedResponse.map((item) => {
              return item.deger_sum;
            });

            if (graphicNumber === 1) {
              setAfetGraphic1([chartOptionX, chartOptionY]);
            }
            if (graphicNumber === 2) {
              setAfetGraphic2(
                sortedResponse.map((item) => {
                  return { value: item.deger_sum, name: item.parametre };
                })
              );
            }
            if (graphicNumber === 3) {
              setAfetGraphic3([chartOptionX, chartOptionY]);
            }
            if (graphicNumber === 4) {
              setAfetGraphic4(
                sortedResponse.map((item) => {
                  return { value: item.deger_sum, name: item.parametre };
                })
              );
            }
            if (graphicNumber === 5) {
              setAfetGraphic5([chartOptionX, chartOptionY]);
            }
            if (graphicNumber === 6) {
              setAfetGraphic6(
                sortedResponse.map((item) => {
                  return { value: item.deger_sum, name: item.parametre };
                })
              );
            }
          } else {
            if (graphicNumber === 1) {
              setAfetGraphic1([[], []]);
            }
            if (graphicNumber === 2) {
              setAfetGraphic2([]);
            }
            if (graphicNumber === 3) {
              setAfetGraphic3([[], []]);
            }
            if (graphicNumber === 4) {
              setAfetGraphic4([]);
            }
            if (graphicNumber === 5) {
              setAfetGraphic5([[], []]);
            }
            if (graphicNumber === 6) {
              setAfetGraphic6([]);
            }
          }
        } catch (error) {
          console.error(error);
          if (graphicNumber === 1) {
            setAfetGraphic1([[], []]);
          }
          if (graphicNumber === 2) {
            setAfetGraphic2([]);
          }
          if (graphicNumber === 3) {
            setAfetGraphic3([[], []]);
          }
          if (graphicNumber === 4) {
            setAfetGraphic4([]);
          }
          if (graphicNumber === 5) {
            setAfetGraphic5([[], []]);
          }
          if (graphicNumber === 6) {
            setAfetGraphic6([]);
          }
        }
      }

      fetchAfetItem("DEPREM", "RISK ARASTIRMASI", 1);
      fetchAfetItem("DEPREM", "DIRI FAY HATTI YAKINLIGI", 2);
      fetchAfetItem("DEPREM", "DEPREM RISK DERECE SEVIYESI", 3);
      fetchAfetItem(
        "YERLESIM YERI ENVANTERI",
        "YAPIM YILINA GORE BINA SAYISI",
        4
      );
      fetchAfetItem(
        "YERLESIM YERI ENVANTERI",
        "KAT SAYISINA GORE BINA SAYISI",
        5
      );
      fetchAfetItem(
        "YERLESIM YERI ENVANTERI",
        "YAPIM TURUNE GORE BINA SAYISI",
        6
      );
    },
    [context?.chartColor]
  );

  async function fetchTesisler(
    page = 1,
    city = null,
    district = null,
    neighborhood = null,
    filter = null
  ) {
    setTesislerData(null);

    setTesisMarker(null);
    setTesisMarkerSelected(null);
    setAllTesisMarkers(null);

    const payload = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `saglik-kurumlari`,
      description: "",
      customer: null,
      status: true,
      paginate: true,
      order: 1,
      sql: {
        column: [
          {
            key: "Şehir",
            value: "select",
          },
          {
            key: "İlçe",
            value: "select",
          },
          {
            key: "Kurum Adı",
            value: "select",
          },
          {
            key: "Kategori",
            value: "select",
          },
          {
            key: "Adres",
            value: "select",
          },
          {
            key: "Enlem",
            value: "select",
          },
          {
            key: "Boylam",
            value: "select",
          },
          {
            key: "Telefon",
            value: "select",
          },
          {
            key: "Web Sitesi",
            value: "select",
          },
          {
            key: "Bölümler",
            value: "select",
          },
        ],
        condition: [
          city
            ? {
                key: "Şehir",
                value: context
                  .removeTurkishCharacters(city.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          district
            ? {
                key: "İlçe",
                value: context
                  .removeTurkishCharacters(district.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          filter && filter.value
            ? {
                key: "Kategori",
                value: filter.value,
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(
        `/query/preview?page=${page}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setTesislerData(response);
    } catch (error) {
      setTesislerData([]);
      console.error(error);
    }
  }

  async function handleData(city, district, neighborhood) {
    setCityValue({
      value: null,
      label: context?.t?.all,
    });
    setDistrictValue(null);
    setNeighborhoodValue(null);

    setFilterCity(city && city.value !== "" ? city : null);
    setFilterDistrict(district && district.value !== "" ? district : null);
    setFilterNeighborhood(
      neighborhood && neighborhood.value !== "" ? neighborhood : null
    );

    setCurrentPage(1);
    fetchTesislerSelect(null, null, null);
    setTesislerSelectValue({
      value: null,
      label: context?.t?.all,
    });

    fetchTesisler(
      1,
      city && city.value !== "" ? city : null,
      district && district.value !== "" ? district : null,
      neighborhood && neighborhood.value !== "" ? neighborhood : null,
      null
    );

    fetchAfetFile(
      city && city.value !== "" ? city : null,
      district && district.value !== "" ? district : null,
      neighborhood && neighborhood.value !== "" ? neighborhood : null
    );
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchTesisler(
      page,
      filterCity,
      filterDistrict,
      filterNeighborhood,
      tesislerSelectValue
    );
  };

  const handleTesislerSelect = (e) => {
    setCurrentPage(1);

    setTesislerSelectValue(e);
    fetchTesisler(1, filterCity, filterDistrict, filterNeighborhood, e);
    fetchTesislerSelect(filterCity, filterDistrict, filterNeighborhood);
  };

  useEffect(() => {
    fetchTesisler();
    fetchTesislerSelect(null, null, null);
    fetchAfetFile(null, null, null);
  }, []);

  // Demografik Analiz
  const currentYear = new Date().getFullYear();

  const [ages, setAges] = useState(null);
  const [dayCount, setDayCount] = useState(1);
  const [ihtiyacTablosu, setIhtiyacTablosu] = useState(null);

  const fetchAges = useCallback(
    async (city, district, neighborhood, street, building, daire) => {
      setAges(null);

      try {
        // API isteği
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 23,
            title: "-",
            type: `bireysel-data-customer-${context?.me?.customer?.id}`,
            description: "",
            customer: null,
            status: true,
            order: 1,
            sql: {
              column: [],
              condition: [
                city
                  ? {
                      key: "Adres Il",
                      value: context
                        .removeTurkishCharacters(city.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                district
                  ? {
                      key: "Adres Ilce",
                      value: context
                        .removeTurkishCharacters(district.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                neighborhood
                  ? {
                      key: "Adres Mahalle",
                      value: context
                        .removeTurkishCharacters(neighborhood.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                street
                  ? {
                      key: "Adres Sokak",
                      value: context
                        .removeTurkishCharacters(street.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                building
                  ? {
                      key: "Bina NO",
                      value: context
                        .removeTurkishCharacters(building.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                daire
                  ? {
                      key: "Daire",
                      value: context
                        .removeTurkishCharacters(daire.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                {
                  key: "Dogum_Tarihi_toYear",
                  value: (currentYear - 65).toString(), // En düşük yıl 65 yaş için
                  operator: ">=",
                  type: "and",
                },
                {
                  key: "Dogum_Tarihi_toYear",
                  value: currentYear.toString(), // En yüksek yıl bugünkü yıl
                  operator: "<=",
                  type: "and",
                },
              ].filter((item) => item !== null),
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.length !== 0) {
          // Gelen datayı al ve parse et
          const data = response.map((item) => JSON.parse(item.data));

          const daireler = data
            .map((person) => person["Daire"])
            .filter((value) => value && value.trim() !== "")
            .filter(
              (value, index, self) => self.indexOf(value) === index
            ).length;

          // Yaş ve cinsiyet filtrelemeleri
          const ages0To4 = data.filter((person) => {
            const birthYear = new Date(person["Dogum Tarihi"]).getFullYear();
            const age = currentYear - birthYear;
            return age >= 0 && age <= 4;
          }).length;

          const ages5To9 = data.filter((person) => {
            const birthYear = new Date(person["Dogum Tarihi"]).getFullYear();
            const age = currentYear - birthYear;
            return age >= 5 && age <= 9;
          }).length;

          const ages10To65Female = data.filter((person) => {
            const birthYear = new Date(person["Dogum Tarihi"]).getFullYear();
            const age = currentYear - birthYear;
            return age >= 10 && age <= 65 && person["Cinsiyet"] === "K";
          }).length;

          const ages10To65Male = data.filter((person) => {
            const birthYear = new Date(person["Dogum Tarihi"]).getFullYear();
            const age = currentYear - birthYear;
            return age >= 10 && age <= 65 && person["Cinsiyet"] === "E";
          }).length;

          const ages65PlusFemale = data.filter((person) => {
            const birthYear = new Date(person["Dogum Tarihi"]).getFullYear();
            const age = currentYear - birthYear;
            return age > 65 && person["Cinsiyet"] === "K";
          }).length;

          const ages65PlusMale = data.filter((person) => {
            const birthYear = new Date(person["Dogum Tarihi"]).getFullYear();
            const age = currentYear - birthYear;
            return age > 65 && person["Cinsiyet"] === "E";
          }).length;

          setAges({
            data: true,
            "0_4": ages0To4,
            "5_9": ages5To9,
            "10_65_female": ages10To65Female,
            "10_65_male": ages10To65Male,
            "65_female": ages65PlusFemale,
            "65_male": ages65PlusMale,
            hane: daireler,
          });
        } else {
          setAges({
            data: false,
          });
        }
      } catch (error) {
        console.error(error);
        setAges(null);
      }
    },
    []
  );

  useEffect(() => {
    fetchAges();
  }, [fetchAges]);

  const fetchIhtiyactablosu = useCallback(async () => {
    setIhtiyacTablosu(null);

    try {
      // API isteği
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type: `ihtiyac-tablosu`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "0_4",
                value: "select",
              },
              {
                key: "5_9",
                value: "select",
              },
              {
                key: "10_65_kadin",
                value: "select",
              },
              {
                key: "10_65_erkek",
                value: "select",
              },
              {
                key: "65_kadin",
                value: "select",
              },
              {
                key: "65_erkek",
                value: "select",
              },
              {
                key: "hane",
                value: "select",
              },
              {
                key: "tur",
                value: "select",
              },
              {
                key: "icerik",
                value: "select",
              },
              {
                key: "birim",
                value: "select",
              },
            ],
            condition: [],
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setIhtiyacTablosu(response.sort((a, b) => a.tur.localeCompare(b.tur)));
    } catch (error) {
      console.error(error);
      setIhtiyacTablosu(null);
    }
  }, []);

  useEffect(() => {
    fetchIhtiyactablosu();
  }, [fetchIhtiyactablosu]);

  return (
    (context?.me?.role?.id === 1 ||
      context?.me?.role?.id === 2 ||
      context?.me?.role?.id === 4) && (
      <div className="disaster-container mb-5 pb-5 mx-3 text-center">
        <div style={{ maxWidth: 1500 }} className="mx-auto">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h3>{context?.t?.disaster_management}</h3>

            {/* <button
              onClick={() => handleBack()}
              className="btn btn-danger mb-3"
            >
              {context?.t?.go_back}
            </button> */}
          </div>

          <Card>
            <CardBody>
              <div className="row align-items-end text-start g-2">
                <div className="col">
                  <span className="d-block">{context?.t?.city}</span>
                  {context.allCity ? (
                    <Select
                      options={[
                        { value: null, label: context?.t?.all },
                        ...context.allCity,
                      ]}
                      value={
                        cityValue
                          ? cityValue
                          : city &&
                            context.allCity.find(function (item) {
                              return item.value === city;
                            })
                      }
                      classNamePrefix="react-select"
                      placeholder={context?.t?.choose_city}
                      noOptionsMessage={() => context?.t?.no_option}
                      onChange={(e) => handleCity(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col">
                  <span className="d-block">{context?.t?.district}</span>
                  {districtLoader ? (
                    <Select
                      options={allDistrict}
                      value={districtValue}
                      classNamePrefix="react-select"
                      placeholder={context?.t?.choose_district}
                      noOptionsMessage={() => context?.t?.no_option}
                      onChange={(e) => handleDistrict(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col">
                  <span className="d-block">{context?.t?.neighborhood}</span>
                  {neighborhoodLoader ? (
                    <Select
                      options={allNeighborhood}
                      value={neighborhoodValue ? neighborhoodValue : ""}
                      classNamePrefix="react-select"
                      placeholder={context?.t?.choose_neighborhood}
                      noOptionsMessage={() => context?.t?.no_option}
                      onChange={(e) => handleNeighborhood(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                {/* <div className="col">
                  <span className="d-block">{context?.t?.street}</span>
                  {streetLoader ? (
                    <Select
                      options={allStreet}
                      value={streetValue ? streetValue : ""}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      placeholder={context?.t?.choose_street}
                      onChange={handleStreet}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col">
                  <span className="d-block">{context?.t?.building}</span>
                  {buildingLoader ? (
                    <Select
                      options={allBuilding}
                      value={buildingValue ? buildingValue : ""}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      placeholder={context?.t?.choose_building}
                      onChange={handleBuilding}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col">
                  <span className="d-block">{context?.t?.apartment}</span>
                  {apartmentLoader ? (
                    <Select
                      options={allApartment}
                      value={apartmentValue ? apartmentValue : ""}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      placeholder={context?.t?.choose_apartment}
                      onChange={handleApartment}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div> */}
                <div className="col flex-none w-auto">
                  <button
                    type="button"
                    className="btn btn-outline-theme px-3 me-2"
                    onClick={() => {
                      handleData(null, null, null);
                      fetchAges(null, null, null, null, null, null);
                    }}
                  >
                    {context?.t?.all}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-theme px-4"
                    onClick={() => {
                      handleData(cityValue, districtValue, neighborhoodValue);
                      fetchAges(
                        cityValue && cityValue.value !== "" ? cityValue : null,
                        districtValue && districtValue.value !== ""
                          ? districtValue
                          : null,
                        neighborhoodValue && neighborhoodValue.value !== ""
                          ? neighborhoodValue
                          : null,
                        streetValue && streetValue.value !== ""
                          ? streetValue
                          : null,
                        buildingValue && buildingValue.value !== ""
                          ? buildingValue
                          : null,
                        apartmentValue && apartmentValue.value !== ""
                          ? apartmentValue
                          : null
                      );
                    }}
                  >
                    {context?.t?.find}
                  </button>
                </div>
              </div>

              <hr />

              {earthquakeData ? (
                <>
                  <div className="mt-3 position-relative">
                    <div className="d-flex gap-2 justify-content-center mb-3">
                      {earthquakeData.metadata.date_starts && (
                        <span className="d-block">
                          {context?.t?.started_date} <br />
                          <strong>
                            {moment(
                              earthquakeData.metadata.date_starts,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD MMMM YYYY HH:mm:ss")}
                          </strong>
                        </span>
                      )}

                      {earthquakeData.metadata.date_ends && (
                        <span className="d-block border-start ps-3 ms-3">
                          {context?.t?.end_date} <br />
                          <strong>
                            {moment(
                              earthquakeData.metadata.date_ends,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD MMMM YYYY HH:mm:ss")}
                          </strong>
                        </span>
                      )}

                      {earthquakeData.metadata.total && (
                        <span className="d-block border-start ps-3 ms-3">
                          {context?.t?.total} <br />{" "}
                          <strong>{earthquakeData.metadata.total}</strong>
                        </span>
                      )}
                    </div>

                    <div
                      className="d-flex flex-column gap-1 position-absolute left-0 bottom-0 mx-3 my-2"
                      style={{ zIndex: 1 }}
                    >
                      <div className="d-flex flex-column gap-1 bg-white text-black rounded px-3 py-2 text-start">
                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_0_2_9"
                              id="mag_0_2_9"
                              checked={filter.mag_0_2_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_0_2_9"
                              className="form-check-label"
                            >
                              M≥0 M≤2.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#FF0000" }}
                            className="rounded ms-2 px-2 flex-1 text-center text-white"
                          >
                            d≥0 d≤5
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_3_3_9"
                              id="mag_3_3_9"
                              checked={filter.mag_3_3_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_3_3_9"
                              className="form-check-label"
                            >
                              M≥3 M≤3.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#FF6666" }}
                            className="rounded ms-2 px-2 flex-1 text-center"
                          >
                            d{">"}5 d≤10{" "}
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_4_4_9"
                              id="mag_4_4_9"
                              checked={filter.mag_4_4_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_4_4_9"
                              className="form-check-label"
                            >
                              M≥4 M≤4.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#FFA500" }}
                            className="rounded ms-2 px-2 flex-1 text-center"
                          >
                            d{">"}10 d≤20
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_5_5_9"
                              id="mag_5_5_9"
                              checked={filter.mag_5_5_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_5_5_9"
                              className="form-check-label"
                            >
                              M≥5 M≤5.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#FFFF00" }}
                            className="rounded ms-2 px-2 flex-1 text-center"
                          >
                            d{">"}20 d≤40
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_6_6_9"
                              id="mag_6_6_9"
                              checked={filter.mag_6_6_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_6_6_9"
                              className="form-check-label"
                            >
                              M≥6 M≤6.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#008000" }}
                            className="rounded ms-2 px-2 flex-1 text-center text-white"
                          >
                            d{">"}40 d≤80
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_7_7_9"
                              id="mag_7_7_9"
                              checked={filter.mag_7_7_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_7_7_9"
                              className="form-check-label"
                            >
                              M≥7 M≤7.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#0000FF" }}
                            className="rounded ms-2 px-2 flex-1 text-center text-white"
                          >
                            d{">"}80 d≤150
                          </div>
                        </div>
                      </div>
                      {/* <button
                        type="button"
                        className="btn btn-dark"
                        onClick={() => setShowTable(!showTable)}
                      >
                        Tablo {showTable ? "Gizle" : "Göster"}
                      </button> */}
                    </div>

                    <GoogleMap
                      mapContainerStyle={{
                        height: 500,
                        width: "100%",
                      }}
                      options={options}
                    >
                      {allTesisMarkers && allTesisMarkers.length > 0 && (
                        <MarkerClusterer
                          options={{
                            maxZoom: 20, // Set this to control when clusters should stop grouping markers
                            styles: [
                              {
                                textColor: "white", // Set the cluster text color to white
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
                                height: 50,
                                width: 50,
                              },
                              {
                                textColor: "white",
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png",
                                height: 56,
                                width: 56,
                              },
                              {
                                textColor: "white",
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png",
                                height: 66,
                                width: 66,
                              },
                            ],
                          }}
                        >
                          {(clusterer) =>
                            allTesisMarkers.map((item, index) => (
                              <Marker
                                key={index}
                                clusterer={clusterer}
                                position={{
                                  lat: parseFloat(item.enlem.replace(",", ".")),
                                  lng: parseFloat(
                                    item.boylam.replace(",", ".")
                                  ),
                                }}
                                onClick={() => setTesisMarkerSelected(item)}
                              />
                            ))
                          }
                        </MarkerClusterer>
                      )}

                      {tesisMarker && (
                        <Marker
                          position={{
                            lat: parseFloat(
                              tesisMarker.enlem.replace(",", ".")
                            ),
                            lng: parseFloat(
                              tesisMarker.boylam.replace(",", ".")
                            ),
                          }}
                          onClick={() => setTesisMarkerSelected(tesisMarker)}
                        />
                      )}
                      {tesisMarkerSelected && (
                        <InfoWindow
                          position={{
                            lat: parseFloat(
                              tesisMarkerSelected.enlem.replace(",", ".")
                            ),
                            lng: parseFloat(
                              tesisMarkerSelected.boylam.replace(",", ".")
                            ),
                          }}
                          onCloseClick={() => setTesisMarkerSelected(null)}
                        >
                          <div className="text-start">
                            <strong>{context?.t?.kurum_adi}:</strong>{" "}
                            {tesisMarkerSelected["kurum adı"]}
                            <br />
                            <strong>{context?.t?.address}:</strong>{" "}
                            {tesisMarkerSelected["adres"]}
                            <br />
                            <strong>{context?.t?.phone}:</strong>{" "}
                            {tesisMarkerSelected["telefon"]}
                            <br />
                            <strong>{context?.t?.web_site}:</strong>{" "}
                            {tesisMarkerSelected["web sitesi"] ? (
                              <a
                                href={tesisMarkerSelected["web sitesi"]}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-black"
                              >
                                {context?.t?.view}
                              </a>
                            ) : (
                              ""
                            )}
                            <br />
                            <strong>{context?.t?.city}:</strong>{" "}
                            {tesisMarkerSelected["şehir"]}
                            <br />
                            <strong>{context?.t?.district}:</strong>{" "}
                            {tesisMarkerSelected["i̇lçe"]}
                            <br />
                            <strong>{context?.t?.category}:</strong>{" "}
                            {tesisMarkerSelected["kategori"]}
                            <br />
                            <strong>{context?.t?.bolumler}:</strong>{" "}
                            <div>
                              {tesisMarkerSelected["bölümler"] ? (
                                <div className="d-flex flex-wrap gap-1">
                                  {tesisMarkerSelected["bölümler"]
                                    .split(",")
                                    .map((item) => (
                                      <span className="btn btn-sm btn-dark">
                                        {item}
                                      </span>
                                    ))}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </InfoWindow>
                      )}

                      {earthquakeData.result.map(
                        (earthquake, index) =>
                          index === 0 && (
                            <Marker
                              key={earthquake._id + "_live"}
                              position={{
                                lat: earthquake.geojson.coordinates[1],
                                lng: earthquake.geojson.coordinates[0],
                              }}
                              icon={{
                                url: "http://www.koeri.boun.edu.tr/sismo/zeqmap/images/neweq0.gif",
                                scaledSize: new window.google.maps.Size(50, 50),
                                anchor: new window.google.maps.Point(25, 25),
                              }}
                            />
                          )
                      )}

                      {filterEarthquakes().map((earthquake, index) => (
                        <Marker
                          key={earthquake._id}
                          position={{
                            lat: earthquake.geojson.coordinates[1],
                            lng: earthquake.geojson.coordinates[0],
                          }}
                          icon={getMarkerStyle(
                            earthquake.mag,
                            earthquake.depth
                          )}
                          onClick={() => setSelectedEarthquake(earthquake)}
                        />
                      ))}

                      {selectedEarthquake && (
                        <InfoWindow
                          position={{
                            lat: selectedEarthquake.geojson.coordinates[1],
                            lng: selectedEarthquake.geojson.coordinates[0],
                          }}
                          onCloseClick={() => setSelectedEarthquake(null)}
                        >
                          <div
                            className="text-start overflow-auto"
                            style={{ height: 300 }}
                          >
                            <strong>{context?.t?.title}:</strong>{" "}
                            {selectedEarthquake.title} <br />
                            <strong>{context?.t?.date}:</strong>{" "}
                            {selectedEarthquake.date} <br />
                            <strong>{context?.t?.size}:</strong>{" "}
                            {selectedEarthquake.mag} <br />
                            <strong>{context?.t?.depth}:</strong>{" "}
                            {selectedEarthquake.depth} km <br />
                            <strong>{context?.t?.closestCities}:</strong>
                            {selectedEarthquake.location_properties
                              .closestCities.length !== 0 &&
                              selectedEarthquake.location_properties.closestCities.map(
                                (item, index) => (
                                  <div
                                    key={index}
                                    className="border-top border-black mt-1 pt-1"
                                  >
                                    <strong>{context?.t?.city}:</strong>{" "}
                                    {item.name}
                                    <br />
                                    <strong>
                                      {context?.t?.population}:
                                    </strong>{" "}
                                    {item.population}
                                    <br />
                                    <strong>
                                      {context?.t?.distance}:
                                    </strong>{" "}
                                    {item.distance.toFixed(2)} m
                                  </div>
                                )
                              )}
                          </div>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  </div>

                  {showTable && (
                    <div className="dataTables_wrapper dt-bootstrap5">
                      <div className="dataTables_scroll">
                        <div
                          className="dataTables_scrollBody table-responsive"
                          style={{ height: 300 }}
                        >
                          <table className="table text-nowrap w-100 dataTable mb-0 text-start">
                            <thead>
                              <tr>
                                <th className="px-2">
                                  {context?.t?.time_of_creation}
                                </th>
                                <th className="px-2">{context?.t?.lat}</th>
                                <th className="px-2">{context?.t?.lng}</th>
                                <th className="px-2">{context?.t?.depth}</th>
                                <th className="px-2">{context?.t?.size}</th>
                                <th className="px-2">{context?.t?.place}</th>
                              </tr>
                            </thead>

                            <tbody>
                              {filterEarthquakes().map((earthquake, index) => (
                                <tr key={index}>
                                  <td>{earthquake.date_time}</td>
                                  <td>{earthquake.geojson.coordinates[0]}</td>
                                  <td>{earthquake.geojson.coordinates[1]}</td>
                                  <td>{earthquake.depth}</td>
                                  <td>{earthquake.mag}</td>
                                  <td>{earthquake.title}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="spinner-border text-theme"></div>
              )}

              {ages && <hr />}

              {ages && (
                <div className="mt-3 text-start">
                  <div className="mb-3 row align-items-center justify-content-between">
                    <div className="col-6">
                      <div className="d-flex align-items-center gap-2">
                        <h4 className="mb-0">
                          {context?.t?.requirements_table}
                        </h4>
                        <h5 className="mb-0">
                          <span> / {context?.t?.total_population}: </span>
                          {parseInt(
                            Math.ceil(
                              ages["0_4"] +
                                ages["5_9"] +
                                ages["10_65_female"] +
                                ages["10_65_male"] +
                                ages["65_female"] +
                                ages["65_male"] +
                                ages["hane"]
                            )
                          ).toLocaleString("tr-TR")}
                        </h5>
                      </div>
                    </div>

                    <div className="col w-auto flex-none">
                      <div className="d-flex align-items-end gap-2">
                        <div
                          className="w-100"
                          style={{ minWidth: 150, maxWidth: 150 }}
                        >
                          <span className="d-block">
                            {context?.t?.how_many_days_required}
                          </span>
                          <input
                            type="number"
                            className="form-control"
                            value={dayCount}
                            onChange={(e) => setDayCount(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {ihtiyacTablosu ? (
                    ages.data ? (
                      <div className="dataTables_wrapper dt-bootstrap5">
                        <div className="dataTables_scroll">
                          <div
                            className="dataTables_scrollBody table-responsive"
                            style={{ height: 400 }}
                          >
                            <table className="table text-nowrap w-100 dataTable mb-0 text-start">
                              <thead>
                                <tr>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    {context?.t?.category}
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    {context?.t?.total_requirement}
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    {context?.t?.unit}
                                  </th>

                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    {context?.t?.contents}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {ihtiyacTablosu.map((item, index) => (
                                  <tr key={index}>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item.tur}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {(
                                        parseInt(
                                          Math.ceil(
                                            ages["0_4"] *
                                              parseFloat(
                                                item["0_4"].replace(",", ".")
                                              ) +
                                              ages["5_9"] *
                                                parseFloat(
                                                  item["5_9"].replace(",", ".")
                                                ) +
                                              ages["10_65_female"] *
                                                parseFloat(
                                                  item["10_65_kadin"].replace(
                                                    ",",
                                                    "."
                                                  )
                                                ) +
                                              ages["10_65_male"] *
                                                parseFloat(
                                                  item["10_65_erkek"].replace(
                                                    ",",
                                                    "."
                                                  )
                                                ) +
                                              ages["65_female"] *
                                                parseFloat(
                                                  item["65_kadin"].replace(
                                                    ",",
                                                    "."
                                                  )
                                                ) +
                                              ages["65_male"] *
                                                parseFloat(
                                                  item["65_erkek"].replace(
                                                    ",",
                                                    "."
                                                  )
                                                ) +
                                              ages["hane"] *
                                                parseFloat(
                                                  item["hane"].replace(",", ".")
                                                )
                                          )
                                        ) * dayCount
                                      ).toLocaleString("tr-TR")}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item.birim}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item.icerik}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span>{context?.t?.analysis_data_not_added}</span>
                    )
                  ) : (
                    <div className="spinner-border text-theme"></div>
                  )}
                </div>
              )}

              <hr />

              <h5 className="mb-3">{context?.t?.deprem}</h5>

              <div style={{ height: 600 }}>
                <div className="row h-100 overflow-hidden">
                  <div className="col-12 col-md-4 h-100">
                    <DynamicCard
                      height="h-full"
                      fullScreen={true}
                      events={false}
                      data={afetGraphic1 && afetGraphic1[0]}
                      item={{
                        title: context?.t?.risk_arastirmasi,
                        options: {
                          options: {
                            grid: {
                              bottom: "20%",
                            },
                            xAxis: [
                              {
                                data: afetGraphic1 && afetGraphic1[0],
                                type: "category",
                                axisLabel: {
                                  rotate: 45,
                                  textStyle: {
                                    color: "#fff",
                                    fontSize: 10,
                                  },
                                },
                              },
                            ],
                            yAxis: [
                              {
                                type: "value",
                                axisLabel: {
                                  textStyle: {
                                    color: "#fff",
                                  },
                                },
                              },
                            ],
                            series: [
                              {
                                data: afetGraphic1 && afetGraphic1[1],
                                name: null,
                                type: "bar",
                                itemStyle: {
                                  color: context?.chartColor,
                                },
                                markPoint: {
                                  data: [
                                    {
                                      name: "Max",
                                      type: "max",
                                    },
                                    {
                                      name: "Min",
                                      type: "min",
                                    },
                                  ],
                                },
                              },
                            ],
                            toolbox: {
                              show: true,
                              feature: {
                                restore: {
                                  show: false,
                                },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar"],
                                },
                                saveAsImage: {
                                  show: false,
                                },
                              },
                              iconStyle: {
                                borderColor: "#fff",
                              },
                            },
                            tooltip: {
                              trigger: "axis",
                            },
                            darkMode: true,
                            visualMap: context?.chartColor
                              ? {
                                  max:
                                    afetGraphic1 && afetGraphic1[1]
                                      ? Math.max(
                                          ...afetGraphic1[1].map((item) => item)
                                        )
                                      : 0,

                                  min: 0,
                                  show: false,
                                  inRange: {
                                    colorLightness: [0.2, 1],
                                  },
                                }
                              : false,
                            calculable: true,
                          },
                        },
                      }}
                    />
                  </div>

                  <div className="col-12 col-md-4 h-100">
                    <DynamicCard
                      height="h-full"
                      fullScreen={true}
                      events={false}
                      data={afetGraphic2}
                      item={{
                        title: context?.t?.diri_fay_hatti_yakinligi,
                        options: {
                          options: {
                            series: [
                              {
                                data: afetGraphic2,
                                name: null,
                                type: "pie",
                                label: {
                                  textStyle: {
                                    fontSize: 15,
                                  },
                                },
                                radius: "50%",
                                itemStyle: {
                                  color: context?.chartColor,
                                },
                              },
                            ],
                            tooltip: {
                              trigger: "item",
                            },
                            darkMode: true,
                            legend: {
                              show: true,
                              orient: "horizontal",
                              left: "center",
                              itemGap: 20,
                              textStyle: {
                                color: "#fff",
                              },
                            },
                            visualMap: context?.chartColor
                              ? {
                                  min:
                                    afetGraphic2 && afetGraphic2.length !== 0
                                      ? Math.min(
                                          ...afetGraphic2.map(
                                            (obj) => obj.value
                                          )
                                        )
                                      : 0,
                                  max:
                                    afetGraphic2 && afetGraphic2.length !== 0
                                      ? Math.max(
                                          ...afetGraphic2.map(
                                            (obj) => obj.value
                                          )
                                        )
                                      : 0,
                                  show: false,
                                  inRange: {
                                    colorLightness: [0.2, 1],
                                  },
                                }
                              : false,
                          },
                        },
                      }}
                    />
                  </div>

                  <div className="col-12 col-md-4 h-100">
                    <DynamicCard
                      height="h-full"
                      fullScreen={true}
                      events={false}
                      data={afetGraphic3 && afetGraphic3[0]}
                      item={{
                        title: context?.t?.deprem_risk_derece_seviyesi,
                        options: {
                          options: {
                            grid: {
                              bottom: "20%",
                            },
                            xAxis: [
                              {
                                data: afetGraphic3 && afetGraphic3[0],
                                type: "category",
                                axisLabel: {
                                  rotate: 45,
                                  textStyle: {
                                    color: "#fff",
                                    fontSize: 10,
                                  },
                                },
                              },
                            ],
                            yAxis: [
                              {
                                type: "value",
                                axisLabel: {
                                  textStyle: {
                                    color: "#fff",
                                  },
                                },
                              },
                            ],
                            series: [
                              {
                                data: afetGraphic3 && afetGraphic3[1],
                                name: null,
                                type: "bar",
                                itemStyle: {
                                  color: context?.chartColor,
                                },
                                markPoint: {
                                  data: [
                                    {
                                      name: "Max",
                                      type: "max",
                                    },
                                    {
                                      name: "Min",
                                      type: "min",
                                    },
                                  ],
                                },
                              },
                            ],
                            toolbox: {
                              show: true,
                              feature: {
                                restore: {
                                  show: false,
                                },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar"],
                                },
                                saveAsImage: {
                                  show: false,
                                },
                              },
                              iconStyle: {
                                borderColor: "#fff",
                              },
                            },
                            tooltip: {
                              trigger: "axis",
                            },
                            darkMode: true,
                            visualMap: context?.chartColor
                              ? {
                                  max:
                                    afetGraphic3 && afetGraphic3[1]
                                      ? Math.max(
                                          ...afetGraphic3[1].map((item) => item)
                                        )
                                      : 0,

                                  min: 0,
                                  show: false,
                                  inRange: {
                                    colorLightness: [0.2, 1],
                                  },
                                }
                              : false,
                            calculable: true,
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

              <hr />

              <h5 className="mb-3">{context?.t?.yerlesim_yeri_envanteri}</h5>

              <div style={{ height: 600 }}>
                <div className="row h-100 overflow-hidden">
                  <div className="col-12 col-md-4 h-100">
                    <DynamicCard
                      height="h-full"
                      fullScreen={true}
                      events={false}
                      data={afetGraphic4}
                      item={{
                        title: context?.t?.yapim_yilina_gore_bina_sayisi,
                        options: {
                          options: {
                            series: [
                              {
                                data: afetGraphic4,
                                name: null,
                                type: "pie",
                                label: {
                                  textStyle: {
                                    fontSize: 15,
                                  },
                                },
                                radius: "50%",
                                itemStyle: {
                                  color: context?.chartColor,
                                },
                              },
                            ],
                            tooltip: {
                              trigger: "item",
                            },
                            darkMode: true,
                            legend: {
                              show: true,
                              orient: "horizontal",
                              left: "center",
                              itemGap: 20,
                              textStyle: {
                                color: "#fff",
                              },
                            },
                            visualMap: context?.chartColor
                              ? {
                                  min:
                                    afetGraphic4 && afetGraphic4.length !== 0
                                      ? Math.min(
                                          ...afetGraphic4.map(
                                            (obj) => obj.value
                                          )
                                        )
                                      : 0,
                                  max:
                                    afetGraphic4 && afetGraphic4.length !== 0
                                      ? Math.max(
                                          ...afetGraphic4.map(
                                            (obj) => obj.value
                                          )
                                        )
                                      : 0,
                                  show: false,
                                  inRange: {
                                    colorLightness: [0.2, 1],
                                  },
                                }
                              : false,
                          },
                        },
                      }}
                    />
                  </div>

                  <div className="col-12 col-md-4 h-100">
                    <DynamicCard
                      height="h-full"
                      fullScreen={true}
                      events={false}
                      data={afetGraphic5 && afetGraphic5[0]}
                      item={{
                        title: context?.t?.kat_sayisina_gore_bina_sayisi,
                        options: {
                          options: {
                            grid: {
                              bottom: "20%",
                            },
                            xAxis: [
                              {
                                data: afetGraphic5 && afetGraphic5[0],
                                type: "category",
                                axisLabel: {
                                  rotate: 45,
                                  textStyle: {
                                    color: "#fff",
                                    fontSize: 10,
                                  },
                                },
                              },
                            ],
                            yAxis: [
                              {
                                type: "value",
                                axisLabel: {
                                  textStyle: {
                                    color: "#fff",
                                  },
                                },
                              },
                            ],
                            series: [
                              {
                                data: afetGraphic5 && afetGraphic5[1],
                                name: null,
                                type: "bar",
                                itemStyle: {
                                  color: context?.chartColor,
                                },
                                markPoint: {
                                  data: [
                                    {
                                      name: "Max",
                                      type: "max",
                                    },
                                    {
                                      name: "Min",
                                      type: "min",
                                    },
                                  ],
                                },
                              },
                            ],
                            toolbox: {
                              show: true,
                              feature: {
                                restore: {
                                  show: false,
                                },
                                magicType: {
                                  show: true,
                                  type: ["line", "bar"],
                                },
                                saveAsImage: {
                                  show: false,
                                },
                              },
                              iconStyle: {
                                borderColor: "#fff",
                              },
                            },
                            tooltip: {
                              trigger: "axis",
                            },
                            darkMode: true,
                            visualMap: context?.chartColor
                              ? {
                                  max:
                                    afetGraphic5 && afetGraphic5[1]
                                      ? Math.max(
                                          ...afetGraphic5[1].map((item) => item)
                                        )
                                      : 0,

                                  min: 0,
                                  show: false,
                                  inRange: {
                                    colorLightness: [0.2, 1],
                                  },
                                }
                              : false,
                            calculable: true,
                          },
                        },
                      }}
                    />
                  </div>

                  <div className="col-12 col-md-4 h-100">
                    <DynamicCard
                      height="h-full"
                      fullScreen={true}
                      events={false}
                      data={afetGraphic6}
                      item={{
                        title: context?.t?.yapim_turune_gore_bina_sayisi,
                        options: {
                          options: {
                            series: [
                              {
                                data: afetGraphic6,
                                name: null,
                                type: "pie",
                                label: {
                                  textStyle: {
                                    fontSize: 15,
                                  },
                                },
                                radius: "50%",
                                itemStyle: {
                                  color: context?.chartColor,
                                },
                              },
                            ],
                            tooltip: {
                              trigger: "item",
                            },
                            darkMode: true,
                            legend: {
                              show: true,
                              orient: "horizontal",
                              left: "center",
                              itemGap: 20,
                              textStyle: {
                                color: "#fff",
                              },
                            },
                            visualMap: context?.chartColor
                              ? {
                                  min:
                                    afetGraphic6 && afetGraphic6.length !== 0
                                      ? Math.min(
                                          ...afetGraphic6.map(
                                            (obj) => obj.value
                                          )
                                        )
                                      : 0,
                                  max:
                                    afetGraphic6 && afetGraphic6.length !== 0
                                      ? Math.max(
                                          ...afetGraphic6.map(
                                            (obj) => obj.value
                                          )
                                        )
                                      : 0,
                                  show: false,
                                  inRange: {
                                    colorLightness: [0.2, 1],
                                  },
                                }
                              : false,
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

              <hr />

              <div className="mt-3 text-start">
                <div className="mb-3 row align-items-center justify-content-between">
                  <div className="col-6">
                    <h4>{context?.t?.healthcare_facilities}</h4>
                  </div>

                  <div className="col w-auto flex-none">
                    <div className="d-flex gap-2">
                      <div
                        className="w-100"
                        style={{ minWidth: 250, maxWidth: 250 }}
                      >
                        <span className="d-block">{context?.t?.category}</span>
                        <Select
                          options={tesislerSelectData}
                          value={tesislerSelectValue}
                          classNamePrefix="react-select"
                          noOptionsMessage={() => context?.t?.no_option}
                          placeholder=""
                          onChange={(e) => handleTesislerSelect(e)}
                        />
                      </div>
                      <div>
                        <span className="d-block">
                          {context?.t?.all_locations}
                        </span>
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          onClick={() =>
                            allTesisMarkers
                              ? setAllTesisMarkers(null)
                              : handleLocationAllTesis()
                          }
                        >
                          {allTesisMarkers
                            ? context?.t?.hide
                            : context?.t?.show}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dataTables_wrapper dt-bootstrap5">
                  {tesislerData ? (
                    tesislerData.data.length !== 0 ? (
                      <>
                        <div className="dataTables_scroll">
                          <div
                            className="dataTables_scrollBody table-responsive"
                            style={{ height: 400 }}
                          >
                            <table className="table text-nowrap w-100 dataTable mb-0 text-start">
                              <thead>
                                <tr>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    {context?.t?.kurum_adi}
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    {context?.t?.address}
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    {context?.t?.phone}
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    {context?.t?.web_site}
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    {context?.t?.city}
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    {context?.t?.district}
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    {context?.t?.category}
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{
                                      minWidth: 500,
                                      width: 500,
                                      borderColor: "#ffffff50",
                                    }}
                                  >
                                    {context?.t?.bolumler}
                                  </th>

                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    {context?.t?.location}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {tesislerData.data.map((item, index) => (
                                  <tr key={index}>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["kurum adı"]}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["adres"]}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["telefon"]}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["web sitesi"] ? (
                                        <a
                                          href={item["web sitesi"]}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {context?.t?.view}
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["şehir"]}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["i̇lçe"]}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["kategori"]}
                                    </td>
                                    <td
                                      style={{
                                        whiteSpace: "pre-wrap",
                                        borderColor: "#ffffff50",
                                      }}
                                    >
                                      {item["bölümler"] ? (
                                        <div className="d-flex flex-wrap gap-1">
                                          {item["bölümler"]
                                            .split(",")
                                            .map((item) => (
                                              <span className="btn btn-sm btn-dark">
                                                {item}
                                              </span>
                                            ))}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </td>

                                    <td style={{ borderColor: "#ffffff50" }}>
                                      <span
                                        className="pointer text-theme text-underline"
                                        onClick={() => {
                                          setAllTesisMarkers(null);
                                          setTesisMarkerSelected(null);
                                          setTesisMarker(
                                            tesisMarker
                                              ? tesisMarker.enlem ===
                                                  item.enlem &&
                                                tesisMarker.boylam ===
                                                  item.boylam
                                                ? null
                                                : item
                                              : item
                                          );
                                        }}
                                      >
                                        {tesisMarker
                                          ? tesisMarker.enlem === item.enlem &&
                                            tesisMarker.boylam === item.boylam
                                            ? context?.t?.hide
                                            : context?.t?.show
                                          : context?.t?.show}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="dataTables_paginate paging_simple_numbers d-flex flex-wrap justify-content-center text-center mt-3">
                          <ul className="pagination">
                            <li className={`paginate_button page-item`}>
                              <button
                                type="button"
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                                className={`page-link pointer ${
                                  !tesislerData.prev_page_url ? "disabled" : ""
                                }`}
                              >
                                {context?.t?.prev}
                              </button>
                            </li>

                            {tesislerData.links.map(
                              (number, index) =>
                                index > 0 &&
                                index < tesislerData.links.length - 1 && (
                                  <li
                                    key={index}
                                    className={`paginate_button page-item ${
                                      currentPage === parseInt(number.label)
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      onClick={() =>
                                        handlePageChange(parseInt(number.label))
                                      }
                                      className="page-link pointer"
                                    >
                                      {number.label}
                                    </div>
                                  </li>
                                )
                            )}

                            <li className={`paginate_button page-item`}>
                              <button
                                type="button"
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                                className={`page-link pointer ${
                                  !tesislerData.next_page_url ? "disabled" : ""
                                }`}
                              >
                                {context?.t?.next}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      <div className="text-center">
                        {context?.t?.analysis_data_not_added}
                      </div>
                    )
                  ) : (
                    <div className="spinner-border text-theme"></div>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  );
}
