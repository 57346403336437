import { useRef, useState, useEffect, useContext } from "react";
import { Card, CardBody } from "../../components/card/card";
import toastMessage from "../../components/ToastMessage";
import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

export default function AnketSorulari({
  city,
  district,
  neighbourhood,
  street,
  building,
  independent,
  location,
  tcSurvey,
  surveyInnerPage,
  setSubmitSurveyLoading,

  data,
  setAnswers,
  setCurrentQuestion,
  errors,
  setErrors,
  imageFields,

  handleReset,
}) {
  const context = useContext(AppSettings);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [numberValues, setNumberValues] = useState({});
  const questionRefs = useRef({});
  const [additionalText, setAdditionalText] = useState({});

  const clearConditionalAnswers = (questionId) => {
    // Koşullu soruları bul
    const question = data.questions.find((q) => q.id === questionId);
    question &&
      question.answers &&
      question.answers.length !== 0 &&
      question.answers.forEach((answer) => {
        if (answer.condition) {
          setSelectedAnswers((prev) => ({
            ...prev,
            [answer.condition.id]: [], // Koşullu sorunun cevabını temizle
          }));
        }
      });
  };

  const handleRadioChange = (questionId, answer, isRequired) => {
    clearConditionalAnswers(questionId);

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [questionId]: [answer],
    }));

    if (isRequired) {
      setNumberValues((prevValues) => ({
        ...prevValues,
        [questionId]: prevValues[questionId] || 0,
      }));
    }

    setErrors((prevErrors) => ({ ...prevErrors, [questionId]: false }));
  };

  const handleCheckboxChange = (questionId, answer, isRequired) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const currentAnswers = prevSelectedAnswers[questionId] || [];
      const newAnswers = currentAnswers.includes(answer)
        ? currentAnswers.filter((a) => a !== answer)
        : [...currentAnswers, answer];

      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: newAnswers,
      }));

      if (isRequired) {
        setNumberValues((prevValues) => ({
          ...prevValues,
          [questionId]: prevValues[questionId] || 0,
        }));
      }

      setErrors((prevErrors) => ({ ...prevErrors, [questionId]: false }));

      return {
        ...prevSelectedAnswers,
        [questionId]: newAnswers,
      };
    });
  };

  const handleTextChange = (questionId, answerId, event) => {
    const value = event.target.value;

    // Cevabı selectedAnswers'a ekle
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [questionId]: value ? [answerId] : [],
    }));

    setAdditionalText((prevText) => ({
      ...prevText,
      [questionId]: value, // Store the additional text for the textarea
    }));

    // Answers'ı da güncelle (answerId ile birlikte)
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answerId, // Burada answerId kullanılıyor
    }));

    // Hata durumunu temizle
    setErrors((prevErrors) => ({ ...prevErrors, [questionId]: false }));
  };

  const incrementNumber = (questionId) => {
    setNumberValues((prevValues) => ({
      ...prevValues,
      [questionId]: (prevValues[questionId] || 0) + 1,
    }));
  };

  const decrementNumber = (questionId) => {
    setNumberValues((prevValues) => ({
      ...prevValues,
      [questionId]: Math.max((prevValues[questionId] || 0) - 1, 0),
    }));
  };

  useEffect(() => {
    if (errors) {
      const firstErrorKey = Object.keys(errors).find((key) => errors[key]);
      if (firstErrorKey) {
        questionRefs.current[firstErrorKey]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [errors]);

  // Recursive validasyon fonksiyonu
  const validateQuestion = (question, selectedAnswers, errors) => {
    let hasError = false;

    if (question.is_required) {
      const answer = selectedAnswers[question.id];

      // Radio ve Checkbox tipleri için en az bir cevap olmalı
      if (question.type === "radio" || question.type === "checkbox") {
        if (!answer || answer.length === 0) {
          errors[question.id] = true;
          hasError = true;
        }
      }

      // String (textarea) tipi için boş olup olmadığını kontrol edelim
      if (question.type === "string") {
        const textAnswer =
          selectedAnswers[question.id] && selectedAnswers[question.id][0];

        if (!textAnswer || textAnswer.length === 0) {
          errors[question.id] = true;
          hasError = true;
        }
      }

      // Eğer resim yüklemek zorunluysa ve resim eklenmediyse hata
      if (
        selectedAnswers[question.id]?.some((answer) => answer.is_required) &&
        (!numberValues[question.id] || numberValues[question.id] === 0)
      ) {
        errors[question.id] = true;
        hasError = true;
      }
    }

    // Condition'lı alt soruları da kontrol edelim
    if (selectedAnswers[question.id]?.length > 0 && question.answers) {
      question.answers.forEach((answer) => {
        if (
          selectedAnswers[question.id].includes(answer.id) &&
          answer.condition
        ) {
          const conditionHasError = validateQuestion(
            answer.condition,
            selectedAnswers,
            errors
          );
          hasError = hasError || conditionHasError;
        }
      });
    }

    return hasError;
  };

  const validateRequiredQuestions = () => {
    let hasError = false;
    const newErrors = {};
    let firstErrorQuestionId = null;

    data.questions.forEach((question) => {
      const questionHasError = validateQuestion(
        question,
        selectedAnswers,
        newErrors
      );
      if (questionHasError && !firstErrorQuestionId) {
        firstErrorQuestionId = question.id; // İlk hatalı sorunun ID'sini bulalım
      }
      hasError = hasError || questionHasError;
    });

    setErrors(newErrors);

    if (firstErrorQuestionId) {
      questionRefs.current[firstErrorQuestionId].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    return !hasError;
  };

  const isImageRequired = (question) => {
    const selectedAnswerIds = selectedAnswers[question.id];
    if (selectedAnswerIds && question.answers) {
      return question.answers.some(
        (answer) => selectedAnswerIds.includes(answer.id) && answer.is_required
      );
    }
    return false;
  };

  const handleSubmit = async () => {
    let missingImages = false; // Resim eksikliği durumu

    // Ana sorular için resim kontrolü
    data.questions.forEach((question) => {
      const selectedAnswerIds = selectedAnswers[question.id];

      if (
        isImageRequired(question) &&
        (!imageFields[question.id] ||
          imageFields[question.id].length === 0 ||
          imageFields[question.id].some((image) => !image.selectedMarker) ||
          imageFields[question.id].some((image) => image.files.length === 0))
      ) {
        toastMessage(
          `"${question.question}" - ${context?.t?.sorusu_icin_resim_konum_gerekiyor}`,
          "error"
        );
        missingImages = true;
      }
    });

    // Seçilen cevaplar üzerinde döngü yapıyoruz (koşullu sorular)
    Object.keys(selectedAnswers).forEach((questionId) => {
      const selectedAnswerIds = selectedAnswers[questionId];
      const question = data.questions.find(
        (q) => q.id === parseInt(questionId)
      );

      if (!question) return;

      selectedAnswerIds.forEach((selectedAnswerId) => {
        const answer = question.answers.find(
          (ans) => ans.id === selectedAnswerId
        );

        if (answer?.condition) {
          const condition = answer.condition;
          const conditionAnswer = selectedAnswers[condition.id];

          // Koşullu sorunun durumu ve resim kontrolü
          if (conditionAnswer) {
            const selectedConditionAnswer = condition.answers.find((ans) =>
              conditionAnswer.includes(ans.id)
            );
            if (selectedConditionAnswer?.is_required) {
              const imageField = imageFields[condition.id];
              if (
                !imageField ||
                imageField.length === 0 ||
                imageField.some((image) => !image.selectedMarker) ||
                imageField.some((image) => image.files.length === 0)
              ) {
                toastMessage(
                  `"${condition.question}" - ${context?.t?.sorusu_icin_resim_konum_gerekiyor}`,
                  "error"
                );
                missingImages = true;
              }
            }
          }
        }
      });
    });

    // Eğer eksik resim varsa submit işlemini durduruyoruz
    if (missingImages) {
      return;
    }

    if (validateRequiredQuestions()) {
      // Final payload preparation
      const payload = {
        report_id: data?.id,
        city,
        district,
        neighbourhood,
        street,
        building,
        independent,
        location: tcSurvey ? { lat: "0.0", lng: "0.0", zoom: 1 } : location,
        reply: [],
      };

      // if (!city || city === "") {
      //   toastMessage("Şehir Seçmelisiniz", "error");
      //   return;
      // }

      // if (!district || district === "") {
      //   toastMessage("İlçe Seçmelisiniz", "error");
      //   return;
      // }

      // if (!neighbourhood === "") {
      //   toastMessage("Mahalle Seçmelisiniz", "error");
      //   return;
      // }

      // if (!street === "") {
      //   toastMessage("Sokak Seçmelisiniz", "error");
      //   return;
      // }

      if (!tcSurvey) {
        if (!location) {
          toastMessage(context?.t?.pin_belirleyin, "error");
          return;
        }
      }

      Object.keys(selectedAnswers).forEach((questionId) => {
        const answers = selectedAnswers[questionId];
        answers.forEach((answerId) => {
          const answerPayload = {
            question: parseInt(questionId),
            answer: answerId,
            images: [],
            text: additionalText[questionId] || null,
          };

          // Add images if available
          if (imageFields[questionId]) {
            imageFields[questionId].forEach((image) => {
              answerPayload.images.push({
                description: image.description || "",
                list: image.files || [],
                location: image.selectedMarker || null,
              });
            });
          }

          payload.reply.push(answerPayload);
        });
      });

      if (tcSurvey) {
        payload.tc = tcSurvey.toString();
      }

      setSubmitSurveyLoading(true);

      try {
        const response = await apiClient.customPost(
          `/street-report-reply`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          toastMessage(context?.t?.cevap_gonderildi, "success");

          setSelectedAnswers({});
          setNumberValues({});
          setAdditionalText({});
          handleReset();
          document.getElementById("reportQuestionsModalCloseButton").click();

          if (tcSurvey) {
            setTimeout(() => {
              window.location.reload();
            }, 300);
          }
        }
      } catch (error) {
        setSubmitSurveyLoading(false);

        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  };

  const renderQuestion = (question, questionIndex = 1) => {
    return (
      <div
        key={question.id}
        className={`survey-question-list-item ${
          errors[question.id] ? "border-danger" : ""
        }`}
        style={{ borderWidth: 2 }}
        ref={(el) => (questionRefs.current[question.id] = el)}
      >
        <div className="list-item-header">
          <div className="d-flex gap-2">
            <div className="border-end pe-1 text-white">#{questionIndex}</div>
            <div>
              <span className="d-block">{question?.category?.name}</span>
              <span className={`d-block h4 mt-1 mb-0`}>
                {question?.is_required && (
                  <span style={{ color: "red" }}>*</span>
                )}
                {question?.question}
              </span>
            </div>
          </div>

          {isImageRequired(question) && (
            <div style={{ width: 150 }}>
              <button
                type="button"
                className="btn btn-theme w-100"
                data-bs-toggle="modal"
                data-bs-target="#imageUploadModal"
                onClick={() => setCurrentQuestion(question)}
              >
                {context?.t?.add_image}
              </button>

              <div className="d-flex align-items-center mt-2">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => decrementNumber(question.id)}
                >
                  -
                </button>
                <input
                  type="text"
                  className="form-control text-center mx-2"
                  value={numberValues[question.id] || 0}
                  readOnly
                />
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => incrementNumber(question.id)}
                >
                  +
                </button>
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="overflow-hidden my-0" style={{ borderRadius: 8 }}>
            {question.answers.map((answer, answerIndex) => {
              const inputId = `question_${question.id}_answer_${answerIndex}`;
              return (
                <div key={answerIndex}>
                  {question.type === "string" ? (
                    <textarea
                      type="text"
                      className={`form-control`}
                      value={additionalText[question.id] || ""}
                      placeholder={
                        answer.answer || context?.t?.cevabinizi_yaziniz
                      }
                      style={{ resize: "none", height: 80 }}
                      onChange={(e) =>
                        handleTextChange(
                          question.id,
                          answer.id,
                          e,
                          question.is_required
                        )
                      }
                    />
                  ) : (
                    <>
                      <div className="form-check form-checkbox d-flex align-items-center">
                        <input
                          id={inputId}
                          className="form-check-input"
                          type={question.type}
                          name={`question_${question.id}`}
                          value={answer.answer}
                          onChange={() =>
                            question.type === "radio"
                              ? handleRadioChange(
                                  question.id,
                                  answer.id,
                                  answer.is_required
                                )
                              : handleCheckboxChange(
                                  question.id,
                                  answer.id,
                                  answer.is_required
                                )
                          }
                          checked={selectedAnswers[question.id]?.includes(
                            answer.id
                          )}
                        />
                        <label
                          htmlFor={inputId}
                          className="form-check-label py-1 ps-2 pointer"
                        >
                          {answer.answer}
                          <span className="h5" style={{ color: "red" }}>
                            {answer.is_required && "*"}
                          </span>
                        </label>
                      </div>

                      {/* Render conditional question if selected answer has children */}
                      {selectedAnswers[question.id]?.includes(answer.id) &&
                        answer.children_id &&
                        answer.condition && (
                          <div className="ms-2 ps-3 border-start border-white mt-3">
                            {renderQuestion(answer.condition)}
                          </div>
                        )}
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    data && (
      <>
        <div className="row gap-4 mb-4">
          <div className="col-12">
            <Card>
              <CardBody>
                <div className="survey-question-list">
                  {data.questions.map((item, index) =>
                    renderQuestion(item, index + 1)
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

        <hr className="my-3" />

        <div className="row g-3">
          <div className="col-12">
            <div className="row g-2 justify-content-end h-100">
              <div className="col-6 h-100">
                {tcSurvey ? (
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn btn-lg btn-outline-default w-100 h-100 d-flex align-items-center justify-content-center"
                  >
                    {context?.t?.cancel}
                  </button>
                ) : (
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target={
                      surveyInnerPage ? "#kisiAnketiModal" : "#addReportModal"
                    }
                    className="btn btn-lg btn-outline-default w-100 h-100 d-flex align-items-center justify-content-center"
                  >
                    {context?.t?.cancel}
                  </button>
                )}
              </div>

              <div className="col-6 h-100">
                <button
                  type="button"
                  onClick={() => handleSubmit()}
                  className="btn btn-lg btn-outline-theme w-100 h-100 d-flex align-items-center justify-content-center"
                >
                  {context?.t?.send}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}
