import Select from "react-select";
import React, { useContext, useEffect, useState } from "react";
import apiClient from "../../ApiClient";
import moment from "moment";
import { Card, CardBody } from "../../components/card/card";
import { AppSettings } from "../../config/app-settings";
import toastMessage from "../../components/ToastMessage";

export default function PersonsSurveyTable({
  assigmentSelect,
  setAssigmentSelect,
  setTCSurvey,
}) {
  const context = useContext(AppSettings);

  const [data, setData] = useState(null);
  const [phoneValues, setPhonesValues] = useState({});

  const [tcValue, setTCValue] = useState("");
  const [statusValue, setStatusValue] = useState({
    value: null,
    label: "HEPSİ",
  });

  // Fetch Phones
  async function fetchPhones(tc) {
    const option = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `bireysel-data-customer-${context?.me?.customer?.id}`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      sql: {
        column: [
          {
            key: "Telefon NO",
            value: "select",
          },
        ],
        condition: [
          {
            key: "TC",
            value: tc.toString(),
            operator: "=",
            type: "and",
          },
        ],
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(`/query/preview`, option, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response) {
        return response && response.length !== 0
          ? response[0]["telefon no"]
          : null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  // Fetch
  async function fetchGetFilters(page, tc = "", status) {
    setData(null);

    try {
      const response = await apiClient.get(
        `/citizen/assignment?paginate&page=${page}
        ${tc !== "" ? `&tc=${tc}` : ""}
        ${
          context?.me?.role?.id === 6
            ? `&status=0`
            : status && status.value !== null
            ? `&status=${status.value}`
            : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        setData(response.data);

        const newPhoneValues = {};

        for (const item of response.data.data) {
          const phone = await fetchPhones(item.tc);
          newPhoneValues[item.tc] = phone || "Bilinmiyor";

          setPhonesValues((prevValues) => ({
            ...prevValues,
            [item.tc]: newPhoneValues[item.tc],
          }));
        }
      }
    } catch (error) {
      setData([]);
      console.error("Get Page", error);
    }
  }

  useEffect(() => {
    if (context && context?.me) {
      fetchGetFilters(1);
    }
  }, [context]);

  // Pagination
  function handlePagination(page) {
    fetchGetFilters(page, tcValue, statusValue);
  }

  // Filter
  function handleFilter() {
    fetchGetFilters(1, tcValue, statusValue);
  }

  // Delete
  const [deleteLoader, setDeleteLoader] = useState(false);

  async function handleDelete(id) {
    setDeleteLoader(false);

    try {
      const response = await apiClient.delete(`/citizen/assignment/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setDeleteLoader(false);
        fetchGetFilters(1, tcValue, statusValue);
      }
    } catch (error) {
      setDeleteLoader(false);
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  return (
    <div className="col-12">
      {data ? (
        <div>
          {context?.me?.role?.id !== 6 && (
            <>
              <div className="row align-items-end mt-3 w-50">
                <div className="col">
                  <span className="d-block">TC</span>
                  <input
                    type="text"
                    className="form-control"
                    value={tcValue}
                    onChange={(e) => setTCValue(e.target.value)}
                  />
                </div>

                <div className="col">
                  <span className="d-block">DURUM</span>
                  <Select
                    options={[
                      { value: null, label: "HEPSİ" },
                      { value: 1, label: "YAPILDI" },
                      { value: 0, label: "YAPILMADI" },
                    ]}
                    placeholder=""
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "Seçenek Yok"}
                    value={statusValue}
                    onChange={(e) => setStatusValue(e)}
                  />
                </div>

                <div className="col w-auto flex-none">
                  <button
                    type="button"
                    className="btn btn-outline-theme"
                    onClick={() => handleFilter()}
                  >
                    Getir
                  </button>
                </div>
              </div>

              <hr />
            </>
          )}

          {data.data.length !== 0 ? (
            <>
              <div className="dataTables_wrapper dt-bootstrap5 mt-4">
                {context?.me?.role?.id !== 6 ? (
                  data.data.map((item, index) => (
                    <div className="dataTables_scroll" key={index}>
                      <div className="dataTables_scrollBody table-responsive">
                        <table className="table text-nowrap w-100 dataTable">
                          <thead>
                            <tr>
                              <th className="px-3">#</th>
                              <th className="px-3">İşlem</th>
                              <th className="px-3">Durum</th>
                              <th className="px-3">Anket</th>
                              <th className="px-3">TC</th>
                              <th className="px-3">Oluşturma Tarihi</th>
                              <th className="px-3">Güncelleme Tarihi</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td style={{ minWidth: 80 }} className="p-3">
                                {index + 1}
                              </td>
                              <td style={{ minWidth: 80 }} className="p-3">
                                <button
                                  className="btn btn-danger"
                                  onClick={() => handleDelete(item.id)}
                                  disabled={deleteLoader}
                                >
                                  Kaldır
                                  {deleteLoader && (
                                    <div className="spinner-border spinner-border-sm"></div>
                                  )}
                                </button>
                              </td>
                              <td style={{ minWidth: 150 }} className="p-3">
                                {item.status ? (
                                  <span className="text-theme">Yapıldı</span>
                                ) : (
                                  <span className="text-danger">
                                    Yapılamadı
                                  </span>
                                )}
                              </td>
                              <td style={{ minWidth: 150 }} className="p-3">
                                {item?.report?.name}
                              </td>
                              <td style={{ minWidth: 150 }} className="p-3">
                                {item.tc}
                              </td>
                              <td style={{ minWidth: 150 }} className="p-3">
                                {item.created_at
                                  ? moment(
                                      item.created_at,
                                      "YYYY-MM-DD HH:mm:ss"
                                    ).format("DD MMMM YYYY")
                                  : "Bilinmiyor"}
                              </td>
                              <td style={{ minWidth: 150 }} className="p-3">
                                {item.updated_at
                                  ? moment(
                                      item.updated_at,
                                      "YYYY-MM-DD HH:mm:ss"
                                    ).format("DD MMMM YYYY")
                                  : "Bilinmiyor"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="row g-2 mb-4">
                        {item?.reply !== 0 &&
                        item?.reply[0] &&
                        item?.reply[0].reply &&
                        item?.reply[0].reply.length !== 0 ? (
                          item?.reply[0].reply[0].map(
                            (itemAnswer, indexAnswer) => (
                              <div
                                key={indexAnswer}
                                className="col-12 col-md-6 col-lg-3"
                              >
                                <Card className="h-100">
                                  <CardBody className="d-flex flex-column overflow-hidden">
                                    <div className="row text-white border-bottom pb-2 mb-3">
                                      <span className="col">
                                        <span className="fw-bold h5">
                                          {indexAnswer + 1}.
                                        </span>{" "}
                                        Soru
                                      </span>
                                      <span className="col text-end">
                                        {itemAnswer?.category?.name}
                                      </span>
                                    </div>
                                    <div
                                      className="flex-1"
                                      style={{
                                        maxHeight: 350,
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        paddingRight: 10,
                                        marginRight: -10,
                                      }}
                                    >
                                      <div>
                                        <span className="d-block h5 mb-3">
                                          {itemAnswer?.question?.question}
                                        </span>
                                      </div>
                                      <div>
                                        <div
                                          className="d-flex flex-wrap gap-1"
                                          style={{ borderRadius: 8 }}
                                        >
                                          <span
                                            className={`pe-none py-1 px-2 rounded fw-bold text-white bg-dark`}
                                          >
                                            {itemAnswer.answer.text ||
                                              itemAnswer.answer.answer.answer}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </div>
                            )
                          )
                        ) : (
                          <span>Cevap Vermemiş</span>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="dataTables_scroll">
                    <div className="dataTables_scrollBody table-responsive">
                      <table className="table text-nowrap w-100 dataTable">
                        <thead>
                          <tr>
                            <th className="px-3">#</th>
                            <th className="px-3">İşlem</th>
                            <th className="px-3">ATANMA TARİHİ</th>
                            <th className="px-3">TELEFON</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data.data.map((item, index) => (
                            <tr key={index}>
                              <td
                                style={{ minWidth: 50, width: 50 }}
                                className="p-3"
                              >
                                {index + 1}.
                              </td>
                              <td
                                style={{ minWidth: 100, width: 100 }}
                                className="p-3"
                              >
                                {phoneValues[item.tc] !== "Bilinmiyor" ? (
                                  <button
                                    type="button"
                                    className="btn btn-outline-theme btn-sm w-100"
                                    data-bs-toggle="modal"
                                    data-bs-target="#reportQuestionsModal"
                                    onClick={() => {
                                      setTCSurvey(item.tc);
                                      setAssigmentSelect(item.report_id);
                                    }}
                                  >
                                    Anket Yap
                                  </button>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td
                                className="p-3"
                                style={{ minWidth: 100, width: 100 }}
                              >
                                {item.created_at
                                  ? moment(
                                      item.created_at,
                                      "YYYY-MM-DD HH:mm:ss"
                                    ).format("DD MMMM YYYY")
                                  : "Bilinmiyor"}
                              </td>
                              <td className="p-3">
                                {phoneValues[item.tc] ? (
                                  phoneValues[item.tc] !== "Bilinmiyor" ? (
                                    context.phoneFormat(phoneValues[item.tc])
                                  ) : (
                                    "Bilinmiyor"
                                  )
                                ) : (
                                  <div className="spinner-border spinner-border-sm text-theme"></div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                <div className="row align-items-center mt-3">
                  <div className="mb-0 col-md-12">
                    <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center">
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item ${
                            !data.prev_page_url && "disabled"
                          }`}
                        >
                          <span
                            onClick={() =>
                              data.prev_page_url &&
                              handlePagination(
                                data.prev_page_url.split("?page=")[1]
                              )
                            }
                            className="page-link"
                          >
                            Geri
                          </span>
                        </li>

                        {data.links.map(
                          (item, index) =>
                            !isNaN(parseInt(item.label)) && (
                              <li
                                key={index}
                                className={`paginate_button page-item ${
                                  item.active ? "active" : ""
                                }`}
                              >
                                <span
                                  onClick={() => handlePagination(item.label)}
                                  className="page-link"
                                >
                                  {item.label}
                                </span>
                              </li>
                            )
                        )}

                        <li
                          className={`paginate_button page-item ${
                            !data.next_page_url && "disabled"
                          }`}
                        >
                          <span
                            onClick={() =>
                              data.next_page_url &&
                              handlePagination(
                                data.next_page_url.split("?page=")[1]
                              )
                            }
                            className="page-link"
                          >
                            İleri
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <span className="d-block mt-4">Atanmış bir anket bulunamadı.</span>
          )}
        </div>
      ) : (
        <div className="spinner-border text-theme mt-4"></div>
      )}
    </div>
  );
}
