import React from "react";

export default function TableWithPrediction() {
  return <div>TableWithPrediction</div>;
}

// import React, { useState, useEffect, useContext } from "react";
// import { DndProvider, useDrag, useDrop } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import apiClient from "../../ApiClient";
// import { AppSettings } from "../../config/app-settings";
// import DynamicCard from "../../components/DynamicCard";

// const ItemTypes = {
//   BOX: "box",
// };

// const LOCAL_STORAGE_KEY = "boxLayout";

// const Box = ({ id, width, height, index, moveBox, onResize, content }) => {
//   const [, drag] = useDrag({
//     type: ItemTypes.BOX,
//     item: { id, index },
//   });

//   const [, drop] = useDrop({
//     accept: ItemTypes.BOX,
//     hover(item) {
//       if (item.index !== index) {
//         moveBox(item.index, index);
//         item.index = index;
//       }
//     },
//   });

//   const handleResizeMouseDown = (e) => {
//     const startX = e.clientX;
//     const startY = e.clientY;
//     const startWidth = width;
//     const startHeight = height;

//     const handleMouseMove = (e) => {
//       const newWidth = Math.max(50, startWidth + (e.clientX - startX));
//       const newHeight = Math.max(50, startHeight + (e.clientY - startY));
//       onResize(id, newWidth, newHeight);
//     };

//     const handleMouseUp = () => {
//       document.removeEventListener("mousemove", handleMouseMove);
//       document.removeEventListener("mouseup", handleMouseUp);
//     };

//     document.addEventListener("mousemove", handleMouseMove);
//     document.addEventListener("mouseup", handleMouseUp);
//   };

//   return (
//     <div
//       ref={(node) => drag(drop(node))}
//       className="box"
//       style={{
//         width: `${width}px`,
//         height: `${height}px`,
//       }}
//     >
//       {/* Dinamik içerik buraya yerleştirilecek */}
//       {content}

//       <div
//         className="resizer"
//         onMouseDown={handleResizeMouseDown}
//         style={{
//           position: "absolute",
//           bottom: "0",
//           right: "0",
//           width: "10px",
//           height: "10px",
//           backgroundColor: "red",
//           cursor: "nwse-resize",
//         }}
//       />
//     </div>
//   );
// };

// const Container = ({ menuId = 77, filterPath }) => {
//   const context = useContext(AppSettings);

//   // Varsayılan kutuları boş olarak başlatıyoruz, çünkü artık API'den gelen veri ile oluşturacağız
//   const [boxes, setBoxes] = useState([]);

//   const [data, setData] = useState(null);

//   const fetchPageData = async (menuId, filterPath) => {
//     try {
//       const response = await apiClient.get(`/page/${menuId}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });
//       if (response.status && response?.data?.graphics) {
//         const graphicsData = response.data.graphics;

//         // Gelen grafik verilerine göre kutuları dinamik olarak oluşturuyoruz
//         const dynamicBoxes = graphicsData.map((graphic, index) => ({
//           id: graphic.id, // API'deki id'yi kullanıyoruz
//           width: 300, // Varsayılan genişlik
//           height: 300, // Varsayılan yükseklik
//         }));

//         setBoxes(dynamicBoxes); // Kutuları güncelliyoruz
//         setData(graphicsData); // Grafik verilerini de güncelliyoruz
//       }
//     } catch (error) {
//       console.error("Get Page Error", error);
//     }
//   };

//   useEffect(() => {
//     if (menuId) {
//       fetchPageData(menuId, filterPath);
//     }
//   }, [menuId, filterPath]);

//   useEffect(() => {
//     if (boxes.length > 0) {
//       localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(boxes));
//     }
//   }, [boxes]);

//   const moveBox = (fromIndex, toIndex) => {
//     const updatedBoxes = [...boxes];
//     const [movedBox] = updatedBoxes.splice(fromIndex, 1);
//     updatedBoxes.splice(toIndex, 0, movedBox);
//     setBoxes(updatedBoxes);
//   };

//   const handleResize = (id, newWidth, newHeight) => {
//     setBoxes((prevBoxes) =>
//       prevBoxes.map((box) =>
//         box.id === id ? { ...box, width: newWidth, height: newHeight } : box
//       )
//     );
//   };

//   const renderContent = (boxId) => {
//     if (!data) return <div className="spinner-border text-theme"></div>;

//     const matchedData = data.find((item) => item.id === boxId);

//     if (matchedData) {
//       return (
//         // <div>
//         //   <h5 className="text-black">{matchedData.title}</h5>
//         //   <p>{matchedData.description}</p>
//         // </div>
//         <DynamicCard
//           fullScreen={true}
//           item={matchedData}
//           result={matchedData.result}
//         />
//       );
//     } else {
//       return <p>Veri bulunamadı</p>;
//     }
//   };

//   return (
//     <DndProvider backend={HTML5Backend}>
//       <div className="d-flex flex-wrap gap-2 position-relative">
//         {boxes.map((box, index) => (
//           <Box
//             key={box.id}
//             id={box.id}
//             width={box.width}
//             height={box.height}
//             index={index}
//             moveBox={moveBox}
//             onResize={handleResize}
//             content={renderContent(box.id)} // İçerik kutuya eklendi
//           />
//         ))}
//       </div>
//     </DndProvider>
//   );
// };

// export default Container;
