import React, { useCallback, useContext, useEffect, useState } from "react";
import apiClient from "../../ApiClient";
import { Card, CardBody } from "../../components/card/card";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { AppSettings } from "../../config/app-settings";
import Fancybox from "../../components/Fancybox";

import logoWhite from "../../images/logo-white.png";

import "swiper/css/pagination";

export default function Gorevler() {
  const context = useContext(AppSettings);

  // Swiper
  const [imageSwipers, setImageSwipers] = useState([]);
  const [todoSwipers, setTodoSwipers] = useState([]);

  // States for data and category filtering
  const [groupedTodos, setGroupedTodos] = useState(null);
  const [filteredTodos, setFilteredTodos] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("TÜMÜ");

  // Function to group by category
  const groupByCategory = (data) => {
    const grouped = {};
    data.forEach((item) => {
      const categoryId = item.category.id;
      if (!grouped[categoryId]) {
        grouped[categoryId] = {
          category: item.category,
          list: [],
        };
      }
      const { category, ...rest } = item;
      grouped[categoryId].list.push(rest);
    });
    // Sort list by id within each category
    Object.values(grouped).forEach((group) => {
      group.list.sort((a, b) => a.sort - b.sort);
    });
    return Object.values(grouped);
  };

  // Fetch todos data
  const todoData = useCallback(async () => {
    setGroupedTodos(null);
    try {
      const response = await apiClient.get("/todos", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response) {
        const groupedData = groupByCategory(response.data);
        setGroupedTodos(groupedData);
        setFilteredTodos(groupedData); // Initially set filtered to show all
      }
    } catch (error) {
      console.error("TODO", error);
    }
  }, []);

  useEffect(() => {
    todoData();
  }, [todoData]);

  // Handle category click
  const handleCategoryClick = (category) => {
    if (category === "TÜMÜ") {
      setFilteredTodos(groupedTodos); // Reset to show all categories
    } else {
      const filtered = groupedTodos.filter(
        (group) => group.category.name === category
      );
      setFilteredTodos(filtered); // Show only selected category
    }
    setSelectedCategory(category); // Update selected category
  };

  // Item Return
  function TaskItem(group, todo) {
    return (
      <Card>
        <CardBody>
          <div className="progress-wrapper d-flex align-items-center mb-2">
            <span
              className="d-block text-center fw-bold me-2 text-white"
              style={{ fontSize: 12 }}
            >
              %{todo.title.includes("#") ? todo.title.split("#")[0] : 0}
            </span>
            <div className="progress flex-1" style={{ height: 5 }}>
              <div
                className="progress-bar bg-theme"
                role="progressbar"
                style={{
                  width: `${
                    todo.title.includes("#") ? todo.title.split("#")[0] : 0
                  }%`,
                }}
                aria-valuenow={
                  todo.title.includes("#") ? todo.title.split("#")[0] : 0
                }
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>

          <h5>
            {todo.title.includes("#") ? todo.title.split("#")[1] : todo.title}
          </h5>

          <div className="mt-3 position-relative">
            {todo?.image && todo?.image.length !== 0 && (
              <>
                <button
                  style={{
                    zIndex: 2,
                    transform: "translateY(-50%)",
                    borderRadius: "100%",
                    width: 32,
                    height: 32,
                  }}
                  className="btn btn-dark d-flex align-items-center justify-content-center position-absolute start-0 mx-1 top-50"
                  onClick={() => imageSwipers[todo.id]?.slidePrev()}
                >
                  <i className="fa-lg fa-fw fas fa-angle-left"></i>
                </button>
                <button
                  style={{
                    zIndex: 2,
                    transform: "translateY(-50%)",
                    borderRadius: "100%",
                    width: 32,
                    height: 32,
                  }}
                  className="btn btn-dark d-flex align-items-center justify-content-center position-absolute end-0 mx-1 top-50"
                  onClick={() => imageSwipers[todo.id]?.slideNext()}
                >
                  <i className="fa-lg fa-fw fas fa-angle-right"></i>
                </button>
              </>
            )}

            {todo?.image && todo?.image.length !== 0 ? (
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                <Swiper
                  onSwiper={(s) => {
                    setImageSwipers((prevSwipers) => ({
                      ...prevSwipers,
                      [todo?.id]: s,
                    }));
                  }}
                  slidesPerView={1}
                >
                  {todo?.image.map((img, imgIndex) => (
                    <SwiperSlide key={imgIndex}>
                      <a href={img} data-fancybox={todo?.id + "-gallery"}>
                        <img
                          key={imgIndex}
                          src={img}
                          alt={todo.title + "_" + imgIndex}
                          className="w-100 img-cover"
                          style={{ height: 200 }}
                        />
                      </a>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Fancybox>
            ) : (
              <div
                className="w-100 d-flex align-items-center justify-content-center"
                style={{ background: "#ffffff10", height: 200 }}
              >
                {context && context?.me?.customer?.settings?.logo ? (
                  <img
                    src={context?.me?.customer?.settings?.logo}
                    alt={context?.me?.customer?.customer?.name}
                    height={45}
                  />
                ) : (
                  <img src={logoWhite} alt={"Strateji Merkezi"} height={20} />
                )}
              </div>
            )}
          </div>

          {todo?.description && (
            <div className="mt-3">
              <span className="d-block">{context?.t?.description}</span>
              <div
                className="text-white mb-0"
                dangerouslySetInnerHTML={{
                  __html: todo?.description,
                }}
              />
            </div>
          )}

          {todo?.summary && (
            <div className="mt-3">
              <span className="d-block">{context?.t?.yonetici_ozeti}</span>
              <div
                className="text-white mb-0"
                dangerouslySetInnerHTML={{
                  __html: todo?.summary,
                }}
              />
            </div>
          )}

          <div className="mt-3">
            <span>{context?.t?.address}</span>
            <p className="text-white mb-0">
              {todo?.neighbourhood?.label} {todo?.street?.label}{" "}
              {todo?.building ? todo?.building[0] : ""} {todo?.independent}
              {todo?.city?.label}/{todo?.district?.label}
            </p>
          </div>

          {todo?.tags && todo?.tags.length !== 0 && (
            <div className="mt-3">
              <span className="d-block">{context?.t?.etiketler}</span>
              <div className="d-flex flex-wrap gap-1">
                {todo?.tags.map((tag, tagIndex) => (
                  <span
                    key={tagIndex}
                    className="btn gap-1 text-white fw-bold btn-sm py-0"
                    style={{
                      background: group?.category?.color,
                    }}
                  >
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          )}

          {todo?.file && (
            <div className="mt-3">
              <span className="d-block">{context?.t?.file}</span>
              <a href={todo?.file} target="_blank" rel="noopener noreferrer">
                {context?.t?.dosyayi_goruntule}
              </a>
            </div>
          )}

          <div style={{ background: "#ffffff10" }} className="px-3 py-2 mt-3">
            <div className="row align-items-center">
              <div className="col-12">
                {todo?.created_at && (
                  <span className="d-block" style={{ fontSize: 13 }}>
                    {context?.t?.olusturulma}:
                    <span className="text-white ms-2">
                      {moment(todo?.created_at).format("DD MMMM YYYY - HH:mm")}
                    </span>
                  </span>
                )}

                {todo?.updated_at && (
                  <span className="d-block" style={{ fontSize: 13 }}>
                    {context?.t?.guncelleme}:
                    <span className="text-white ms-2">
                      {moment(todo?.updated_at).format("DD MMMM YYYY - HH:mm")}
                    </span>
                  </span>
                )}
              </div>

              {todo?.user && (
                <div className="col position-relative user-info-hover border-top pt-2 mt-2">
                  <div className="pointer">
                    {todo?.user?.name}
                    <i className="fas fa-info-circle ms-2"></i>
                  </div>

                  <div
                    className="position-absolute start-0 bg-black text-white border p-2 user-info-hover-item"
                    style={{
                      fontSize: 12,
                      bottom: "100%",
                    }}
                  >
                    <div>
                      {context?.t?.role}: {todo?.user?.role?.name}
                    </div>
                    <div>
                      {context?.t?.username}: {todo?.user?.username}
                    </div>
                    <div>
                      {context?.t?.email}: {todo?.user?.email}
                    </div>
                    <div>
                      {context?.t?.phone}: {todo?.user?.phone}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <div className="row mb-5 pb-5 g-4">
      {groupedTodos ? (
        groupedTodos.length !== 0 ? (
          <div className="col-12">
            <div className="row g-4">
              {/* <div className="col-12">
                <div className="border p-3">
                  <Fancybox
                    options={{
                      Carousel: {
                        infinite: false,
                      },
                    }}
                  >
                    <div className="position-relative">
                      <Swiper
                        pagination={{ clickable: true }}
                        autoplay={{
                          delay: 5000,
                          disableOnInteraction: false,
                        }}
                        slidesPerView={1}
                        spaceBetween={20}
                        modules={[Autoplay, Pagination]}
                        breakpoints={{
                          767: {
                            slidesPerView: 2,
                          },
                        }}
                      >
                        {groupedTodos.map((group, index) =>
                          group.list
                            .filter(
                              (item) => item?.image && item?.image.length !== 0
                            )
                            .map((todo) =>
                              todo?.image.map((img, imgIndex) => (
                                <SwiperSlide key={imgIndex}>
                                  <Card>
                                    <CardBody>
                                      <a
                                        href={img}
                                        data-fancybox={"hero-gallery"}
                                        className="d-block"
                                      >
                                        <img
                                          key={imgIndex}
                                          src={img}
                                          alt={todo.title + "_" + imgIndex}
                                          className="w-100 img-cover"
                                          style={{ height: 400 }}
                                        />
                                      </a>

                                      <div className="row mt-2">
                                        <div className="col-12 col-md-6">
                                          <h5 className="mb-0">
                                            {todo.title.includes("#")
                                              ? todo.title.split("#")[1]
                                              : todo.title}
                                          </h5>
                                        </div>
                                        <div className="col-12 col-md-6 text-end">
                                          <span className="btn btn-dark btn-sm">
                                            {group?.category?.name}
                                          </span>
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </SwiperSlide>
                              ))
                            )
                        )}
                      </Swiper>
                    </div>
                  </Fancybox>
                </div>
              </div> */}

              {/* Category Filter Sidebar */}
              <div className="col" style={{ flex: "0 0 200px" }}>
                <div className="border p-3 h-100">
                  <div
                    className="d-flex flex-column gap-2 position-sticky"
                    style={{ top: 15 }}
                  >
                    <button
                      className={`btn w-100 px-1 btn${
                        selectedCategory === "TÜMÜ" ? "" : "-outline"
                      }-theme`}
                      onClick={() => handleCategoryClick("TÜMÜ")}
                    >
                      {context?.t?.all}
                    </button>

                    {groupedTodos.map((group, index) => (
                      <div
                        key={index}
                        className={`btn w-100 px-1 btn${
                          selectedCategory === group?.category?.name
                            ? ""
                            : "-outline"
                        }-theme`}
                        onClick={() =>
                          handleCategoryClick(group?.category?.name)
                        }
                      >
                        {group?.category?.name} ({group?.list.length})
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Content Display */}
              <div className="col">
                <div className="row g-4">
                  {selectedCategory === "TÜMÜ"
                    ? /* Display All Categories with Swiper */
                      filteredTodos.map((group, index) => (
                        <div className="col-12" key={group?.category?.id}>
                          <div className="border p-3">
                            <div className="mb-4 d-flex gap-2 align-items-center flex-wrap border-bottom pb-3">
                              <h4 className="mb-0">
                                {group?.category?.name} ({group?.list.length})
                              </h4>

                              <div className="d-flex gap-2 border-start ps-2">
                                <button
                                  className="btn btn-outline-theme"
                                  onClick={() =>
                                    todoSwipers[
                                      group?.category?.id
                                    ]?.slidePrev()
                                  }
                                >
                                  {context?.t?.prev}
                                </button>
                                <button
                                  className="btn btn-outline-theme"
                                  onClick={() =>
                                    todoSwipers[
                                      group?.category?.id
                                    ]?.slideNext()
                                  }
                                >
                                  {context?.t?.next}
                                </button>
                              </div>
                            </div>

                            <Swiper
                              onSwiper={(s) => {
                                setTodoSwipers((prevSwipers) => ({
                                  ...prevSwipers,
                                  [group?.category?.id]: s,
                                }));
                              }}
                              //   autoplay={{
                              //     delay: 3000,
                              //     disableOnInteraction: false,
                              //   }}
                              slidesPerView={1}
                              spaceBetween={20}
                              //   modules={[Autoplay]}
                              breakpoints={{
                                1370: {
                                  slidesPerView: 4,
                                },
                                1199: {
                                  slidesPerView: 4,
                                },
                                767: {
                                  slidesPerView: 2,
                                },
                              }}
                            >
                              {group.list.map((todo) => (
                                <SwiperSlide key={todo.id}>
                                  {TaskItem(group, todo)}
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      ))
                    : /* Display Only Selected Category without Swiper */
                      filteredTodos.map((group, groupIndex) => (
                        <div className="col-12" key={groupIndex}>
                          <div className="border p-3">
                            <div className="mb-4 d-flex gap-2 align-items-center flex-wrap border-bottom pb-3">
                              <h4 className="mb-0">
                                {group?.category?.name} ({group?.list.length})
                              </h4>
                            </div>

                            <div className="row g-4">
                              {group.list.map((todo) => (
                                <div
                                  className="col-12 col-md-6 col-lg-3"
                                  key={todo.id}
                                >
                                  {TaskItem(group, todo)}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12">{context?.t?.gorev_mevcut_degil}</div>
        )
      ) : (
        <div className="col-12">
          <div className="spinner-border spinner-border-sm text-theme"></div>
        </div>
      )}
    </div>
  );
}
