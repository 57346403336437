import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";

import DatePicker, { registerLocale } from "react-datepicker";
import { AppSettings } from "../../config/app-settings";
import { Card, CardBody } from "../../components/card/card";
import toastMessage from "../../components/ToastMessage";
import apiClient from "../../ApiClient";
import moment from "moment";

import tr from "date-fns/locale/tr";
import QuestionPool from "./QuestionPool";
registerLocale("tr", tr);

const ConditionComponent = ({
  t,
  condition,
  birimlerList,
  departmanAssign,
  removeDepartmanAssign,
}) => {
  if (!condition) return null;

  return (
    <div
      className="d-flex flex-column gap-2 mt-0 text-white"
      style={{
        marginLeft: "20px",
        borderLeft: "1px solid #fafafa",
        paddingLeft: "10px",
      }}
    >
      <div
        className="text-white d-flex align-items-center"
        style={{ whiteSpace: "nowrap" }}
      >
        <strong>{condition.answer}</strong>
        <span className="btn btn-dark px-2 py-0 ms-2">{condition.point}</span>
        {condition.is_required && (
          <span className="btn btn-danger px-2 py-0 ms-2">
            {t?.resim_zorunlu}
          </span>
        )}
      </div>
      <div
        style={{
          marginLeft: "20px",
          borderLeft: "1px solid #fafafa",
          paddingLeft: "10px",
        }}
      >
        <h5>{condition.question}</h5>
        <p className="my-2 text-white">
          <strong>{t?.cevaplar} - </strong>
          {condition?.type === "checkbox"
            ? " " + t?.coklu_cevap
            : condition?.type === "radio"
            ? " " + t?.tekli_cevap
            : condition?.type === "string" || condition?.type === "texxt"
            ? " " + t?.acik_uclu_cevap
            : " "}
        </p>
        <AnswerComponent
          t={t}
          answers={condition.answers}
          birimlerList={birimlerList}
          departmanAssign={departmanAssign}
          removeDepartmanAssign={removeDepartmanAssign}
        />
      </div>
    </div>
  );
};

const AnswerComponent = ({
  t,
  answers,
  birimlerList,
  departmanAssign,
  removeDepartmanAssign,
}) => {
  const [selectedDepartment, setSelectedDepartment] = useState({});

  const handleSelectChange = (answerId, selectedOption) => {
    setSelectedDepartment((prev) => ({
      ...prev,
      [answerId]: selectedOption,
    }));
  };

  return (
    <>
      {answers.map((answer) => {
        // Atanmış departmanların ID'lerini al
        const assignedDepartmentIds =
          answer.assigment?.map((assignment) => assignment.group?.id) || [];

        // Atanmamış departmanları filtrele
        const availableDepartments = birimlerList?.filter(
          (item) => !assignedDepartmentIds.includes(item.id)
        );

        return (
          <div key={answer.id} style={{ marginLeft: "20px" }} className="mb-2">
            <div
              className="mb-2 text-white d-flex align-items-center"
              style={{ whiteSpace: "nowrap" }}
            >
              <strong>{answer.answer}</strong>
              <span className="btn btn-dark px-2 py-0 ms-2">
                {answer.point}
              </span>
              {answer.is_required && (
                <span className="btn btn-danger px-2 py-0 ms-2">
                  {t?.resim_zorunlu}
                </span>
              )}
              <div
                className="border-start ps-2 ms-2 d-flex align-items-center gap-2"
                style={{ whiteSpace: "nowrap" }}
              >
                <div className="d-flex">
                  <div style={{ minWidth: 170 }}>
                    <Select
                      value={selectedDepartment[answer.id]}
                      onChange={(selectedOption) =>
                        handleSelectChange(answer.id, selectedOption)
                      }
                      options={
                        availableDepartments
                          ? availableDepartments.map((item) => {
                              return {
                                value: item.id,
                                label: item.name,
                              };
                            })
                          : []
                      }
                      classNamePrefix="react-select"
                      placeholder={t?.departman_sec}
                      noOptionsMessage={() => t?.no_option}
                    />
                  </div>
                  <div
                    className="btn btn-outline-theme ms-2"
                    onClick={() =>
                      departmanAssign(answer, selectedDepartment[answer.id])
                    }
                  >
                    {t?.atama_yap}
                  </div>
                </div>

                {answer.assigment &&
                  answer.assigment.length !== 0 &&
                  answer.assigment.map((answerAssignment) => (
                    <span className="btn btn-sm btn-warning position-relative">
                      {answerAssignment?.group?.name}
                      <div
                        className="btn btn-sm d-flex align-items-center py-0 px-1 btn-danger position-absolute top-0 end-0 mt-n2 me-n2 rounded"
                        style={{ height: 15 }}
                        onClick={() =>
                          removeDepartmanAssign(answer, {
                            value: answerAssignment?.group?.id,
                          })
                        }
                      >
                        <i
                          className="fas fa-trash-alt"
                          style={{ fontSize: 10 }}
                        ></i>
                      </div>
                    </span>
                  ))}
              </div>
            </div>

            {answer.condition && (
              <ConditionComponent
                t={t}
                condition={answer.condition}
                birimlerList={birimlerList}
                departmanAssign={departmanAssign}
                removeDepartmanAssign={removeDepartmanAssign}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

const QuestionComponent = ({
  t,
  question,
  index,
  totalQuestions,
  departmanAssign,
  removeDepartmanAssign,
  birimlerList,
}) => {
  return (
    <Card className="mb-3" style={{ zIndex: totalQuestions - index - 1 }}>
      <CardBody>
        <h5>
          {index + 1}. {question.question}
          <span className="btn btn-theme text-black fw-bold px-2 py-0 ms-2">
            {question.point}
          </span>
          {question.category && (
            <span className="btn btn-dark px-2 py-0 ms-2">
              {question.category?.name} - {question.category?.point}
            </span>
          )}
          {question.is_required && (
            <span className="btn btn-danger px-2 py-0 ms-2">{t?.zorunlu}</span>
          )}
        </h5>
        <p className="mb-2 text-white">
          <strong>{t?.cevaplar} - </strong>
          {question.type === "checkbox"
            ? " " + t?.coklu_cevap
            : question.type === "radio"
            ? " " + t?.tekli_cevap
            : question.type === "string" || question.type === "text"
            ? " " + t?.acik_uclu_cevap
            : ""}{" "}
        </p>
        <AnswerComponent
          t={t}
          answers={question.answers}
          birimlerList={birimlerList}
          departmanAssign={departmanAssign}
          removeDepartmanAssign={removeDepartmanAssign}
        />
      </CardBody>
    </Card>
  );
};

const SurveyComponent = ({
  t,
  survey,
  birimlerList,
  departmanAssign,
  removeDepartmanAssign,
}) => {
  return (
    <div
      className="mt-2 overflow-auto position-relative"
      style={{ whiteSpace: "nowrap", zIndex: 1 }}
    >
      {survey.questions.map((question, index) => (
        <QuestionComponent
          t={t}
          key={question.id}
          question={question}
          birimlerList={birimlerList}
          departmanAssign={departmanAssign}
          removeDepartmanAssign={removeDepartmanAssign}
          totalQuestions={survey.questions.length}
          index={index}
        />
      ))}
    </div>
  );
};

const TreeItemModal = ({ t, item, onSelect, selectedId }) => {
  const handleChange = () => {
    onSelect(item.id);
  };

  return (
    <li className="mx-1 my-1">
      <div className="d-flex gap-2 mt-3">
        <div className="form-check form-radio">
          <input
            type="radio"
            className="form-check-input"
            id={`radio-${item.id}`}
            checked={selectedId === item.id}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor={`radio-${item.id}`}>
            <strong>{item.name}</strong> - {item.point}
          </label>
        </div>
      </div>

      {item.children && item.children.length > 0 && (
        <ol className="my-1">
          {item.children.map((child) => (
            <TreeItemModal
              t={t}
              key={child.id}
              item={child}
              onSelect={onSelect}
              selectedId={selectedId}
            />
          ))}
        </ol>
      )}
    </li>
  );
};

const TreeItem = ({
  t,
  item,
  index,
  parentIndex,
  setCategoryDelete,
  setCategoryUpdate,
  createSurvey,
}) => {
  return (
    <Card className="mt-3 bg-none">
      <CardBody className="p-3 bg-none">
        <div className="row align-items-center">
          {createSurvey && (
            <div className="col w-auto flex-none">
              <button
                type="button"
                className="btn btn-sm btn-success me-2"
                data-bs-toggle="modal"
                data-bs-target="#createCategoryModal"
                onClick={() => setCategoryUpdate(item)}
              >
                <i className="fas fa-edit"></i>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#reportCategoryDeleteConfirmModal"
                onClick={() => setCategoryDelete(item)}
              >
                <i className="fas fa-trash-alt"></i>
              </button>
            </div>
          )}

          <div className="col">
            <div>
              <strong className="me-2">
                {parentIndex ? parentIndex + "-" : ""}
                {index + 1}.
              </strong>
              <strong className="text-white">{item.name}</strong> - {item.point}
            </div>
          </div>
        </div>

        {item.children && item.children.length > 0 && (
          <div className="mt-3">
            {item.children.map((child, childIndex) => (
              <TreeItem
                t={t}
                key={child.id}
                item={child}
                index={childIndex}
                parentIndex={index + 1}
                setCategoryDelete={setCategoryDelete}
                setCategoryUpdate={setCategoryUpdate}
                createSurvey={createSurvey}
              />
            ))}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

const TreeView = ({
  t,
  data,
  setCategoryDelete,
  setCategoryUpdate,
  createSurvey,
}) => {
  return (
    <div className="overflow-auto">
      {data.map((item, index) => (
        <TreeItem
          t={t}
          key={item.id}
          item={item}
          index={index}
          setCategoryDelete={setCategoryDelete}
          setCategoryUpdate={setCategoryUpdate}
          createSurvey={createSurvey}
        />
      ))}
    </div>
  );
};

export default function RaporOlustur() {
  const context = useContext(AppSettings);

  const [raporCustomer, setRaporCustomer] = useState(null);
  const [raporAdi, setRaporAdi] = useState("");
  // const [questions, setQuestions] = useState([]);
  const [reportEdit, setReportEdit] = useState(null);
  const [reportCopy, setReportCopy] = useState(false);

  const [reportLoader, setReportLoader] = useState(false);

  const [userSelectValue, setUserSelectValue] = useState(false);
  const [userSelectData, setUserSelectData] = useState([]);
  const [raporUserSelectData, setRaporUserSelectData] = useState([]);

  const [userStartDate, setUserStartDate] = useState(new Date());
  const [userEndDate, setUserEndDate] = useState(new Date());

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        kategori: null,
        soruRequired: false,
        soruPuani: "",
        soruAdi: "",
        cevapTipi: null,
        textAnswer: "",
        textAnswerPuan: "",
        answers: [],
      },
    ]);
  };

  const handleQuestionChange = (index, key, value) => {
    const newQuestions = questions.slice();
    newQuestions[index][key] = value;
    setQuestions(newQuestions);
  };

  const handleRemoveQuestion = (questionIndex) => {
    const newQuestions = questions.slice();
    newQuestions.splice(questionIndex, 1);
    setQuestions(newQuestions);
  };

  const handleAddAnswer = (questionIndex) => {
    const newQuestions = questions.slice();
    newQuestions[questionIndex].answers.push({
      puan: "",
      cevap: "",
      is_required: false,
    });
    setQuestions(newQuestions);
  };

  const handleRemoveAnswer = (questionIndex, answerIndex) => {
    const newQuestions = questions.slice();
    newQuestions[questionIndex].answers.splice(answerIndex, 1);
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (questionIndex, answerIndex, key, value) => {
    const newQuestions = questions.slice();
    newQuestions[questionIndex].answers[answerIndex][key] = value;
    setQuestions(newQuestions);
  };

  const handleSubmit = async () => {
    if (!raporCustomer) {
      toastMessage("Müşteri Seçmelisiniz", "error");
      return;
    }

    if (!raporAdi && raporAdi === "") {
      toastMessage("Anket Adı Girmelisiniz", "error");
      return;
    }

    if (questions.length === 0) {
      toastMessage("En Az Bir Soru Girmelisiniz", "error");
      return;
    }

    for (const question of questions) {
      if (
        !question.kategori ||
        !question.soruPuani ||
        !question.soruAdi ||
        !question.cevapTipi
      ) {
        toastMessage("Tüm Soru Alanlar Doldurulmalısınız", "error");
        return;
      }

      if (question.cevapTipi.value === "string") {
        if (!question.textAnswerPuan) {
          toastMessage("Metin Puanı Girmelisiniz", "error");
          return;
        }
      } else {
        if (question.answers.length === 0) {
          toastMessage("En Az Bir Cevap Girmelisiniz", "error");
          return;
        }

        for (const answer of question.answers) {
          if (!answer.puan || !answer.cevap) {
            toastMessage("Tüm Cevap Alanları Doldurulmalısınız", "error");
            return;
          }
        }
      }
    }

    const transformedData = questions.map((item) => {
      return {
        type: item.cevapTipi.value,
        question: item.soruAdi,
        is_required: item.soruRequired,
        point: parseInt(item.soruPuani, 10),
        category: item.kategori.value,
        answers:
          item.cevapTipi.value === "string"
            ? [
                {
                  answer: "-",
                  point: item.textAnswerPuan,
                  is_required: false,
                },
              ]
            : item.answers.map((answer) => ({
                answer: answer.cevap,
                point: parseFloat(answer.puan, 10),
                is_required: answer.is_required,
              })),
      };
    });

    const payload = {
      name: raporAdi,
      customer_id: raporCustomer.value,
      is_required: true,
      questions: transformedData,
    };

    setReportLoader(true);

    try {
      reportEdit
        ? await apiClient.put(`/street-report/${reportEdit[0].id}`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
        : await apiClient.customPost(`/street-report`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

      setReportLoader(false);

      toastMessage(
        `Anket ${reportEdit ? "Güncellendi" : "Oluşturuldu"}`,
        "success"
      );
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      setReportLoader(false);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  };

  const [reportDelete, setReportDelete] = useState(null);
  const [reportDeleteLoader, setReportDeleteLoader] = useState(false);

  async function handleDeleteReport() {
    setReportDeleteLoader(true);

    try {
      await apiClient.delete(`/street-report/${reportDelete[0].id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      toastMessage(context?.t?.anket_silindi, "success");
      setTimeout(() => {
        setReportDeleteLoader(false);
        document.getElementById("reportDeleteConfirmModalCloseButton").click();
        handleGetReports();
        setReportNameSelect(null);
        setReportData([]);
      }, 300);
    } catch (error) {
      setReportDeleteLoader(false);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  // useEffect(() => {
  //   if (reportEdit) {
  //     setRaporCustomer({
  //       value: reportEdit[0]?.customer_id,
  //       label: context.allCustomerSelect.find(
  //         (customer) => customer.value === reportEdit[0]?.customer_id
  //       )?.label,
  //     });
  //     setRaporAdi(reportEdit[0]?.name);

  //     const editQuestionsData = reportEdit[0]?.questions.map((item) => {
  //       return {
  //         cevapTipi: {
  //           value: item.type,
  //           label:
  //             item.type === "checkbox"
  //               ? "Çoklu Cevap"
  //               : item.type === "radio"
  //               ? "Tekli Cevap"
  //               : item.type === "string"
  //               ? "Açık Uçlu Cevap"
  //               : "",
  //         },
  //         soruAdi: item.question,
  //         soruRequired: item.is_required,
  //         soruPuani: item.point,
  //         kategori: { value: item.category.id, label: item.category.name },
  //         textAnswer: "-",
  //         textAnswerPuan: item.answers[0].point
  //           ? parseFloat(item.answers[0].point)
  //           : "",
  //         answers:
  //           item.type !== "string"
  //             ? item.answers.map((answer) => ({
  //                 id: answer.id,
  //                 cevap: answer.answer,
  //                 is_required: answer.is_required,
  //                 puan: parseFloat(answer.point),
  //               }))
  //             : [],
  //       };
  //     });

  //     setQuestions(editQuestionsData);
  //   } else {
  //     setRaporCustomer(null);
  //     setRaporAdi("");
  //     setQuestions([]);
  //   }
  // }, [reportEdit]);

  //--

  const [reportCustomerValue, setReportCustomerValue] = useState(null);
  const [reportsNameData, setReportsNameData] = useState([]);
  const [reportNameSelect, setReportNameSelect] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [reportsUsersData, setReportsUsersData] = useState(null);

  const [categoryPoint, setCategoryPoint] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");

  const [categoryData, setCategoryData] = useState([]);
  const [categoryDelete, setCategoryDelete] = useState(null);
  const [categoryUpdate, setCategoryUpdate] = useState(null);
  const [categoryLoader, setCategoryLoader] = useState(false);

  const [selectedId, setSelectedId] = useState(null);

  async function handleRaporCustomerSelect(e) {
    setRaporCustomer(e);
    setRaporUserSelectData(null);

    if (e) {
      try {
        const response = await apiClient.get(`user?customer_id=${e.value}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setRaporUserSelectData(response.data);
      } catch (error) {
        setRaporUserSelectData([]);
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  async function handleCustomerSelect(e) {
    setReportCustomerValue(e);

    if (e) {
      try {
        const response = await apiClient.get(`user?customer_id=${e.value}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setUserSelectData(response.data);
      } catch (error) {
        setUserSelectData([]);
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  useEffect(() => {
    if (context?.me?.role.id !== 1) {
      handleCustomerSelect({
        value: context?.me?.customer?.id,
        label: context?.me?.customer?.customer?.name,
      });
    }
  }, [context]);

  async function handleApplyUserReport(reportId) {
    if (userSelectValue) {
      try {
        const response = await apiClient.customPost(
          `/street-report-assigment`,
          {
            user_id: userSelectValue.value,
            report_id: reportData[0].id,
            started_at: moment(userStartDate).format("YYYY-MM-DD HH:mm:ss"),
            ended_at: moment(userEndDate).format("YYYY-MM-DD HH:mm:ss"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          toastMessage(context?.t?.kullanici_atandi, "success");
          setReportsUsersData(null);
          handleGetUsersReport({
            id: reportId,
          });
        }
      } catch (error) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  async function handleGetUsersReport(data) {
    if (data) {
      try {
        const response = await apiClient.get(
          `/street-report-assigment?report=${data.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          setReportsUsersData(response.data);
        }
      } catch (error) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  async function handleDeleteUserReport(id, reportId) {
    try {
      await apiClient.delete(`/street-report-assigment/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      toastMessage(context?.t?.kisi_kaldirildi, "success");
      setReportsUsersData(null);
      handleGetUsersReport({
        id: reportId,
      });
    } catch (error) {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  const handleSelect = (id) => {
    setSelectedId(id);
  };

  const flattenData = (data) => {
    let result = [];

    const recurse = (items) => {
      items.forEach((item) => {
        result.push({ value: item.id, label: item.name });
        if (item.children && item.children.length > 0) {
          recurse(item.children);
        }
      });
    };

    recurse(data);
    return result;
  };

  async function handleGetReports() {
    setReportsNameData(null);

    try {
      const response = await apiClient.get(
        `/street-report?customer=${reportCustomerValue.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.length !== 0) {
        const formattedData = response.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        setReportsNameData(formattedData);
      } else {
        setReportsNameData([]);
      }
    } catch (error) {
      setReportsNameData(null);

      if (error?.response?.data) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  useEffect(() => {
    if (reportCustomerValue) {
      handleGetReports();
    }
  }, [reportCustomerValue]);

  async function handleGetShowReport() {
    setReportData(null);

    try {
      const response = await apiClient.get(
        `/street-report/${reportNameSelect.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setReportData([response.data]);
      handleGetUsersReport(response.data);
    } catch (error) {
      setReportData(null);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (reportNameSelect) {
      handleGetShowReport();
    }
  }, [reportNameSelect]);

  // Category
  async function handleGetCategory() {
    setCategoryData(null);

    try {
      const response = await apiClient.get("/street-report-categories", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setCategoryData(response.data);
    } catch (error) {
      setCategoryData(null);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    handleGetCategory();
  }, []);

  async function handleCreateCategory() {
    setCategoryLoader(true);

    if (!categoryPoint && categoryPoint === "") {
      toastMessage(context?.t?.kategori_puan_girmelisiniz, "error");
      return;
    }

    if (!categoryTitle && categoryTitle === "") {
      toastMessage(context?.t?.kategori_adi_girmelisiniz, "error");
      return;
    }

    if (categoryUpdate) {
      try {
        const currentCategory = {
          name: categoryUpdate.name,
          point: categoryUpdate.point,
          parent_id: categoryUpdate.parent_id || null,
        };

        const updatedCategory = {
          name: categoryTitle,
          point: categoryPoint,
          parent_id: selectedId || null,
        };

        let payload = {};

        if (currentCategory.name !== updatedCategory.name) {
          payload.name = updatedCategory.name;
        }
        if (currentCategory.point !== updatedCategory.point) {
          payload.point = updatedCategory.point;
        }
        payload.parent_id = updatedCategory.parent_id;

        if (Object.keys(payload).length > 0) {
          await apiClient.put(
            "/street-report-categories/" + categoryUpdate.id,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          toastMessage(
            ` ${
              categoryUpdate
                ? context?.t?.kategori_guncellendi
                : context?.t?.kategori_olusturuldu
            }`,
            "success"
          );
          document.getElementById("createCategoryModalCloseButton").click();
          handleGetCategory();
          setCategoryLoader(false);
        } else {
          setCategoryLoader(false);
          toastMessage(context?.t?.degisiklik_bulunamadi, "error");
        }
      } catch (error) {
        setCategoryLoader(false);

        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    } else {
      try {
        await apiClient.customPost(
          `/street-report-categories`,
          {
            name: categoryTitle,
            point: categoryPoint,
            parent_id: selectedId || null,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        toastMessage(
          `${
            categoryUpdate
              ? context?.t?.kategori_guncellendi
              : context?.t?.kategori_olusturuldu
          }`,
          "success"
        );

        document.getElementById("createCategoryModalCloseButton").click();
        handleGetCategory();
        setCategoryLoader(false);
      } catch (error) {
        setCategoryLoader(false);

        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  async function handleDeleteCategory() {
    try {
      await apiClient.delete(`/street-report-categories/${categoryDelete.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      toastMessage(context?.t?.kategori_silindi, "success");
      document.getElementById("deleteConfirmModalClose").click();
      handleGetCategory();
    } catch (error) {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (categoryUpdate) {
      setCategoryPoint(categoryUpdate.point);
      setCategoryTitle(categoryUpdate.name);
      setSelectedId(categoryUpdate.parent_id);
    } else {
      setCategoryPoint("");
      setCategoryTitle("");
      setSelectedId(null);
    }
  }, [categoryUpdate]);

  // Departmanlar
  const [birimlerList, setBirimlerList] = useState(null);

  async function fetchBirimler() {
    setBirimlerList(null);

    try {
      const response = await apiClient.get(`/groups`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setBirimlerList(
        response.data.filter(
          (item) => item.customer_id === reportCustomerValue.value
        )
      );
    } catch (error) {
      setBirimlerList([]);
      console.error("Birimler", error);
    }
  }

  useEffect(() => {
    if (reportCustomerValue) {
      fetchBirimler();
    }
  }, [reportCustomerValue]);

  // Departman Assign
  async function departmanAssign(answer, group) {
    if (!group) {
      toastMessage(context?.t?.departman_secmelisiniz, "error");
      return;
    }

    const payload = {
      answer_id: answer?.id,
      group_id: group?.value,
    };

    try {
      const response = await apiClient.customPost(
        `/groups/assignment`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response) {
        toastMessage(context?.t?.departman_atandi, "success");
        handleGetShowReport();
      }
    } catch (error) {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  async function removeDepartmanAssign(answer, group) {
    if (!group) {
      toastMessage(context?.t?.departman_secmelisiniz, "error");
      return;
    }

    const payload = {
      answer_id: answer?.id,
      group_id: group?.value,
    };

    try {
      const response = await apiClient.customDelete(
        `/groups/assignment/delete`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response) {
        toastMessage(context?.t?.departman_kaldirildi, "success");
        handleGetShowReport();
      }
    } catch (error) {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  //--

  const [customer, setCustomer] = useState(null);
  const [anketAdi, setAnketAdi] = useState(null);
  const [questions, setQuestions] = useState([]);

  return (
    <div className="row mb-5 pb-5">
      <div className="col-12">
        <div className="d-flex align-items-center gap-4">
          <h3 className="mb-0">{context?.t?.soru_kategorileri}</h3>
          {context?.me?.customer?.settings?.createSurvey && (
            <div className="d-flex align-items-center gap-3">
              <button
                type="button"
                className="btn btn-outline-theme"
                data-bs-toggle="modal"
                data-bs-target="#createCategoryModal"
                onClick={() => setCategoryUpdate(null)}
              >
                {context?.t?.kategori_olustur}
              </button>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-12">
            {categoryData ? (
              categoryData.length !== 0 ? (
                <TreeView
                  t={context?.t}
                  data={categoryData}
                  setCategoryDelete={setCategoryDelete}
                  setCategoryUpdate={setCategoryUpdate}
                  createSurvey={context?.me?.customer?.settings?.createSurvey}
                />
              ) : (
                <span>{context?.t?.kategori_bulunamadi}</span>
              )
            ) : (
              <div className="spinner-border mt-3 text-theme"></div>
            )}
          </div>
        </div>
      </div>

      <div className="col-12 my-3">
        <hr />
      </div>

      <div className="col-12">
        <div className="d-flex flex-wrap align-items-center gap-3 mb-3">
          <h3 className="mb-0">{context?.t?.anket}</h3>
          {context?.me?.customer?.settings?.createSurvey ? (
            <button
              type="button"
              className="btn btn-outline-theme"
              data-bs-toggle="modal"
              data-bs-target="#createReportModal"
              onClick={() => {
                setCustomer(null);
                setAnketAdi("");
                setQuestions([]);
                setReportEdit(null);
                setReportCopy(false);
              }}
            >
              {context?.t?.anket_olustur}
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-theme"
              onClick={() =>
                toastMessage(
                  "Yetkiniz bulunmamaktadır. Kentmetre teknik ekibiyle iletişime geçiniz.",
                  "error"
                )
              }
            >
              {context?.t?.anket_olustur}
            </button>
          )}

          {context?.me?.role.id === 1 && (
            <div style={{ width: 200 }}>
              {context.allCustomerSelect ? (
                <Select
                  options={context.allCustomerSelect}
                  placeholder={context?.t?.select_customer}
                  classNamePrefix="react-select"
                  noOptionsMessage={() => context?.t?.no_option}
                  value={reportCustomerValue}
                  onChange={(e) => handleCustomerSelect(e)}
                />
              ) : (
                <div className="spinner-border spinner-border-sm text-theme"></div>
              )}
            </div>
          )}

          <div style={{ width: 200 }}>
            {reportsNameData ? (
              <Select
                options={reportsNameData}
                placeholder={context?.t?.anket_sec}
                classNamePrefix="react-select"
                noOptionsMessage={() => context?.t?.no_option}
                value={reportNameSelect}
                onChange={(e) => setReportNameSelect(e)}
              />
            ) : (
              <div className="spinner-border spinner-border-sm text-theme"></div>
            )}
          </div>
        </div>

        <div className="row">
          {reportData ? (
            reportData.length !== 0 ? (
              <div className="col-12">
                <Card>
                  <CardBody>
                    {context?.me?.customer?.settings?.createSurvey && (
                      <>
                        <div className="row g-2 align-items-center justify-content-between">
                          <div className="col-6 w-auto flex-none">
                            <div className="row g-2">
                              <div className="col w-auto flex-none">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-dark"
                                  data-bs-toggle="modal"
                                  data-bs-target="#createReportModal"
                                  onClick={() => {
                                    setCustomer(null);
                                    setAnketAdi("");
                                    setQuestions([]);
                                    setReportEdit(null);
                                    setReportCopy(false);

                                    setTimeout(() => {
                                      setReportEdit(reportData);
                                      setReportCopy(true);
                                    }, 300);
                                  }}
                                >
                                  {context?.t?.anketi_kopyala}
                                </button>
                              </div>

                              <div className="col w-auto flex-none">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-success"
                                  data-bs-toggle="modal"
                                  data-bs-target="#createReportModal"
                                  onClick={() => {
                                    setCustomer(null);
                                    setAnketAdi("");
                                    setQuestions([]);
                                    setReportEdit(null);
                                    setReportCopy(false);

                                    setTimeout(() => {
                                      setReportEdit(reportData);
                                    }, 300);
                                  }}
                                >
                                  {context?.t?.anketi_duzenle}
                                </button>
                              </div>

                              <div className="col w-auto flex-none">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger"
                                  data-bs-toggle="modal"
                                  data-bs-target="#reportDeleteConfirmModal"
                                  onClick={() => setReportDelete(reportData)}
                                >
                                  {context?.t?.anketi_kaldir}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div
                          className="col-6 w-auto flex-none position-relative"
                          style={{ zIndex: 2 }}
                        >
                          <span className="d-block mb-2 text-white fw-bold">
                            {context?.t?.kisiye_atama}
                          </span>

                          <div className="row g-2 align-items-end justify-content-between">
                            <div className="col">
                              <span className="d-block">
                                {context?.t?.kisiler}
                              </span>
                              <Select
                                options={userSelectData
                                  .filter((item) => item.role.id === 5)
                                  .map((item) => {
                                    return { value: item.id, label: item.name };
                                  })}
                                placeholder=""
                                classNamePrefix="react-select"
                                noOptionsMessage={() => context?.t?.no_option}
                                value={userSelectValue}
                                onChange={(e) => setUserSelectValue(e)}
                              />
                            </div>

                            <div className="col">
                              <span className="d-block">
                                {context?.t?.started_date}
                              </span>
                              <DatePicker
                                locale="tr"
                                className="form-control"
                                required
                                selected={userStartDate}
                                onChange={(e) => setUserStartDate(e)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption={context?.t?.hour}
                                dateFormat="dd-MM-yyyy HH:mm"
                              />
                            </div>

                            <div className="col">
                              <span className="d-block">
                                {context?.t?.end_date}
                              </span>
                              <DatePicker
                                locale="tr"
                                className="form-control"
                                required
                                selected={userEndDate}
                                onChange={(e) => setUserEndDate(e)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption={context?.t?.hour}
                                dateFormat="dd-MM-yyyy HH:mm"
                              />
                            </div>

                            {userSelectValue && (
                              <div className="col w-auto flex-none">
                                <button
                                  type="button"
                                  className="btn btn-outline-theme"
                                  onClick={() =>
                                    handleApplyUserReport(reportData[0]?.id)
                                  }
                                >
                                  {context?.t?.send}
                                </button>
                                {/* <button
                                  type="button"
                                  className="btn btn-sm btn-danger ms-2"
                                  onClick={() => setUserSelectValue(null)}
                                >
                                  Kaldır
                                </button> */}
                              </div>
                            )}
                          </div>
                        </div>

                        <hr />
                      </>
                    )}

                    <h5>{context?.t?.atanmis_kullanicilar}</h5>
                    {reportsUsersData ? (
                      reportsUsersData.length !== 0 ? (
                        <div className="dataTables_wrapper dt-bootstrap5">
                          <div className="dataTables_scroll">
                            <div className="dataTables_scrollBody table-responsive">
                              <table className="table text-nowrap w-100 text-center dataTable mb-0">
                                <thead>
                                  <tr>
                                    <th className="px-1 text-center">#</th>
                                    <th className="px-1 text-center">
                                      {context?.t?.kisi}
                                    </th>
                                    <th className="px-1 text-center">
                                      {context?.t?.started_date}
                                    </th>
                                    <th className="px-1 text-center">
                                      {context?.t?.end_date}
                                    </th>
                                    <th className="px-1 text-center">
                                      {context?.t?.transaction}
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {reportsUsersData.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item?.user?.name}</td>
                                      <td>
                                        {moment(item?.started_at).format(
                                          "DD MMMM YYYY HH:mm"
                                        )}
                                      </td>
                                      <td>
                                        {moment(item?.ended_at).format(
                                          "DD MMMM YYYY HH:mm"
                                        )}
                                      </td>
                                      <td>
                                        {context?.me?.customer?.settings
                                          ?.createSurvey && (
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-danger w-100"
                                            onClick={() =>
                                              handleDeleteUserReport(
                                                item?.id,
                                                item?.report_id
                                              )
                                            }
                                          >
                                            {context?.t?.remove}
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <span>{context?.t?.atanmis_kullanici_bulunamadi}</span>
                      )
                    ) : (
                      <div className="spinner-border text-theme"></div>
                    )}

                    <hr />

                    <div className="row align-items-center justify-content-between">
                      <div className="col-6">
                        <h4 className="mb-2">{reportData[0].name || "-"}</h4>
                        <h5>
                          {context?.t?.customer}:{" "}
                          {
                            context.allCustomerSelect.find(
                              (customer) =>
                                customer.value === reportData[0].customer_id
                            )?.label
                          }
                        </h5>
                      </div>
                    </div>

                    {reportData && reportData[0].questions.length !== 0 && (
                      <SurveyComponent
                        t={context?.t}
                        survey={reportData[0]}
                        birimlerList={birimlerList}
                        departmanAssign={departmanAssign}
                        removeDepartmanAssign={removeDepartmanAssign}
                      />
                    )}
                  </CardBody>
                </Card>
              </div>
            ) : (
              <div className="col-12">
                <span className="text-white">
                  {context?.t?.bir_anket_secin}
                </span>
              </div>
            )
          ) : (
            <div className="col-12">
              <div className="spinner-border text-theme"></div>
            </div>
          )}
        </div>
      </div>

      <div className="modal modal-cover fade" id="createCategoryModal">
        <div className="modal-dialog py-4" style={{ maxWidth: 800 }}>
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                {categoryUpdate
                  ? context?.t?.soru_kategori_guncelle
                  : context?.t?.soru_kategori_ekle}
              </h3>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="createCategoryModalCloseButton"
              ></button>
            </div>

            {context?.me?.customer?.settings?.createSurvey && (
              <div className="modal-body">
                <div className="d-flex flex-column gap-3">
                  <div>
                    <span className="d-block mb-1">{context?.t?.puan}</span>
                    <input
                      type="number"
                      className="form-control"
                      value={categoryPoint}
                      onChange={(e) => setCategoryPoint(e.target.value)}
                    />
                  </div>

                  <div>
                    <span className="d-block mb-1">
                      {context?.t?.kategori_adi}
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      value={categoryTitle}
                      onChange={(e) => setCategoryTitle(e.target.value)}
                    />
                  </div>

                  {categoryData && categoryData.length !== 0 && (
                    <div>
                      <div className="d-flex mb-1 gap-1">
                        <span className="d-block">
                          {context?.t?.ust_kategori}
                        </span>
                        {selectedId && (
                          <span
                            className="d-block text-danger pointer"
                            onClick={() => setSelectedId(null)}
                          >
                            {context?.t?.kategori_kaldir}
                          </span>
                        )}
                      </div>

                      <div className="overflow-auto" style={{ height: 300 }}>
                        <ol>
                          {categoryData.map((item) => (
                            <TreeItemModal
                              t={context?.t}
                              key={item.id}
                              item={item}
                              onSelect={handleSelect}
                              selectedId={selectedId}
                            />
                          ))}
                        </ol>
                      </div>
                    </div>
                  )}
                </div>

                {context?.me?.customer?.settings?.createSurvey && (
                  <button
                    type="button"
                    className="btn btn-theme mt-3"
                    onClick={() => handleCreateCategory()}
                    disabled={categoryLoader}
                  >
                    {categoryUpdate
                      ? context?.t?.kategori_guncelle
                      : context?.t?.kategori_ekle}
                    {categoryLoader && (
                      <div className="spinner-border spinner-border-sm text-white"></div>
                    )}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="createReportModal">
        <div className="modal-dialog py-4" style={{ maxWidth: 1300 }}>
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                {reportCopy
                  ? context?.t?.anketi_kopyala
                  : reportEdit
                  ? context?.t?.anketi_duzenle
                  : context?.t?.anketi_olustur}
              </h3>

              <button
                type="button"
                className="btn-close"
                id="createReportModalCloseButton"
                data-bs-dismiss="modal"
              ></button>
            </div>

            {context?.me?.customer?.settings?.createSurvey && (
              <QuestionPool
                reportCopy={reportCopy}
                reportEdit={reportEdit}
                setReportLoader={setReportLoader}
                reportLoader={reportLoader}
                categoryData={categoryData}
                handleGetReports={handleGetReports}
                handleGetShowReport={handleGetShowReport}
                customer={customer}
                anketAdi={anketAdi}
                questions={questions}
                setCustomer={setCustomer}
                setAnketAdi={setAnketAdi}
                setQuestions={setQuestions}
              />
            )}
          </div>
        </div>
      </div>

      <div
        className="modal modal-cover fade"
        id="reportCategoryDeleteConfirmModal"
      >
        <div className="modal-dialog">
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                {context?.t?.are_you_sure}
                <br />
                <span className="h5">
                  {context?.t?.iliskili_tum_kategoriler_silinecektir}
                </span>
                <br />
                <span className="h5">
                  {context?.t?.kategori_adi}:{" "}
                  {categoryDelete && categoryDelete.name}
                </span>
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="deleteConfirmModalClose"
              ></button>
            </div>

            {context?.me?.customer?.settings?.createSurvey && (
              <div className="modal-body">
                <div className="d-flex gap-3">
                  <button
                    type="button"
                    className="btn btn-outline-danger w-100"
                    onClick={() => handleDeleteCategory()}
                  >
                    {context?.t?.delete}
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-theme w-100"
                    data-bs-dismiss="modal"
                  >
                    {context?.t?.cancel}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="reportDeleteConfirmModal">
        <div className="modal-dialog">
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                {context?.t?.are_you_sure}
                <br />
                <span className="h5">
                  {context?.t?.anket_adi}:{" "}
                  {reportDelete && reportDelete[0].name}
                </span>
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="reportDeleteConfirmModalCloseButton"
              ></button>
            </div>

            {context?.me?.customer?.settings?.createSurvey && (
              <div className="modal-body">
                <div className="d-flex gap-3">
                  <button
                    type="button"
                    className="btn btn-outline-danger w-100"
                    onClick={() => handleDeleteReport()}
                    disabled={reportDeleteLoader}
                  >
                    {context?.t?.delete}
                    {reportDeleteLoader && (
                      <div className="spinner-border spinner-border-sm ms-2"></div>
                    )}
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-theme w-100"
                    data-bs-dismiss="modal"
                  >
                    {context?.t?.cancel}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
