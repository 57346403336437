import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback,
} from "react";

import Select from "react-select";
import { ToastContainer } from "react-toastify";
import ReactECharts from "echarts-for-react";

import { AppSettings } from "../../config/app-settings.js";
import { Card, CardBody } from "../card/card.jsx";
import toastMessage from "../ToastMessage.js";
import apiClient from "../../ApiClient.js";
import WordCloud from "../WordCloud.jsx";
import DynamicTable from "../DynamicTable.jsx";
import DynamicMap from "../DynamicMap.jsx";
import moment from "moment";

function formatErrorMessage(message, errorMessage) {
  const parts = message.split(":");

  const mainMessage = parts[0].trim();
  const note = parts[1] && parts[1].trim();

  return `${errorMessage} 
  
  ${mainMessage}
  ${note} `;
}

export default function CreateGraphicComponent({ modalOpen = false }) {
  const context = useContext(AppSettings);

  const [chartViewSelectOptions, setChartViewSelectOptions] = useState([
    { value: 1, label: "Pasta Grafiği" },
    { value: 2, label: "Çubuk Grafiği" },
    { value: 3, label: "Kelime Bulutu" },
    { value: 4, label: "Tablo" },
    { value: 5, label: "Harita" },
  ]);

  const [columnOperationSelectOptions, setColumnOperationSelectOptions] =
    useState([
      { value: "select", label: "TÜMÜ" },
      { value: "column", label: "SÜTUN" },
      { value: "count", label: "SAY" },
      { value: "avg", label: "ORTALAMA" },
      { value: "sum", label: "TOPLAM" },
      { value: "min", label: "MİNİMUM" },
      { value: "max", label: "MAKSİMUM" },
      { value: "distinct", label: "FARKLI" },
      { value: "toDate", label: "TARİH" },
      { value: "dayOfMonth", label: "GÜN" },
      { value: "toMonth", label: "AY" },
      { value: "toYear", label: "YIL" },
      { value: "toHour", label: "SAAT" },
      { value: "toMinute", label: "DAKİKA" },
      { value: "toSecond", label: "SANİYE" },
    ]);

  const [selectedOperatorOptions, setSelectedOperatorOptions] = useState([
    { value: "and", label: "Ve" },
    { value: "or", label: "Veya" },
  ]);

  const [selectedConditionOptions, setSelectedConditionOptions] = useState([
    { value: "=", label: "=" },
    { value: "!=", label: "!=" },
    { value: ">", label: ">" },
    { value: ">=", label: ">=" },
    { value: "<", label: "<" },
    { value: "<=", label: "<=" },
    { value: "in", label: "İÇİNDE" },
    { value: "notin", label: "DIŞINDA" },
    { value: "like", label: "BENZER" },
    { value: "notlike", label: "BENZEMEZ" },
    { value: "isnull", label: "BOŞ" },
    { value: "isnotnull", label: "DOLU" },
  ]);

  const [
    legendPositionOrientSelectOptions,
    setLegendPositionOrientSelectOptions,
  ] = useState([
    {
      value: "vertical",
      label: "Dikey",
    },
    {
      value: "horizontal",
      label: "Yatay",
    },
  ]);

  const [legendPositionLeftSelectOptions, setLegendPositionLeftSelectOptions] =
    useState([
      {
        value: "left",
        label: "Sol",
      },
      {
        value: "center",
        label: "Orta",
      },
      {
        value: "right",
        label: "Sağ",
      },
    ]);

  const [legendPositionTopSelectOptions, setLegendPositionTopSelectOptions] =
    useState([
      {
        value: "top",
        label: "Yukarı",
      },
      {
        value: "middle",
        label: "Orta",
      },
      {
        value: "bottom",
        label: "Aşağı",
      },
    ]);

  const [dateColumn, setDateColumn] = useState([
    {
      value: "todate",
      label: "TARİH",
    },
    {
      value: "dayofmonth",
      label: "GÜN",
    },
    {
      value: "tomonth",
      label: "AY",
    },
    {
      value: "toyear",
      label: "YIL",
    },
    {
      value: "tohour",
      label: "SAAT",
    },
    {
      value: "tominute",
      label: "DAKİKA",
    },
    {
      value: "tosecond",
      label: "SANİYE",
    },
  ]);

  const substringsToRemove = [
    "_todate",
    "_dayofmonth",
    "_tomonth",
    "_toyear",
    "_tohour",
    "_tominute",
    "_tosecond",
  ];

  const [showLegend, setShowLegend] = useState(true);
  const [legendOrient, setLegendOrient] = useState({
    value: "horizontal",
    label: "Yatay",
  });
  const [legendPosition, setLegendPosition] = useState({
    left: { value: "center", label: "Orta" },
    top: { value: "top", label: "Yukarı" },
  });
  const [graphicWidth, setGraphicWidth] = useState({
    value: "col-12",
    label: "Büyük (4/4)",
  });

  const toggleLegend = () => setShowLegend(!showLegend);

  const handleOrientChange = (value) => setLegendOrient(value);

  const handlePositionChange = (name, value) => {
    setLegendPosition((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [customerControl, setCustomerControl] = useState(null);

  const [customerList, setCustomerList] = useState([]);
  const [customerValue, setCustomerValue] = useState(null);

  const [loader, setLoader] = useState(false);
  const [previewShow, setPreviewShow] = useState(false);

  const [previewData, setPreviewData] = useState([]);

  const [status, setStatus] = useState(true);

  const [groupingColumns, setGroupingColumns] = useState([]);

  useEffect(() => {
    if (context.allCustomerSelect && context.allCustomerSelect.length > 0) {
      const hasAllOption = context.allCustomerSelect.some(
        (customer) => customer.value === "all"
      );

      if (!hasAllOption) {
        const customerListArray = [...context.allCustomerSelect];
        customerListArray.unshift({ value: "all", label: "Tümü" });
        setCustomerList(customerListArray);
      } else {
        setCustomerList(context.allCustomerSelect);
      }
    }
  }, [context.allCustomerSelect]);

  const [columns, setColumns] = useState([]);

  const handleAddColumn = () => {
    setColumns([
      ...columns,
      {
        id: Date.now(),
        selectedColumns: [],
        operationType: null,
        dateGroupValue: null,
      },
    ]);
  };

  const handleRemoveColumn = (id) => {
    setColumns(columns.filter((column) => column.id !== id));
  };

  const handleColumnChange = (selectedColumns, index) => {
    const newColumns = [...columns];
    newColumns[index].selectedColumns = selectedColumns;
    setColumns(newColumns);
  };

  const handleOperationTypeChange = (operationType, index) => {
    const newColumns = [...columns];
    newColumns[index].operationType = operationType;
    setColumns(newColumns);
  };

  const handleDateTypeChange = (dateGroupValue, index) => {
    const newColumns = [...columns];
    newColumns[index].dateGroupValue = dateGroupValue;
    setColumns(newColumns);
  };

  const [page, setPage] = useState(null);
  const [chartOrder, setChartOrder] = useState(1);
  const [chartName, setChartName] = useState("");
  const [chartDescription, setChartDescription] = useState("");
  const [chartView, setChartView] = useState(null);
  const [files, setFiles] = useState(null);
  const [fileColumns, setFileColumns] = useState([]);
  const [operation, setOperation] = useState({
    conditions: [],
    operationType: null,
    orderings: [],
  });

  const [fileTypes, setFileTypes] = useState(null);
  const [fileTypesCustomer, setFileTypesCustomer] = useState(null);

  const [pages, setPages] = useState(null);
  const [pageCustomer, setPageCustomer] = useState(null);

  useEffect(() => {
    if (context && context.me) {
      setFileTypesCustomer(
        context?.me?.role?.id === 1
          ? {
              value: null,
              label: context?.t?.mine,
            }
          : {
              value: context.me.customer.id,
              label: context.me.customer.customer.name,
            }
      );
      setCustomerControl(
        context?.me?.role?.id === 1
          ? {
              value: null,
              label: context?.t?.mine,
            }
          : {
              value: context.me.customer.id,
              label: context.me.customer.customer.name,
            }
      );
      setCustomerControl(
        context?.me?.role?.id === 1
          ? {
              value: null,
              label: context?.t?.mine,
            }
          : {
              value: context.me.customer.id,
              label: context.me.customer.customer.name,
            }
      );

      //--

      setChartViewSelectOptions([
        { value: 1, label: context?.t?.pieChart },
        { value: 6, label: context?.t?.pieRoundedChart },
        { value: 8, label: context?.t?.pieRoseTypeChart },
        { value: 2, label: context?.t?.barChart },
        { value: 7, label: context?.t?.barPolarTangential },
        // { value: 9, label: context?.t?.treeChart },
        { value: 3, label: context?.t?.wordCloud },
        { value: 4, label: context?.t?.table },
        { value: 5, label: context?.t?.map },
      ]);

      setColumnOperationSelectOptions([
        { value: "select", label: context?.t?.all },
        { value: "column", label: context?.t?.column },
        { value: "count", label: context?.t?.count },
        { value: "avg", label: context?.t?.average },
        { value: "sum", label: context?.t?.total },
        { value: "min", label: context?.t?.minimum },
        { value: "max", label: context?.t?.maximum },
        { value: "distinct", label: context?.t?.distinct },
        { value: "toDate", label: context?.t?.date },
        { value: "dayOfMonth", label: context?.t?.day },
        { value: "toMonth", label: context?.t?.month },
        { value: "toYear", label: context?.t?.year },
        { value: "toHour", label: context?.t?.hour },
        { value: "toMinute", label: context?.t?.minute },
        { value: "toSecond", label: context?.t?.second },
      ]);

      setSelectedOperatorOptions([
        { value: "and", label: context?.t?.and },
        { value: "or", label: context?.t?.or },
      ]);

      setSelectedConditionOptions([
        { value: "=", label: "=" },
        { value: "!=", label: "!=" },
        { value: ">", label: ">" },
        { value: ">=", label: ">=" },
        { value: "<", label: "<" },
        { value: "<=", label: "<=" },
        { value: "in", label: context?.t?.in },
        { value: "notin", label: context?.t?.out },
        { value: "like", label: context?.t?.like },
        { value: "notlike", label: context?.t?.notLike },
        { value: "isnull", label: context?.t?.empty },
        { value: "isnotnull", label: context?.t?.filled },
      ]);

      setLegendPositionOrientSelectOptions([
        {
          value: "vertical",
          label: context?.t?.vertical,
        },
        {
          value: "horizontal",
          label: context?.t?.horizontal,
        },
      ]);

      setLegendPositionLeftSelectOptions([
        {
          value: "left",
          label: context?.t?.left,
        },
        {
          value: "center",
          label: context?.t?.middle,
        },
        {
          value: "right",
          label: context?.t?.right,
        },
      ]);
      setLegendPositionTopSelectOptions([
        {
          value: "top",
          label: context?.t?.up,
        },
        {
          value: "middle",
          label: context?.t?.middle,
        },
        {
          value: "bottom",
          label: context?.t?.down,
        },
      ]);

      setDateColumn([
        {
          value: "todate",
          label: context?.t?.date,
        },
        {
          value: "dayofmonth",
          label: context?.t?.day,
        },
        {
          value: "tomonth",
          label: context?.t?.month,
        },
        {
          value: "toyear",
          label: context?.t?.year,
        },
        {
          value: "tohour",
          label: context?.t?.hour,
        },
        {
          value: "tominute",
          label: context?.t?.minute,
        },
        {
          value: "tosecond",
          label: context?.t?.second,
        },
      ]);
      setLegendOrient({
        value: "horizontal",
        label: context?.t?.horizontal,
      });
      setLegendPosition({
        left: { value: "center", label: context?.t?.middle },
        top: { value: "top", label: context?.t?.up },
      });
      setGraphicWidth({
        value: "col-12",
        label: context?.t?.xl,
      });
    }
  }, [context]);

  async function handlePageSelect(e) {
    const data = await context.getOncePages(e ? e.value : customerControl);

    if (data) {
      setPages(data);
    } else {
      setPages(null);
    }

    setPageCustomer(e);
  }

  async function handleFileTypesCustomer(e) {
    if (e) {
      setFileTypes(null);
      setFiles(null);

      const data = await context.getFileTypes(e.value, true);

      if (data) {
        setFileTypes(data);
      } else {
        setFileTypes(null);
      }

      setFileTypesCustomer(e);
    }
  }

  async function getFileColumns(name) {
    setFileColumns(null);

    try {
      const response = await apiClient.get(`/columns/${name}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response) {
        setFileColumns(response.data.columns);
      }
    } catch (error) {
      console.error("Get File Types Error", error);
    }
  }

  useEffect(() => {
    if (files) {
      getFileColumns(files.value);
    }
  }, [files]);

  const addCondition = () => {
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: [
        ...prevOperation.conditions,
        {
          selectedColumn: null,
          selectedOperator: null,
          conditionDisabled: false,
          selectedCondition: null,
          inputDisabled: false,
          inputValue: "",
        },
      ],
    }));
  };

  const removeCondition = (conditionIndex) => {
    const newConditions = [...operation.conditions];
    newConditions.splice(conditionIndex, 1);
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleConditionColumnChange = (conditionIndex, selectedOption) => {
    const newConditions = [...operation.conditions];
    newConditions[conditionIndex].selectedColumn = selectedOption;
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleOperatorChange = (conditionIndex, selectedOption) => {
    const newConditions = [...operation.conditions];
    newConditions[conditionIndex].selectedOperator = selectedOption;
    newConditions[conditionIndex].conditionDisabled =
      selectedOption && selectedOption.value !== "";
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleConditionChange = (conditionIndex, selectedOption) => {
    const newConditions = [...operation.conditions];
    newConditions[conditionIndex].selectedCondition = selectedOption;
    if (
      selectedOption &&
      (selectedOption.value === "isnull" ||
        selectedOption.value === "isnotnull")
    ) {
      newConditions[conditionIndex].inputDisabled = true;
      newConditions[conditionIndex].inputValue = "";
    } else {
      newConditions[conditionIndex].inputDisabled = false;
    }
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleInputChange = (conditionIndex, event) => {
    const newConditions = [...operation.conditions];
    newConditions[conditionIndex].inputValue = event.target.value;
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleAddOrder = () => {
    setOperation((prevOperation) => ({
      ...prevOperation,
      orderings: [
        ...prevOperation.orderings,
        {
          column: null,
          order: null,
        },
      ],
    }));
  };

  const handleOrderChange = (orderIndex, selectedOption) => {
    const newOrderings = [...operation.orderings];
    newOrderings[orderIndex].order = selectedOption;
    setOperation((prevOperation) => ({
      ...prevOperation,
      orderings: newOrderings,
    }));
  };

  const handleOrderColumnChange = (orderIndex, selectedOption) => {
    const newOrderings = [...operation.orderings];
    newOrderings[orderIndex].column = selectedOption;
    setOperation((prevOperation) => ({
      ...prevOperation,
      orderings: newOrderings,
    }));
  };

  const handleRemoveOrder = (orderIndex) => {
    const newOrderings = [...operation.orderings];
    newOrderings.splice(orderIndex, 1);
    setOperation((prevOperation) => ({
      ...prevOperation,
      orderings: newOrderings,
    }));
  };

  // const checkDateColumnSelected = (selectedOptions) => {
  //   console.log(selectedOptions);
  //   const selectedValues = selectedOptions.map((option) => option.value);
  //   const dateColumns = ["day", "month", "year", "hour", "minute", "second"];
  //   for (const selectedValue of selectedValues) {
  //     if (dateColumns.includes(selectedValue)) {
  //       toastMessage("Tarih sütunu seçmeyi unutmayın!", "warning");
  //       return;
  //     }
  //   }
  // };

  const handleGroupingChange = (selectedOptions) => {
    setGroupingColumns(selectedOptions);
  };

  const validateConditions = () => {
    for (const condition of operation.conditions) {
      if (
        !condition.selectedColumn ||
        !condition.selectedCondition ||
        !condition.selectedOperator ||
        (condition.selectedCondition.value !== "isnull" &&
          condition.selectedCondition.value !== "isnotnull" &&
          !condition.inputValue)
      ) {
        toastMessage(context?.t?.conditions_sections_required, "error");
        return false;
      }
    }
    return true;
  };

  const validateColumns = () => {
    for (const column of columns) {
      if (!column.selectedColumns || !column.operationType) {
        toastMessage(context?.t?.columns_sections_required, "error");
        return false;
      }
    }
    return true;
  };

  const [data, setData] = useState(null);
  const [tablePage, setTablePage] = useState(1);
  const [tableCondition, setTableCondition] = useState({
    column: null,
    value: "",
  });

  const handleCreateButtonClick = async () => {
    if (!page || !chartOrder || !chartName || !chartView || !files) {
      toastMessage(context?.t?.chart_sections_required, "error");
      return;
    }

    if (chartName.length > 100) {
      toastMessage(context?.t?.chart_name_error, "error");
      return;
    }

    if (columns.length === 0) {
      toastMessage(context?.t?.select_column_error, "error");
      return;
    }

    if (!validateColumns()) {
      return;
    }

    // if (operation.conditions.length === 0) {
    //   toastMessage("En Az Bir Koşul Seçin.", "error");
    //   return;
    // }

    if (operation.conditions.length !== 0 && !validateConditions()) {
      return;
    }

    const selectedColumnValues = columns.map(
      (col) => col.selectedColumns.value
    );
    for (const groupingColumn of groupingColumns) {
      if (!selectedColumnValues.includes(groupingColumn.value)) {
        toastMessage(
          `${context?.t?.group_warning} - "${groupingColumn.label}"`,
          "error"
        );
        return;
      }
    }

    fetchPreview();
  };

  async function fetchPreview(params) {
    if (page && chartOrder && chartName && chartView && files) {
      const data = {
        chart: chartView.value,
        page_id: page.value,
        title: chartName,
        description: chartDescription,
        status: true,
        order: chartOrder,
        type: files.value,
        customer_id: context.me
          ? context?.me?.role?.id === 1
            ? null
            : context.me.customer.id
          : null,
        customer: context.me
          ? context?.me?.role?.id === 1
            ? null
            : context.me.customer.id
          : null,
        sql: {
          column:
            columns.length !== 0
              ? columns.map((item) => {
                  return {
                    key: item.selectedColumns.value,
                    value: item.operationType.value,
                  };
                })
              : [],
          condition:
            operation.conditions.length !== 0
              ? [
                  tableCondition.column
                    ? {
                        key: tableCondition.column,
                        value: tableCondition.value,
                        operator: "like",
                        type: "and",
                      }
                    : null,
                  ...operation.conditions.map((item) => {
                    return {
                      key: item.selectedColumn.value,
                      value: item.inputValue,
                      operator: item.selectedCondition.value,
                      type: item.selectedOperator.value,
                    };
                  }),
                ].filter((item) => item !== null)
              : [
                  tableCondition.column
                    ? {
                        key: tableCondition.column,
                        value: tableCondition.value,
                        operator: "like",
                        type: "and",
                      }
                    : null,
                ].filter((item) => item !== null),
          group:
            columns.length !== 0
              ? [
                  ...columns
                    .map((item) =>
                      item.dateGroupValue
                        ? item.dateGroupValue.map(
                            (dateGroup) => dateGroup.value
                          )
                        : []
                    )
                    .flat(),
                  ...groupingColumns.map((item) => item.value),
                ]
              : [],
        },
      };

      setLoader(true);

      if (chartView.value === 4) {
        data.paginate = true;
      } else {
        if (data.paginate) {
          delete data.paginate;
        }
      }

      setData(data);

      try {
        const response = await apiClient.customPost(
          `/query/preview${data.paginate ? `?page=${tablePage}` : ""}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          // toastMessage("İşlem Doğrulandı", "success");
          setLoader(false);
          setPreviewShow(true);

          if (chartView.value === 4 || chartView.value === 5) {
            setPreviewData(response);
          } else {
            const filteredResponse = response.filter((item) => {
              return Object.values(item).every(
                (value) => value !== "" && value !== null
              );
            });

            setPreviewData(filteredResponse);
          }

          if (context.graphicUpdate) {
            if (context.graphicUpdate.options.selectedColumn[0]) {
              setPreviewSelectValue(
                response.some((item) =>
                  item.hasOwnProperty(
                    context.graphicUpdate.options.selectedColumn[0].value
                  )
                )
                  ? context.graphicUpdate.options.selectedColumn[0]
                  : null
              );
            } else {
              setPreviewSelectValue(null);
            }

            if (context.graphicUpdate.options.selectedColumn[1]) {
              setPreviewSelectLabel(
                response.some((item) =>
                  item.hasOwnProperty(
                    context.graphicUpdate.options.selectedColumn[1].value
                  )
                )
                  ? context.graphicUpdate.options.selectedColumn[1]
                  : null
              );
            } else {
              setPreviewSelectLabel(null);
            }

            if (context.graphicUpdate.options.selectedColumn[2]) {
              setPreviewSelectWordCloud(
                response.some((item) =>
                  item.hasOwnProperty(
                    context.graphicUpdate.options.selectedColumn[2].value
                  )
                )
                  ? context.graphicUpdate.options.selectedColumn[2]
                  : null
              );
            } else {
              setPreviewSelectWordCloud(null);
            }

            // if (context.graphicUpdate.options.selectedColumn[2]) {
            //   setPreviewChartShow(true);
            // } else {
            //   if (
            //     response.some((item) =>
            //       item.hasOwnProperty(
            //         context.graphicUpdate.options.selectedColumn[0].value
            //       )
            //     ) &&
            //     response.some((item) =>
            //       item.hasOwnProperty(
            //         context.graphicUpdate.options.selectedColumn[1].value
            //       )
            //     )
            //   ) {
            //     setPreviewChartShow(true);
            //   }
            // }
          } else {
            setPreviewSelectWordCloud(null);
            setPreviewSelectValue(null);
            setPreviewSelectLabel(null);
          }
        }
      } catch (error) {
        setLoader(false);

        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(
                formatErrorMessage(errorMessage, context?.t?.data_type_error),
                "error"
              )
            )
          );
        } else {
          toastMessage(
            formatErrorMessage(
              error.response.data.message,
              context?.t?.data_type_error
            ),
            "error"
          );
        }
      }
    }
  }

  useEffect(() => {
    fetchPreview();
  }, [tablePage, tableCondition]);

  const [previewChartShow, setPreviewChartShow] = useState(false);
  const [previewSelectValue, setPreviewSelectValue] = useState(null);
  const [previewSelectLabel, setPreviewSelectLabel] = useState(null);
  const activeChartOptions = [
    {
      id: 1,
      darkMode: true,
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          name: "",
          type: "pie",
          radius: "50%",
          data: [],
          label: {
            textStyle: {
              fontSize: 15,
            },
          },
          itemStyle: {
            color: context?.chartColor,
          },
        },
      ],
    },
    {
      id: 2,
      tooltip: {
        trigger: "axis",
      },
      darkMode: true,
      toolbox: {
        show: true,
        feature: {
          magicType: { show: true, type: ["line", "bar"] },
          restore: { show: false },
          saveAsImage: { show: false },
        },
        iconStyle: { borderColor: "#fff" },
      },
      calculable: true,
      xAxis: [
        {
          type: "category",
          data: [],
          axisLabel: {
            rotate: 45,
            textStyle: {
              color: "#fff",
              fontSize: 10,
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
        },
      ],
      grid: { bottom: "20%" },
      series: [
        {
          name: "",
          type: "bar",
          data: [],
          markPoint: {
            data: [
              { type: "max", name: "Max" },
              { type: "min", name: "Min" },
            ],
          },
          itemStyle: {
            color: context?.chartColor,
          },
        },
      ],
    },
    {
      id: 6,
      darkMode: true,
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          radius: ["40%", "70%"],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1,
          },
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 15,
              fontWeight: "bold",
            },
          },
          name: "",
          type: "pie",
          data: [],
        },
      ],
    },
    {
      id: 7,
      darkMode: true,
      polar: {
        radius: [30, "80%"],
      },
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          type: "bar",
          coordinateSystem: "polar",
          label: {
            show: true,
            position: "middle",
            formatter: "{b}: {c}",
            itemStyle: {
              color: "#fff",
            },
          },
        },
      ],
    },
    {
      id: 8,
      darkMode: true,
      toolbox: {
        show: false,
      },
      series: [
        {
          name: "",
          type: "pie",
          radius: [50, 250],
          center: ["50%", "50%"],
          roseType: "area",
          itemStyle: {
            borderRadius: 8,
          },
          data: [],
        },
      ],
    },
    {
      id: 9,
      darkMode: true,
      series: [
        {
          type: "sankey",
          left: 50.0,
          top: 20.0,
          right: 150.0,
          bottom: 25.0,
          data: [
            {
              name: "Agricultural 'waste'",
            },
            {
              name: "Bio-conversion",
            },
            {
              name: "Liquid",
            },
            {
              name: "Losses",
            },
            {
              name: "Solid",
            },
            {
              name: "Gas",
            },
            {
              name: "Biofuel imports",
            },
            {
              name: "Biomass imports",
            },
            {
              name: "Coal imports",
            },
            {
              name: "Coal",
            },
            {
              name: "Coal reserves",
            },
            {
              name: "District heating",
            },
            {
              name: "Industry",
            },
            {
              name: "Heating and cooling - commercial",
            },
            {
              name: "Heating and cooling - homes",
            },
            {
              name: "Electricity grid",
            },
            {
              name: "Over generation / exports",
            },
            {
              name: "H2 conversion",
            },
            {
              name: "Road transport",
            },
            {
              name: "Agriculture",
            },
            {
              name: "Rail transport",
            },
            {
              name: "Lighting & appliances - commercial",
            },
            {
              name: "Lighting & appliances - homes",
            },
            {
              name: "Gas imports",
            },
            {
              name: "Ngas",
            },
            {
              name: "Gas reserves",
            },
            {
              name: "Thermal generation",
            },
            {
              name: "Geothermal",
            },
            {
              name: "H2",
            },
            {
              name: "Hydro",
            },
            {
              name: "International shipping",
            },
            {
              name: "Domestic aviation",
            },
            {
              name: "International aviation",
            },
            {
              name: "National navigation",
            },
            {
              name: "Marine algae",
            },
            {
              name: "Nuclear",
            },
            {
              name: "Oil imports",
            },
            {
              name: "Oil",
            },
            {
              name: "Oil reserves",
            },
            {
              name: "Other waste",
            },
            {
              name: "Pumped heat",
            },
            {
              name: "Solar PV",
            },
            {
              name: "Solar Thermal",
            },
            {
              name: "Solar",
            },
            {
              name: "Tidal",
            },
            {
              name: "UK land based bioenergy",
            },
            {
              name: "Wave",
            },
            {
              name: "Wind",
            },
          ],
          links: [
            {
              source: "Agricultural 'waste'",
              target: "Bio-conversion",
              value: 124.729,
            },
            {
              source: "Bio-conversion",
              target: "Liquid",
              value: 0.597,
            },
            {
              source: "Bio-conversion",
              target: "Losses",
              value: 26.862,
            },
            {
              source: "Bio-conversion",
              target: "Solid",
              value: 280.322,
            },
            {
              source: "Bio-conversion",
              target: "Gas",
              value: 81.144,
            },
            {
              source: "Biofuel imports",
              target: "Liquid",
              value: 35,
            },
            {
              source: "Biomass imports",
              target: "Solid",
              value: 35,
            },
            {
              source: "Coal imports",
              target: "Coal",
              value: 11.606,
            },
            {
              source: "Coal reserves",
              target: "Coal",
              value: 63.965,
            },
            {
              source: "Coal",
              target: "Solid",
              value: 75.571,
            },
            {
              source: "District heating",
              target: "Industry",
              value: 10.639,
            },
            {
              source: "District heating",
              target: "Heating and cooling - commercial",
              value: 22.505,
            },
            {
              source: "District heating",
              target: "Heating and cooling - homes",
              value: 46.184,
            },
            {
              source: "Electricity grid",
              target: "Over generation / exports",
              value: 104.453,
            },
            {
              source: "Electricity grid",
              target: "Heating and cooling - homes",
              value: 113.726,
            },
            {
              source: "Electricity grid",
              target: "H2 conversion",
              value: 27.14,
            },
            {
              source: "Electricity grid",
              target: "Industry",
              value: 342.165,
            },
            {
              source: "Electricity grid",
              target: "Road transport",
              value: 37.797,
            },
            {
              source: "Electricity grid",
              target: "Agriculture",
              value: 4.412,
            },
            {
              source: "Electricity grid",
              target: "Heating and cooling - commercial",
              value: 40.858,
            },
            {
              source: "Electricity grid",
              target: "Losses",
              value: 56.691,
            },
            {
              source: "Electricity grid",
              target: "Rail transport",
              value: 7.863,
            },
            {
              source: "Electricity grid",
              target: "Lighting & appliances - commercial",
              value: 90.008,
            },
            {
              source: "Electricity grid",
              target: "Lighting & appliances - homes",
              value: 93.494,
            },
            {
              source: "Gas imports",
              target: "Ngas",
              value: 40.719,
            },
            {
              source: "Gas reserves",
              target: "Ngas",
              value: 82.233,
            },
            {
              source: "Gas",
              target: "Heating and cooling - commercial",
              value: 0.129,
            },
            {
              source: "Gas",
              target: "Losses",
              value: 1.401,
            },
            {
              source: "Gas",
              target: "Thermal generation",
              value: 151.891,
            },
            {
              source: "Gas",
              target: "Agriculture",
              value: 2.096,
            },
            {
              source: "Gas",
              target: "Industry",
              value: 48.58,
            },
            {
              source: "Geothermal",
              target: "Electricity grid",
              value: 7.013,
            },
            {
              source: "H2 conversion",
              target: "H2",
              value: 20.897,
            },
            {
              source: "H2 conversion",
              target: "Losses",
              value: 6.242,
            },
            {
              source: "H2",
              target: "Road transport",
              value: 20.897,
            },
            {
              source: "Hydro",
              target: "Electricity grid",
              value: 6.995,
            },
            {
              source: "Liquid",
              target: "Industry",
              value: 121.066,
            },
            {
              source: "Liquid",
              target: "International shipping",
              value: 128.69,
            },
            {
              source: "Liquid",
              target: "Road transport",
              value: 135.835,
            },
            {
              source: "Liquid",
              target: "Domestic aviation",
              value: 14.458,
            },
            {
              source: "Liquid",
              target: "International aviation",
              value: 206.267,
            },
            {
              source: "Liquid",
              target: "Agriculture",
              value: 3.64,
            },
            {
              source: "Liquid",
              target: "National navigation",
              value: 33.218,
            },
            {
              source: "Liquid",
              target: "Rail transport",
              value: 4.413,
            },
            {
              source: "Marine algae",
              target: "Bio-conversion",
              value: 4.375,
            },
            {
              source: "Ngas",
              target: "Gas",
              value: 122.952,
            },
            {
              source: "Nuclear",
              target: "Thermal generation",
              value: 839.978,
            },
            {
              source: "Oil imports",
              target: "Oil",
              value: 504.287,
            },
            {
              source: "Oil reserves",
              target: "Oil",
              value: 107.703,
            },
            {
              source: "Oil",
              target: "Liquid",
              value: 611.99,
            },
            {
              source: "Other waste",
              target: "Solid",
              value: 56.587,
            },
            {
              source: "Other waste",
              target: "Bio-conversion",
              value: 77.81,
            },
            {
              source: "Pumped heat",
              target: "Heating and cooling - homes",
              value: 193.026,
            },
            {
              source: "Pumped heat",
              target: "Heating and cooling - commercial",
              value: 70.672,
            },
            {
              source: "Solar PV",
              target: "Electricity grid",
              value: 59.901,
            },
            {
              source: "Solar Thermal",
              target: "Heating and cooling - homes",
              value: 19.263,
            },
            {
              source: "Solar",
              target: "Solar Thermal",
              value: 19.263,
            },
            {
              source: "Solar",
              target: "Solar PV",
              value: 59.901,
            },
            {
              source: "Solid",
              target: "Agriculture",
              value: 0.882,
            },
            {
              source: "Solid",
              target: "Thermal generation",
              value: 400.12,
            },
            {
              source: "Solid",
              target: "Industry",
              value: 46.477,
            },
            {
              source: "Thermal generation",
              target: "Electricity grid",
              value: 525.531,
            },
            {
              source: "Thermal generation",
              target: "Losses",
              value: 787.129,
            },
            {
              source: "Thermal generation",
              target: "District heating",
              value: 79.329,
            },
            {
              source: "Tidal",
              target: "Electricity grid",
              value: 9.452,
            },
            {
              source: "UK land based bioenergy",
              target: "Bio-conversion",
              value: 182.01,
            },
            {
              source: "Wave",
              target: "Electricity grid",
              value: 19.013,
            },
            {
              source: "Wind",
              target: "Electricity grid",
              value: 289.366,
            },
          ],
          lineStyle: {
            color: "source",
            curveness: 0.5,
          },
          itemStyle: {
            borderColor: "#ffffff",
          },
          label: {
            color: "#ffffff",
            fontFamily: "Arial",
            fontSize: 10,
          },
        },
      ],
      tooltip: {
        trigger: "item",
      },
    },
  ];
  const [chartOptions, setChartOptions] = useState();
  const [wordCloudData, setWordCloudData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [previewSelectWordCloud, setPreviewSelectWordCloud] = useState(null);

  function handleSelectWordCloud(e) {
    setPreviewSelectWordCloud(e);
    setPreviewSelectValue(null);
    setPreviewSelectLabel(null);
  }

  useEffect(() => {
    if (chartView) {
      setChartOptions(
        activeChartOptions.find((item) => item.id === chartView.value)
      );
    }
  }, [chartView]);

  useEffect(() => {
    if (chartView) {
      if (chartView.value !== 2) {
        setChartOptions((prevOptions) => ({
          ...prevOptions,
          legend: {
            show: showLegend,
            orient: legendOrient.value,
            left: legendPosition.left.value,
            top: legendPosition.top.value,
            itemGap: 20,
            textStyle: {
              color: "#fff",
            },
          },
        }));
      }
    }
  }, [chartView, showLegend, legendOrient, legendPosition, showLegend]);

  function handlePreview() {
    if (!previewSelectWordCloud && chartView.value !== 4) {
      if (!previewSelectValue || !previewSelectLabel) {
        toastMessage(context?.t?.choose_column_2, "error");
        return;
      }
    }

    if (chartView.value === 1) {
      const chartData = previewData.map((item) => ({
        value: item[previewSelectValue.value],
        name: item[previewSelectLabel.value],
      }));

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        tooltip: previewSelectValue.value.includes("_avg")
          ? {
              trigger: "item",
              formatter: function (params) {
                return (
                  params.data.name + "<br /> %" + params.data.value.toFixed(2)
                );
              },
            }
          : {
              trigger: "item",
            },
        visualMap: context?.chartColor
          ? {
              show: false,
              min: Math.min(...chartData.map((obj) => obj.value)),
              max: Math.max(...chartData.map((obj) => obj.value)),
              inRange: {
                colorLightness: [0.2, 1],
              },
            }
          : false,
        series: [
          {
            ...prevOptions.series[0],
            data: chartData,
          },
        ],
      }));
    } else if (chartView.value === 8) {
      const chartData = previewData.map((item) => ({
        value: item[previewSelectValue.value],
        name: item[previewSelectLabel.value],
      }));

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        tooltip: previewSelectValue.value.includes("_avg")
          ? {
              trigger: "item",
              formatter: function (params) {
                return (
                  params.data.name + "<br /> %" + params.data.value.toFixed(2)
                );
              },
            }
          : {
              trigger: "item",
            },
        visualMap: context?.chartColor
          ? {
              show: false,
              min: Math.min(...chartData.map((obj) => obj.value)),
              max: Math.max(...chartData.map((obj) => obj.value)),
              inRange: {
                colorLightness: [0.2, 1],
              },
            }
          : false,
        series: [
          {
            ...prevOptions.series[0],
            data: chartData,
          },
        ],
      }));
    } else if (chartView.value === 6) {
      const chartData = previewData.map((item) => ({
        value: item[previewSelectValue.value],
        name: item[previewSelectLabel.value],
      }));

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        tooltip: previewSelectValue.value.includes("_avg")
          ? {
              trigger: "item",
              formatter: function (params) {
                return (
                  params.data.name + "<br /> %" + params.data.value.toFixed(2)
                );
              },
            }
          : {
              trigger: "item",
            },
        visualMap: context?.chartColor
          ? {
              show: false,
              min: Math.min(...chartData.map((obj) => obj.value)),
              max: Math.max(...chartData.map((obj) => obj.value)),
              inRange: {
                colorLightness: [0.2, 1],
              },
            }
          : false,
        series: [
          {
            ...prevOptions.series[0],
            data: chartData,
          },
        ],
      }));
    } else if (chartView.value === 7) {
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        tooltip: previewSelectValue.value.includes("_avg")
          ? {
              trigger: "item",
              formatter: function (params) {
                return (
                  params.data.name + "<br /> %" + params.data.value.toFixed(2)
                );
              },
            }
          : {
              trigger: "item",
            },
        angleAxis: {
          max: Math.max(
            ...previewData.map((item) => item[previewSelectValue.value])
          ),
          startAngle: 75,
          axisLabel: {
            color: "#fff",
          },
        },
        radiusAxis: {
          type: "category",
          data: previewData.map((item) => item[previewSelectLabel.value]),
          axisLabel: {
            color: "#fff",
          },
        },
        series: [
          {
            ...prevOptions.series[0],
            data: previewData.map((item) => item[previewSelectValue.value]),
          },
        ],
      }));
    } else if (chartView.value === 9) {
      const chartData = previewData.map((item) => ({
        value: item[previewSelectValue.value],
        name: item[previewSelectLabel.value],
      }));
      console.log("previewData", previewData);
      console.log("previewSelectValue", previewSelectValue);
      console.log("previewSelectLabel", previewSelectLabel);
      console.log("chartData", chartData);
    } else if (chartView.value === 2) {
      // // Tarihleri parse eden ve en büyük tarihi bulan fonksiyon
      // const getMaxDate = (data) => {
      //   const parsedDates = data.map((item) => ({
      //     ...item,
      //     parsedTarih: moment(
      //       item.tarih,
      //       ["YYYY", "YYYY-MM", "YYYY-MM-DD"],
      //       true
      //     ),
      //   }));
      //   parsedDates.sort((a, b) => b.parsedTarih - a.parsedTarih);
      //   return parsedDates[0]; // En büyük tarihi döndür
      // };

      // // Basit lineer regresyon fonksiyonu
      // const linearRegression = (data) => {
      //   const n = data.length;
      //   const sumX = data.reduce((acc, item, index) => acc + index, 0); // Tarihler için index'leri kullanıyoruz
      //   const sumY = data.reduce((acc, item) => acc + parseInt(item.deger), 0);
      //   const sumXY = data.reduce(
      //     (acc, item, index) => acc + index * parseInt(item.deger),
      //     0
      //   );
      //   const sumX2 = data.reduce((acc, item, index) => acc + index * index, 0);

      //   const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
      //   const intercept = (sumY - slope * sumX) / n;

      //   return (x) => slope * x + intercept; // x'e göre tahmin döner
      // };

      // // Regresyon ile tahminleri hesapla ve sonuçları console.log ile göster
      // const calculatePredictions = () => {
      //   const maxDateItem = getMaxDate(previewData); // En büyük tarihi bul
      //   console.log("En Büyük Tarih:", maxDateItem.tarih);

      //   const regression = linearRegression(previewData); // Regresyonu hesapla
      //   const lastIndex = previewData.length - 1; // Son veri noktasını al

      //   // Sonraki 5 yılı tahmin et
      //   const predictions = [];
      //   for (let i = 1; i <= 5; i++) {
      //     const futureYear = moment(maxDateItem.parsedTarih).add(i, "years"); // Gelecek yılları oluştur
      //     const predictedValue = regression(lastIndex + i); // Regresyon ile tahmin

      //     predictions.push({
      //       tarih: futureYear.format("YYYY-MM-DD"), // Tarih formatı
      //       deger: predictedValue,
      //     });
      //   }

      //   console.log("Gelecek 5 Yıl Tahminleri:", predictions);
      // };

      // calculatePredictions();

      setChartOptions((prevOptions) => {
        const sortedData = [...previewData].sort(
          (a, b) => a[previewSelectLabel.value] - b[previewSelectLabel.value]
        );

        return {
          ...prevOptions,
          visualMap: context?.chartColor
            ? {
                show: false,
                min: Math.min(
                  ...sortedData.map((item) => item[previewSelectValue.value])
                ),
                max: Math.max(
                  ...sortedData.map((item) => item[previewSelectValue.value])
                ),
                inRange: {
                  colorLightness: [0.2, 1],
                },
              }
            : false,
          xAxis: [
            {
              ...prevOptions.xAxis[0],
              // Sıralanmış verilere göre xAxis etiketleri
              data: sortedData.map((item) => item[previewSelectLabel.value]),
            },
          ],
          series: [
            {
              ...prevOptions.series[0],
              // Sıralanmış verilere göre serideki veriler
              data: sortedData.map((item) => item[previewSelectValue.value]),
            },
          ],
        };
      });
    } else if (chartView.value === 3) {
      let chartData;

      if (previewSelectWordCloud) {
        chartData = previewData
          .map((item) => item[previewSelectWordCloud.value])
          .join(", ");
      } else {
        chartData = previewData.map((item) => ({
          category: item[previewSelectLabel.value],
          value: item[previewSelectValue.value],
        }));
      }

      setWordCloudData(chartData);
    } else if (chartView.value === 4) {
      setTableData(previewData);
    }

    setPreviewChartShow(true);
  }

  async function handleGraphicPost() {
    setLoader(true);

    const postData = data;
    postData.options = {
      file: {
        customerSelect: fileTypesCustomer,
        fileSelect: files,
      },
      page: {
        customerSelect: pageCustomer,
        pageSelect: page,
      },
      selectedColumn: [
        previewSelectValue,
        previewSelectLabel,
        previewSelectWordCloud,
      ],
      width: graphicWidth,
      options: wordCloudData.length !== 0 ? wordCloudData : chartOptions,
    };

    try {
      const response = context.graphicUpdate
        ? await apiClient.put(`/query/${context.graphicUpdate.id}`, postData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
        : await apiClient.customPost(`/query`, postData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
      if (response) {
        setLoader(false);
        toastMessage(
          context.graphicUpdate
            ? context?.t?.updated_chart
            : context?.t?.added_to_chart,
          "success"
        );

        setTimeout(() => {
          // context.getPages();
          // document.getElementById("createGraphicModalCloseButton").click();
          window.location.reload();
          // context.setGraphicFetch(!context.graphicFetch);
        }, 300);
      }
    } catch (error) {
      setLoader(false);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  function findNameById(array, id) {
    for (let item of array) {
      if (item.id === id) {
        return item.name;
      }
      if (item.children) {
        for (let child of item.children) {
          if (child.id === id) {
            return child.name;
          }
        }
      }
    }
    return null; // id bulunamazsa null döner
  }

  function handleResetPreview() {
    setPreviewShow(false);
    setPreviewChartShow(false);

    if (!context.graphicUpdate) {
      setPreviewData([]);
      setPreviewSelectValue(null);
      setPreviewSelectWordCloud(null);
      setPreviewSelectLabel(null);
      setShowLegend(true);
      setLegendOrient({
        value: "horizontal",
        label: context?.t?.horizontal,
      });
      setLegendPosition({
        left: { value: "center", label: context?.t?.middle },
        top: { value: "top", label: context?.t?.up },
      });
      setGraphicWidth({
        value: "col-12",
        label: context?.t?.xl,
      });
    }
  }

  function handleReset() {
    if (context && context.me) {
      setChartView(null);

      setPage(null);

      setChartName("");
      setFileTypesCustomer(
        context?.me?.role?.id === 1
          ? {
              value: null,
              label: context?.t?.mine,
            }
          : {
              value: context.me.customer.id,
              label: context.me.customer.customer.name,
            }
      );
      setChartOrder(1);
      setChartDescription("");
      setFiles(null);
      setColumns([]);
      setGroupingColumns([]);
      setOperation({
        conditions: [],
        operationType: null,
        orderings: [],
      });

      handleFileTypesCustomer(customerControl);
      handlePageSelect(customerControl);
    }
  }

  // Update
  function getUpdateItems() {
    setChartView({
      value: context.graphicUpdate.chart,
      label: chartViewSelectOptions.find(
        (e) => e.value === context.graphicUpdate.chart
      ).label,
    });

    setChartName(context.graphicUpdate.title);
    setChartOrder(context.graphicUpdate.order);
    setChartDescription(context.graphicUpdate.description);

    if (context.graphicUpdate.options?.page?.customerSelect) {
      handlePageSelect(context.graphicUpdate.options?.page?.customerSelect);
      setPageCustomer(context.graphicUpdate.options?.page?.customerSelect);
    }

    if (context.graphicUpdate.options?.page?.pageSelect) {
      setPage(context.graphicUpdate.options?.page?.pageSelect);
    }

    if (context.graphicUpdate.options?.file?.customerSelect) {
      handleFileTypesCustomer(
        context.graphicUpdate.options?.file?.customerSelect
      );
      setFileTypesCustomer(context.graphicUpdate.options?.file?.customerSelect);
    }
    if (context.graphicUpdate.options?.file?.customerSelect) {
      setFiles(context.graphicUpdate.options.file.fileSelect);
    }

    let newArray = [];

    context.graphicUpdate.payload.group.forEach((item) => {
      substringsToRemove.forEach((checkItem) => {
        if (item.includes(checkItem)) {
          newArray.push(item);
        }
      });
    });

    setColumns(
      context.graphicUpdate.payload.column.map((item, index) => {
        let matchedItems = newArray.filter((newItem) =>
          newItem.startsWith(item.key + "_")
        );
        let dateGroupValue = matchedItems.map((match) => ({
          value: match,
          label: dateColumn.find(
            (e) => e.value === match.toLowerCase().split("_")[1]
          ).label,
        }));

        return {
          id: index,
          selectedColumns: { value: item.key, label: item.key },
          operationType: {
            value: item.value,
            label: columnOperationSelectOptions.find(
              (e) => e.value === item.value
            ).label,
          },
          dateGroupValue: dateGroupValue,
        };
      })
    );

    const groupArray = context.graphicUpdate.payload.group.filter(
      (item) =>
        !substringsToRemove.some((substring) => item.includes(substring))
    );

    setGroupingColumns(
      groupArray.map((item) => ({
        value: item,
        label: item,
      }))
    );

    setOperation({
      conditions: context.graphicUpdate.payload.condition.map((item) => {
        return {
          selectedColumn: { value: item.key, label: item.key },
          selectedOperator: {
            value: item.type,
            label: selectedOperatorOptions.find((e) => e.value === item.type)
              .label,
          },
          conditionDisabled:
            item.operator === "isnull" || item.operator === "isnotnull"
              ? true
              : false,
          selectedCondition: {
            value: item.operator,
            label: selectedConditionOptions.find(
              (e) => e.value === item.operator
            ).label,
          },
          inputDisabled: false,
          inputValue: item.value,
        };
      }),
      operationType: null,
      orderings: [],
    });

    //--

    // if (context.graphicUpdate.chart === 3) {
    //   setWordCloudData(context.graphicUpdate.options.options);
    // } else {
    //   setChartOptions(context.graphicUpdate.options.options);
    // }
    setGraphicWidth(context.graphicUpdate.options.width);

    if (context.graphicUpdate.options.options.legend) {
      setShowLegend(context.graphicUpdate.options.options.legend.show);
      setLegendOrient({
        value: context.graphicUpdate.options.options.legend.orient,
        label: legendPositionOrientSelectOptions.find(
          (e) => e.value === context.graphicUpdate.options.options.legend.orient
        ).label,
      });
      setLegendPosition({
        left: {
          value: context.graphicUpdate.options.options.legend.left,
          label: legendPositionLeftSelectOptions.find(
            (e) => e.value === context.graphicUpdate.options.options.legend.left
          ).label,
        },
        top: {
          value: context.graphicUpdate.options.options.legend.top,
          label: legendPositionTopSelectOptions.find(
            (e) => e.value === context.graphicUpdate.options.options.legend.top
          ).label,
        },
      });
    }
  }

  useEffect(() => {
    if (!context.graphicUpdate) {
      handleReset();
    }
  }, [context.graphicUpdate]);

  useEffect(() => {
    if (context.graphicUpdate) {
      getUpdateItems();
    } else {
      handleResetPreview();
    }
  }, [context]);

  return (
    context &&
    context.me && (
      <div className="row justify-content-center">
        {!previewShow ? (
          <div className="col-12 col-lg-8">
            <h4>{context?.t?.chart}</h4>

            <Card className="mb-4">
              <CardBody>
                <div className="row g-3">
                  {/* <div className="col-12">
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="create_graphic_status_radio"
                      defaultChecked={status}
                      onChange={(e) => setStatus(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="create_graphic_status_radio"
                    >
                      {status ? "Aktif" : "Pasif"}
                    </label>
                  </div>
                </div> */}

                  <div className="col-12">
                    <div className="row">
                      {context?.me?.role?.id === 1 && (
                        <div className="col-6">
                          <span className="mb-1 d-block">
                            {context?.t?.pageCustomer}
                          </span>
                          {context.allCustomerSelect ? (
                            <Select
                              options={[
                                {
                                  value: null,
                                  label: context?.t?.mine,
                                },
                                ...context.allCustomerSelect,
                              ]}
                              placeholder=""
                              classNamePrefix="react-select"
                              noOptionsMessage={() => context?.t?.no_option}
                              value={pageCustomer}
                              onChange={(e) => handlePageSelect(e)}
                            />
                          ) : (
                            <div className="spinner-border spinner-border-sm text-theme"></div>
                          )}
                        </div>
                      )}

                      <div
                        className={
                          context?.me?.role?.id === 1 ? "col-6" : "col-12"
                        }
                      >
                        <span className="mb-1 d-block">
                          {context?.t?.selectPage}
                        </span>
                        {pages ? (
                          <Select
                            options={pages
                              .filter((page) => {
                                if (
                                  !page.name.startsWith("Anket Sayfası") &&
                                  page.parent_id !== 44 &&
                                  page.page_type &&
                                  !page.iframe &&
                                  !page.url &&
                                  localStorage.getItem("user") &&
                                  JSON.parse(localStorage.getItem("user")).role
                                    .id === 1
                                ) {
                                  return true;
                                }
                                return (
                                  !page.name.startsWith("Anket Sayfası") &&
                                  page.parent_id !== 44 &&
                                  page.page_type &&
                                  !page.url &&
                                  page.page_type !== "default"
                                );
                              })
                              .sort((a, b) => a.order - b.order)
                              .map((item) => {
                                if (item.children && item.children.length > 0) {
                                  return {
                                    label: item.name,
                                    options: item.children.map((child) => ({
                                      value: child.id,
                                      label: child.name,
                                    })),
                                  };
                                } else {
                                  return {
                                    value: item.id,
                                    label: item.name,
                                  };
                                }
                              })}
                            placeholder=""
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            value={page}
                            onChange={(e) => setPage(e)}
                          />
                        ) : (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row">
                      {context?.me?.role?.id === 1 && (
                        <div className="col-6">
                          <span className="mb-1 d-block">
                            {context?.t?.fileCustomer}
                          </span>
                          {context.allCustomerSelect ? (
                            <Select
                              options={[
                                {
                                  value: null,
                                  label: context?.t?.mine,
                                },
                                ...context.allCustomerSelect,
                              ]}
                              placeholder=""
                              classNamePrefix="react-select"
                              noOptionsMessage={() => context?.t?.no_option}
                              value={fileTypesCustomer}
                              onChange={(e) => handleFileTypesCustomer(e)}
                            />
                          ) : (
                            <div className="spinner-border spinner-border-sm text-theme"></div>
                          )}
                        </div>
                      )}

                      <div
                        className={
                          context?.me?.role?.id === 1 ? "col-6" : "col-12"
                        }
                      >
                        <span className="mb-1 d-block">
                          {context?.t?.selectFile}
                        </span>
                        {fileTypes ? (
                          <Select
                            options={fileTypes.map((item) => {
                              return {
                                value: item.type,
                                label: item.type,
                              };
                            })}
                            placeholder=""
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            value={files}
                            onChange={(e) => setFiles(e)}
                          />
                        ) : (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <span className="mb-1 d-block">
                      {context?.t?.chartOrder}
                    </span>
                    <input
                      type="number"
                      value={chartOrder}
                      min={1}
                      className="form-control"
                      onChange={(e) => setChartOrder(e.target.value)}
                    />
                  </div>

                  <div className="col-6">
                    <span className="mb-1 d-block">
                      {context?.t?.chartView}
                    </span>
                    <Select
                      options={chartViewSelectOptions}
                      placeholder=""
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      value={chartView}
                      onChange={setChartView}
                    />
                  </div>

                  <div className="col-12">
                    <span className="mb-1 d-block">
                      {context?.t?.chartName}
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      value={chartName}
                      onChange={(e) => setChartName(e.target.value)}
                    />
                  </div>

                  <div className="col-12">
                    <span className="mb-1 d-block">
                      {context?.t?.chartDescription}
                    </span>
                    <textarea
                      className="form-control"
                      value={chartDescription}
                      onChange={(e) => setChartDescription(e.target.value)}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>

            <h4>{context?.t?.query}</h4>

            <Card className="mb-4" style={{ zIndex: 1 }}>
              <CardBody>
                <div className="row gap-3">
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-outline-theme w-100 mb-3"
                      onClick={handleAddColumn}
                    >
                      {context?.t?.addColumn}
                    </button>

                    {columns.length !== 0 ? (
                      columns.map((column, index) => (
                        <div key={index} className="row g-2 mb-3">
                          <div className="col">
                            {fileColumns ? (
                              <Select
                                options={fileColumns.map((item) => {
                                  return {
                                    value: item,
                                    label: item,
                                  };
                                })}
                                placeholder={context?.t?.choose_column}
                                classNamePrefix="react-select"
                                noOptionsMessage={() => context?.t?.no_option}
                                value={column.selectedColumns}
                                onChange={(selected) =>
                                  handleColumnChange(selected, index)
                                }
                              />
                            ) : (
                              <div className="spinner-border spinner-border-sm text-theme"></div>
                            )}
                          </div>

                          <div className="col">
                            <Select
                              options={columnOperationSelectOptions}
                              placeholder={context?.t?.selectAction}
                              classNamePrefix="react-select"
                              noOptionsMessage={() => context?.t?.no_option}
                              value={column.operationType}
                              onChange={(selected) =>
                                handleOperationTypeChange(selected, index)
                              }
                            />
                          </div>

                          <div className="col">
                            <Select
                              options={[
                                { value: "", label: context?.t?.none },
                                {
                                  value:
                                    column.selectedColumns.value + "_todate",
                                  label: context?.t?.date,
                                },
                                {
                                  value:
                                    column.selectedColumns.value +
                                    "_dayofmonth",
                                  label: context?.t?.day,
                                },
                                {
                                  value:
                                    column.selectedColumns.value + "_tomonth",
                                  label: context?.t?.month,
                                },
                                {
                                  value:
                                    column.selectedColumns.value + "_toyear",
                                  label: context?.t?.year,
                                },
                                {
                                  value:
                                    column.selectedColumns.value + "_tohour",
                                  label: context?.t?.hour,
                                },
                                {
                                  value:
                                    column.selectedColumns.value + "_tominute",
                                  label: context?.t?.minute,
                                },
                                {
                                  value:
                                    column.selectedColumns.value + "_tosecond",
                                  label: context?.t?.second,
                                },
                              ]}
                              isMulti
                              placeholder={context?.t?.dateGrouping}
                              classNamePrefix="react-select"
                              noOptionsMessage={() => context?.t?.no_option}
                              value={column.dateGroupValue}
                              onChange={(selected) =>
                                handleDateTypeChange(selected, index)
                              }
                            />
                          </div>

                          <div className="col w-auto" style={{ flex: "none" }}>
                            <button
                              className="btn border-0 btn-danger h-100"
                              onClick={() => handleRemoveColumn(column.id)}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>{context?.t?.columnNotFound}</div>
                    )}
                  </div>

                  <div className="col-12">
                    <hr className="my-0" />
                  </div>

                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-outline-theme w-100 mb-3"
                      onClick={addCondition}
                    >
                      {context?.t?.add_condition}
                    </button>

                    {operation.conditions.length !== 0 ? (
                      operation.conditions.map((condition, conditionIndex) => (
                        <div
                          key={conditionIndex}
                          className="row align-items-center g-2 mb-2"
                        >
                          <div
                            className="col w-auto"
                            style={{ flex: "none", minWidth: 80 }}
                          >
                            {conditionIndex + 1}. {context?.t?.condition}:
                          </div>
                          <div className="col">
                            {fileColumns ? (
                              <Select
                                options={fileColumns.map((item) => {
                                  return {
                                    value: item,
                                    label: item,
                                  };
                                })}
                                placeholder={context?.t?.choose_column}
                                classNamePrefix="react-select"
                                noOptionsMessage={() => context?.t?.no_option}
                                // isDisabled={condition.conditionDisabled}
                                value={condition.selectedColumn}
                                onChange={(selectedOption) =>
                                  handleConditionColumnChange(
                                    conditionIndex,
                                    selectedOption
                                  )
                                }
                              />
                            ) : (
                              <div className="spinner-border spinner-border-sm text-theme"></div>
                            )}
                          </div>
                          <div className="col">
                            <Select
                              options={selectedConditionOptions}
                              placeholder={context?.t?.selectOperator}
                              classNamePrefix="react-select"
                              noOptionsMessage={() => context?.t?.no_option}
                              // isDisabled={condition.conditionDisabled}
                              value={condition.selectedCondition}
                              onChange={(selectedOption) =>
                                handleConditionChange(
                                  conditionIndex,
                                  selectedOption
                                )
                              }
                            />
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={context?.t?.value}
                              disabled={condition.inputDisabled}
                              value={condition.inputValue}
                              onChange={(event) =>
                                handleInputChange(conditionIndex, event)
                              }
                            />
                          </div>
                          <div className="col">
                            <Select
                              options={selectedOperatorOptions}
                              value={condition.selectedOperator}
                              placeholder={context?.t?.choose_condition}
                              classNamePrefix="react-select"
                              noOptionsMessage={() => context?.t?.no_option}
                              onChange={(selectedOption) =>
                                handleOperatorChange(
                                  conditionIndex,
                                  selectedOption
                                )
                              }
                            />
                          </div>
                          <div className="col w-auto" style={{ flex: "none" }}>
                            <button
                              className="btn border-0 btn-danger h-100"
                              onClick={() => removeCondition(conditionIndex)}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>{context?.t?.conditionNotFound}</div>
                    )}
                  </div>

                  <div className="col-12">
                    <hr className="my-0" />
                  </div>

                  {/* <div className="col-12">
                <button
                  type="button"
                  className="btn btn-outline-theme w-100 mb-3"
                  onClick={handleAddOrder}
                >
                  Sıralama Ekle
                </button>

                {operation.orderings.length !== 0 ? (
                  operation.orderings.map((order, orderIndex) => (
                    <div
                      key={orderIndex}
                      className="row align-items-center g-2 mb-2"
                    >
                      <div className="col">
                       {fileColumns ? (
                          <Select
                            options={fileColumns.map((item) => {
                              return {
                                value: item,
                                label: item,
                              };
                            })}
                            placeholder={context?.t?.choose_column}
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            value={order.column}
                            onChange={(selectedOption) =>
                              handleOrderColumnChange(orderIndex, selectedOption)
                            }
                          />
                        ) : (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        )}
                      </div>
                      <div className="col">
                        <Select
                          options={[
                            { value: "asc", label: "ARTAN" },
                            { value: "desc", label: "AZALAN" },
                          ]}
                          placeholder="Sıra Seç"
                          classNamePrefix="react-select"
                          noOptionsMessage={() => context?.t?.no_option}
                          value={order.order}
                          onChange={(selectedOption) =>
                            handleOrderChange(orderIndex, selectedOption)
                          }
                        />
                      </div>
                      <div className="col w-auto" style={{ flex: "none" }}>
                        <button
                          className="btn border-0 btn-danger h-100"
                          onClick={() => handleRemoveOrder(orderIndex)}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>Sıralama Bulunamadı</div>
                )}
              </div> */}
                  {/* 
              <div className="col-12">
                <hr className="my-0" />
              </div> */}

                  <div className="col-12">
                    <span className="mb-1 d-block">{context?.t?.group}</span>
                    {fileColumns ? (
                      <Select
                        options={fileColumns.map((item) => {
                          return {
                            value: item,
                            label: item,
                          };
                        })}
                        isMulti
                        placeholder={context?.t?.choose_column}
                        classNamePrefix="react-select"
                        noOptionsMessage={() => context?.t?.no_option}
                        value={groupingColumns}
                        onChange={(selectedOptions) => {
                          // checkDateColumnSelected(selectedOptions);
                          handleGroupingChange(selectedOptions);
                        }}
                      />
                    ) : (
                      <div className="spinner-border spinner-border-sm text-theme"></div>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>

            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-outline-theme w-100"
                  data-bs-dismiss="modal"
                >
                  {context?.t?.cancel}
                </button>
              </div>

              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-theme w-100 mb-3"
                  onClick={!loader ? handleCreateButtonClick : undefined}
                >
                  {context?.t?.preview}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12">
            {chartView && chartView.value && (
              <>
                <hr className="mt-n3" />

                <div className="row">
                  <div className="col-6">
                    <h4 className="mb-0">{context?.t?.graphic_preview}</h4>
                  </div>

                  <div className="col-6 text-end">
                    <small className="fw-medium">
                      {context?.t?.choose_page}: {page?.label}
                    </small>
                    <br />
                    <small className="fw-medium">
                      {context?.t?.choose_chart}:{" "}
                      {chartViewSelectOptions &&
                        chartViewSelectOptions.find(
                          (e) => e.value === chartView.value
                        ).label}
                    </small>
                  </div>
                </div>

                <div className="row align-items-center mt-3">
                  {chartView.value !== 4 && (
                    <>
                      <div className="col">
                        <span className="mb-1 d-block">
                          {chartView.value === 5
                            ? "Latitude"
                            : context?.t?.value}
                        </span>
                        <Select
                          options={
                            previewData.length !== 0
                              ? Object.keys(previewData[0]).map((key) => ({
                                  value: key,
                                  label: key,
                                }))
                              : []
                          }
                          placeholder={context?.t?.choose_column}
                          classNamePrefix="react-select"
                          noOptionsMessage={() => context?.t?.no_option}
                          value={previewSelectValue}
                          onChange={(e) => {
                            setPreviewSelectWordCloud(null);
                            setPreviewSelectValue(e);
                          }}
                        />
                      </div>

                      <div className="col">
                        <span className="mb-1 d-block">
                          {chartView.value === 5
                            ? "Longitude"
                            : context?.t?.title}
                        </span>
                        <Select
                          options={
                            previewData.length !== 0
                              ? Object.keys(previewData[0]).map((key) => ({
                                  value: key,
                                  label: key,
                                }))
                              : []
                          }
                          placeholder={context?.t?.choose_column}
                          classNamePrefix="react-select"
                          noOptionsMessage={() => context?.t?.no_option}
                          value={previewSelectLabel}
                          onChange={(e) => {
                            setPreviewSelectWordCloud(null);
                            setPreviewSelectLabel(e);
                          }}
                        />
                      </div>
                    </>
                  )}

                  {chartView.value === 3 && (
                    <div className="col border-start">
                      <span className="mb-1 d-block">{context?.t?.string}</span>
                      <Select
                        options={
                          previewData.length !== 0
                            ? Object.keys(previewData[0]).map((key) => ({
                                value: key,
                                label: key,
                              }))
                            : []
                        }
                        placeholder={context?.t?.choose_column}
                        classNamePrefix="react-select"
                        noOptionsMessage={() => context?.t?.no_option}
                        value={previewSelectWordCloud}
                        onChange={(e) => handleSelectWordCloud(e)}
                      />
                    </div>
                  )}
                </div>

                <hr />

                <div className="row">
                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-outline-theme w-100"
                      onClick={() => handleResetPreview()}
                    >
                      {context?.t?.prev}
                    </button>
                  </div>

                  <div className="col-6">
                    <button
                      type="button"
                      className="btn btn-theme w-100 mb-3"
                      onClick={() => handlePreview()}
                    >
                      {context?.t?.view}
                    </button>
                  </div>
                </div>
              </>
            )}

            {previewChartShow && (
              <>
                {chartView && chartView.value && (
                  <>
                    <hr className="mt-0" />

                    <div className="col-12">
                      <div className="row g-3 mb-3">
                        {chartView.value !== 2 &&
                          chartView.value !== 3 &&
                          chartView.value !== 4 &&
                          chartView.value !== 7 &&
                          chartView.value !== 5 && (
                            <>
                              <div className="col">
                                <span>{context?.t?.menu_section}</span>
                                <button
                                  onClick={toggleLegend}
                                  className="btn btn-theme w-100"
                                >
                                  {context?.t?.show_hide}
                                </button>
                              </div>

                              <div className="col">
                                <span>{context?.t?.menu_align}</span>
                                <Select
                                  options={legendPositionOrientSelectOptions}
                                  placeholder=""
                                  classNamePrefix="react-select"
                                  noOptionsMessage={() => context?.t?.no_option}
                                  value={legendOrient}
                                  onChange={(e) => handleOrientChange(e)}
                                />
                              </div>
                              <div className="col">
                                <span>{context?.t?.menu_horizontal}</span>
                                <Select
                                  options={legendPositionLeftSelectOptions}
                                  placeholder=""
                                  classNamePrefix="react-select"
                                  noOptionsMessage={() => context?.t?.no_option}
                                  value={legendPosition.left}
                                  onChange={(e) =>
                                    handlePositionChange("left", e)
                                  }
                                />
                              </div>
                              <div className="col border-end">
                                <span>{context?.t?.menu_vertical}</span>
                                <Select
                                  options={legendPositionTopSelectOptions}
                                  placeholder=""
                                  classNamePrefix="react-select"
                                  noOptionsMessage={() => context?.t?.no_option}
                                  value={legendPosition.top}
                                  onChange={(e) =>
                                    handlePositionChange("top", e)
                                  }
                                />
                              </div>
                            </>
                          )}

                        <div className="col">
                          <span>{context?.t?.chart_width}</span>
                          <Select
                            options={[
                              {
                                value: "col-12",
                                label: context?.t?.xl,
                              },
                              {
                                value: "col-4",
                                label: context?.t?.lg,
                              },
                              {
                                value: "col-6",
                                label: context?.t?.m,
                              },
                              {
                                value: "col-3",
                                label: context?.t?.sm,
                              },
                            ]}
                            placeholder=""
                            classNamePrefix="react-select"
                            noOptionsMessage={() => context?.t?.no_option}
                            value={graphicWidth}
                            onChange={(e) => setGraphicWidth(e)}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className={graphicWidth.value}>
                          <Card style={{ height: 700 }}>
                            <CardBody className="d-flex flex-column overflow-hidden">
                              <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
                                <span
                                  className={`fw-bold h6 mb-0 d-flex align-items-center justify-content-center text-center flex-1`}
                                >
                                  {chartName.toUpperCase()}
                                </span>
                              </div>

                              <div className="position-relative overflow-hidden flex-1">
                                {chartView.value === 5 ? (
                                  <DynamicMap
                                    data={previewData}
                                    selectedColumnLabel={previewSelectLabel}
                                    selectedColumnValue={previewSelectValue}
                                    tableColumn={columns}
                                  />
                                ) : chartView.value === 4 ? (
                                  <DynamicTable
                                    data={previewData}
                                    tablePage={tablePage}
                                    setTablePage={setTablePage}
                                    setTableCondition={setTableCondition}
                                    tableColumn={columns}
                                  />
                                ) : chartView.value === 3 ? (
                                  <WordCloud data={wordCloudData} />
                                ) : (
                                  <ReactECharts
                                    option={chartOptions}
                                    className="h-100"
                                  />
                                )}
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <hr />

                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-theme w-100 mb-3"
                    onClick={() => handleGraphicPost()}
                  >
                    {context.graphicUpdate
                      ? context?.t?.update_chart
                      : context?.t?.added_chart}
                  </button>
                </div>
              </>
            )}
          </div>
        )}

        {loader && (
          <div
            className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
            style={{ background: "rgba(29, 40, 53, 0.95)", zIndex: 99 }}
          >
            <div className="spinner-border text-theme"></div>
          </div>
        )}
      </div>
    )
  );
}
