import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

import toastMessage from "../ToastMessage";
import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";
// import TusUploader from "./tus-upload";

export default function ImportFile({
  closeId,
  pageNameRequired = false,
  pageName = null,
  dataname = null,
  customerProps = null,
  pageImport = false,
  columnTypesString = false,
}) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [separator, setSeparator] = useState(null);
  const [columns, setColumns] = useState([]);
  const [fileImportError, setFileImportError] = useState(null);
  const [file, setFile] = useState(null);
  const [dataName, setDataName] = useState("");
  const [columnTypes, setColumnTypes] = useState({});

  const [customerValue, setCustomerValue] = useState({
    value: null,
    label: context?.t?.all,
  });

  useEffect(() => {
    if (customerProps) {
      setCustomerValue({
        value: customerProps?.value,
        label: customerProps?.label,
        user_id: customerProps?.user_id,
      });
    } else {
      setCustomerValue({
        value: null,
        label: context?.t?.all,
        user_id: null,
      });
    }
  }, [customerProps, context]);

  function errorMessages(error) {
    if (error.response && error.response.status) {
      if (error.response.status === 422) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }

      if (error.response.status === 401) {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }

      if (error.response.status === 403) {
        alert(context?.t?.try_again_error);
        setTimeout(() => {
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }, 1000);
      }

      if (error.response.status === 500) {
        alert(context?.t?.server_error);
      }
    } else {
      toastMessage(context?.t?.file_size_error, "error");
    }
  }

  const separators = [
    ",",
    ";",
    "|",
    ":",
    "!",
    "#",
    "$",
    "%",
    "&",
    "*",
    "+",
    ".",
    "/",
    "<",
    "=",
    ">",
    "?",
    "@",
    "^",
    "-",
    "_",
    "`",
    "~",
  ];

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setColumns([]);

      if (file.size >= 200 * 1024 * 1024) {
        toastMessage(context?.t?.max_200_mb, "error");
      } else {
        setFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target.result;
          const detectedSeparator = detectSeparator(text);
          if (detectedSeparator) {
            setSeparator(detectedSeparator);
            setFileImportError(null);
            const columns = extractColumns(text, detectedSeparator);
            setColumns(columns);
            setColumnTypes(
              columns.reduce((acc, col) => {
                acc[col] = "";
                return acc;
              }, {})
            );
          } else {
            setSeparator(null);
            setFileImportError(context?.t?.separators_error);
            setColumns([]);
          }
        };
        reader.readAsText(file);
      }
    }
  };

  const detectSeparator = (text) => {
    let maxSeparator = null;
    let maxCount = 0;

    separators.forEach((sep) => {
      const count = text.split(sep).length - 1;
      if (count > maxCount) {
        maxCount = count;
        maxSeparator = sep;
      }
    });

    return maxCount > 0 ? maxSeparator : null;
  };

  const extractColumns = (text, separator) => {
    const rows = text.split("\n");
    if (rows.length > 0) {
      return rows[0].split(separator).map((col) => col.trim());
    }
    return [];
  };

  const handleColumnTypeChange = (column, selectedOption) => {
    setColumnTypes({
      ...columnTypes,
      [column]: selectedOption ? selectedOption.value : "",
    });
  };

  const handleSubmit = async () => {
    let staticColumnTypes = null;

    if (pageNameRequired && !pageName) {
      toastMessage(context?.t?.analysisRequired, "error");
      return;
    }

    if (!dataName) {
      toastMessage(context?.t?.data_name_important, "error");
      return;
    }
    const maxFileSize = 200 * 1024 * 1024; // 5MB in bytes

    if (!file) {
      toastMessage(context?.t?.file_important, "error");
      return;
    }
    if (file.size > maxFileSize) {
      toastMessage(context?.t?.max_200_mb, "error");
      return;
    }
    if (!separator) {
      toastMessage(context?.t?.separators_warning, "error");
      return;
    }

    if (columnTypesString) {
      staticColumnTypes = Object.fromEntries(
        Object.entries(columnTypes).map(([key, value]) => [key, "string"])
      );
    } else {
      staticColumnTypes = null;
    }

    if (!columnTypesString && columns.some((col) => !columnTypes[col])) {
      toastMessage(context?.t?.column_warning, "error");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "data_type",
      context?.me?.role?.id === 1
        ? dataname &&
          dataname.includes("customer-") &&
          dataname.split("customer-")[1].split("-")[0] !== "null"
          ? "custom"
          : customerValue?.value
          ? "custom"
          : "global"
        : "custom"
    );

    if (
      dataname &&
      dataname.includes("user-") &&
      dataname.split("user-")[1] !== "null"
    ) {
      formData.append("user_id", dataname.split("user-")[1]);
    } else {
      const localUser = localStorage.getItem("user");
      if (localUser) {
        const parsedUser = JSON.parse(localUser);
        formData.append(
          "user_id",
          customerValue?.value
            ? customerValue?.user_id
            : parsedUser.customer &&
              parsedUser.customer.user &&
              parsedUser.customer.user.length !== 0
            ? parsedUser.customer.user[0].id
            : null
        );
      } else {
        formData.append("user_id", null);
      }
    }

    formData.append(
      "type",
      dataname ? dataname.split("-user-")[0] || dataname : dataName
    );
    formData.append("separator", separator);
    columns.forEach((col, index) => {
      formData.append("columns[" + index + "][key]", col);
      formData.append(
        "columns[" + index + "][value]",
        staticColumnTypes ? staticColumnTypes[col] : columnTypes[col]
      );
    });

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    setLoader(true);

    try {
      const response = await apiClient.post("/csv/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setLoader(false);

      if (response.status) {
        toastMessage(context?.t?.file_import_success, "success");
        toastMessage(context?.t?.file_import_info, "success");

        setSeparator(null);
        setColumns([]);
        setFileImportError(null);
        setFile(null);
        setDataName("");
        setColumnTypes({});

        if (closeId) {
          setTimeout(() => {
            document.getElementById(closeId).click();
          }, 300);
        }
      } else {
        toastMessage(context?.t?.file_import_error, "error");
      }
    } catch (error) {
      setLoader(false);
      console.error("Import CSV File Error", error);
      errorMessages(error);
    }
  };

  //--

  useEffect(() => {
    if (dataname) {
      setDataName(dataname);
    } else {
      setDataName("");
    }
  }, [dataname]);

  return (
    <div>
      {/* <TusUploader /> */}

      {!columnTypesString && context && context?.me?.role?.id === 1 && (
        <div className="mb-3">
          <span className="mb-1 d-block">{context?.t?.customer}</span>
          {context.allCustomerSelect ? (
            <Select
              options={[
                {
                  value: null,
                  label: context?.t?.all,
                  user_id: null,
                },
                ...context.allCustomerSelect,
              ]}
              placeholder=""
              classNamePrefix="react-select"
              noOptionsMessage={() => context?.t?.no_option}
              value={customerValue}
              onChange={(e) => setCustomerValue(e)}
            />
          ) : (
            <div className="spinner-border spinner-border-sm text-theme"></div>
          )}
        </div>
      )}

      {!pageImport && (
        <div className="mb-3">
          <span className="mb-1 d-block">{context?.t?.data_name}</span>
          <input
            type="text"
            className="form-control"
            value={dataName}
            onChange={(e) => setDataName(e.target.value)}
          />
        </div>
      )}

      <div className="mb-3">
        <span className="mb-1 d-block">{context?.t?.file}</span>
        <input
          id={`import_file_csv${dataName ? "_" + dataName : ""}`}
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          className="d-none"
        />
        <label
          htmlFor={`import_file_csv${dataName ? "_" + dataName : ""}`}
          className="btn btn-outline-theme w-100 h-100 d-flex align-items-center justify-content-center pointer"
        >
          {context?.t?.choose_csv_file}
        </label>
        {!columnTypesString && (
          <div className="d-flex gap-1 flex-wrap mt-2">
            <span className="me-2">{context?.t?.separators}:</span>
            {separators.map((item, index) => (
              <span
                className="px-1"
                style={{
                  background: "rgb(255 255 255 / 10%)",
                }}
                key={index}
              >
                {item}
              </span>
            ))}
          </div>
        )}
      </div>

      {fileImportError && <p style={{ color: "red" }}>{fileImportError}</p>}

      {columns.length !== 0 && (
        <div className="mb-3 border-top pt-3 mt-4">
          <span className="mb-1 d-block">
            <span className="d-block">
              {context?.t?.file_name}: {file.name}
            </span>
          </span>

          {!columnTypesString && (
            <div className="table-responsive pb-5" style={{ minHeight: 300 }}>
              <table className="align-middle table border">
                <tbody>
                  {columns.map((column, index) => (
                    <tr key={index}>
                      <td>{column}</td>
                      <td className="border-start" width={200}>
                        <Select
                          options={[
                            { value: "id", label: context?.t?.id },
                            { value: "integer", label: context?.t?.integer },
                            // { value: "integer", label: context?.t?.ondalik_sayi },
                            { value: "string", label: context?.t?.string },
                            { value: "date", label: context?.t?.date },
                            { value: "json", label: context?.t?.json },
                            { value: "email", label: context?.t?.email },
                            { value: "phone", label: context?.t?.phone },
                            {
                              value: "boolean",
                              label: context?.t?.boolean,
                            },
                          ]}
                          placeholder={context?.t?.data_type}
                          classNamePrefix="react-select"
                          noOptionsMessage={() => context?.t?.no_option}
                          onChange={(selectedOption) =>
                            handleColumnTypeChange(column, selectedOption)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}

      {columnTypes && Object.values(columnTypes).includes("date") && (
        <p
          dangerouslySetInnerHTML={{
            __html: context?.t?.warning_for_date,
          }}
        />
      )}

      <button
        type="button"
        className="btn btn-theme w-100 mb-3"
        onClick={handleSubmit}
        disabled={loader}
      >
        {context?.t?.upload}
        {loader && (
          <div className="spinner-border spinner-border-sm text-white ms-2"></div>
        )}
      </button>
    </div>
  );
}
