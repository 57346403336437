import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import apiClient from "../../ApiClient.js";
import { AppSettings } from "../../config/app-settings.js";

import TaskItem from "../../components/TaskItem.jsx";
import toastMessage from "../../components/ToastMessage.js";
import { ToastContainer } from "react-toastify";

export default function Todo({
  scrum,
  setTodoEditData,
  todoData,
  getTodoData,
}) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  // Error Messages
  function errorMessages(error) {
    if (error.response.status === 422) {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }

    if (error.response.status === 401) {
      context.setMe(null);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/giris-yap");
    }

    if (error.response.status === 403) {
      toastMessage(context?.t?.try_again_error, "error");
      setTimeout(() => {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }, 1000);
    }

    if (error.response.status === 500) {
      toastMessage(context?.t?.server_error, "error");
    }
  }

  // Todo Edit
  async function handleTodoEdit(todo) {
    setTodoEditData(todo);
  }

  // Todo Delete
  async function handleTodoDelete(id) {
    try {
      const response = await apiClient.delete("/todos/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        toastMessage(context?.t?.gorev_silindi, "success");
        setTodoEditData(true);
        getTodoData();
      }
    } catch (error) {
      console.error("Görev Silindi Error", error);
      errorMessages(error);
    }
  }

  //--

  const [userSelectData, setUserSelectData] = useState([]);

  async function handleCustomerSelect(id) {
    if (scrum && id) {
      try {
        const response = await apiClient.get(`user?customer_id=${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setUserSelectData(response.data);
      } catch (error) {
        setUserSelectData([]);
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  //--

  useEffect(() => {
    getTodoData();
  }, []);

  useEffect(() => {
    handleCustomerSelect(context?.me?.customer?.id);
    // eslint-disable-next-line
  }, [scrum, context]);

  return (
    <>
      {todoData ? (
        todoData.length !== 0 ? (
          todoData.map(
            (item, index) =>
              item.category && (
                <div key={index + "_todoitem"}>
                  <TaskItem
                    item={item}
                    index={index}
                    todoDataLength={todoData.length}
                    handleTodoEdit={handleTodoEdit}
                    handleTodoDelete={handleTodoDelete}
                    getTodoData={getTodoData}
                  />
                </div>
              )
          )
        ) : (
          <span className="d-block text-center">
            {context?.t?.eklenmis_bir_gorev_bulunmamaktadir}
          </span>
        )
      ) : (
        <div className="spinner-border text-theme"></div>
      )}

      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
}
