import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

import DynamicCard from "../../components/DynamicCard";
import TableWithPrediction from "./TableWithPrediction";

const DinamikPage = ({
  menuId = null,
  width = null,
  height = 600,
  getChartImages = false,
  removeGrid = false,
  firstItem = false,
  lastItem = false,
  events = true,
  filterPath,
}) => {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const location = useLocation();
  const menu = location.search.split("id=")
    ? location.search.split("id=")[1]
    : null;

  const [data, setData] = useState(null);
  const [iframe, setIframe] = useState(null);

  const [triggerFetch, setTriggerFetch] = useState(false);

  const fetchPageData = async (menuId, filterPath) => {
    setData(null);

    try {
      const response = await apiClient.get(`/page/${menuId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status) {
        if (response?.data?.iframe && response?.data?.iframe !== "") {
          setIframe(response?.data?.iframe);
          setData([]);
        } else {
          let updatedData = response?.data?.graphics.sort(
            (a, b) => a.order - b.order
          );

          if (lastItem) {
            updatedData = updatedData.slice(-lastItem);
          } else if (firstItem) {
            updatedData = updatedData.slice(0, updatedData.length - firstItem);
          }

          const responses = [];

          for (const item of updatedData) {
            const graphicResponse = await apiClient.get(
              `/graphics/${item.id}${filterPath || ""}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );

            if (graphicResponse.data.query.chart !== 4) {
              if (
                graphicResponse.data.query.options.selectedColumn[0].label.includes(
                  "_avg"
                )
              ) {
                graphicResponse.data.query.options.options.tooltip = {
                  trigger: "item",
                  formatter: function (params) {
                    return (
                      params.data.name +
                      "<br /> %" +
                      params.data.value.toFixed(2)
                    );
                  },
                };
              }
            }

            if (item.chart === 1) {
              const result = graphicResponse.data.result;
              const label0 =
                graphicResponse.data.query.options.selectedColumn[0].label;
              const label1 =
                graphicResponse.data.query.options.selectedColumn[1].label;

              const formattedResult = result.map((entry) => ({
                value: entry[label0],
                name: entry[label1],
              }));

              graphicResponse.data.query.options.options.series[0].data =
                formattedResult;

              const values = formattedResult.map((entry) => entry.value);
              const maxValue = Math.max(...values);
              const minValue = Math.min(...values);

              if (graphicResponse.data.query.options.options.visualMap) {
                graphicResponse.data.query.options.options.visualMap.max =
                  maxValue;
                graphicResponse.data.query.options.options.visualMap.min =
                  minValue;
              }
            }

            responses.push(graphicResponse.data);
          }

          setData(responses);
          setIframe(null);
        }
      }
    } catch (error) {
      console.error("Get Page", error);
    }
  };

  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    if (menuId) {
      fetchPageData(menuId, filterPath);
    } else {
      if (menu) {
        fetchPageData(menu, filterPath);
        window.speechSynthesis.cancel();
        setActiveIndex(null, filterPath);
      } else {
        if (window.location.search.split("id=")) {
          fetchPageData(
            parseInt(window.location.search.split("id=")[1]),
            filterPath
          );
        } else {
          fetchPageData(null, filterPath);
          navigate("/404");
        }
      }
    }
  }, [menuId, menu, filterPath, triggerFetch, context?.chartColor]);

  //--

  const readText = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const [cardName, setCardName] = useState("");
  const [cardNote, setCardNote] = useState("");

  const renderContent = () => {
    return data ? (
      !iframe ? (
        data.length !== 0 ? (
          data
            .sort((a, b) => a.query.order - b.query.order)
            .map((item, index) => (
              <DynamicCard
                triggerFetch={triggerFetch}
                setTriggerFetch={setTriggerFetch}
                dataLength={data.length}
                key={index}
                fullScreen={true}
                item={item.query}
                result={item.result}
                width={width}
                height={height}
                menu={menu}
                events={events}
                isActive={index === activeIndex}
                setActiveIndex={setActiveIndex}
                readText={() => readText(index)}
                setCardNote={setCardNote}
                setCardName={setCardName}
                getChartImages={getChartImages}
              />
            ))
        ) : (
          <p>İçerik Bulunamadı</p>
        )
      ) : (
        <div className="page-iframe">
          <iframe src={iframe} title={menu?.name}></iframe>
        </div>
      )
    ) : (
      <div className="spinner-border text-theme"></div>
    );
  };

  return (
    <>
      {/* <div className="mb-5 pb-5">
        <TableWithPrediction filterPath={filterPath} />
      </div> */}
      {!removeGrid ? (
        <div className="row g-4 mb-5 pb-5 zoom-item">{renderContent()}</div>
      ) : (
        renderContent()
      )}

      {menu && (
        <div
          className="modal modal-cover fade"
          id={`pageCardNoteModal_${menu}`}
        >
          <div className="modal-dialog">
            <div
              className="modal-content rounded"
              style={{ background: "#141b24" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">
                  {cardName} - {context?.t?.note}
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div className="modal-body">
                <textarea
                  className="form-control"
                  defaultValue={cardNote}
                  readOnly
                  style={{ height: 300 }}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DinamikPage;
