const ar = {
    olusturulma: "تاريخ الإنشاء",
    guncelleme: "تاريخ التحديث",

    menu_rengi: "لون القائمة",
    site_rengi: "لون الموقع",
    grafik_rengi: "لون الرسم البياني",

    "deprem": "زلزال",
    "risk_arastirmasi": "دراسة المخاطر",
    "diri_fay_hatti_yakinligi": "القرب من خط الصدع النشط",
    "deprem_risk_derece_seviyesi": "مستوى درجة خطر الزلزال",
    "yerlesim_yeri_envanteri": "جرد موقع الاستيطان",
    "yapim_yilina_gore_bina_sayisi": "عدد المباني حسب سنة البناء",
    "kat_sayisina_gore_bina_sayisi": "عدد المباني حسب عدد الطوابق",
    "yapim_turune_gore_bina_sayisi": "عدد المباني حسب نوع البناء",

    rapor_basariyla_olusturuldu: "تم إنشاء التقرير بنجاح.",
    ondalik_sayi: "عدد عشري",

    "ileri_analiz": "تحليل متقدم",
    "genel_gorunum": "عرض عام",
    "anket_ve_arastirmalar": "استطلاعات وأبحاث",
    "ozel_sayfalar": "صفحات خاصة",
    "analiz_icin_iletisime_gecin": "تواصل للتحليل",

    "ocak": "يناير",
    "subat": "فبراير",
    "mart": "مارس",
    "nisan": "أبريل",
    "mayis": "مايو",
    "haziran": "يونيو",
    "temmuz": "يوليو",
    "agustos": "أغسطس",
    "eylul": "سبتمبر",
    "ekim": "أكتوبر",
    "kasim": "نوفمبر",
    "aralik": "ديسمبر",

    "BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2009": "انتخابات بلدية عام 2009",
    "DONEM_MILLETVEKILLIGI_GENEL_SECIMI_24": "الانتخابات العامة للبرلمان للدورة 24",
    "BELEDIYE_BASKANLIGI_SECIMI_2014": "انتخابات بلدية عام 2014",
    "CUMHURBASKANLIGI_SECIMI_12": "الانتخابات الرئاسية 12",
    "DONEM_MILLETVEKILLIGI_SECIMLERI_25": "الانتخابات البرلمانية للدورة 25",
    "MILLETVEKILLIGI_GENEL_SECIMLERI_26": "الانتخابات العامة للبرلمان 26",
    "BASKANLIK_SECIMI_1": "الانتخابات الرئاسية 1",
    "DONEM_MILLETVEKILLIGI_GENEL_SECIMLERI_27": "الانتخابات العامة للبرلمان للدورة 27",
    "BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2019": "انتخابات بلدية عام 2019",
    "YENILENEN_ISTANBUL_SECIMI_2019": "انتخابات إسطنبول المعادة 2019",
    "DONEM_MILLETVEKILI_GENEL_SECIMLERI_28": "الانتخابات العامة للبرلمان للدورة 28",
    "BASKANLIK_SECIMI_2_TUR_2": "الانتخابات الرئاسية الجولة الثانية 2",

    "siyasal_kimlik_analiz_sonuclari": "نتائج تحليل الهوية السياسية",
    "baskanlik_secimi_ve_sokaga_gore_sandik_basina_birey_sayisi_tablosu": "جدول عدد الأفراد حسب الانتخابات الرئاسية والشارع",
    "yeni_secmen": "ناخب جديد",
    "birey": "فرد",
    "mahallede_son_yil_itibariyle_toplam_sayisi": "إجمالي العدد في الحي في السنة الأخيرة",
    "birey_bulunmaktadir": "يوجد فرد.",
    "hane_bulunmaktadir": "يوجد أسرة.",
    "sokak_bulunmaktadir": "يوجد شارع.",
    "bina_bulunmaktadir": "يوجد مبنى.",
    "gorev_mevcut_degil": "المهمة غير موجودة",
    "mahallede_bulunan_sendikalar": "النقابات الموجودة في الحي",
    "mahallede_bulunan_dernekler": "الجمعيات الموجودة في الحي",
    "secim_analiz_raporu": "تقرير تحليل الانتخابات",
    "medeni_durum_analizi": "تحليل الحالة الاجتماعية",
    "downloading_text": "جارٍ التحميل...",
    "yas_grubuna_gore_birey_durumu": "حالة الأفراد حسب الفئة العمرية",
    "egitim_durumu": "الحالة التعليمية",
    "mahallede_ortalama_secmen_yasi": "متوسط عمر الناخبين في الحي",
    "bulunmaktadir": "يوجد.",
    "strateji_merkezi_mahalle_raporu": "تقرير الحي لمركز الاستراتيجية",
    "strateji_merkezi_sokak_raporu": "تقرير الشارع لمركز الاستراتيجية",
    "anket_cevaplanmamis": "لم يتم الرد على الاستبيان",
    "cevap_silindi": "تم حذف الرد",
    "pin_filtre": "فلتر الدبوس",
    "arastirma_cevaplari": "ردود البحث",
    "bu_cevabi_sil": "احذف هذا الرد",
    "telefon_kontrol_edildi": "تم التحقق من الهاتف",
    "haritayi_gizle": "اخفاء الخريطة",
    "kullanici_bilgileri": "معلومات المستخدم",
    "toplam_soru": "إجمالي الأسئلة",
    "resim_yok": "لا توجد صورة",
    "guncel_hali": "الحالة الحالية",

    "desteklenmeyen_dosya_formati": "تنسيق الملف غير مدعوم",
    "dosya_boyutu_yuksek": "حجم الملف كبير",
    "baslik_tarihi_bos_birakmayin": "لا تترك العنوان فارغًا",
    "baslangic_tarihi_bos_birakmayin": "لا تترك تاريخ البدء فارغًا",
    "bitis_tarihi_bos_birakmayin": "لا تترك تاريخ الانتهاء فارغًا",
    "kategori_bos_birakmayin": "لا تترك الفئة فارغة",
    "aciklama_bos_birakmayin": "لا تترك الوصف فارغًا",
    "il_bos_birakmayin": "لا تترك المحافظة فارغة",
    "ilce_bos_birakmayin": "لا تترك المنطقة فارغة",
    "mahalle_bos_birakmayin": "لا تترك الحي فارغًا",
    "koordinat_belirleyin": "حدد الإحداثيات",
    "ilce_girmelisiniz": "يجب إدخال المنطقة",
    "tamamlanma_yuzdesi": "نسبة الإنجاز",
    "etiketler": "علامات",
    "etiket_girin": "أدخل العلامة",
    "dosya_kaldir": "إزالة الملف",
    "resimleri_yukle": "تحميل الصور",
    "dosyalari_buraya_birakin": "اسحب الملفات هنا",
    "surukle_birak_veya_tikla": "اسحب أو انقر",
    "gorev_guncelle": "تحديث المهمة",
    "gorev_guncellendi": "تم تحديث المهمة",
    "gorev_olusturuldu": "تم إنشاء المهمة",
    "gorev_olustur": "إنشاء مهمة",
    "kategori_duzenle": "تعديل الفئة",
    "kategori_ekle": "إضافة فئة",
    "kategori_adi": "اسم الفئة",
    "kategori_rengi": "لون الفئة",
    "kategorisi_ve_kategoriye_bagli_basliklar_silinecektir": "سيتم حذف الفئة والعناوين المرتبطة بها.",
    "eklenmis_bir_gorev_bulunmamaktadir": "لا توجد مهمة مضافة",
    "gorev_silindi": "تم حذف المهمة",
    "kategori_sirasi_guncellendi": "تم تحديث ترتيب الفئة",
    "liste_sirasi_guncellendi": "تم تحديث ترتيب القائمة",
    "yonetici_ozetini_kapat_mesaji": "ملخص المدير، انقر في منطقة أخرى للإغلاق.",
    "yonetici_ozetini_oku": "اقرأ ملخص المدير",
    "olusturan_kisi": "الشخص الذي أنشأه",
    "olusturulma_tarihi": "تاريخ الإنشاء",
    "guncelleyen_kisi": "الشخص الذي قام بالتحديث",
    "olusturma_tarihi": "تاريخ الإنشاء",
    "guncelleme_tarihi": "تاريخ التحديث",
    "yonetici_ozeti": "ملخص المدير",

    "yapildi": "تم",
    "yapilmadi": "لم يتم",
    "kisiye_anket_ata": "تعيين استبيان لشخص",
    "atanmis_bir_anket_yok": "لا يوجد استبيان معين.",
    "kac_kisi": "كم شخص",
    "female": "أنثى",
    "male": "ذكر",
    "muhtarliklar": "المخاتير",
    "yapilacak_anketler": "الاستبيانات المطلوب إجراؤها",
    "kisilere_atanmiş_anketler": "الاستبيانات المعينة للأشخاص",
    "olusturulmus_tablo_secin": "اختر الجدول المنشأ",
    "tabloyu_goster": "عرض الجدول",
    "tabloyu_gizle": "إخفاء الجدول",
    "tabloyu_sil": "حذف الجدول",
    "tabloyu_indir": "تنزيل الجدول",
    "tablo_secin": "اختر الجدول",
    "filtre_olustur": "إنشاء فلتر",
    "filtre_adi_giriniz": "أدخل اسم الفلتر",
    "filtre_tablosu": "جدول الفلتر",
    "anket_ata": "تعيين استبيان",
    "anket_atandi": "تم تعيين الاستبيان",
    "anket_secmelisiniz": "يجب اختيار استبيان",
    "tc_bulunamadi": "لم يتم العثور على رقم الهوية",
    "tablo_silindi": "تم حذف الجدول",
    "filtre_adi_girmelisiniz": "يجب إدخال اسم الفلتر",
    "filtre_eklendi": "تم إضافة الفلتر",
    "filtre_yapmalisınız": "يجب عليك إعداد فلتر",
    "hizmet_bulunamadi": "لم يتم العثور على الخدمة",
    "sikayet_bulunamadi": "لم يتم العثور على الشكوى",
    "ayni_ildeki_akraba_sayisi": "عدد الأقارب في نفس المحافظة",
    "ayni_ilcedeki_akraba_sayisi": "عدد الأقارب في نفس المنطقة",
    "duzenleyen": "المنظم",
    "memleket_il": "محافظة مسقط الرأس",
    "memleket_ilce": "منطقة مسقط الرأس",
    "aslen_il": "المحافظة الأصلية",
    "aslen_ilce": "المنطقة الأصلية",
    "aile_buyugu": "رئيس الأسرة",
    "aile": "عائلة",
    "kisi_bulunamadi": "لم يتم العثور على شخص",
    "not_eklendi": "تم إضافة الملاحظة",

    "not_eklenmedi": "لم تتم إضافة الملاحظة",
    "not_ekle": "إضافة ملاحظة",
    "not_guncelle": "تحديث الملاحظة",
    "not_guncellenmedi": "لم يتم تحديث الملاحظة",
    "not_guncellendi": "تم تحديث الملاحظة",
    "resim_eklenmedi": "لم تتم إضافة الصورة",
    "resim_eklendi": "تم إضافة الصورة",
    "details": "تفاصيل",
    "detail": "تفصيل",
    "hizmetler": "الخدمات",
    "sikayetler": "الشكاوى",
    "iletilen_sikayetler": "الشكاوى المقدمة",
    "istenilen_hizmetler": "الخدمات المطلوبة",
    "is_kolu": "مجال العمل",
    "sendika_adi": "اسم النقابة",
    "sendikalar": "النقابات",
    "kurum_adres": "عنوان المؤسسة",
    "detayli_faaliyet_alani": "مجال النشاط التفصيلي",
    "faaliyet_alani": "مجال النشاط",
    "dernekler": "الجمعيات",
    "muhtarlik": "المخاتير",
    "muhtar_telefon": "هاتف المختار",
    "muhtar_adi": "اسم المختار",
    "muhtarlik_adi": "اسم المخاتير",
    "kisi_ve_uzeri_goc_eden_birey_sayisi": "عدد الأفراد المهاجرين من شخص واحد أو أكثر",
    "olum": "وفاة",
    "dogum": "ولادة",
    "olum_oranlari": "معدلات الوفاة",
    "dogum_oranlari": "معدلات الولادة",
    "dagilim": "التوزيع",
    "universite_ve_universite_ogrenci_sayilari": "عدد الجامعات وطلاب الجامعات",
    "verilen_goc": "الهجرة الممنوحة",
    "alinan_goc": "الهجرة المستلمة",
    "ilce_bazinda_verilen_goc": "الهجرة الممنوحة حسب المنطقة",
    "ilce_bazinda_alinan_goc": "الهجرة المستلمة حسب المنطقة",
    "secmen_genel_egitim_durumu": "الحالة التعليمية العامة للناخبين",
    "yillara_gore_sosyoekonomik_statu_dagilimi": "توزيع الحالة الاجتماعية والاقتصادية حسب السنوات",
    "yillara_gore_dogum_ve_olum_oranlari": "معدلات الولادة والوفاة حسب السنوات",
    "yillara_gore_secmen_medeni_durum_dagilimi": "توزيع الحالة المدنية للناخبين حسب السنوات",
    "birey_hemsehrilik_durumu": "حالة القرابة للفرد",
    "yas_grubuna_gore_birey_sayilari": "عدد الأفراد حسب فئة العمر",
    "yas_grubu": "فئة العمر",
    "tahmini_1_derece_aile": "تقدير عضوية الأسرة من الدرجة الأولى",
    "tahmini_2_derece_aile": "تقدير عضوية الأسرة من الدرجة الثانية",
    "tahmini_uye_yakini_olmayan": "تقدير عدم وجود قريب للعضو",
    "ortalama_secmen_yasi": "متوسط عمر الناخبين",
    "olum_sayisi": "عدد الوفيات",
    "dogum_sayisi": "عدد الولادات",
    "ilk_defa_oy_sayisi": "عدد الناخبين الذين سيصوتون للمرة الأولى",
    "toplam_secmen": "إجمالي الناخبين",
    "guncel": "حديث",
    "baskanlik_secimi": "الانتخابات الرئاسية الثانية",
    "tur": "الجولة",
    "tur_1": "الجولة 1",
    "tur_2": "الجولة 2",
    "sokaga_gore_sandik_basina_secmen_sayisi": "عدد الناخبين لكل صندوق حسب الشارع",
    "kusaklara_gore_secmen_sayisi": "عدد الناخبين حسب الأجيال",
    "sosyoekonomik_status_dagilimi": "توزيع الحالة الاجتماعية والاقتصادية",
    "sokak_bina_ve_hane_analizleri": "تحليلات الشارع والبناء والبيت",
    "en_buyuk_nesil": "أكبر جيل",
    "sessiz_kusak": "الجيل الصامت",
    "baby_boomer_kusagi": "جيل طفرة المواليد",
    "y_kusagi": "الجيل Y",
    "x_kusagi": "الجيل X",
    "z_kusagi": "الجيل Z",
    "hemsehrilik_ve_goc": "القرابة والهجرة",

    "oran": "نسبة",
    "secmen_sayisi": "عدد الناخبين",
    "sandik_geneli": "صندوق عام",
    "sandik_no": "رقم الصندوق",
    "siyasal_kimlik": "هوية سياسية",
    "hicbir_bulunamadi_uzgunum": "لم يتم العثور على أي شيء - آسف",
    "hicbir_kayit_mevcut_degil": "لا يوجد سجل موجود",
    "icerisinde_bulunan": "الموجود داخل",
    "numarali_sandikta": "في الصندوق رقم",
    "secmen_oy_kullanmistir": "الناخب قد صوت.",
    "hemsehrilik_durumu": "حالة القربى",
    "sandik_sonucu": "نتيجة الصندوق",
    "genelinde_ikamet_eden": "المقيم بشكل عام",
    "baskanlik_sistemine_gore_secim_sandik_analizi": "تحليل صندوق الانتخابات وفقًا لنظام الرئاسة",
    "aradiginiz_sayfayi_bulamiyoruz": "لا يمكننا العثور على الصفحة التي تبحث عنها",
    "doldur_ve_en_az_bir_kosul_ekleyin": "يرجى التأكد من ملء جميع الحقول وإضافة شرط واحد على الأقل.",
    "olusturulan_anket_bulunamadi": "لم يتم العثور على استبيان تم إنشاؤه",
    "tamamlanmis_anket_yukleme_alani": "منطقة تحميل الاستبيان المكتمل",
    "genel_anket_bulunamadi": "لم يتم العثور على استبيان عام",
    "anket_secin": "اختر استبيانًا",
    "bir_anket_secin": "اختر استبيانًا",
    "analiz_ici_bir_anket_secin": "اختر استبيانًا للتحليل",
    "devam_eden_bir_anket_yoktur": "لا يوجد استبيان جاري.",
    "kategori_adi_girmelisiniz": "يجب عليك إدخال اسم الفئة",
    "kategori_puan_girmelisiniz": "يجب عليك إدخال نقاط الفئة",
    "atanmis_kullanici_bulunamadi": "لم يتم العثور على مستخدم معين",
    "kisi_kaldirildi": "تم إزالة الشخص",
    "reklam_seciniz": "اختر الإعلان",
    "kullanici_secin": "اختر المستخدم",
    "kullanici_atandi": "تم تعيين المستخدم",
    "anket": "استبيان",
    "reklam_basliklari": "عناوين الإعلانات",
    "anket_silindi": "تم حذف الاستبيان",
    "tamamla": "أكمل",
    "tamamlandi": "اكتمل",
    "anketler": "استبيانات",
    "tamamlanan_anketler": "الاستبيانات المكتملة",
    "anket_tamamlandi": "تم الانتهاء من الاستبيان",
    "devam_eden_anketler": "الاستبيانات الجارية",
    "analiz_bulunamadi": "لم يتم العثور على التحليل",
    "status": "الحالة",
    "kategori_kaldir": "إزالة الفئة",
    "ust_kategori": "فئة رئيسية",
    "puan": "نقاط",
    "indir": "تنزيل",
    "anket_indir": "تحميل الاستبيان",
    "anket_olustur": "إنشاء استبيان",
    "departman_kaldirildi": "تم إزالة القسم",
    "departman_atandi": "تم تعيين القسم",
    "anket_duzenle": "تعديل الاستبيان",
    "anket_detay": "تفاصيل الاستبيان",
    "degisiklik_bulunamadi": "لم يتم العثور على تغيير",
    "departman_secmelisiniz": "يجب عليك اختيار القسم",
    "kategori_eklendi": "تم إضافة الفئة",
    "kategori_silindi": "تم حذف الفئة",
    "kategori_olustur": "إنشاء فئة",
    "kategori_bulunamadi": "لم يتم العثور على الفئة",
    "kategori_olusturuldu": "تم إنشاء الفئة",
    "cok_fazla_istek_atildi": "تم إرسال الكثير من الطلبات.",
    "renk_secmelisiniz": "يجب عليك اختيار اللون",
    "kategori_guncelle": "تحديث الفئة",
    "kategori_guncellendi": "تم تحديث الفئة",
    "atanmis_kullanicilar": "المستخدمون المعينون",
    "kategori_sec": "اختر الفئة",
    "zorunlu": "إلزامي",
    "ata": "تعيين",
    "isim": "اسم",
    "tamamlanma_tarih_saat": "تاريخ ووقت الانتهاء",
    "toplam_sure": "الوقت الكلي",
    "soyisim": "اسم العائلة",
    "id": "ID",
    "tc": "TC",
    "ip_adresi": "عنوان IP",
    "id_bulunamadi": "ID غير موجود",
    "denek_id": "معرف العينة",
    "denek_id_gor": "عرض معرف العينة",
    "genel_anketler": "استبيانات عامة",
    "benim_anketlerim": "استبياناتي",
    "turkiye_gundemi": "أجندة تركيا",
    "analiz_yukle": "تحميل التحليل",
    "analiz_yukleyin": "قم بتحميل التحليل",
    "sonuc_bulunamadi": "لم يتم العثور على النتيجة",
    "veri_silmek_icin_tikla": "انقر لحذف البيانات",
    "satir_guncelle": "تحديث الصف",
    "satir_ekle": "إضافة صف",
    "satir_yuklenemedi": "تعذر تحميل الصف",
    "satir_yuklendi": "تم تحميل الصف بنجاح",
    "satir_guncellenemedi": "تعذر تحديث الصف",
    "satir_guncellendi": "تم تحديث الصف بنجاح",
    "veriler_silindi": "تم حذف البيانات",
    "satirlar_bos_birakilamaz": "لا يمكن ترك الصفوف فارغة",
    "dosya_bulunamadi": "لم يتم العثور على الملف",
    "filtrele": "تصفية",
    "sokak_raporu": "تقرير الشارع",
    "mahalle_analiz_raporu": "تقرير تحليل الحي",
    "sokak_analiz_raporu": "تقرير تحليل الشارع",
    "mahallede_toplam": "إجمالي في الحي",
    "iletisim_numarasi": "رقم الاتصال",
    "mahalle_muhtari": "عمدة الحي",
    "mahalle_raporu": "تقرير الحي",
    "add_image": "أضف صورة",
    "cevabinizi_yaziniz": "اكتب إجابتك",
    "cevap_gonderildi": "تم إرسال الإجابات",
    "sorusu_icin_resim_konum_gerekiyor": "مطلوب صورة وموقع للسؤال.",
    "ekle": "إضافة",
    "alan": "مجال",
    "atama_yap": "قم بالتعيين",
    "departman_sec": "اختر القسم",
    "resim_zorunlu": "الصورة إلزامية",
    "sorular": "الأسئلة",
    "coklu_cevap": "إجابة متعددة",
    "tekli_cevap": "إجابة واحدة",
    "acik_uclu_cevap": "إجابة مفتوحة",
    "cevaplar": "الإجابات",
    "sorulara_don": "العودة إلى الأسئلة",
    "resim_alani_ekle": "إضافة حقل صورة",
    "soru_ekle": "أضف سؤالاً",
    "soru_kaldir": "إزالة السؤال",
    "anket_adi": "اسم الاستبيان",
    "arastirma_sorulari": "أسئلة البحث",
    "rapor_olustur": "إنشاء التقرير",
    "tespit_raporu_olustur": "إنشاء تقرير الكشف",
    "pin_belirleyin": "حدد رمز التعريف",
    "pin_eklemek_haritaya_tiklayin": "يمكنك النقر على الخريطة لإضافة رمز التعريف",
    "gozlem_olustur": "إنشاء ملاحظة",
    "atanmis_anketler": "الاستبيانات المعينة",
    "dosya_ara": "ابحث عن ملف",
    "sizin_yukledikleriniz": "ما قمت بتحميله",
    "kosul_olustur": "إنشاء شرط",
    "secilenleri_kaldir": "إزالة المحددين",
    "uzerine_veri_yukle": "تحميل البيانات فوق",
    "soru_kategori_ekle": "إضافة فئة السؤال",
    "soru_kategori_guncelle": "تحديث فئة السؤال",
    "soru_kategorileri": "فئات السؤال",
    "anketi_kaldir": "إزالة الاستبيان",
    "kisi": "شخص",
    "kisiye_atama": "تعيين إلى الشخص",
    "anketi_olustur": "إنشاء الاستبيان",
    "anketi_duzenle": "تحرير الاستبيان",
    "anketi_kopyala": "نسخ الاستبيان",
    "anketi_kopyalandi": "تم نسخ الاستبيان",
    "anketi_guncellendi": "تم تحديث الاستبيان",
    "anketi_olusturuldu": "تم إنشاء الاستبيان",
    "gorev_ekle": "إضافة مهمة",
    "anket_sec": "اختيار استبيان",
    "tum_soru_alanlar_doldurmalisiniz": "يجب ملء جميع حقول السؤال",
    "en_az_bir_soru_girmelisiniz": "يجب إدخال سؤال واحد على الأقل",
    "anket_adi_girmelisiniz": "يجب إدخال اسم الاستبيان",

    "musteri_secmelisiniz": "يجب عليك اختيار عميل",
    "tum_cevap_alanlari_doldurmalisiniz": "يجب ملء جميع حقول الإجابة",
    "en_az_bir_cevap_girmelisiniz": "يجب إدخال إجابة واحدة على الأقل",
    "cevap_ekle": "إضافة إجابة",
    "metin_puani": "نقاط النص",
    "metin_puani_girmelisiniz": "يجب إدخال نقاط النص",
    "metin_basligi": "عنوان النص",
    "metin_basligi_girmelisiniz": "يجب إدخال عنوان النص",
    "tumunu_kaldir": "إزالة الكل",
    "error_delete_data_graphics": "ستتأثر جميع البيانات والرسوم البيانية المرتبطة بهذا الملف بعملية الحذف. هل أنت متأكد أنك تريد الحذف؟",
    "tum_alanlari_doldurun": "يرجى ملء جميع الحقول.",
    "taleb_gonderildi": "تم إرسال طلبك",
    "taleb_gonderilemedi": "لم يتم إرسال طلبك",
    "talep_formu": "نموذج الطلب",
    "contact": "الاتصال",
    "rapor": "تقرير",
    "kentmetre_raporu": "تقرير كينت ميتري",
    "customers": "العملاء",
    "yonetim": "الإدارة",
    "yonetici": "مدير",
    "halkla_iliskiler_gozlemci": "مراقب العلاقات العامة",
    "info_hane_ve_birey_analizi": "تحليل الأسرة والفرد (يمكنه فقط رؤية جزء الاستبيان)",
    "kentmetre_haritasi": "خريطة كينت ميتري",
    "strateji_haritasi": "خريطة الاستراتيجية",
    "hane_ve_birey_analizi": "تحليل الأسرة والفرد",
    "halkla_iletisim_yonetimi": "إدارة العلاقات العامة",
    "hazir_raporlar": "تقارير جاهزة",
    "public_relations": "العلاقات العامة",
    "institution_official": "مسؤول المؤسسة",
    "gozlemci": "مراقب",
    "arastirma_raporu": "تقرير البحث",
    "cannot_appoint_manager": "لا يمكن تعيين مدير",
    "password": "كلمة المرور",
    "password_confirm": "تأكيد كلمة المرور",
    "pass_8_min": "يجب أن تكون كلمة المرور 8 أحرف على الأقل",
    "pass_same": "يجب أن تكون كلمة المرور متطابقة",

    "durdur": "إيقاف",
    "not_dinle": "استمع إلى الملاحظة",
    "not_oku": "اقرأ الملاحظة",
    "etiket_yazin": "اكتب الملصق",
    "profile": "الملف الشخصي",
    "user_address": "عنوان المستخدم",
    "user_title": "لقب المستخدم",
    "user_role": "صلاحيات المستخدم",
    "user_activation_phone": "هاتف تفعيل المستخدم",
    "user_representative": "اسم ولقب ممثل المستخدم",
    "customer_representative": "اسم ولقب ممثل العميل",
    "example_file_download": "تحميل ملف نموذج",
    "fixed_files": "الملفات الثابتة",
    "clear": "مسح",
    "clear_cache": "مسح التخزين المؤقت",
    "info_clear_cache": "سيتم حذف جميع بيانات المسح المؤقت.",
    "error_delete_condition": "ستُحذف الشروط الموجودة بشكل دائم ولن يكون هناك عودة. <strong>هل أنت متأكد؟</strong>",

    "raporu_goruntulemek_icin_filtre_yapmalisiniz": "يجب عليك تطبيق فلتر لعرض التقرير",
    "footer_text": `<strong class="text-theme">stratejimerkezi.com</strong> ليس له أي علاقة بالمؤسسات أو المنظمات. يتم عرض بيانات البحث المفتوحة والبيانات التنبؤية فقط في هذا الموقع. <br /> لا تضمن <strong class="text-theme">stratejimerkezi.com</strong> دقة الأماكن والعناوين والمعلومات وبيانات الخرائط المعروضة، بل هي لأغراض إعلامية فقط.`,
    "GROUP_OF_COMPANIES": "مجموعة شركات",
    "birth_day_persons": "المواطنون الذين لديهم عيد ميلاد",
    "coming_from_field": "القادمون من الميدان",
    "il_secmelisiniz": "يجب عليك اختيار المحافظة",
    "il_ve_ilce_filtrelemeniz_gerekiyor": "يجب عليك تصفية المحافظة والمقاطعة",
    "sokak_secmelisiniz": "يجب عليك اختيار الشارع",
    "mahalle_secmelisiniz": "يجب عليك اختيار الحي",
    "ilce_secmelisiniz": "يجب عليك اختيار المقاطعة",
    "il_ve_ilce_secmelisiniz": "يجب عليك اختيار المحافظة والمقاطعة",
    "no_data_available": "لا توجد بيانات متاحة",
    "noanswer_sent_you": "لا يوجد جواب مرسل إليك.",
    "bina_basina_ortalama_hane_sayisi": "متوسط عدد الأسر لكل مبنى",
    "sokak_basina_ortalama_bina_sayisi": "متوسط عدد المباني لكل شارع",
    "dosyayi_goruntule": "عرض الملف",
    "description": "الوصف",
    "add_sub_question": "إضافة سؤال فرعي",
    "bosanmis": "مطلقة",
    "bekar": "عازب",
    "evli_sayisi": "عدد المتزوجين",
    "esi_olmus": "توفي شريك الحياة",
    "answer": "إجابة",
    "remove_answer": "إزالة الإجابة",
    "required_image": "الصورة <br /> مطلوبة",
    "answer_point": "نقاط الإجابة",
    "write_answer": "اكتب إجابتك",
    "cevap_tipi": "نوع الإجابة",
    "soru_adi": "اسم السؤال",
    "soru_puani": "نقاط السؤال",
    "soru_kategorisi": "فئة السؤال",
    "required_question": "سؤال مطلوب",
    "esi_oldu": "توفي شريك الحياة",
    "evli": "متزوج",
    "hic_evlenmedi": "لم يتزوج أبداً",
    "bosandi": "مطلقة",
    "question": "سؤال",
    "age": "العمر",
    "unknown": "غير معروف",
    "gender": "الجنس",
    "address": "العنوان",
    "degree": "اللقب",
    "authorized_province": "المقاطعة المخولة",
    "authorized_district": "المحافظة المخولة",
    "upload_image": "تحميل الصورة",
    "updated_image": "تم تحديث الصورة",
    "max_file_size_5mb": "لا يمكن أن يزيد حجم الملف عن 5 ميغابايت",
    "max_file_size_10mb": "يمكن أن يكون حجم الملف 10 ميغابايت كحد أقصى.",
    "please_select_image_file": "يرجى اختيار ملف صورة.",
    "add_group": "إضافة قسم",
    "membership": "عضوية",
    "user": "مستخدم",
    "log_kayitlari": "سجلات السجل",
    "users": "المستخدمون",
    "edit_user": "تعديل المستخدم",
    "add_user": "إضافة مستخدم",
    "user_name": "اسم المستخدم",
    "person_name_surname": "اسم ولقب الممثل",
    "user_phone": "هاتف المستخدم",
    "user_email": "بريد المستخدم الإلكتروني",
    "search": "بحث",
    "reset": "إعادة تعيين",
    "grafik_eklenmedi": "لم يتم إضافة الرسم البياني",
    "user_not_found": "لم يتم العثور على المستخدم",
    "transaction": "معاملة",
    "transactions": "المعاملات",
    "yes": "نعم",
    "no": "لا",
    "next": "التالي",
    "department": "قسم",
    "department_name": "اسم القسم",
    "department_name_required": "يجب إدخال اسم القسم",
    "updated_department": "تم تعديل القسم",
    "added_department": "تم إضافة القسم",
    "deleted_department": "تم حذف القسم",
    "departments": "الأقسام",
    "choose_customer": "اختر العميل",
    "show_users": "عرض الأشخاص",
    "kisiler": "الأشخاص",
    "hide_users": "إخفاء الأشخاص",
    "remove": "إزالة",
    "isim_soyisim": "الاسم واللقب",
    "iletisim_bilgisi": "معلومات الاتصال",
    "dogum_tarihi": "تاريخ الميلاد",
    "name_surname": "الاسم واللقب",
    "username": "اسم المستخدم",
    "note": "ملاحظة",
    "role": "دور",
    "department_not_added": "لم يتم إضافة القسم",
    "department_edit": "تعديل القسم",
    "department_add": "إضافة قسم",
    "save": "حفظ",
    "copy": "نسخ",
    "edit": "تعديل",
    "treeChart": "مخطط شجري",
    "sanketChart": "مخطط تدفق",
    "pieRoseTypeChart": "مخطط فطيرة مزهر",
    "pieRoundedChart": "مخطط فطيرة دائري",
    "barPolarTangential": "مخطط عمودي قطبي",
    "analiz_silindi": "تم حذف التحليل",
    "analiz_adi_olusturuldu": "تم إنشاء اسم التحليل",
    "analiz_adi_guncellendi": "تم تحديث اسم التحليل",
    "analiz_adi_olustururken_hata": "خطأ أثناء إنشاء اسم التحليل",
    "analiz_adi_guncellerken_hata": "خطأ أثناء تحديث اسم التحليل",
    "analysisRequired": "أدخل اسم التحليل",
    "etiketlenen": "تم وضع علامة",
    "getir": "إحضار",
    "analiz_adi": "اسم التحليل",
    "breaking_news": "أخبار عاجلة",
    "choose_city": "اختر المحافظة",
    "choose_district": "اختر المقاطعة",
    "choose_neighborhood": "اختر الحي",
    "choose_street": "اختر الشارع",
    "choose_building": "اختر المبنى",
    "choose_apartment": "اختر الشقة",
    "find": "العثور على",
    "disaster_management": "إدارة الكوارث",
    "go_back": "العودة",
    "city": "مدينة",
    "population": "عدد السكان",
    "distance": "المسافة",
    "district": "مقاطعة",
    "neighborhood": "حي",
    "street": "شارع",
    "building": "مبنى",
    "apartment": "شقة",
    "started_date": "تاريخ البدء",
    "end_date": "تاريخ الانتهاء",
    "category": "فئة",
    "kapanis_metni": "نص الإغلاق",
    "acilis_metni": "نص الافتتاح",
    "main_category": "الفئة الرئيسية",
    "sub_category": "الفئة الفرعية",
    "sub_category_1": "الفئة الفرعية 1",
    "sub_category_2": "الفئة الفرعية 2",
    "bolumler": "الأقسام",
    "web_site": "الموقع الإلكتروني",
    "kurum_adi": "اسم المؤسسة",
    "size": "الحجم",
    "depth": "العمق",
    "closestCities": "أقرب المدن",
    "time_of_creation": "وقت الإنشاء",
    "lat": "خط العرض",
    "lng": "خط الطول",
    "place": "مكان",
    "requirements_table": "جدول المتطلبات",
    "total_population": "إجمالي عدد السكان",
    "how_many_days_required": "كم عدد الأيام المطلوبة؟",
    "total_requirement": "إجمالي المتطلبات",
    "unit": "وحدة",
    "contents": "المحتويات",
    "analysis_data_not_added": "لم تتم إضافة بيانات التحليل",
    "healthcare_facilities": "المرافق الصحية",
    "location": "الموقع",
    "all_locations": "جميع المواقع",
    "locations": "المواقع",
    "all_locations_show": "عرض جميع المواقع",
    "locations_show": "عرض المواقع",
    "locations_hide": "إخفاء المواقع",

    page: "صفحة",
    create_page: "إنشاء صفحة",
    import_file: "تحميل ملف",
    create_graphic: "إنشاء رسم بياني",
    are_you_sure: "هل أنت متأكد؟",
    iliskili_tum_kategoriler_silinecektir: "سيتم حذف جميع الفئات المرتبطة",
    mine: "لي",
    show: "عرض",
    hide: "إخفاء",
    show_hide: "عرض/إخفاء",
    updateed: "تم التحديث",
    update: "تحديث",
    updating: "جاري التحديث",
    created: "تم الإضافة",
    create: "إنشاء",
    creating: "جاري الإضافة",
    send: "إرسال",
    cancel: "إلغاء",
    preview: "معاينة",
    prev: "السابق",
    view: "عرض",
    delete: "حذف",
    no_option: "لا يوجد خيار",
    max_200_mb: "لا يمكن أن يتجاوز 200 ميجابايت",
    file_size_error: "حجم الملف كبير جدًا",
    server_error: "خطأ في الخادم!",
    try_again_error: "يرجى المحاولة مرة أخرى بعد فترة.",
    added_to_chart: "تم إضافة الرسم البياني إلى الصفحة",
    updated_chart: "تم تحديث الرسم البياني",
    deleted_chart: "تم حذف الرسم البياني",
    conditions_sections_required: "لا تترك حقول الشروط فارغة.",
    columns_sections_required: "لا تترك حقول الأعمدة فارغة.",
    chart_sections_required: "لا تترك حقول الرسم البياني فارغة.",
    chart_name_error: "يجب أن يكون اسم الرسم البياني أقل من 100 حرف.",
    select_column_error: "يرجى اختيار عمود واحد على الأقل.",
    choose_column: "اختر عمود",
    choose_column_2: "اختر عمودًا",
    group_warning: "يجب أن يتواجد عمود التجميع في الأعمدة المختارة.",
    value: "قيمة",
    title: "عنوان",
    tum_musteriler: "جميع العملاء",
    icon: "أيقونة",
    iframe: "إطار",
    customer: "عميل",
    select_customer: "اختر عميلًا",
    page_order: "ترتيب الصفحة",
    page_deleted: "تم حذف الصفحة",
    parent_menu: "قائمة الوالدين",
    page_name: "اسم الصفحة",
    external_link: "رابط خارجي للصفحة",
    pageOrder: "أدخل ترتيب الصفحة",
    pageName: "أدخل اسم الصفحة",
    pageNameCharLimit: "يجب أن يكون اسم الصفحة أكثر من 3 أحرف",
    externalLinkError: "رابط خارجي خاطئ",
    iframeLinkError: "رابط إطار خاطئ",
    file_import_max: "لا يمكن أن يتجاوز حجم الملف 50 ميجابايت",
    file_important: "الملف مطلوب",
    file_import: "استيراد ملف",
    data_name_important: "اسم البيانات مطلوب",
    data_name: "اسم البيانات",
    file: "ملف",
    file_name: "اسم الملف",
    data_type: "نوع البيانات",
    data_type_error: "عدم توافق نوع البيانات",
    integer: "عدد صحيح",
    string: "نص",
    date: "تاريخ",
    json: "JSON",
    email: "البريد الإلكتروني",
    phone: "هاتف",
    phone_control: "تحقق من الهاتف",
    boolean: "منطقي (صحيح/خطأ)",
    separators: "فواصل",
    separators_warning: "لم يتم التعرف على الفواصل",
    separators_error: "تم استخدام فاصل غير معروف في ملف CSV.",
    choose_csv_file: "اختر ملف CSV",
    warning_for_date: "تحذير: يجب أن يكون تنسيق التاريخ للأعمدة التي اخترتها بهذا الشكل <strong>2024-01-01</strong>.",
    upload: "تحميل",
    column_warning: "حدد أنواع البيانات لجميع الأعمدة",
    file_import_info: "سيتم إضافة البيانات إلى النظام في نهاية عمليات التكديس والتحليل. قد تستغرق هذه العملية بضع دقائق.",
    file_import_success: "تم تحميل الملف بنجاح",
    file_import_error: "تعذر تحميل الملف",
    mahalle_sayisi: "عدد الأحياء",
    sokak_sayisi: "عدد الشوارع",
    bina_sayisi: "عدد المباني",
    hane_sayisi: "عدد الأسر",
    kisi_sayisi: "عدد الأشخاص",

    graphic_preview: "معاينة الرسم البياني",
    secilen: "المحدد",
    choose_page: "اختر الصفحة",
    choose_chart: "اختر الرسم البياني",
    added_chart: "تم إضافة الرسم البياني إلى الصفحة",
    update_chart: "تحديث الرسم البياني",
    chart_width: "عرض الرسم البياني",
    chart: "رسم بياني",
    query: "استعلام",
    group: "تجميع",
    pageCustomer: "عميل الصفحة",
    selectPage: "اختر صفحة",
    fileCustomer: "عميل الملف",
    selectFile: "اختر ملف",
    chartOrder: "ترتيب الرسم البياني",
    chartView: "عرض الرسم البياني",
    pieChart: "رسم بياني دائري",
    barChart: "رسم بياني شريطي",
    wordCloud: "سحابة كلمات",
    table: "جدول",
    map: "خريطة",
    chartName: "اسم الرسم البياني",
    chartDescription: "وصف الرسم البياني",
    addColumn: "إضافة عمود",
    columnNotFound: "لم يتم العثور على العمود",
    selectColumn: "اختر عمودًا",
    selectOperator: "اختر عامل",
    selectCondition: "اختر شرط",
    selectAction: "اختر إجراء",
    dateGrouping: "تجميع التاريخ",
    menu_section: "قسم القائمة",
    menu_align: "محاذاة القائمة",
    menu_horizontal: "محاذاة القائمة (أفقي)",
    menu_vertical: "محاذاة القائمة (رأسي)",
    xl: "كبير (4/4)",
    lg: "متوسط - كبير (4/3)",
    m: "متوسط (4/2)",
    sm: "صغير (4/1)",
    all: "الكل",
    column: "عمود",
    count: "عدد",
    average: "متوسط",
    total: "إجمالي",
    minimum: "حد أدنى",
    maximum: "حد أقصى",
    distinct: "مختلف",
    day: "يوم",
    month: "شهر",
    year: "سنة",
    hour: "ساعة",
    minute: "دقيقة",
    second: "ثانية",
    none: "لا شيء",
    condition: "شرط",
    add_condition: "إضافة شرط",
    choose_condition: "اختر شرطًا",
    conditionNotFound: "لم يتم العثور على شرط",
    you_can_add_condition: "يمكنك إضافة شرط",
    in: "داخل",
    out: "خارج",
    like: "مثل",
    notLike: "ليس مثل",
    empty: "فارغ",
    filled: "مليء",
    and: "و",
    or: "أو",
    vertical: "عمودي",
    horizontal: "أفقي",
    left: "يسار",
    center: "مركز",
    right: "يمين",
    up: "أعلى",
    middle: "وسط",
    down: "أسفل",
    loader_data: "البيانات",
    loader_gathering: "يتم التجميع...",
    loader_in_progress: "يتم التحليل...",
    loader_visualizing: "يتم التصور...",
};

export default ar;