const en = {
    olusturulma: "Creation Date",
    guncelleme: "Update Date",

    menu_rengi: "Menu Color",
    site_rengi: "Site Color",
    grafik_rengi: "Graph Color",

    "deprem": "EARTHQUAKE",
    "risk_arastirmasi": "RISK RESEARCH",
    "diri_fay_hatti_yakinligi": "PROXIMITY TO ACTIVE FAULT LINE",
    "deprem_risk_derece_seviyesi": "EARTHQUAKE RISK DEGREE LEVEL",
    "yerlesim_yeri_envanteri": "SETTLEMENT INVENTORY",
    "yapim_yilina_gore_bina_sayisi": "NUMBER OF BUILDINGS BY CONSTRUCTION YEAR",
    "kat_sayisina_gore_bina_sayisi": "NUMBER OF BUILDINGS BY NUMBER OF FLOORS",
    "yapim_turune_gore_bina_sayisi": "NUMBER OF BUILDINGS BY CONSTRUCTION TYPE",

    rapor_basariyla_olusturuldu: "The report has been successfully created.",
    ondalik_sayi: "Decimal Number",

    "ileri_analiz": "Advanced Analysis",
    "genel_gorunum": "General View",
    "anket_ve_arastirmalar": "Surveys and Research",
    "ozel_sayfalar": "Special Pages",
    "analiz_icin_iletisime_gecin": "Contact for Analysis",

    "ocak": "January",
    "subat": "February",
    "mart": "March",
    "nisan": "April",
    "mayis": "May",
    "haziran": "June",
    "temmuz": "July",
    "agustos": "August",
    "eylul": "September",
    "ekim": "October",
    "kasim": "November",
    "aralik": "December",

    "BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2009": "2009 LOCAL MUNICIPALITY ELECTION",
    "DONEM_MILLETVEKILLIGI_GENEL_SECIMI_24": "24th TERM PARLIAMENTARY GENERAL ELECTION",
    "BELEDIYE_BASKANLIGI_SECIMI_2014": "2014 MUNICIPALITY ELECTION",
    "CUMHURBASKANLIGI_SECIMI_12": "12th PRESIDENTIAL ELECTION",
    "DONEM_MILLETVEKILLIGI_SECIMLERI_25": "25th TERM PARLIAMENTARY ELECTION",
    "MILLETVEKILLIGI_GENEL_SECIMLERI_26": "26th PARLIAMENTARY GENERAL ELECTION",
    "BASKANLIK_SECIMI_1": "1st PRESIDENTIAL ELECTION",
    "DONEM_MILLETVEKILLIGI_GENEL_SECIMLERI_27": "27th TERM PARLIAMENTARY GENERAL ELECTION",
    "BELEDIYE_BASKANLIGI_YEREL_SECIMLERI_2019": "2019 LOCAL MUNICIPALITY ELECTION",
    "YENILENEN_ISTANBUL_SECIMI_2019": "2019 RENEWED ISTANBUL ELECTION",
    "DONEM_MILLETVEKILI_GENEL_SECIMLERI_28": "28th TERM PARLIAMENTARY GENERAL ELECTION",
    "BASKANLIK_SECIMI_2_TUR_2": "2nd ROUND PRESIDENTIAL ELECTION",

    "siyasal_kimlik_analiz_sonuclari": "POLITICAL IDENTITY ANALYSIS RESULTS",
    "baskanlik_secimi_ve_sokaga_gore_sandik_basina_birey_sayisi_tablosu": "2nd PRESIDENTIAL ELECTION AND INDIVIDUALS PER BALLOT BOX ACCORDING TO STREET TABLE",
    "yeni_secmen": "New Voter",
    "birey": "INDIVIDUAL",
    "mahallede_son_yil_itibariyle_toplam_sayisi": "Total number as of last year in the neighborhood",
    "birey_bulunmaktadir": "Individuals present.",
    "hane_bulunmaktadir": "Households present.",
    "sokak_bulunmaktadir": "Streets present.",
    "bina_bulunmaktadir": "Buildings present.",
    "gorev_mevcut_degil": "No Task Available",
    "mahallede_bulunan_sendikalar": "UNIONS PRESENT IN THE NEIGHBORHOOD",
    "mahallede_bulunan_dernekler": "ASSOCIATIONS PRESENT IN THE NEIGHBORHOOD",
    "secim_analiz_raporu": "ELECTION ANALYSIS REPORT",
    "medeni_durum_analizi": "MARITAL STATUS ANALYSIS",
    "downloading_text": "Loading...",
    "yas_grubuna_gore_birey_durumu": "INDIVIDUAL STATUS BY AGE GROUP",
    "egitim_durumu": "EDUCATION STATUS",
    "mahallede_ortalama_secmen_yasi": "Average Voter Age in the Neighborhood",
    "bulunmaktadir": "is present.",
    "strateji_merkezi_mahalle_raporu": "Strategy Center Neighborhood Report",
    "strateji_merkezi_sokak_raporu": "Strategy Center Street Report",
    "anket_cevaplanmamis": "Survey Not Answered",
    "cevap_silindi": "Answer Deleted",
    "pin_filtre": "Pin Filter",
    "arastirma_cevaplari": "Research Answers",
    "bu_cevabi_sil": "Delete This Answer",
    "telefon_kontrol_edildi": "Phone Checked",
    "haritayi_gizle": "Hide Map",
    "kullanici_bilgileri": "User Information",
    "toplam_soru": "Total Questions",
    "resim_yok": "No Image",
    "guncel_hali": "Current Version",

    "desteklenmeyen_dosya_formati": "Unsupported file format",
    "dosya_boyutu_yuksek": "File size is too large",
    "baslik_tarihi_bos_birakmayin": "Do not leave the title empty",
    "baslangic_tarihi_bos_birakmayin": "Do not leave the start date empty",
    "bitis_tarihi_bos_birakmayin": "Do not leave the end date empty",
    "kategori_bos_birakmayin": "Do not leave the category empty",
    "aciklama_bos_birakmayin": "Do not leave the description empty",
    "il_bos_birakmayin": "Do not leave the city empty",
    "ilce_bos_birakmayin": "Do not leave the district empty",
    "mahalle_bos_birakmayin": "Do not leave the neighborhood empty",
    "koordinat_belirleyin": "Specify the coordinates",
    "ilce_girmelisiniz": "You must enter a district",
    "tamamlanma_yuzdesi": "Completion Percentage",
    "etiketler": "Tags",
    "etiket_girin": "Enter tags",
    "dosya_kaldir": "Remove File",
    "resimleri_yukle": "Upload Images",
    "dosyalari_buraya_birakin": "Drop Files Here",
    "surukle_birak_veya_tikla": "Drag and Drop or Click",
    "gorev_guncelle": "Update Task",
    "gorev_guncellendi": "Task Updated",
    "gorev_olusturuldu": "Task Created",
    "gorev_olustur": "Create Task",
    "kategori_duzenle": "Edit Category",
    "kategori_ekle": "Add Category",
    "kategori_adi": "Category Name",
    "kategori_rengi": "Category Color",
    "kategorisi_ve_kategoriye_bagli_basliklar_silinecektir": "Category and related titles will be deleted.",
    "eklenmis_bir_gorev_bulunmamaktadir": "No task has been added",
    "gorev_silindi": "Task Deleted",
    "kategori_sirasi_guncellendi": "Category Order Updated",
    "liste_sirasi_guncellendi": "List Order Updated",
    "yonetici_ozetini_kapat_mesaji": "ADMIN SUMMARY, CLICK ON ANOTHER AREA TO CLOSE.",
    "yonetici_ozetini_oku": "Read Admin Summary",
    "olusturan_kisi": "Creator",
    "olusturulma_tarihi": "Creation Date",
    "guncelleyen_kisi": "Updated By",
    "olusturma_tarihi": "Creation Date",
    "guncelleme_tarihi": "Update Date",
    "yonetici_ozeti": "Admin Summary",

    "yapildi": "Completed",
    "yapilmadi": "Not Completed",
    "kisiye_anket_ata": "Assign Survey to Person",
    "atanmis_bir_anket_yok": "No survey has been assigned.",
    "kac_kisi": "HOW MANY PEOPLE",
    "female": "FEMALE",
    "male": "MALE",
    "muhtarliklar": "MUNICIPALITIES",
    "yapilacak_anketler": "SURVEYS TO BE CONDUCTED",
    "kisilere_atanmış_anketler": "SURVEYS ASSIGNED TO PEOPLE",
    "olusturulmus_tablo_secin": "SELECT A CREATED TABLE",
    "tabloyu_goster": "Show Table",
    "tabloyu_gizle": "Hide Table",
    "tabloyu_sil": "Delete Table",
    "tabloyu_indir": "Download Table",
    "tablo_secin": "Select Table",
    "filtre_olustur": "Create Filter",
    "filtre_adi_giriniz": "Enter filter name",
    "filtre_tablosu": "FILTER TABLE",
    "anket_ata": "Assign Survey",
    "anket_atandi": "Survey Assigned",
    "anket_secmelisiniz": "You must select a survey",
    "tc_bulunamadi": "ID not found",
    "tablo_silindi": "Table Deleted",
    "filtre_adi_girmelisiniz": "You must enter a filter name",
    "filtre_eklendi": "Filter Added",
    "filtre_yapmalisiniz": "You must filter",
    "hizmet_bulunamadi": "Service not found",
    "sikayet_bulunamadi": "Complaint not found",
    "ayni_ildeki_akraba_sayisi": "Number of Relatives in the Same City",
    "ayni_ilcedeki_akraba_sayisi": "Number of Relatives in the Same District",
    "duzenleyen": "Organizer",
    "memleket_il": "Hometown City",
    "memleket_ilce": "Hometown District",
    "aslen_il": "Originally From City",
    "aslen_ilce": "Originally From District",
    "aile_buyugu": "Family Elder",
    "aile": "Family",
    "kisi_bulunamadi": "Person not found",
    "not_eklendi": "Note Added",

    "not_eklenmedi": "Note Could Not Be Added",
    "not_ekle": "Add Note",
    "not_guncelle": "Update Note",
    "not_guncellenmedi": "Note Could Not Be Updated",
    "not_guncellendi": "Note Updated",
    "resim_eklenmedi": "Image Could Not Be Added",
    "resim_eklendi": "Image Added",
    "details": "Details",
    "detail": "Detail",
    "hizmetler": "Services",
    "sikayetler": "Complaints",
    "iletilen_sikayetler": "FORWARDED COMPLAINTS",
    "istenilen_hizmetler": "REQUESTED SERVICES",
    "is_kolu": "Work Branch",
    "sendika_adi": "Union Name",
    "sendikalar": "UNIONS",
    "kurum_adres": "Institution Address",
    "detayli_faaliyet_alani": "Detailed Activity Area",
    "faaliyet_alani": "Activity Area",
    "dernekler": "ASSOCIATIONS",
    "muhtarlik": "MUNICIPALITY",
    "muhtar_telefon": "Municipal Head Phone",
    "muhtar_adi": "Municipal Head Name",
    "muhtarlik_adi": "Municipality Name",
    "kisi_ve_uzeri_goc_eden_birey_sayisi": "Number of Individuals Migrating with Family or More",
    "olum": "Death",
    "dogum": "Birth",
    "olum_oranlari": "Death Rates",
    "dogum_oranlari": "Birth Rates",
    "dagilim": "Distribution",
    "universite_ve_universite_ogrenci_sayilari": "Universities and Number of University Students",
    "verilen_goc": "Outmigration",
    "alinan_goc": "Inmigration",
    "ilce_bazinda_verilen_goc": "Outmigration by District",
    "ilce_bazinda_alinan_goc": "Inmigration by District",
    "secmen_genel_egitim_durumu": "GENERAL VOTER EDUCATION STATUS",
    "yillara_gore_sosyoekonomik_statu_dagilimi": "SOCIOECONOMIC STATUS DISTRIBUTION OVER THE YEARS",
    "yillara_gore_dogum_ve_olum_oranlari": "Birth and Death Rates Over the Years",
    "yillara_gore_secmen_medeni_durum_dagilimi": "Voter Marital Status Distribution Over the Years",
    "birey_hemsehrilik_durumu": "INDIVIDUAL CITIZENSHIP STATUS",
    "yas_grubuna_gore_birey_sayilari": "NUMBER OF INDIVIDUALS BY AGE GROUP",
    "yas_grubu": "AGE GROUP",
    "tahmini_1_derece_aile": "Estimated 1st Degree Family Membership",
    "tahmini_2_derece_aile": "Estimated 2nd Degree Family Membership",
    "tahmini_uye_yakini_olmayan": "Estimated No Member Close Relatives",
    "ortalama_secmen_yasi": "Average Voter Age",
    "olum_sayisi": "Number of Deaths",
    "dogum_sayisi": "Number of Births",
    "ilk_defa_oy_sayisi": "FIRST-TIME VOTER COUNT",
    "toplam_secmen": "Total Voters",
    "guncel": "Current",
    "baskanlik_secimi": "2ND PRESIDENTIAL ELECTION",
    "tur": "Round",
    "tur_1": "Round 1",
    "tur_2": "Round 2",
    "sokaga_gore_sandik_basina_secmen_sayisi": "NUMBER OF VOTERS PER BALLOT BY STREET",
    "kusaklara_gore_secmen_sayisi": "NUMBER OF VOTERS BY GENERATION",
    "sosyoekonomik_status_dagilimi": "Socioeconomic Status Distribution",
    "sokak_bina_ve_hane_analizleri": "STREET, BUILDING, AND HOUSEHOLD ANALYSES",
    "en_buyuk_nesil": "LARGEST GENERATION",
    "sessiz_kusak": "SILENT GENERATION",
    "baby_boomer_kusagi": "BABY BOOMER GENERATION",
    "y_kusagi": "GENERATION Y",
    "x_kusagi": "GENERATION X",
    "z_kusagi": "GENERATION Z",
    "hemsehrilik_ve_goc": "Citizenship and Migration",

    "oran": "Ratio",
    "secmen_sayisi": "Number of Voters",
    "sandik_geneli": "Overall Ballot",
    "sandik_no": "Ballot Number",
    "siyasal_kimlik": "Political Identity",
    "hicbir_bulunamadi_uzgunum": "Nothing was found - I'm sorry",
    "hicbir_kayit_mevcut_degil": "No records found",
    "icerisinde_bulunan": "contained within",
    "numarali_sandikta": "in the numbered ballot",
    "secmen_oy_kullanmistir": "the voter has cast a vote.",
    "hemsehrilik_durumu": "HOMETOWN STATUS",
    "sandik_sonucu": "BALLOT RESULT",
    "genelinde_ikamet_eden": "residing in general",
    "baskanlik_sistemine_gore_secim_sandik_analizi": "2. ELECTION BALLOT ANALYSIS BY PRESIDENCY SYSTEM",
    "aradiginiz_sayfayi_bulamiyoruz": "We cannot find the page you are looking for",
    "doldur_ve_en_az_bir_kosul_ekleyin": "Please make sure all fields are filled and at least one condition is added.",
    "olusturulan_anket_bulunamadi": "No Created Survey Found",
    "tamamlanmis_anket_yukleme_alani": "Completed Survey Upload Area",
    "genel_anket_bulunamadi": "No General Survey Found",
    "anket_secin": "Select Survey",
    "bir_anket_secin": "Select a Survey",
    "analiz_ici_bir_anket_secin": "Select a Survey for Analysis",
    "devam_eden_bir_anket_yoktur": "There are no ongoing surveys.",
    "kategori_adi_girmelisiniz": "You must enter a category name",
    "kategori_puan_girmelisiniz": "You must enter a category score",
    "atanmis_kullanici_bulunamadi": "Assigned User Not Found",
    "kisi_kaldirildi": "Person Removed",
    "reklam_seciniz": "Select an Advertisement",
    "kullanici_secin": "Select User",
    "kullanici_atandi": "User Assigned",
    "anket": "Survey",
    "reklam_basliklari": "Advertisement Titles",
    "anket_silindi": "Survey Deleted",
    "tamamla": "Complete",
    "tamamlandi": "Completed",
    "anketler": "Surveys",
    "tamamlanan_anketler": "Completed Surveys",
    "anket_tamamlandi": "Survey Completed",
    "devam_eden_anketler": "Ongoing Surveys",
    "analiz_bulunamadi": "Analysis Not Found",
    "status": "Status",
    "kategori_kaldir": "Remove Category",
    "ust_kategori": "Parent Category",
    "puan": "Score",
    "indir": "Download",
    "anket_indir": "Download Survey",
    "anket_olustur": "Create Survey",
    "departman_kaldirildi": "Department Removed",
    "departman_atandi": "Department Assigned",
    "anket_duzenle": "Edit Survey",
    "anket_detay": "Survey Details",
    "degisiklik_bulunamadi": "Change Not Found",
    "departman_secmelisiniz": "You must select a department",
    "kategori_eklendi": "Category Added",
    "kategori_silindi": "Category Deleted",
    "kategori_olustur": "Create Category",
    "kategori_bulunamadi": "Category Not Found",
    "kategori_olusturuldu": "Category Created",
    "cok_fazla_istek_atildi": "Too many requests were made.",
    "renk_secmelisiniz": "You must select a color",
    "kategori_guncelle": "Update Category",
    "kategori_guncellendi": "Category Updated",
    "atanmis_kullanicilar": "Assigned Users",
    "kategori_sec": "Select Category",
    "zorunlu": "Mandatory",
    "ata": "Assign",
    "isim": "Name",
    "tamamlanma_tarih_saat": "Completion Date and Time",
    "toplam_sure": "Total Duration",
    "soyisim": "Surname",
    "id": "ID",
    "tc": "ID",
    "ip_adresi": "IP Address",
    "id_bulunamadi": "ID Not Found",
    "denek_id": "Subject ID",
    "denek_id_gor": "View Subject ID",
    "genel_anketler": "General Surveys",
    "benim_anketlerim": "My Surveys",
    "turkiye_gundemi": "Turkey Agenda",
    "analiz_yukle": "Upload Analysis",
    "analiz_yukleyin": "Upload Analysis",
    "sonuc_bulunamadi": "Result Not Found",
    "veri_silmek_icin_tikla": "Click to Delete Data",
    "satir_guncelle": "Update Row",
    "satir_ekle": "Add Row",
    "satir_yuklenemedi": "Row Could Not Be Loaded",
    "satir_yuklendi": "Row Successfully Loaded",
    "satir_guncellenemedi": "Row Could Not Be Updated",
    "satir_guncellendi": "Row Successfully Updated",
    "veriler_silindi": "Data Deleted",
    "satirlar_bos_birakilamaz": "Rows cannot be left blank",
    "dosya_bulunamadi": "File Not Found",
    "filtrele": "Filter",
    "sokak_raporu": "Street Report",
    "mahalle_analiz_raporu": "Neighborhood Analysis Report",
    "sokak_analiz_raporu": "Street Analysis Report",
    "mahallede_toplam": "Total in Neighborhood",
    "iletisim_numarasi": "Contact Number",
    "mahalle_muhtari": "Neighborhood Muhtar",
    "mahalle_raporu": "Neighborhood Report",
    "add_image": "Add Image",
    "cevabinizi_yaziniz": "Please Write Your Answer",
    "cevap_gonderildi": "Answers Sent",
    "sorusu_icin_resim_konum_gerekiyor": "image and location are required for the question.",
    "ekle": "Add",
    "alan": "Field",
    "atama_yap": "Make Assignment",
    "departman_sec": "Select Department",
    "resim_zorunlu": "Image Required",
    "sorular": "Questions",
    "coklu_cevap": "Multiple Choice",
    "tekli_cevap": "Single Choice",
    "acik_uclu_cevap": "Open-Ended Response",
    "cevaplar": "Answers",
    "sorulara_don": "Return to Questions",
    "resim_alani_ekle": "Add Image Field",
    "soru_ekle": "Add Question",
    "soru_kaldir": "Remove Question",
    "anket_adi": "Survey Name",
    "arastirma_sorulari": "Research Questions",
    "rapor_olustur": "Create Report",
    "tespit_raporu_olustur": "Create Detection Report",
    "pin_belirleyin": "Set a Pin",
    "pin_eklemek_haritaya_tiklayin": "Click on the map to add a pin",
    "gozlem_olustur": "Create Observation",
    "atanmis_anketler": "Assigned Surveys",
    "dosya_ara": "Search File",
    "sizin_yukledikleriniz": "Your Uploads",
    "kosul_olustur": "Create Condition",
    "secilenleri_kaldir": "Remove Selected",
    "uzerine_veri_yukle": "Upload Data to",
    "soru_kategori_ekle": "Add Question Category",
    "soru_kategori_guncelle": "Update Question Category",
    "soru_kategorileri": "Question Categories",
    "anketi_kaldir": "Remove Survey",
    "kisi": "Person",
    "kisiye_atama": "Assignment to Person",
    "anketi_olustur": "Create Survey",
    "anketi_duzenle": "Edit Survey",
    "anketi_kopyala": "Copy Survey",
    "anketi_kopyalandi": "Survey Copied",
    "anketi_guncellendi": "Survey Updated",
    "anketi_olusturuldu": "Survey Created",
    "gorev_ekle": "Add Task",
    "anket_sec": "Select Survey",
    "tum_soru_alanlar_doldurmalisiniz": "All Question Fields Must Be Filled",
    "en_az_bir_soru_girmelisiniz": "You must enter at least one question",
    "anket_adi_girmelisiniz": "You must enter a survey name",

    "musteri_secmelisiniz": "You Must Select a Customer",
    "tum_cevap_alanlari_doldurmalisiniz": "You Must Fill All Answer Fields",
    "en_az_bir_cevap_girmelisiniz": "You Must Enter At Least One Answer",
    "cevap_ekle": "Add Answer",
    "metin_puani": "Text Score",
    "metin_puani_girmelisiniz": "You Must Enter Text Score",
    "metin_basligi": "Text Title",
    "metin_basligi_girmelisiniz": "You Must Enter Text Title",
    "tumunu_kaldir": "Remove All",
    "error_delete_data_graphics": "Deleting will affect all data and graphics associated with this file. Are you sure you want to delete?",
    "tum_alanlari_doldurun": "Please fill all fields.",
    "taleb_gonderildi": "Your Request Has Been Sent",
    "taleb_gonderilemedi": "Your Request Could Not Be Sent",
    "talep_formu": "Request Form",
    "contact": "Contact",
    "rapor": "Report",
    "kentmetre_raporu": "Kentmetre Report",
    "customers": "Customers",
    "yonetim": "Management",
    "yonetici": "Manager",
    "halkla_iliskiler_gozlemci": "Public Relations Observer",
    "info_hane_ve_birey_analizi": "Household and Individual Analysis (Can Only See the Survey Part)",
    "kentmetre_haritasi": "Kentmetre Map",
    "strateji_haritasi": "Strategy Map",
    "hane_ve_birey_analizi": "Household and Individual Analysis",
    "halkla_iletisim_yonetimi": "Public Communication Management",
    "hazir_raporlar": "Ready Reports",
    "public_relations": "Public Relations",
    "institution_official": "Institution Official",
    "gozlemci": "Observer",
    "arastirma_raporu": "Research Report",
    "cannot_appoint_manager": "Cannot Appoint Manager",
    "password": "Password",
    "password_confirm": "Confirm Password",
    "pass_8_min": "Password Must Be At Least 8 Characters",
    "pass_same": "Passwords Must Be The Same",

    "durdur": "Stop",
    "not_dinle": "Do Not Listen",
    "not_oku": "Do Not Read",
    "etiket_yazin": "WRITE LABEL",
    "profile": "Profile",
    "user_address": "User Address",
    "user_title": "User Title",
    "user_role": "User Permission",
    "user_activation_phone": "User Activation Phone",
    "user_representative": "User Representative Full Name",
    "customer_representative": "Customer Representative Full Name",
    "example_file_download": "Download Sample File",
    "fixed_files": "Fixed Files",
    "clear": "Clear",
    "clear_cache": "Clear Cache",
    "info_clear_cache": "All cached browsing data will be deleted.",
    "error_delete_condition": "The found conditions will be permanently deleted and cannot be undone. <strong>Are you sure?</strong>",

    "raporu_goruntulemek_icin_filtre_yapmalisiniz": "You must filter to view the report.",
    "footer_text": `<strong class="text-theme">stratejimerkezi.com</strong> has no affiliation with any institution or organization. This site only displays open source research and predictive data. <br /> <strong class="text-theme">stratejimerkezi.com</strong> does not guarantee the accuracy of the locations, addresses, information, and map details shown, it is for informational purposes only.`,
    "GROUP_OF_COMPANIES": "GROUP OF COMPANIES",
    "birth_day_persons": "Citizens with Birthdays",
    "coming_from_field": "Field Incoming",
    "il_secmelisiniz": "You must select a province.",
    "il_ve_ilce_filtrelemeniz_gerekiyor": "You need to filter by province and district.",
    "sokak_secmelisiniz": "You must select a street.",
    "mahalle_secmelisiniz": "You must select a neighborhood.",
    "ilce_secmelisiniz": "You must select a district.",
    "il_ve_ilce_secmelisiniz": "You must select a province and district.",
    "no_data_available": "No data available.",
    "noanswer_sent_you": "No answer has been assigned to you.",
    "bina_basina_ortalama_hane_sayisi": "Average Household Number Per Building",
    "sokak_basina_ortalama_bina_sayisi": "Average Building Number Per Street",
    "dosyayi_goruntule": "View File",
    "description": "Description",
    "add_sub_question": "Add Sub-question",
    "bosanmis": "Divorced",
    "bekar": "Single",
    "evli_sayisi": "Number of Married",
    "esi_olmus": "Spouse Deceased",
    "answer": "Answer",
    "remove_answer": "Remove Answer",
    "required_image": "Image <br /> Required",
    "answer_point": "Answer Points",
    "write_answer": "Please Write an Answer",
    "cevap_tipi": "Answer Type",
    "soru_adi": "Question Name",
    "soru_puani": "Question Points",
    "soru_kategorisi": "Question Category",
    "required_question": "Mandatory Question",
    "esi_oldu": "SPOUSE DECEASED",
    "evli": "MARRIED",
    "hic_evlenmedi": "NEVER MARRIED",
    "bosandi": "DIVORCED",
    "question": "Question",
    "age": "Age",
    "unknown": "Unknown",
    "gender": "Gender",
    "address": "Address",
    "degree": "Title",
    "authorized_province": "Authorized District",
    "authorized_district": "Authorized Province",
    "upload_image": "Upload Image",
    "updated_image": "Image Updated",
    "max_file_size_5mb": "File Size Cannot Exceed 5MB",
    "max_file_size_10mb": "File size can be a maximum of 10MB.",
    "please_select_image_file": "Please select an image file.",
    "add_group": "Add Department",
    "membership": "Membership",
    "user": "User",
    "log_kayitlari": "Log Records",
    "users": "Users",
    "edit_user": "Edit User",
    "add_user": "Add User",
    "user_name": "Username",
    "person_name_surname": "Authorized Person Name Surname",
    "user_phone": "User Phone",
    "user_email": "User Email",
    "search": "Search",
    "reset": "Reset",
    "grafik_eklenmedi": "Graph Not Added",
    "user_not_found": "User Not Found",
    "transaction": "Transaction",
    "transactions": "Transactions",
    "yes": "Yes",
    "no": "No",
    "next": "Next",
    "department": "Department",
    "department_name": "Department Name",
    "department_name_required": "You must enter a department name.",
    "updated_department": "Department Updated",
    "added_department": "Department Added",
    "deleted_department": "Department Deleted",
    "departments": "Departments",
    "choose_customer": "Select Customer",
    "show_users": "Show People",
    "kisiler": "People",
    "hide_users": "Hide People",
    "remove": "Remove",
    "isim_soyisim": "First and Last Name",
    "iletisim_bilgisi": "Contact Information",
    "dogum_tarihi": "Date of Birth",
    "name_surname": "Full Name",
    "username": "Username",
    "note": "Note",
    "role": "Role",
    "department_not_added": "Department Not Added",
    "department_edit": "Edit Department",
    "department_add": "Add Department",
    "save": "Save",
    "copy": "Copy",
    "edit": "Edit",
    "treeChart": "Tree Chart",
    "sanketChart": "Flow Chart",
    "pieRoseTypeChart": "Pie Rose Chart",
    "pieRoundedChart": "Rounded Pie Chart",
    "barPolarTangential": "Polar Bar Chart",
    "analiz_silindi": "Analysis Deleted",
    "analiz_adi_olusturuldu": "Analysis Name Created",
    "analiz_adi_guncellendi": "Analysis Name Updated",
    "analiz_adi_olustururken_hata": "Error Creating Analysis Name",
    "analiz_adi_guncellerken_hata": "Error Updating Analysis Name",
    "analysisRequired": "Enter Analysis Name",
    "etiketlenen": "Labeled",
    "getir": "Fetch",
    "analiz_adi": "Analysis Name",
    "breaking_news": "Breaking News",
    "choose_city": "Select City",
    "choose_district": "Select District",
    "choose_neighborhood": "Select Neighborhood",
    "choose_street": "Select Street",
    "choose_building": "Select Building",
    "choose_apartment": "Select Apartment",
    "find": "Find",
    "disaster_management": "Disaster Management",
    "go_back": "Go Back",
    "city": "City",
    "population": "Population",
    "distance": "Distance",
    "district": "District",
    "neighborhood": "Neighborhood",
    "street": "Street",
    "building": "Building",
    "apartment": "Apartment",
    "started_date": "Start Date",
    "end_date": "End Date",
    "category": "Category",
    "kapanis_metni": "Closing Text",
    "acilis_metni": "Opening Text",
    "main_category": "Main Category",
    "sub_category": "Sub Category",
    "sub_category_1": "Sub Category 1",
    "sub_category_2": "Sub Category 2",
    "bolumler": "Sections",
    "web_site": "Website",
    "kurum_adi": "Institution Name",
    "size": "Size",
    "depth": "Depth",
    "closestCities": "Closest Cities",
    "time_of_creation": "Time of Creation",
    "lat": "Latitude",
    "lng": "Longitude",
    "place": "Place",
    "requirements_table": "Requirements Table",
    "total_population": "Total Population",
    "how_many_days_required": "How Many Days Needed?",
    "total_requirement": "Total Requirement",
    "unit": "Unit",
    "contents": "Contents",
    "analysis_data_not_added": "Analysis Data Not Added",
    "healthcare_facilities": "Healthcare Facilities",
    "location": "Location",
    "all_locations": "All Locations",
    "locations": "Locations",
    "all_locations_show": "Show All Locations",
    "locations_show": "Show Locations",
    "locations_hide": "Hide Locations",

    "page": "Page",
    "create_page": "Create Page",
    "import_file": "Upload File",
    "create_graphic": "Create Graphic",
    "are_you_sure": "Are you sure?",
    "iliskili_tum_kategoriler_silinecektir": "All Related Categories Will Be Deleted",
    "mine": "MINE",
    "show": "Show",
    "hide": "Hide",
    "show_hide": "Show/Hide",
    "updateed": "Updated",
    "update": "Update",
    "updating": "Updating",
    "created": "Added",
    "create": "Create",
    "creating": "Adding",
    "send": "Send",
    "cancel": "Cancel",
    "preview": "Preview",
    "prev": "Back",
    "view": "View",
    "delete": "Delete",
    "no_option": "No Option",
    "max_200_mb": "Cannot Exceed 200 MB",
    "file_size_error": "File Size Too Large",
    "server_error": "Server Error!",
    "try_again_error": "Please try again later.",
    "added_to_chart": "Chart Added to Page",
    "updated_chart": "Chart Updated",
    "deleted_chart": "Chart Deleted",
    "conditions_sections_required": "Do Not Leave Condition Fields Empty.",
    "columns_sections_required": "Do Not Leave Column Fields Empty.",
    "chart_sections_required": "Do Not Leave Chart Fields Empty.",
    "chart_name_error": "Chart Name Must Be Less Than 100 Characters.",
    "select_column_error": "Select At Least One Column.",
    "choose_column": "Choose Column",
    "choose_column_2": "Select Column",
    "group_warning": "Grouping Column Must Be Among Selected Columns.",
    "value": "Value",
    "title": "Title",
    "tum_musteriler": "All Customers",
    "icon": "Icon",
    "iframe": "Iframe",
    "customer": "Customer",
    "select_customer": "Select Customer",
    "page_order": "Page Order",
    "page_deleted": "Page Deleted",
    "parent_menu": "Parent Menu",
    "page_name": "Page Name",
    "external_link": "External Page Link",
    "pageOrder": "Enter Page Order",
    "pageName": "Enter Page Name",
    "pageNameCharLimit": "Page Name Must Exceed 3 Characters",
    "externalLinkError": "External Link Error",
    "iframeLinkError": "Iframe Link Error",
    "file_import_max": "File Size Cannot Exceed 50MB",
    "file_important": "File Required",
    "file_import": "Import File",
    "data_name_important": "Data Name Required",
    "data_name": "Data Name",
    "file": "File",
    "file_name": "File Name",
    "data_type": "Data Type",
    "data_type_error": "Data Type Mismatch",
    "integer": "Integer",
    "string": "String",
    "date": "Date",
    "json": "JSON",
    "email": "Email",
    "phone": "Phone",
    "phone_control": "Phone Check",
    "boolean": "Boolean (True/False)",
    "separators": "Separators",
    "separators_warning": "Separators Could Not Be Detected",
    "separators_error": "An Unrecognized Separator Was Used in the CSV File.",
    "choose_csv_file": "Select CSV File",
    "warning_for_date": "Warning: The format of the selected date columns must be like this <strong>2024-01-01</strong> for processing.",
    "upload": "Upload",
    "column_warning": "Specify Data Types for All Columns",
    "file_import_info": "Data will be added to the system at the end of queuing and analysis processes. This may take a few minutes.",
    "file_import_success": "File Uploaded Successfully",
    "file_import_error": "File Could Not Be Uploaded",
    "mahalle_sayisi": "Number of Neighborhoods",
    "sokak_sayisi": "Number of Streets",
    "bina_sayisi": "Number of Buildings",
    "hane_sayisi": "Number of Households",
    "kisi_sayisi": "Number of People",

    "graphic_preview": "Graphic Preview",
    "secilen": "Selected",
    "choose_page": "Select Page",
    "choose_chart": "Select Chart",
    "added_chart": "Add Chart to Page",
    "update_chart": "Update Chart",
    "chart_width": "Chart Width",
    "chart": "Chart",
    "query": "Query",
    "group": "Grouping",
    "pageCustomer": "Page Customer",
    "selectPage": "Select Page",
    "fileCustomer": "File Customer",
    "selectFile": "Select File",
    "chartOrder": "Chart Order",
    "chartView": "Chart View",
    "pieChart": "Pie Chart",
    "barChart": "Bar Chart",
    "wordCloud": "Word Cloud",
    "table": "Table",
    "map": "Map",
    "chartName": "Chart Name",
    "chartDescription": "Chart Description",
    "addColumn": "Add Column",
    "columnNotFound": "Column Not Found",
    "selectColumn": "Select Column",
    "selectOperator": "Select Operator",
    "selectCondition": "Select Condition",
    "selectAction": "Select Action",
    "dateGrouping": "Date Grouping",
    "menu_section": "Menu Area",
    "menu_align": "Menu Alignment",
    "menu_horizontal": "Menu Alignment (Horizontal)",
    "menu_vertical": "Menu Alignment (Vertical)",
    "xl": "Large (4/4)",
    "lg": "Medium-Large (4/3)",
    "m": "Medium (4/2)",
    "sm": "Small (4/1)",
    "all": "ALL",
    "column": "COLUMN",
    "count": "COUNT",
    "average": "AVERAGE",
    "total": "TOTAL",
    "minimum": "MINIMUM",
    "maximum": "MAXIMUM",
    "distinct": "DISTINCT",
    "day": "DAY",
    "month": "MONTH",
    "year": "YEAR",
    "hour": "HOUR",
    "minute": "MINUTE",
    "second": "SECOND",
    "none": "NONE",
    "condition": "Condition",
    "add_condition": "Add Condition",
    "choose_condition": "Select Condition",
    "conditionNotFound": "Condition Not Found",
    "you_can_add_condition": "You Can Add Condition",
    "in": "IN",
    "out": "OUT",
    "like": "LIKE",
    "notLike": "NOT LIKE",
    "empty": "EMPTY",
    "filled": "FILLED",
    "and": "And",
    "or": "Or",
    "vertical": "Vertical",
    "horizontal": "Horizontal",
    "left": "Left",
    "center": "Center",
    "right": "Right",
    "up": "Up",
    "middle": "Middle",
    "down": "Down",
    "loader_data": "DATA",
    "loader_gathering": "GATHERING...",
    "loader_in_progress": "ANALYZING...",
    "loader_visualizing": "VISUALIZING..."
};

export default en;
