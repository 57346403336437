import React, { useContext, useEffect, useState } from "react";

import { AppSettings } from "../../config/app-settings.js";

function ThemePanel({ hexToRgba }) {
  const context = useContext(AppSettings);

  const [active, setActive] = useState(0);

  const [theme, setTheme] = useState(null);
  const [theme2, setTheme2] = useState(null);

  const toggleAppThemePanel = (event) => {
    event.preventDefault();
    setActive(active === 0 ? active + 1 : 0);
  };

  const [savedTheme, setSavedTheme] = useState(null);
  const [savedTheme2, setSavedTheme2] = useState(null);
  const [graphicColor, setGraphicColor] = useState(null);

  // Tema ayarlama fonksiyonu
  const applyThemes = (theme, theme2) => {
    if (context.me) {
      if (theme) {
        document.getElementById("header").style.background = hexToRgba(
          theme,
          0.8
        );
        document.getElementById("sidebar").style.background = hexToRgba(
          theme,
          0.4
        );
        document.querySelector("body").style.background = hexToRgba(theme, 0.4);
      } else {
        if (context.me?.customer?.settings?.theme.split("|")[0] === "null") {
          document.getElementById("header").removeAttribute("style");
          document.getElementById("sidebar").removeAttribute("style");
          document.querySelector("body").removeAttribute("style");
        } else {
          document.getElementById("header").style.background = hexToRgba(
            context.me?.customer?.settings?.theme.split("|")[0],
            0.8
          );
          document.getElementById("sidebar").style.background = hexToRgba(
            context.me?.customer?.settings?.theme.split("|")[0],
            0.4
          );
          document.querySelector("body").style.background = hexToRgba(
            context.me?.customer?.settings?.theme.split("|")[0],
            0.4
          );
        }
      }

      if (theme2) {
        document.querySelector(
          ".app"
        ).style.background = `linear-gradient(180deg, ${theme2} -190%, rgb(0, 7, 23) 90%)`;
      } else {
        if (context.me?.customer?.settings?.theme.split("|")[1] === "null") {
          document.querySelector(".app").removeAttribute("style");
        } else {
          document.querySelector(
            ".app"
          ).style.background = `linear-gradient(180deg, ${
            context.me?.customer?.settings?.theme.split("|")[1]
          } -190%, rgb(0, 7, 23) 90%)`;
        }
      }

      localStorage.setItem(
        "custom-theme",
        `${theme || "null"}|${theme2 || "null"}`
      );
    }
  };

  // Sayfa yüklendiğinde localStorage'dan tema bilgilerini alın ve uygulayın
  useEffect(() => {
    if (localStorage.getItem("custom-theme")) {
      const storedTheme = localStorage.getItem("custom-theme");

      if (storedTheme) {
        const [storedTheme1, storedTheme2] = storedTheme.split("|");
        setSavedTheme(storedTheme1 === "null" ? null : storedTheme1);
        setSavedTheme2(storedTheme2 === "null" ? null : storedTheme2);
        setTheme(storedTheme1 === "null" ? null : storedTheme1);
        setTheme2(storedTheme2 === "null" ? null : storedTheme2);
        applyThemes(
          storedTheme1 === "null" ? null : storedTheme1,
          storedTheme2 === "null" ? null : storedTheme2
        );
      }
    } else if (localStorage.getItem("theme")) {
      const storedTheme = localStorage.getItem("theme");

      if (storedTheme) {
        const [storedTheme1, storedTheme2] = storedTheme.split("|");
        setSavedTheme(storedTheme1 === "null" ? null : storedTheme1);
        setSavedTheme2(storedTheme2 === "null" ? null : storedTheme2);
        setTheme(storedTheme1 === "null" ? null : storedTheme1);
        setTheme2(storedTheme2 === "null" ? null : storedTheme2);
        applyThemes(
          storedTheme1 === "null" ? null : storedTheme1,
          storedTheme2 === "null" ? null : storedTheme2
        );
      }
    }
  }, [context]);

  // Kaldır butonuna tıklandığında ilgili temayı kaldır ve uygulamaları güncelle
  const handleRemoveTheme = (isMenuTheme) => {
    if (isMenuTheme) {
      setTheme(null);
      setSavedTheme(null);
      applyThemes(null, theme2);
    } else {
      setTheme2(null);
      setSavedTheme2(null);
      applyThemes(theme, null);
    }
  };

  // Grafik Rengi

  function handleGraphicColor(color) {
    setGraphicColor(color);
    context?.setChartColor(color);

    if (color) {
      localStorage.setItem("graphic-color", color);
    } else {
      if (localStorage.getItem("graphic-color")) {
        localStorage.removeItem("graphic-color");
      }
      setTimeout(() => {
        window.location.reload();
      }, 300);
    }
  }

  useEffect(() => {
    if (localStorage.getItem("graphic-color")) {
      const graphicTheme = localStorage.getItem("graphic-color");

      if (graphicTheme) {
        setGraphicColor(graphicTheme);
        context?.setChartColor(graphicTheme);
      }
    } else {
      setGraphicColor(null);
      context?.setChartColor(null);
    }
  }, []);

  return (
    <div className={"app-theme-panel" + (active ? " active" : "")}>
      <div className="app-theme-panel-container">
        <a
          href="#/"
          onClick={toggleAppThemePanel}
          className="app-theme-toggle-btn"
        >
          <span className="rotate-animation">
            <i className="bi bi-gear"></i>
          </span>
        </a>

        <div className="app-theme-panel-content">
          <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <span className="small opacity-70 text-inverse mb-1 d-block">
                {context?.t?.menu_rengi}
              </span>
              {(theme || savedTheme) && (
                <span
                  className="small opacity-70 text-inverse mb-1 d-block text-red pointer"
                  onClick={() => handleRemoveTheme(true)}
                >
                  {context?.t?.remove}
                </span>
              )}
            </div>

            <div className="app-theme-list">
              {context.themeColorList.length > 0 &&
                context.themeColorList.map((t, index) => (
                  <div
                    className={
                      "app-theme-list-item " +
                      (theme === t.color || savedTheme === t.color
                        ? "active"
                        : "")
                    }
                    style={{ flex: "none" }}
                    key={index}
                  >
                    <a
                      href="#/"
                      className={"app-theme-list-link"}
                      style={{
                        background: t.color,
                      }}
                      onClick={(event) => {
                        event.preventDefault();
                        setTheme(t.color);
                        setSavedTheme(t.color);
                        applyThemes(t.color, theme2);
                      }}
                    >
                      &nbsp;
                    </a>
                  </div>
                ))}
            </div>
          </div>

          <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <span className="small opacity-70 text-inverse mb-1 d-block">
                {context?.t?.site_rengi}
              </span>

              {(theme2 || savedTheme2) && (
                <span
                  className="small opacity-70 text-inverse mb-1 d-block text-red pointer"
                  onClick={() => handleRemoveTheme(false)}
                >
                  {context?.t?.remove}
                </span>
              )}
            </div>
            <div className="app-theme-list">
              {context.themeColorList.length > 0 &&
                context.themeColorList.map((t, index) => (
                  <div
                    className={
                      "app-theme-list-item " +
                      (theme2 === t.color || savedTheme2 === t.color
                        ? "active"
                        : "")
                    }
                    style={{ flex: "none" }}
                    key={index}
                  >
                    <a
                      href="#/"
                      className={"app-theme-list-link"}
                      style={{
                        background: t.color,
                      }}
                      onClick={(event) => {
                        event.preventDefault();
                        setTheme2(t.color);
                        setSavedTheme2(t.color);
                        applyThemes(theme, t.color);
                      }}
                    >
                      &nbsp;
                    </a>
                  </div>
                ))}
            </div>
          </div>

          <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <span className="small opacity-70 text-inverse mb-1 d-block">
                {context?.t?.grafik_rengi}
              </span>

              {graphicColor && (
                <span
                  className="small opacity-70 text-inverse mb-1 d-block text-red pointer"
                  onClick={() => handleGraphicColor(null)}
                >
                  {context?.t?.remove}
                </span>
              )}
            </div>

            <div className="app-theme-list">
              {context.themeColorList.length > 0 &&
                context.themeColorList.map((t, index) => (
                  <div
                    className={
                      "app-theme-list-item " +
                      (graphicColor === t.color ? "active" : "")
                    }
                    style={{ flex: "none" }}
                    key={index}
                  >
                    <a
                      href="#/"
                      className={"app-theme-list-link"}
                      style={{
                        background: t.color,
                      }}
                      onClick={(event) => {
                        event.preventDefault();
                        handleGraphicColor(t.color);
                      }}
                    >
                      &nbsp;
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThemePanel;
