import React, { useContext, useEffect, useRef, useState } from "react";

import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Swiper, SwiperSlide } from "swiper/react";

import apiClient from "../../../ApiClient";
import { AppSettings } from "../../../config/app-settings";

import { Card, CardBody } from "../../../components/card/card";
import PageFilter from "../../../components/PageFilter";
import DynamicCard from "../../../components/DynamicCard";

const $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs5");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.colVis.min.js");
require("datatables.net-buttons/js/buttons.flash.min.js");
require("datatables.net-buttons/js/buttons.html5.min.js");
require("datatables.net-buttons/js/buttons.print.min.js");
require("datatables.net-buttons-bs5");
require("datatables.net-responsive");
require("datatables.net-responsive-bs5");
require("datatables.net-fixedcolumns");
require("datatables.net-fixedcolumns-bs5");

export default function SandikAnalizi({
  show = true,
  setNeighborhoodVoterTypeData,
  setNeighborhoodPoliticalProfileData,
  setStreetPoliticalProfileData,
  city,
  district,
  neighbourhood,
  street,
}) {
  // Page Filter
  const [cityFilter, setCityFilter] = useState();
  const [districtFilter, setDistrictFilter] = useState("filter");

  // Get BirthDay
  const [months, setMonths] = useState([
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ]);

  function formatDate(dateStr) {
    const [year, month, day] = dateStr.split("-").map(Number);
    return `${day} ${months[month - 1]} ${year}`;
  }

  // Variables
  const context = useContext(AppSettings);
  const navigate = useNavigate();
  const chartDivRef = useRef(null);
  const tableRef = useRef(null);

  useEffect(() => {
    setMonths([
      context?.t?.ocak,
      context?.t?.subat,
      context?.t?.mart,
      context?.t?.nisan,
      context?.t?.mayis,
      context?.t?.haziran,
      context?.t?.temmuz,
      context?.t?.agustos,
      context?.t?.eylul,
      context?.t?.ekim,
      context?.t?.kasim,
      context?.t?.aralik,
    ]);
  }, [context, context?.t]);

  const [chartData, setChartData] = useState([]);
  const [tableMahalle, setTableMahalle] = useState(false);
  const [chartDataStreet, setChartDataStreet] = useState([]);
  const [chartDataChest, setChartDataChest] = useState([]);
  const [chartValue, setChartValue] = useState(false);
  const [chartValueLoader, setChartValueLoader] = useState(false);
  const [chartSlideActive, setChartSlideActive] = useState("");
  const [chartInfoDataLoading, setChartInfoDataLoading] = useState(false);
  const [chartInfoSlide, setChartInfoSlide] = useState(null);

  const [chartInfoData1, setChartInfoData1] = useState([]);
  const [chartInfoData2, setChartInfoData2] = useState([]);
  const [chartInfoData3, setChartInfoData3] = useState([]);
  const [chartInfoData4, setChartInfoData4] = useState([]);
  const [chartInfoData5, setChartInfoData5] = useState([]);

  const [hoveredTableRow, setHoveredTableRow] = useState(null);

  useEffect(() => {
    if (!context?.me?.customer?.settings?.sandikAnalizi) {
      navigate("/404");
    }
  }, [context]);

  function handleTableScroll() {
    const myElement = tableRef.current;

    myElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  // First Chart Data
  async function getChartData() {
    if (cityFilter && districtFilter && districtFilter !== "filter") {
      setChartDataStreet([]);
      setChartData(null);
      setChartDataChest([]);

      setChartSlideActive("");
      setChartInfoDataLoading(false);
      setChartValue(false);
      setChartValueLoader(false);
      setChartInfoData1([]);
      setChartInfoData2([]);
      setChartInfoData3([]);
      setChartInfoData4([]);
      setChartInfoData5([]);

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 48,
            title: "Sandık Analizi",
            type: `bireysel-data-customer-${context?.me?.customer?.id}`,
            description: "",
            customer: null,
            status: true,
            order: 1,
            sql: {
              column: [
                {
                  key: "Adres Il",
                  value: "select",
                },
                {
                  key: "Adres Ilce",
                  value: "select",
                },
                {
                  key: "Adres Mahalle",
                  value: "select",
                },
                {
                  key: "Adres Sokak",
                  value: "select",
                },
                {
                  key: "Sandık No",
                  value: "select",
                },
              ],
              condition: [
                cityFilter
                  ? {
                      key: "Adres Il",
                      value: context
                        .removeTurkishCharacters(cityFilter.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
                districtFilter
                  ? {
                      key: "Adres Ilce",
                      value: context
                        .removeTurkishCharacters(districtFilter.label)
                        .toUpperCase(),
                      operator: "=",
                      type: "and",
                    }
                  : null,
              ].filter((item) => item !== null),
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response) {
          if (response.length !== 0) {
            const outputArray = [];
            const outputArray2 = [];
            const chartLinkWith = [];

            response.forEach((item, index) => {
              const neighbourhoodIndex = outputArray.findIndex(
                (elem) => elem.name === String(item["adres mahalle"])
              );

              if (neighbourhoodIndex === -1) {
                outputArray.push({
                  name: String(item["adres mahalle"]),
                  value: item["adres mahalle"],
                  children: [],
                });

                outputArray2.push({
                  name: String(item["adres mahalle"]),
                  value: item["adres mahalle"],
                  children: [
                    {
                      name: String(item["adres sokak"]),
                      value: item["adres sokak"],
                    },
                  ],
                });
              } else {
                const streetIndex = outputArray2[
                  neighbourhoodIndex
                ].children.findIndex(
                  (elem) => elem.name === String(item["adres sokak"])
                );
                if (streetIndex === -1) {
                  outputArray2[neighbourhoodIndex].children.push({
                    name: String(item["adres sokak"]),
                    value: item["adres sokak"],
                    neighbourhood: item["adres mahalle"],
                    sandik_no: item["sandık no"] || "-",
                  });
                }
              }
            });

            outputArray.forEach((item) => {
              chartLinkWith.push(item.name);
            });

            outputArray.unshift({
              name: context
                .removeTurkishCharacters(districtFilter.label)
                .toUpperCase(),
              linkWith: chartLinkWith,
            });

            //--

            const chestArray = [];

            response.forEach((item) => {
              const streetIndex = chestArray.findIndex(
                (elem) => elem.name === String(item["adres sokak"])
              );

              if (streetIndex === -1) {
                chestArray.push({
                  name: String(item["adres sokak"]),
                  neighbourhood: String(item["adres mahalle"]),
                  children: [
                    {
                      name: "NO",
                      value: item["sandık no"],
                    },
                  ],
                });
              } else {
                chestArray[streetIndex].children.push({
                  name: "NO",
                  value: item["sandık no"],
                });
              }
            });

            setChartData(outputArray);
            setChartDataStreet(outputArray2);
            setChartDataChest(chestArray);
          } else {
            setChartDataStreet([]);
            setChartDataChest([]);
            setChartData([]);

            setChartSlideActive("");
            setChartInfoDataLoading(false);
            setChartValue(false);
            setChartValueLoader(false);

            setChartInfoData1([]);
            setChartInfoData2([]);
            setChartInfoData3([]);
            setChartInfoData4([]);
            setChartInfoData5([]);
          }
        }
      } catch (error) {
        setChartData([]);
        console.error(error);
      }
    }
  }

  useEffect(() => {
    if (context?.me?.customer?.settings?.sandikAnalizi) {
      getChartData();
    }
  }, [context, cityFilter, districtFilter]);

  // Get Others Chart Data
  async function getChartDataInfo(
    graphic,
    city,
    district,
    neighbourhood,
    street,
    chest
  ) {
    const conditionData = [
      city
        ? {
            key: graphic === 2 ? "Sehir" : "Adres Il",
            value: context.removeTurkishCharacters(city).toUpperCase(),
            operator: "=",
            type: "and",
          }
        : null,
      district
        ? {
            key: graphic === 2 ? "Ilce" : "Adres Ilce",
            value: context.removeTurkishCharacters(district).toUpperCase(),
            operator: "=",
            type: "and",
          }
        : null,
      neighbourhood
        ? {
            key: graphic === 2 ? "Mahalle" : "Adres Mahalle",
            value: context.removeTurkishCharacters(neighbourhood).toUpperCase(),
            operator: "like",
            type: "and",
          }
        : null,
      street && graphic !== 2
        ? {
            key: "Adres Sokak",
            value: street.toUpperCase(),
            operator: "like",
            type: "and",
          }
        : null,
      chest
        ? {
            key: graphic === 2 ? "Alt_Kategori_2" : "Sandık No",
            value: chest,
            operator: "=",
            type: "and",
          }
        : null,
    ];
    const conditionDataFiltered = conditionData.filter((item) => item !== null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 48,
          title: "Sandık Analizi",
          type:
            graphic === 2
              ? "siyasetmetre_secim_3"
              : `bireysel-data-customer-${context?.me?.customer?.id}`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: graphic === 2 ? "Sehir" : "Adres Il",
                value: "select",
              },
              {
                key: graphic === 2 ? "Ilce" : "Adres Ilce",
                value: "select",
              },
              {
                key: graphic === 2 ? "Mahalle" : "Adres Mahalle",
                value: "select",
              },
              graphic !== 2
                ? {
                    key: "Adres Sokak",
                    value: "select",
                  }
                : null,
              graphic !== 6
                ? {
                    key: graphic === 2 ? "Alt_Kategori_2" : "Sandık No",
                    value: "select",
                  }
                : null,
              graphic === 2
                ? {
                    key: "Parametre",
                    value: "select",
                  }
                : null,
              graphic === 2
                ? {
                    key: "Deger",
                    value: "select",
                  }
                : null,
              graphic !== 2 && graphic !== 6
                ? {
                    key: "Secmen Profili",
                    value: "select",
                  }
                : null,
              graphic !== 2 && graphic !== 6
                ? {
                    key: "Siyasal Kimlik 1",
                    value: "select",
                  }
                : null,
              graphic !== 2 && graphic !== 6
                ? {
                    key: "Siyasal Kimlik 2",
                    value: "select",
                  }
                : null,
              graphic !== 2 && graphic !== 6
                ? {
                    key: "Memleket Il",
                    value: "select",
                  }
                : null,
              graphic !== 2 && graphic !== 6
                ? {
                    key: "Memleket Ilce",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "TC",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Ad Soyad",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Dogum Tarihi",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Telefon NO",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Bina NO",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Daire",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Tahmini 1. Derece Ailede Uyelik",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Tahmini 2. Derece Ailede Uyelik",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Sandığa Gitme Ihtimali",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Sandığa Gitmeme Ihtimali",
                    value: "select",
                  }
                : null,
            ].filter((item) => item !== null),
            condition: conditionDataFiltered,
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (graphic === 2) {
        if (response.length !== 0) {
          setChartInfoData1(
            response.map((element) => {
              return {
                name: element.parametre,
                value: element.deger,
              };
            })
          );
        } else {
          setChartInfoData1([]);
        }
      }

      if (graphic === 3) {
        if (response.length !== 0) {
          const siyasalKimlikMap = new Map();
          const siyasalKimlikOptions = [];

          response.forEach((item) => {
            const value =
              item["siyasal kimlik 1"] + " - " + item["siyasal kimlik 2"];
            siyasalKimlikMap.set(value, (siyasalKimlikMap.get(value) || 0) + 1);
          });

          siyasalKimlikMap.forEach((count, value) => {
            const percentage = Math.floor((count / response.length) * 100);
            siyasalKimlikOptions.push({
              value: percentage,
              name: value,
            });
          });

          setChartInfoData2(siyasalKimlikOptions);

          if (setNeighborhoodPoliticalProfileData) {
            const transformedData = [["*", "*"]];

            const headers = [context?.t?.siyasal_kimlik, context?.t?.oran];
            const rows = siyasalKimlikOptions.map((item) => {
              return [item.name, item.value.toString()];
            });

            transformedData.push([headers, ...rows]);

            setNeighborhoodPoliticalProfileData(transformedData);
          }

          if (setStreetPoliticalProfileData) {
            const transformedData = [["*", "*"]];

            const headers = [context?.t?.siyasal_kimlik, context?.t?.oran];
            const rows = siyasalKimlikOptions.map((item) => {
              return [item.name, item.value.toString()];
            });

            transformedData.push([headers, ...rows]);

            setStreetPoliticalProfileData(transformedData);
          }
        } else {
          setChartInfoData2([]);

          if (setNeighborhoodPoliticalProfileData) {
            setNeighborhoodPoliticalProfileData([[], [[], []]]);
          }

          if (setStreetPoliticalProfileData) {
            setStreetPoliticalProfileData([[], [[], []]]);
          }
        }
      }

      if (graphic === 4) {
        if (response.length !== 0) {
          const secmenProfiliMap = new Map();
          const secmenProfiliOptions = [];

          response.forEach((item) => {
            const value = item["secmen profili"];
            secmenProfiliMap.set(value, (secmenProfiliMap.get(value) || 0) + 1);
          });

          secmenProfiliMap.forEach((count, value) => {
            const percentage = Math.floor((count / response.length) * 100);
            secmenProfiliOptions.push({
              value: percentage,
              name: value,
            });
          });

          if (setNeighborhoodVoterTypeData) {
            setNeighborhoodVoterTypeData([
              secmenProfiliOptions.map((item) => {
                return "*";
              }),
              secmenProfiliOptions.map((item) => {
                return item.name;
              }),
              secmenProfiliOptions.map((item) => {
                return item.value;
              }),
            ]);
          }

          setChartInfoData3(secmenProfiliOptions);
        } else {
          setChartInfoData3([]);

          if (setNeighborhoodVoterTypeData) {
            setNeighborhoodVoterTypeData([[], [], []]);
          }
        }
      }

      if (graphic === 5) {
        if (response.length !== 0) {
          const hemsehrilikDurumuMap = new Map();
          const hemsehrilikDurumuOptions = [];

          response.forEach((item) => {
            const value = item["memleket il"] + " - " + item["memleket ilce"];
            hemsehrilikDurumuMap.set(
              value,
              (hemsehrilikDurumuMap.get(value) || 0) + 1
            );
          });

          hemsehrilikDurumuMap.forEach((count, value) => {
            hemsehrilikDurumuOptions.push({
              value: count,
              category: value,
            });
          });

          setChartInfoData4(hemsehrilikDurumuOptions);
        } else {
          setChartInfoData4([]);
        }
      }

      if (graphic === 6) {
        if (response.length !== 0) {
          setChartInfoData5(response);
        } else {
          setChartInfoData5([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  // First Chart
  useEffect(() => {
    if (context?.me?.customer?.settings?.sandikAnalizi && chartDataStreet) {
      if (chartDataStreet.length !== 0) {
        // Create root element
        const root = am5.Root.new(chartDivRef.current);

        // Set themes
        root.setThemes([am5themes_Animated.new(root)]);

        // Your data here...
        let data = {
          name: "Root",
          children: chartData,
        };

        // Create wrapper container
        let container = root.container.children.push(
          am5.Container.new(root, {
            width: am5.percent(100),
            height: am5.percent(100),
            layout: root.verticalLayout,
          })
        );

        // Create series
        let series = container.children.push(
          am5hierarchy.ForceDirected.new(root, {
            singleBranchOnly: true,
            downDepth: 1,
            topDepth: 1,
            maxRadius: 40,
            minRadius: 8,
            maxLevels: 1,
            categoryField: "name",
            childDataField: "children",
            idField: "name",
            linkWithStrength: 0.3,
            linkWithField: "linkWith",
            manyBodyStrength: -40,
            centerStrength: 1,
            initialDepth: 1,
          })
        );

        series
          .get("colors")
          .set("colors", [
            am5.color(0x2b8e6d),
            am5.color(0x38b088),
            am5.color(0x1c664e),
          ]);
        series.get("colors").setAll({
          step: 1,
        });

        series.nodes.template.events.on("click", function (e) {
          setChartSlideActive("");
          setChartInfoDataLoading(false);

          if (
            e.target.dataItem.dataContext.children &&
            e.target.dataItem.dataContext.children.length === 0
          ) {
            chartData.forEach(function (item) {
              item.children = [];
            });

            const chartDataStreetFiltered = chartDataStreet.find(
              (item) => item.value === e.target.dataItem.dataContext.value
            );

            let targetIndex = chartData.findIndex(function (item) {
              return item.value === e.target.dataItem.dataContext.value;
            });
            if (targetIndex !== -1) {
              chartData[targetIndex].children =
                chartDataStreetFiltered.children;
            }

            let chartDataUpdated = chartData.slice();

            setChartData(chartDataUpdated);
          }

          if (
            e.target.dataItem.dataContext.linkWith ||
            e.target.dataItem.dataContext.children
          ) {
            setChartValueLoader(false);
            setChartValue(false);
          } else {
            setChartValueLoader(false);
            setTimeout(() => {
              setChartValueLoader(true);
            }, 300);

            setChartValue(e.target.dataItem.dataContext);
          }

          if (e.target.dataItem.dataContext.children) {
            setTableMahalle(e.target.dataItem.dataContext.name);
          }
        });

        series.data.setAll([data]);

        series.nodes.template.set("tooltipText", "{category}");

        series.set("selectedDataItem", series.dataItems[0]);

        series.appear(1000, 100);

        return () => {
          root.dispose();
        };
      }
    }
  }, [context, chartData, chartDataStreet]);

  // Get Chart Info Data
  function getChartInfoData(street, neighbourhood, chest) {
    setChartInfoDataLoading(true);
    setChartSlideActive({ id: chest, street: street });

    setChartInfoData1(false);
    setChartInfoData2(false);
    setChartInfoData3(false);
    setChartInfoData4(false);
    setChartInfoData5(false);

    getChartDataInfo(
      2,
      cityFilter.label,
      districtFilter.label,
      neighbourhood,
      street,
      chest
    );
    getChartDataInfo(
      3,
      cityFilter.label,
      districtFilter.label,
      neighbourhood,
      street,
      chest
    );
    getChartDataInfo(
      4,
      cityFilter.label,
      districtFilter.label,
      neighbourhood,
      street,
      chest
    );
    getChartDataInfo(
      5,
      cityFilter.label,
      districtFilter.label,
      neighbourhood,
      street,
      chest
    );
    getChartDataInfo(
      6,
      cityFilter.label,
      districtFilter.label,
      neighbourhood,
      street,
      chest
    );
  }

  // Table
  useEffect(() => {
    if (context?.me?.customer?.settings?.sandikAnalizi && chartInfoData5) {
      if ($("#datatableDefault_1").length) {
        $("#datatableDefault_1").DataTable({
          dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center mt-3'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
          lengthMenu: [10, 20, 30, 40, 50],
          pageLength: 50,
          scrollX: 100,
          buttons: [
            { extend: "csv", className: "btn btn-outline-default btn-sm" },
          ],
          searching: false,
          language: {
            info: "",
            infoEmpty: context?.t?.hicbir_kayit_mevcut_degil,
            // infoFiltered: "(toplam _MAX_ kayıttan filtrelendi)",
            lengthMenu: " _MENU_ ",
            zeroRecords: context?.t?.hicbir_bulunamadi_uzgunum,
            paginate: {
              next: context?.t?.next,
              previous: context?.t?.prev,
            },
            search: "",
          },
          deferRender: true,
        });

        return () => {
          if ($(".dataTables_wrapper table").length) {
            $(".dataTables_wrapper table").DataTable().destroy(true);
          }
        };
      }
    }

    // eslint-disable-next-line
  }, [context, chartInfoDataLoading, chartInfoData5]);

  // Hazır Raporlar
  useEffect(() => {
    if (context?.me?.customer?.settings?.sandikAnalizi) {
      getChartDataInfo(4, city, district, neighbourhood, null, null);
    }
  }, [context, setNeighborhoodVoterTypeData, city, district, neighbourhood]);

  useEffect(() => {
    if (context?.me?.customer?.settings?.sandikAnalizi) {
      getChartDataInfo(3, city, district, neighbourhood, null, null);
    }
  }, [
    context,
    setNeighborhoodPoliticalProfileData,
    city,
    district,
    neighbourhood,
  ]);

  useEffect(() => {
    if (context?.me?.customer?.settings?.sandikAnalizi) {
      getChartDataInfo(3, city, district, neighbourhood, street, null);
    }
  }, [
    context,
    setStreetPoliticalProfileData,
    city,
    district,
    neighbourhood,
    street,
  ]);

  return (
    show &&
    context?.me?.customer?.settings?.sandikAnalizi && (
      <div className="row g-4 mb-5 pb-5">
        <div className="zoom-item">
          <PageFilter
            setCityFilter={setCityFilter}
            setDistrictFilter={setDistrictFilter}
            neighbourhood={false}
            street={false}
          />
        </div>

        {chartSlideActive && (
          <div className="col-12">
            {chartSlideActive.street ? (
              <p className="mb-0 h5 fw-normal">
                <strong>{tableMahalle || "-"}</strong>{" "}
                {context?.t?.icerisinde_bulunan}{" "}
                <strong>{chartSlideActive?.id || "-"}</strong>{" "}
                {context?.t?.numarali_sandikta}{" "}
                <strong>{chartValue?.name || "-"}</strong>{" "}
                {context?.t?.genelinde_ikamet_eden}{" "}
                <strong>
                  {(chartInfoData5 && chartInfoData5.length !== 0) || "-"}
                </strong>{" "}
                {context?.t?.secmen_oy_kullanmistir}
              </p>
            ) : (
              <p className="mb-0 h5 fw-normal">
                <strong>{tableMahalle || "-"}</strong>{" "}
                {context?.t?.icerisinde_bulunan}{" "}
                <strong>{chartSlideActive?.id || "-"}</strong>{" "}
                {context?.t?.numarali_sandikta}{" "}
                <strong>{tableMahalle || "-"}</strong>{" "}
                {context?.t?.genelinde_ikamet_eden}{" "}
                <strong>
                  {(chartInfoData5 && chartInfoData5.length !== 0) || "-"}
                </strong>{" "}
                {context?.t?.secmen_oy_kullanmistir}
              </p>
            )}
          </div>
        )}

        <div className="col-12">
          <Card>
            <CardBody>
              {districtFilter && districtFilter !== "filter" ? (
                chartDataStreet ? (
                  chartDataStreet.length !== 0 ? (
                    <>
                      <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
                        <span className="fw-bold h6 mb-0 d-flex align-items-center">
                          {
                            context?.t
                              ?.baskanlik_sistemine_gore_secim_sandik_analizi
                          }
                        </span>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div
                            className="row sandik-analizi-chart"
                            style={{ height: "calc(100vh - 100px)" }}
                          >
                            <div className={`col-12 col-xl`}>
                              <div
                                ref={chartDivRef}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            </div>

                            {chartInfoDataLoading && (
                              <div
                                className="col-12 col-xl-5 zoom-item"
                                style={{
                                  height: "calc(100% - 20px)",
                                }}
                              >
                                <div className="row flex-column h-100 g-3">
                                  <div className="col overflow-hidden">
                                    <DynamicCard
                                      events={false}
                                      height="h-full"
                                      data={chartInfoData1}
                                      allEvents={false}
                                      item={{
                                        title: context?.t?.sandik_sonucu,
                                        options: {
                                          options: {
                                            series: [
                                              {
                                                data: chartInfoData1,
                                                name: null,
                                                type: "pie",
                                                label: {
                                                  textStyle: {
                                                    fontSize: 15,
                                                  },
                                                },
                                                radius: "50%",
                                                itemStyle: {
                                                  color: context?.chartColor,
                                                },
                                              },
                                            ],
                                            tooltip: {
                                              trigger: "item",
                                            },
                                            darkMode: true,
                                            visualMap: context?.chartColor?{
                                              min:
                                                chartInfoData1 &&
                                                chartInfoData1.length !== 0
                                                  ? Math.min(
                                                      ...chartInfoData1.map(
                                                        (obj) => obj.value
                                                      )
                                                    )
                                                  : 0,
                                              max:
                                                chartInfoData1 &&
                                                chartInfoData1.length !== 0
                                                  ? Math.max(
                                                      ...chartInfoData1.map(
                                                        (obj) => obj.value
                                                      )
                                                    )
                                                  : 0,
                                              show: false,
                                              inRange: {
                                                colorLightness: [0.2, 1],
                                              },
                                            }:false,
                                          },
                                        },
                                      }}
                                    />
                                  </div>

                                  {/* <div className="col overflow-hidden">
                                    <div className="row h-100">
                                      <div className="col-12 col-lg-12 h-100">
                                        <DynamicCard
                                          events={false}
                                          allEvents={false}
                                          height="h-full"
                                          data={chartInfoData2}
                                          item={{
                                            title: "SİYASAL KİMLİK",
                                            options: {
                                              options: {
                                                series: [
                                                  {
                                                    data: chartInfoData2,
                                                    name: null,
                                                    type: "pie",
                                                    label: {
                                                      textStyle: {
                                                        fontSize: 15,
                                                      },
                                                    },
                                                    radius: "50%",
                                                    itemStyle: {
                                                      color: context?.chartColor,
                                                    },
                                                  },
                                                ],
                                                tooltip: {
                                                  trigger: "item",
                                                },
                                                darkMode: true,
                                                visualMap: {
                                                  min:
                                                    chartInfoData2 &&
                                                    chartInfoData2.length !== 0
                                                      ? Math.min(
                                                          ...chartInfoData2.map(
                                                            (obj) => obj.value
                                                          )
                                                        )
                                                      : 0,
                                                  max:
                                                    chartInfoData2 &&
                                                    chartInfoData2.length !== 0
                                                      ? Math.max(
                                                          ...chartInfoData2.map(
                                                            (obj) => obj.value
                                                          )
                                                        )
                                                      : 0,
                                                  show: false,
                                                  inRange: {
                                                    colorLightness: [0.2, 1],
                                                  },
                                                },
                                              },
                                            },
                                          }}
                                        />
                                      </div>

                                      <div className="col-12 col-lg-6 h-100">
                                        <DynamicCard
                                          events={false}
                                          height="h-full"
                                          allEvents={false}
                                          data={chartInfoData3}
                                          item={{
                                            title: "SEÇMEN PROFİLİ",
                                            options: {
                                              options: {
                                                series: [
                                                  {
                                                    data: chartInfoData3,
                                                    name: null,
                                                    type: "pie",
                                                    label: {
                                                      textStyle: {
                                                        fontSize: 15,
                                                      },
                                                    },
                                                    radius: "50%",
                                                    itemStyle: {
                                                      color: context?.chartColor,
                                                    },
                                                  },
                                                ],
                                                tooltip: {
                                                  trigger: "item",
                                                },
                                                darkMode: true,
                                                visualMap: {
                                                  min:
                                                    chartInfoData3 &&
                                                    chartInfoData3.length !== 0
                                                      ? Math.min(
                                                          ...chartInfoData3.map(
                                                            (obj) => obj.value
                                                          )
                                                        )
                                                      : 0,
                                                  max:
                                                    chartInfoData3 &&
                                                    chartInfoData3.length !== 0
                                                      ? Math.max(
                                                          ...chartInfoData3.map(
                                                            (obj) => obj.value
                                                          )
                                                        )
                                                      : 0,
                                                  show: false,
                                                  inRange: {
                                                    colorLightness: [0.2, 1],
                                                  },
                                                },
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div> */}

                                  <div className="col d-flex flex-column overflow-hidden">
                                    <div className="flex-1 h-100">
                                      <DynamicCard
                                        events={false}
                                        allEvents={false}
                                        height="h-full"
                                        data={chartInfoData4}
                                        item={{
                                          chart: 3,
                                          title: context?.t?.hemsehrilik_durumu,
                                          options: {
                                            options: chartInfoData4,
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-12">
                                  <button
                                    className="d-block btn px-2 py-0 btn-primary btn-theme mt-n2 ms-auto"
                                    onClick={handleTableScroll}
                                  >
                                    {context?.t?.table}
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>

                          {chartValue && (
                            <div
                              className="row align-items-center position-relative bg-header-bg overflow-hidden sandik-analizi-chart-slide"
                              style={{
                                marginTop: "-20px",
                                marginBottom: "-20px",
                                background: "#0d151d",
                                padding: "20px 10px",
                                height: "140px",
                              }}
                            >
                              {chartValueLoader ? (
                                <>
                                  <div className="col overflow-hidden">
                                    <Swiper
                                      onSwiper={(s) => {
                                        setChartInfoSlide(s);
                                      }}
                                      speed={800}
                                      slidesPerView={"auto"}
                                      spaceBetween={12}
                                    >
                                      {chartDataChest.map(
                                        (item, index) =>
                                          item.name === chartValue.value &&
                                          item.children.map(
                                            (number, numberIndex) => (
                                              <SwiperSlide
                                                style={{ width: 150 }}
                                                key={numberIndex}
                                              >
                                                <Card>
                                                  <CardBody className="text-center">
                                                    <span
                                                      className={`pointer slide-chest ${
                                                        chartSlideActive.street &&
                                                        chartSlideActive.id ===
                                                          number.value
                                                          ? "active"
                                                          : ""
                                                      }`}
                                                      onClick={() =>
                                                        getChartInfoData(
                                                          item.name,
                                                          item.neighbourhood,
                                                          number.value
                                                        )
                                                      }
                                                    >
                                                      {context?.t?.sandik_no}
                                                      <br />
                                                      <strong className="h3 mt-2">
                                                        {number.value || "-"}
                                                      </strong>
                                                      <br />
                                                    </span>

                                                    <span
                                                      onClick={() =>
                                                        getChartInfoData(
                                                          null,
                                                          item.neighbourhood,
                                                          number.value
                                                        )
                                                      }
                                                      className={`slide-general btn btn-theme btn-sm m px-1 py-0 pointer mt-1 ${
                                                        !chartSlideActive.street &&
                                                        chartSlideActive.id ===
                                                          number.value
                                                          ? "active"
                                                          : ""
                                                      }`}
                                                    >
                                                      {
                                                        context?.t
                                                          ?.sandik_geneli
                                                      }
                                                    </span>
                                                  </CardBody>
                                                </Card>
                                              </SwiperSlide>
                                            )
                                          )
                                      )}
                                    </Swiper>
                                  </div>
                                  <div className="w-auto d-flex flex-column">
                                    <button
                                      className="d-block btn px-2 py-0 btn-outline-theme"
                                      onClick={() => chartInfoSlide.slidePrev()}
                                    >
                                      {context?.t?.prev}
                                    </button>

                                    <button
                                      className="d-block btn px-2 py-0 btn-outline-theme mt-2"
                                      onClick={() => chartInfoSlide.slideNext()}
                                    >
                                      {context?.t?.next}
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <div className="spinner-border text-theme"></div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <span className="d-block text-center">
                      {context?.t?.no_data_available}
                    </span>
                  )
                ) : (
                  <div className="spinner-border text-theme"></div>
                )
              ) : (
                <span className="d-block text-center">
                  {context?.t?.il_ve_ilce_secmelisiniz}
                </span>
              )}
            </CardBody>
          </Card>
        </div>

        <div ref={tableRef}>
          {chartInfoDataLoading &&
            (chartInfoData5 ? (
              chartInfoData5.length !== 0 ? (
                <div className="col-12">
                  <div className="border-t mt-4 pt-4 mb-5 hide-data">
                    <table
                      id="datatableDefault_1"
                      className="table text-nowrap w-100 table-datatable"
                    >
                      <thead>
                        <tr>
                          <th style={{ minWidth: 150, width: 150 }}>
                            {context?.t?.tc}
                          </th>
                          <th style={{ minWidth: 150, width: 150 }}>
                            {context?.t?.isim_soyisim}
                          </th>
                          <th style={{ minWidth: 150, width: 150 }}>
                            {context?.t?.dogum_tarihi}
                          </th>
                          <th style={{ minWidth: 50, width: 50 }}>
                            {context?.t?.age}
                          </th>
                          <th style={{ minWidth: 150, width: 150 }}>
                            {context?.t?.phone}
                          </th>
                          <th style={{ minWidth: 150, width: 150 }}>
                            {context?.t?.city}
                          </th>
                          <th style={{ minWidth: 150, width: 150 }}>
                            {context?.t?.district}
                          </th>
                          <th style={{ minWidth: 150, width: 150 }}>
                            {context?.t?.neighborhood}
                          </th>
                          <th style={{ minWidth: 150, width: 150 }}>
                            {context?.t?.street}
                          </th>
                          <th style={{ minWidth: 100, width: 100 }}>
                            {context?.t?.building}
                          </th>
                          <th style={{ minWidth: 100, width: 100 }}>
                            {context?.t?.apartment}
                          </th>
                          {/* <th>Tahmini 1. Derece Ailede Üyelik</th>
                          <th>Tahmini 2. Derece Ailede Üyelik</th> */}
                          {/* <th>Sandığa Gitme İhtimali</th>
                          <th>Sandığa Gitmeme İhtimali</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {chartInfoData5.map((item, index) => (
                          <tr
                            key={index}
                            onMouseEnter={() => setHoveredTableRow(index)}
                            onMouseLeave={() => setHoveredTableRow(null)}
                          >
                            <td>
                              {hoveredTableRow === index
                                ? context.me?.customer?.settings?.primary
                                  ? item?.tc || context?.t?.unknown
                                  : "--"
                                : "********"}
                            </td>
                            <td>
                              {hoveredTableRow === index
                                ? context.me?.customer?.settings?.primary
                                  ? item["ad soyad"] || context?.t?.unknown
                                  : "--"
                                : "********"}
                            </td>
                            <td>
                              {hoveredTableRow === index
                                ? context.me?.customer?.settings?.primary
                                  ? item["dogum tarihi"]
                                    ? formatDate(item["dogum tarihi"])
                                    : context?.t?.unknown
                                  : "--"
                                : "********"}
                            </td>
                            <td>
                              {hoveredTableRow === index
                                ? context.me?.customer?.settings?.primary
                                  ? item["dogum tarihi"]
                                    ? moment().diff(
                                        moment(
                                          formatDate(item["dogum tarihi"]),
                                          "DD MMMM YYYY"
                                        ),
                                        "years"
                                      )
                                    : context?.t?.unknown
                                  : "--"
                                : "********"}
                            </td>
                            <td>
                              {hoveredTableRow === index
                                ? context.me?.customer?.settings?.primary
                                  ? item["telefon no"]
                                    ? context.phoneFormat(item["telefon no"])
                                    : context?.t?.unknown
                                  : "--"
                                : "********"}
                            </td>
                            <td>
                              {hoveredTableRow === index
                                ? item["adres il"] || context?.t?.unknown
                                : "********"}
                            </td>
                            <td>
                              {hoveredTableRow === index
                                ? item["adres ilce"] || context?.t?.unknown
                                : "********"}
                            </td>
                            <td>
                              {hoveredTableRow === index
                                ? item["adres mahalle"] || context?.t?.unknown
                                : "********"}
                            </td>
                            <td>
                              {hoveredTableRow === index
                                ? item["adres sokak"] || context?.t?.unknown
                                : "********"}
                            </td>
                            <td>
                              {hoveredTableRow === index
                                ? item["bina no"] || context?.t?.unknown
                                : "********"}
                            </td>
                            <td>
                              {hoveredTableRow === index
                                ? item["daire"] || context?.t?.unknown
                                : "********"}
                            </td>
                            {/* <td>
                              {hoveredTableRow === index
                                ? item["tahmini 1. derece ailede uyelik"] ||
                                  context?.t?.unknown
                                : "********"}
                            </td>
                            <td>
                              {hoveredTableRow === index
                                ? item["tahmini 2. derece ailede uyelik"] ||
                                  context?.t?.unknown
                                : "********"}
                            </td> */}
                            {/* <td>
                              {hoveredTableRow === index
                                ? item["sandığa gitme ihtimali"]
                                  ? "%" + item["sandığa gitme ihtimali"]
                                  : context?.t?.unknown
                                : "********"}
                            </td>
                            <td>
                              {hoveredTableRow === index
                                ? item["sandığa gitmeme ihtimali"]
                                  ? "%" + item["sandığa gitmeme ihtimali"]
                                  : context?.t?.unknown
                                : "********"}
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <span className="d-block text-center">
                  {context?.t?.no_data_available}
                </span>
              )
            ) : (
              <div className="spinner-border text-theme"></div>
            ))}
        </div>

        <ToastContainer pauseOnFocusLoss={false} />
      </div>
    )
  );
}
