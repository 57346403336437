import React, { useEffect, useState, useContext } from "react";

import ChartBar from "../../components/echart/ChartBar.jsx";
import ChartPie from "../../components/echart/ChartPie.jsx";
import ChartBarTimeLine from "../../components/echart/ChartBarTimeLine.jsx";
import ChartLineMultiple from "../../components/echart/ChartLineMultiple.jsx";
import PageFilter from "../../components/PageFilter.jsx";
import PageLoader from "../../components/PageLoader.jsx";
import CardBinaHane from "../../components/info-cards/CardBinaHane.jsx";
import CardMedeniDurum from "../../components/info-cards/CardMedeniDurum.jsx";
import CardVatandasSayisi from "../../components/info-cards/CardVatandasSayisi.jsx";
import CardOlumSayisi from "../../components/info-cards/CardOlumSayisi.jsx";
import CardHemsehrilikGoc from "../../components/info-cards/CardHemsehrilikGoc.jsx";
import CardSosyoekonomik from "../../components/info-cards/CardSosyoekonomik.jsx";
import KentmetreMap from "./KentmetreMap.jsx";
import EchartRace from "../../components/echart/echartRace.jsx";
import apiClient from "../../ApiClient.js";
import PageChartCard from "../../components/page-chart-card/pageChartCard.jsx";
import ChartSankey from "../../components/echart/ChartSankey";
import ChartSankeyReverse from "../../components/echart/ChartSankeyReverse.jsx";
import { Card, CardBody } from "../../components/card/card.jsx";
import DinamikPage from "../dinamik-page/index.jsx";
import DynamicCard from "../../components/DynamicCard.jsx";
import { AppSettings } from "../../config/app-settings.js";

export default function Anasayfa({
  show = true,
  setNeighborhoodAgeData,
  setNeighborhoodVoterPoliticalData,
  setNeighborhoodCitizenshipData,
  city,
  district,
  neighbourhood,
}) {
  useEffect(() => {
    console.log(process.env.NODE_ENV);
  }, []);

  const context = useContext(AppSettings);

  const [loading, setLoading] = useState(false);

  const [cityFilter, setCityFilter] = useState(null);
  const [districtFilter, setDistrictFilter] = useState(null);
  const [neighbourhoodFilter, setNeighbourhoodFilter] = useState(null);

  // Chart Data
  const [yasGrubunaGoreChartOptions, setYasGrubunaGoreChartOptions] =
    useState(null);
  const [hemSehrilikDurumuData, setHemSehrilikDurumuData] = useState(null);
  const [secmenSiyasiDurumData, setSecmenSiyasiDurumData] = useState(null);
  const [internetData, setInternetData] = useState(null);

  async function fetchGenelData(type, city, district, neighbourhood) {
    setYasGrubunaGoreChartOptions(null);
    setHemSehrilikDurumuData(null);
    setSecmenSiyasiDurumData(null);
    setInternetData(null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type:
            type === "İNTERNET"
              ? "internet-kullanim-amaclari-2"
              : type === "YAS"
              ? "test-demografi"
              : `bireysel-data-customer-${context?.me?.customer?.id}`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column:
              type === "İNTERNET"
                ? [
                    {
                      key: "Parametre",
                      value: "select",
                    },
                    {
                      key: "Deger",
                      value: "sum",
                    },
                  ]
                : type === "SIYASI DURUMU"
                ? [
                    {
                      key: "Tahmini 1. Derece Ailede Uyelik",
                      value: "select",
                    },
                    {
                      key: "Tahmini 2. Derece Ailede Uyelik",
                      value: "select",
                    },
                    {
                      key: "TC",
                      value: "select",
                    },
                  ]
                : type === "YAS"
                ? [
                    {
                      key: "Parametre",
                      value: "select",
                    },
                    {
                      key: "Deger",
                      value: "sum",
                    },
                  ]
                : [
                    {
                      key: "Memleket Il",
                      value: "select",
                    },
                    {
                      key: "Memleket Ilce",
                      value: "select",
                    },
                    {
                      key: "Memleket_Il",
                      value: "count",
                    },
                    {
                      key: "Memleket_Ilce",
                      value: "count",
                    },
                  ],
            condition: [
              type === "YAS"
                ? {
                    key: "Alt-Kategori-1",
                    value: "YAS DAGILIMI",
                    operator: "=",
                    type: "and",
                  }
                : null,
              city
                ? {
                    key:
                      type === "İNTERNET" || type === "YAS"
                        ? "Sehir"
                        : "Adres Il",
                    value: context
                      .removeTurkishCharacters(city.label)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key:
                      type === "İNTERNET" || type === "YAS"
                        ? "Ilce"
                        : type === "YAS"
                        ? "Adres Ilcesi"
                        : "Adres Ilce",
                    value: context
                      .removeTurkishCharacters(district.label)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              neighbourhood
                ? {
                    key:
                      type === "İNTERNET" || type === "YAS"
                        ? "Mahalle"
                        : "Adres Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhood.label)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // SIYASI DURUMU
      // if (type === "SIYASI DURUMU") {
      //   if (response.length !== 0) {
      //     let uyelik1VarCount = 0;
      //     let uyelik2VarCount = 0;
      //     let uyelikOlmayanCount = 0;

      //     response.forEach((item) => {
      //       if (item["tahmini 1. derece ailede uyelik"] === "Var") {
      //         uyelik1VarCount++;
      //       }
      //       if (item["tahmini 2. derece ailede uyelik"] === "Var") {
      //         uyelik2VarCount++;
      //       }
      //       if (
      //         item["tahmini 1. derece ailede uyelik"] === "Yok" &&
      //         item["tahmini 2. derece ailede uyelik"] === "Yok"
      //       ) {
      //         uyelikOlmayanCount++;
      //       }
      //     });

      //     const totalCount = response.length;

      //     const uyelik1VarPercentage = (uyelik1VarCount / totalCount) * 100;
      //     const uyelik2VarPercentage = (uyelik2VarCount / totalCount) * 100;
      //     const uyelikOlmayanPercentage =
      //       (uyelikOlmayanCount / totalCount) * 100;

      //     setSecmenSiyasiDurumData([
      //       {
      //         value: uyelik1VarPercentage.toFixed(2),
      //         name: context?.t?.tahmini_1_derece_aile,
      //       },
      //       {
      //         value: uyelik2VarPercentage.toFixed(2),
      //         name: context?.t?.tahmini_2_derece_aile,
      //       },
      //       {
      //         value: uyelikOlmayanPercentage.toFixed(2),
      //         name: context?.t?.tahmini_uye_yakini_olmayan,
      //       },
      //     ]);

      //     if (setNeighborhoodVoterPoliticalData) {
      //       setNeighborhoodVoterPoliticalData([
      //         ["*", "*", "*"],
      //         [
      //           context?.t?.tahmini_1_derece_aile,
      //           context?.t?.tahmini_2_derece_aile,
      //           context?.t?.tahmini_uye_yakini_olmayan,
      //         ],
      //         [
      //           uyelik1VarPercentage.toFixed(2),
      //           uyelik2VarPercentage.toFixed(2),
      //           uyelikOlmayanPercentage.toFixed(2),
      //         ],
      //       ]);
      //     }
      //   } else {
      //     setSecmenSiyasiDurumData([]);
      //     if (setNeighborhoodVoterPoliticalData) {
      //       setNeighborhoodVoterPoliticalData([[], [], []]);
      //     }
      //   }
      // }

      // İNTERNET
      // if (type === "İNTERNET") {
      //   if (response.length !== 0) {
      //     setInternetData(
      //       response.map((item) => {
      //         return {
      //           value: parseInt(item.deger_sum),
      //           name: item.parametre,
      //         };
      //       })
      //     );
      //   } else {
      //     setInternetData([]);
      //   }
      // }

      // HEM SEHRILIK
      if (type === "HEM SEHRILIK") {
        if (response.length !== 0) {
          const mappedData = response.map((item) => {
            const totalCount =
              parseInt(item.memleket_il_count) +
              parseInt(item.memleket_ilce_count);
            return {
              name: `${item["memleket il"]} - ${item["memleket ilce"]}`,
              value: totalCount,
            };
          });

          const sortedData = mappedData
            .sort((a, b) => b.value - a.value)
            .slice(0, 20);

          // const hemsehrilikDurumuMap = new Map();
          // const hemsehrilikDurumuOptions = [];

          // response.forEach((item) => {
          //   const value = item["memleket il"] + " - " + item["memleket ilce"];
          //   hemsehrilikDurumuMap.set(
          //     value,
          //     (hemsehrilikDurumuMap.get(value) || 0) + 1
          //   );
          // });

          // hemsehrilikDurumuMap.forEach((count, value) => {
          //   hemsehrilikDurumuOptions.push({
          //     value: count,
          //     name: value,
          //   });
          // });

          setHemSehrilikDurumuData(sortedData);

          if (setNeighborhoodCitizenshipData) {
            const transformedData = [["*", "*", "*"]];

            const headers = [
              context?.t?.city,
              context?.t?.district,
              context?.t?.integer,
            ];
            const rows = mappedData
              .sort((a, b) => b.value - a.value)
              .map((item) => {
                const [city, district] = item.name.split(" - ");
                return [city.trim(), district.trim(), item.value.toString()];
              });

            transformedData.push([headers, ...rows]);
            setNeighborhoodCitizenshipData(transformedData);
          }
        } else {
          setHemSehrilikDurumuData([]);

          if (setNeighborhoodCitizenshipData) {
            setNeighborhoodCitizenshipData([[], [[], []]]);
          }
        }
      }

      // YAS GRUBU
      if (type === "YAS") {
        if (response.length !== 0) {
          const sortedResponse = response.sort((a, b) => {
            const aStart = parseInt(a.parametre.split("-")[0].replace("+", ""));
            const bStart = parseInt(b.parametre.split("-")[0].replace("+", ""));
            return aStart - bStart;
          });

          let yasGrubunaGoreDataX = sortedResponse.map(function (obj) {
            return obj.parametre;
          });

          let yasGrubunaGoreDataY = sortedResponse.map(function (obj) {
            return obj.deger_sum;
          });

          setYasGrubunaGoreChartOptions([
            yasGrubunaGoreDataX,
            yasGrubunaGoreDataY,
          ]);

          if (setNeighborhoodAgeData) {
            const reportTableWidth = [];

            yasGrubunaGoreDataX.forEach((item) => {
              reportTableWidth.push("auto");
            });

            setNeighborhoodAgeData([
              reportTableWidth,
              yasGrubunaGoreDataX.map((item) => {
                return item + " " + context?.t?.yas_grubu;
              }),
              yasGrubunaGoreDataY,
            ]);
          }
        } else {
          setYasGrubunaGoreChartOptions([[], []]);
          if (setNeighborhoodAgeData) {
            setNeighborhoodAgeData([[], [], []]);
          }
        }
      }
    } catch (error) {
      console.error("Genel Data Error", error);
      setSecmenSiyasiDurumData(null);
      setInternetData(null);
      setYasGrubunaGoreChartOptions(null);
      setHemSehrilikDurumuData(null);
    }
  }

  useEffect(() => {
    // if (cityFilter && cityFilter !== "filter") {
    // fetchGenelData(
    //   "SIYASI DURUMU",
    //   cityFilter,
    //   districtFilter,
    //   neighbourhoodFilter
    // );
    // fetchGenelData("İNTERNET", cityFilter, districtFilter, neighbourhoodFilter);
    fetchGenelData(
      "HEM SEHRILIK",
      cityFilter,
      districtFilter,
      neighbourhoodFilter
    );
    fetchGenelData("YAS", cityFilter, districtFilter, neighbourhoodFilter);
    // }
  }, [cityFilter, districtFilter, neighbourhoodFilter, context?.chartColor]);

  useEffect(() => {
    // if (city) {
    fetchGenelData("YAS", city, district, neighbourhood);
    // }
  }, [setNeighborhoodAgeData, city, district, neighbourhood]);

  useEffect(() => {
    // if (city) {
    // fetchGenelData("SIYASI DURUMU", city, district, neighbourhood);
    // }
  }, [setNeighborhoodVoterPoliticalData, city, district, neighbourhood]);

  useEffect(() => {
    // if (city) {
    fetchGenelData("HEM SEHRILIK", city, district, neighbourhood);
    // }
  }, [setNeighborhoodCitizenshipData, city, district, neighbourhood]);

  // Get Age Data
  const [ageChartLoad, setAgeChartLoad] = useState(true);

  const [aileVeCocuk, setAileVeCocuk] = useState(0);
  const [dogalHayat, setDogalHayat] = useState(0);
  const [egitim, setEgitim] = useState(0);
  const [etkinlik, setEtkinlik] = useState(0);
  const [kulturVeSanat, setKulturVeSanat] = useState(0);
  const [gecim, setGecim] = useState(0);
  const [spor, setSpor] = useState(0);

  const [hayatTarzi, setHayatTarzi] = useState(0);
  const [guvenlik, setGuvenlik] = useState(0);
  const [ulasim, setUlasim] = useState(0);
  const [altyapi, setAltyapi] = useState(0);
  const [hijyenVeSaglik, setHijyenVeSaglik] = useState(0);
  const [ustyapi, setUstyapi] = useState(0);

  const [yuksekLisans, setYuksekLisans] = useState(0);
  const [doktoraMezunu, setDoktoraMezunu] = useState(0);
  const [yuksekOkulFakulte, setYuksekOkulFakulte] = useState(0);

  // async function getAgeData() {
  //   setAgeChartLoad(true);

  //   try {
  //     const response = await apiClient.customPost(
  //       `/query/preview`,
  //       {
  //         chart: 1,
  //         page_id: 23,
  //         title: "-",
  //         type: `hane-data-customer-${context?.me?.customer?.id}`,
  //         description: "",
  //         customer: null,
  //         status: true,
  //         order: 1,
  //         sql: {
  //           column: [
  //             {
  //               key: "(0-4) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(5-6) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(5-9) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(10-14) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(15-19) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(18-24) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(20-24) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(25-29) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(30-34) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(35-39) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(40-44) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(45-49) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(50-54) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(55-59) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(60-64) Yas Arasi",
  //               value: "sum",
  //             },
  //             {
  //               key: "(+65) Yas",
  //               value: "sum",
  //             },
  //           ],
  //           condition: [
  //             cityFilter
  //               ? {
  //                   key: "Adres Il",
  //                   value: context
  //                     .removeTurkishCharacters(cityFilter.label)
  //                     .toUpperCase(),
  //                   operator: "like",
  //                   type: "and",
  //                 }
  //               : null,
  //             districtFilter
  //               ? {
  //                   key: "Adres Ilcesi",
  //                   value: context
  //                     .removeTurkishCharacters(districtFilter.label)
  //                     .toUpperCase(),
  //                   operator: "like",
  //                   type: "and",
  //                 }
  //               : null,
  //             neighbourhoodFilter
  //               ? {
  //                   key: "Adres Mahallesi",
  //                   value: context
  //                     .removeTurkishCharacters(neighbourhoodFilter.label)
  //                     .toUpperCase(),
  //                   operator: "like",
  //                   type: "and",
  //                 }
  //               : null,
  //           ].filter((item) => item !== null),
  //           group: [],
  //         },
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );

  //     if (response) {
  //       const data = response[0];

  //       setAileVeCocuk(
  //         parseInt(data["(0-4) yas arasi_sum"]) +
  //           parseInt(data["(5-6) yas arasi_sum"])
  //       );
  //       setDogalHayat(
  //         parseInt(data["(5-6) yas arasi_sum"]) +
  //           parseInt(data["(5-9) yas arasi_sum"]) +
  //           parseInt(data["(10-14) yas arasi_sum"])
  //       );
  //       setEgitim(
  //         parseInt(data["(10-14) yas arasi_sum"]) +
  //           parseInt(data["(15-19) yas arasi_sum"]) +
  //           parseInt(data["(20-24) yas arasi_sum"])
  //       );
  //       setEtkinlik(
  //         parseInt(data["(15-19) yas arasi_sum"]) +
  //           parseInt(data["(18-24) yas arasi_sum"]) +
  //           parseInt(data["(20-24) yas arasi_sum"]) +
  //           parseInt(data["(25-29) yas arasi_sum"]) +
  //           parseInt(data["(55-59) yas arasi_sum"]) +
  //           parseInt(data["(60-64) yas arasi_sum"])
  //       );
  //       setKulturVeSanat(
  //         parseInt(data["(45-49) yas arasi_sum"]) +
  //           parseInt(data["(50-54) yas arasi_sum"]) +
  //           yuksekLisans +
  //           doktoraMezunu +
  //           yuksekOkulFakulte
  //       );
  //       setGecim(
  //         parseInt(data["(55-59) yas arasi_sum"]) +
  //           parseInt(data["(60-64) yas arasi_sum"]) +
  //           parseInt(data["(+65) yas_sum"])
  //       );
  //       setSpor(
  //         parseInt(data["(5-6) yas arasi_sum"]) +
  //           parseInt(data["(5-9) yas arasi_sum"]) +
  //           parseInt(data["(+65) yas_sum"])
  //       );

  //       //--

  //       setHayatTarzi(
  //         parseInt(data["(+65) yas_sum"]) +
  //           yuksekLisans +
  //           doktoraMezunu +
  //           yuksekOkulFakulte
  //       );
  //       setGuvenlik(
  //         parseInt(data["(15-19) yas arasi_sum"]) +
  //           parseInt(data["(+65) yas_sum"])
  //       );
  //       setUlasim(
  //         parseInt(data["(20-24) yas arasi_sum"]) +
  //           parseInt(data["(25-29) yas arasi_sum"]) +
  //           parseInt(data["(30-34) yas arasi_sum"]) +
  //           parseInt(data["(35-39) yas arasi_sum"]) +
  //           parseInt(data["(40-44) yas arasi_sum"])
  //       );
  //       setAltyapi(
  //         parseInt(data["(0-4) yas arasi_sum"]) +
  //           parseInt(data["(5-6) yas arasi_sum"]) +
  //           parseInt(data["(30-34) yas arasi_sum"]) +
  //           parseInt(data["(35-39) yas arasi_sum"]) +
  //           parseInt(data["(40-44) yas arasi_sum"])
  //       );
  //       setHijyenVeSaglik(
  //         parseInt(data["(0-4) yas arasi_sum"]) +
  //           parseInt(data["(+65) yas_sum"])
  //       );
  //       setUstyapi(
  //         parseInt(data["(0-4) yas arasi_sum"]) +
  //           parseInt(data["(5-6) yas arasi_sum"]) +
  //           parseInt(data["(+65) yas_sum"])
  //       );
  //       setAgeChartLoad(false);
  //     }
  //   } catch (error) {
  //     console.error("Age Error", error);
  //   }
  // }

  async function getEducationData() {
    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type: "2022-2023-üniversite-ve-öğrenci-sayıları",
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "Alt Kategori 3",
                value: "select",
              },
              {
                key: "DEĞERİ",
                value: "sum",
              },
            ],
            condition: [
              cityFilter
                ? {
                    key: "SehIr",
                    value: context
                      .removeTurkishCharacters(cityFilter.label)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              districtFilter
                ? {
                    key: "IlCe",
                    value: context
                      .removeTurkishCharacters(districtFilter.label)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              neighbourhoodFilter
                ? {
                    key: "Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhoodFilter.label)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.length !== 0) {
        response.forEach((item) => {
          if (item["alt kategori 3"] === "YÜKSEK LİSANS") {
            setYuksekLisans(item["değeri̇_sum"]);
          }

          if (item["alt kategori 3"] === "DOKTORA") {
            setDoktoraMezunu(item["değeri̇_sum"]);
          }

          if (item["alt kategori 3"] === "ÖNLİSANS") {
            setYuksekOkulFakulte(item["değeri̇_sum"]);
          }
        });
      }
    } catch (error) {
      console.error("Age Error", error);
    }
  }

  // useEffect(() => {
  //   if (cityFilter && cityFilter !== "filter") {
  //     getAgeData();
  //   }
  // }, [
  //   cityFilter,
  //   districtFilter,
  //   neighbourhoodFilter,
  //   yuksekLisans,
  //   doktoraMezunu,
  //   yuksekOkulFakulte,
  // ]);

  useEffect(() => {
    if (cityFilter && cityFilter !== "filter") {
      getEducationData();
    }
  }, [cityFilter, districtFilter, neighbourhoodFilter]);

  return loading ? (
    <PageLoader loading={loading} setLoading={setLoading} />
  ) : (
    show && (
      <div className="row g-4 mb-4 zoom-item">
        <PageFilter
          cityFilter={cityFilter}
          districtFilter={districtFilter}
          neighbourhoodFilter={neighbourhoodFilter}
          setCityFilter={setCityFilter}
          setDistrictFilter={setDistrictFilter}
          setNeighbourhoodFilter={setNeighbourhoodFilter}
          street={false}
          allActive={true}
        />

        {/* <div className="col-12">
        <Card>
          <CardBody>
            <div className="welcome-container">
              <h1>
                Hoşgeldin,{" "}
                <span> {JSON.parse(localStorage.getItem("user")).name}</span>
                😊
              </h1>
              <p className="text-white opacity-60">
                Solda bulunan menüden istediğin sayfayı ve grafiği oluşturup
                kendi yapını oluşturabilirsin.
              </p>
            </div>
          </CardBody>
        </Card>
      </div> */}

        <div className="col-12" style={{ height: "calc(100vh - 200px)" }}>
          <div className="row g-4 h-100">
            {/* <div className="col-lg-2 col-xxxl-2 map-fullscreen-hide">
              <div
                className="row g-4 flex-column"
                style={{
                  height: "calc(100% - -24px)",
                }}
              >
                <CardBinaHane
                  city={cityFilter?.label}
                  district={districtFilter?.label}
                  neighbourhood={neighbourhoodFilter?.label}
                  graphic={1}
                />
                <CardMedeniDurum
                  title="SOKAK BINA VE HANE ANALIZLERI"
                  path="choice-analysis"
                  city={cityFilter?.label}
                  district={districtFilter?.label}
                  neighbourhood={neighbourhoodFilter?.label}
                  graphic={2}
                  type={2}
                />
                <CardVatandasSayisi
                  city={cityFilter?.label}
                  district={districtFilter?.label}
                  neighbourhood={neighbourhoodFilter?.label}
                  graphic={3}
                />
              </div>
            </div> */}

            <div className="col-12 h-100">
              <div className="row flex-column h-100 g-4 position-relative">
                {/* <>
                  <div
                    className="col-3 px-0 map-fullscreen-hide"
                    style={{
                      position: "absolute",
                      left: 26,
                      bottom: -8,
                      zIndex: 99,
                    }}
                  >
                    {!ageChartLoad && (
                      <div
                        style={{
                          height: 300,
                          background: "#0e2127",
                        }}
                      >
                        <ChartPie
                          id={1123}
                          title="Seçmen Öncelikli Hizmet Teması Grafiği"
                          chartType="pie-full"
                          card={true}
                          table={false}
                          legend={false}
                          note={false}
                          fullScreen={false}
                          optionData={[
                            {
                              name: "Aile ve Çocuk",
                              value: (
                                (aileVeCocuk /
                                  (aileVeCocuk +
                                    dogalHayat +
                                    egitim +
                                    etkinlik +
                                    kulturVeSanat +
                                    gecim +
                                    spor)) *
                                100
                              ).toFixed(2),
                            },
                            {
                              name: "Doğal Hayat",
                              value: (
                                (dogalHayat /
                                  (aileVeCocuk +
                                    dogalHayat +
                                    egitim +
                                    etkinlik +
                                    kulturVeSanat +
                                    gecim +
                                    spor)) *
                                100
                              ).toFixed(2),
                            },
                            {
                              name: "Eğitim",
                              value: (
                                (egitim /
                                  (aileVeCocuk +
                                    dogalHayat +
                                    egitim +
                                    etkinlik +
                                    kulturVeSanat +
                                    gecim +
                                    spor)) *
                                100
                              ).toFixed(2),
                            },
                            {
                              name: "Etkinlik",
                              value: (
                                (etkinlik /
                                  (aileVeCocuk +
                                    dogalHayat +
                                    egitim +
                                    etkinlik +
                                    kulturVeSanat +
                                    gecim +
                                    spor)) *
                                100
                              ).toFixed(2),
                            },
                            {
                              name: "Kültür ve Sanat",
                              value: (
                                (kulturVeSanat /
                                  (aileVeCocuk +
                                    dogalHayat +
                                    egitim +
                                    etkinlik +
                                    kulturVeSanat +
                                    gecim +
                                    spor)) *
                                100
                              ).toFixed(2),
                            },
                            {
                              name: "Geçim",
                              value: (
                                (gecim /
                                  (aileVeCocuk +
                                    dogalHayat +
                                    egitim +
                                    etkinlik +
                                    kulturVeSanat +
                                    gecim +
                                    spor)) *
                                100
                              ).toFixed(2),
                            },
                            {
                              name: "Spor",
                              value: (
                                (spor /
                                  (aileVeCocuk +
                                    dogalHayat +
                                    egitim +
                                    etkinlik +
                                    kulturVeSanat +
                                    gecim +
                                    spor)) *
                                100
                              ).toFixed(2),
                            },
                          ]}
                        />
                      </div>
                    )}
                  </div>

                  <div
                    className="col-3 px-0 map-fullscreen-hide"
                    style={{
                      position: "absolute",
                      right: 26,
                      bottom: -8,
                      zIndex: 99,
                    }}
                  >
                    {!ageChartLoad && (
                      <div
                        style={{
                          height: 300,
                          background: "#0e2127",
                        }}
                      >
                        <ChartPie
                          id={1124}
                          title="Seçmen Öncelikli Şikayet Teması Grafiği"
                          chartType="pie-full"
                          card={true}
                          legend={false}
                          fullScreen={false}
                          table={false}
                          note={false}
                          optionData={[
                            {
                              name: "Hayat Tarzı",
                              value: (
                                (hayatTarzi /
                                  (hayatTarzi +
                                    guvenlik +
                                    ulasim +
                                    altyapi +
                                    hijyenVeSaglik +
                                    ustyapi)) *
                                100
                              ).toFixed(2),
                            },
                            {
                              name: "Güvenlik",
                              value: (
                                (guvenlik /
                                  (hayatTarzi +
                                    guvenlik +
                                    ulasim +
                                    altyapi +
                                    hijyenVeSaglik +
                                    ustyapi)) *
                                100
                              ).toFixed(2),
                            },
                            {
                              name: "Ulaşım",
                              value: (
                                (ulasim /
                                  (hayatTarzi +
                                    guvenlik +
                                    ulasim +
                                    altyapi +
                                    hijyenVeSaglik +
                                    ustyapi)) *
                                100
                              ).toFixed(2),
                            },
                            {
                              name: "Altyapı",
                              value: (
                                (altyapi /
                                  (hayatTarzi +
                                    guvenlik +
                                    ulasim +
                                    altyapi +
                                    hijyenVeSaglik +
                                    ustyapi)) *
                                100
                              ).toFixed(2),
                            },
                            {
                              name: "Hijyen ve Sağlık",
                              value: (
                                (hijyenVeSaglik /
                                  (hayatTarzi +
                                    guvenlik +
                                    ulasim +
                                    altyapi +
                                    hijyenVeSaglik +
                                    ustyapi)) *
                                100
                              ).toFixed(2),
                            },
                            {
                              name: "Üstyapı",
                              value: (
                                (ustyapi /
                                  (hayatTarzi +
                                    guvenlik +
                                    ulasim +
                                    altyapi +
                                    hijyenVeSaglik +
                                    ustyapi)) *
                                100
                              ).toFixed(2),
                            },
                          ]}
                        />
                      </div>
                    )}
                  </div>
                </> */}

                <div className="col md-mb-n4">
                  <KentmetreMap
                    tab={true}
                    setCityFilter={setCityFilter}
                    setDistrictFilter={setDistrictFilter}
                    setNeighbourhoodFilter={setNeighbourhoodFilter}
                  />
                </div>
              </div>
            </div>

            {/* <div
              className="col-lg-2 col-xxxl-2 md-mb-n4 map-fullscreen-hide"
              style={{
                height: "calc(100% - -24px)",
              }}
            >
              <div
                className="row g-4 flex-column md-h-100"
                style={{ zIndex: 1 }}
              >
                <CardOlumSayisi
                  city={cityFilter?.label}
                  district={districtFilter?.label}
                  neighbourhood={neighbourhoodFilter?.label}
                  graphic={4}
                />
                <CardHemsehrilikGoc
                  city={cityFilter?.label}
                  district={districtFilter?.label}
                  neighbourhood={neighbourhoodFilter?.label}
                  graphic={5}
                />
                <CardSosyoekonomik
                  title="Sosyoekonomik Statü Dağılımı"
                  city={cityFilter?.label}
                  district={districtFilter?.label}
                  neighbourhood={neighbourhoodFilter?.label}
                  graphic={6}
                />
              </div>
            </div> */}
          </div>
        </div>

        <div className="col-12 map-fullscreen-hide">
          <div className="row g-4">
            <div className="col-12">
              <div className="row g-4">
                <div className="col-12 col-xl-4">
                  <div
                    className="row flex-column g-4"
                    style={{ height: "calc(600px + 25px)" }}
                  >
                    <div className="flex-1 overflow-hidden">
                      <DynamicCard
                        height="h-full"
                        fullScreen={true}
                        cityFilter={cityFilter}
                        data={
                          yasGrubunaGoreChartOptions &&
                          yasGrubunaGoreChartOptions[0]
                        }
                        item={{
                          title: context?.t?.yas_grubuna_gore_birey_sayilari,
                          options: {
                            options: {
                              grid: {
                                bottom: "10%",
                              },
                              xAxis: [
                                {
                                  data:
                                    yasGrubunaGoreChartOptions &&
                                    yasGrubunaGoreChartOptions[0],
                                  type: "category",
                                  axisLabel: {
                                    rotate: 45,
                                    textStyle: {
                                      color: "#fff",
                                      fontSize: 10,
                                    },
                                  },
                                },
                              ],
                              yAxis: [
                                {
                                  type: "value",
                                  axisLabel: {
                                    textStyle: {
                                      color: "#fff",
                                    },
                                  },
                                },
                              ],
                              series: [
                                {
                                  data:
                                    yasGrubunaGoreChartOptions &&
                                    yasGrubunaGoreChartOptions[1],
                                  name: null,
                                  type: "bar",
                                  itemStyle: {
                                    color: context?.chartColor,
                                  },
                                  markPoint: {
                                    data: [
                                      {
                                        name: "Max",
                                        type: "max",
                                      },
                                      {
                                        name: "Min",
                                        type: "min",
                                      },
                                    ],
                                  },
                                },
                              ],
                              toolbox: {
                                show: true,
                                feature: {
                                  restore: {
                                    show: false,
                                  },
                                  magicType: {
                                    show: true,
                                    type: ["line", "bar"],
                                  },
                                  saveAsImage: {
                                    show: false,
                                  },
                                },
                                iconStyle: {
                                  borderColor: "#fff",
                                },
                              },
                              tooltip: {
                                trigger: "axis",
                              },
                              darkMode: true,
                              visualMap: context?.chartColor
                                ? {
                                    max:
                                      yasGrubunaGoreChartOptions &&
                                      yasGrubunaGoreChartOptions[1]
                                        ? Math.max(
                                            ...yasGrubunaGoreChartOptions[1].map(
                                              (item) => item
                                            )
                                          )
                                        : 0,
                                    min: 0,
                                    show: false,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  }
                                : false,
                              calculable: true,
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-4" style={{ height: 600 }}>
                  <div className="row h-100 g-4">
                    <div className="col-12 h-100">
                      <DynamicCard
                        fullScreen={true}
                        height="h-full"
                        events={false}
                        cityFilter={cityFilter}
                        data={hemSehrilikDurumuData}
                        item={{
                          title: context?.t?.birey_hemsehrilik_durumu,
                          options: {
                            options: {
                              series: [
                                {
                                  data: hemSehrilikDurumuData,
                                  name: null,
                                  type: "pie",
                                  label: {
                                    textStyle: {
                                      fontSize: 15,
                                    },
                                  },
                                  radius: "50%",
                                  itemStyle: {
                                    color: context?.chartColor,
                                  },
                                },
                              ],
                              tooltip: {
                                trigger: "item",
                              },
                              darkMode: true,
                              visualMap: context?.chartColor
                                ? {
                                    min:
                                      hemSehrilikDurumuData &&
                                      hemSehrilikDurumuData.length !== 0
                                        ? Math.min(
                                            ...hemSehrilikDurumuData.map(
                                              (obj) => obj.value
                                            )
                                          )
                                        : 0,
                                    max:
                                      hemSehrilikDurumuData &&
                                      hemSehrilikDurumuData.length !== 0
                                        ? Math.max(
                                            ...hemSehrilikDurumuData.map(
                                              (obj) => obj.value
                                            )
                                          )
                                        : 0,
                                    show: false,
                                    inRange: {
                                      colorLightness: [0.2, 1],
                                    },
                                  }
                                : false,
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-4" style={{ height: 600 }}>
                  <div className="row h-100 g-4">
                    <div className="col-12 h-100">
                      <DynamicCard
                        fullScreen={true}
                        height="h-full"
                        events={false}
                        item={{
                          title:
                            context?.t
                              ?.yillara_gore_secmen_medeni_durum_dagilimi,
                        }}
                        chart={
                          <ChartBarTimeLine
                            type="medeni-durum"
                            id={13}
                            title={
                              context?.t?.yillara_gore_dogum_ve_olum_oranlari
                            }
                            city={cityFilter?.label}
                            district={districtFilter?.label}
                            neighbourhood={neighbourhoodFilter?.label}
                            graphic={12}
                            card={false}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="row g-4">
                <div className="col-12 col-xl-4" style={{ height: 600 }}>
                  <DynamicCard
                    fullScreen={true}
                    events={false}
                    height="h-full"
                    item={{
                      title:
                        context?.t?.yillara_gore_sosyoekonomik_statu_dagilimi,
                    }}
                    chart={
                      <ChartBarTimeLine
                        type="ses_raporu"
                        id={13}
                        title={context?.t?.yillara_gore_dogum_ve_olum_oranlari}
                        city={cityFilter?.label}
                        district={districtFilter?.label}
                        neighbourhood={neighbourhoodFilter?.label}
                        graphic={12}
                        card={false}
                      />
                    }
                  />
                </div>

                <div className="col-12 col-xl-4" style={{ height: 600 }}>
                  <DynamicCard
                    fullScreen={true}
                    events={false}
                    height="h-full"
                    item={{
                      title: context?.t?.secmen_genel_egitim_durumu,
                    }}
                    chart={
                      <ChartBarTimeLine
                        type="2023-egitim"
                        id={12}
                        title={context?.t?.secmen_genel_egitim_durumu}
                        city={cityFilter?.label}
                        district={districtFilter?.label}
                        neighbourhood={neighbourhoodFilter?.label}
                        path="voter-analysis"
                        graphic={3}
                        card={false}
                      />
                    }
                  />
                </div>

                <div className="col-12 col-xl-4" style={{ height: 600 }}>
                  <DynamicCard
                    fullScreen={true}
                    height="h-full"
                    events={false}
                    item={{
                      title: context?.t?.yillara_gore_dogum_ve_olum_oranlari,
                    }}
                    chart={
                      <ChartLineMultiple
                        id={13}
                        title={context?.t?.yillara_gore_dogum_ve_olum_oranlari}
                        city={cityFilter?.label}
                        district={districtFilter?.label}
                        neighbourhood={neighbourhoodFilter?.label}
                        graphic={12}
                        card={false}
                      />
                    }
                  />
                </div>

                {/* <div className="col-12 col-lg-6">
              <DynamicCard
                fullScreen={true}
                height={600}
                cityFilter={cityFilter}
                data={internetData}
                item={{
                  title: "BÖLGE ÜZERINDE İNTERNET ANA KULLANIM ARAŞTIRMASI",
                  options: {
                    options: {
                      series: [
                        {
                          data: internetData,
                          name: null,
                          type: "pie",
                          label: {
                            textStyle: {
                              fontSize: 15,
                            },
                          },
                          radius: "50%",
                          itemStyle: {
                            color: context?.chartColor,
                          },
                        },
                      ],
                      tooltip: {
                        trigger: "item",
                      },
                      darkMode: true,
                      legend: {
                        show: true,
                        orient: "horizontal",
                        left: "center",
                        itemGap: 20,
                        textStyle: {
                          color: "#fff",
                        },
                      },
                      visualMap: {
                        min:
                          internetData && internetData.length !== 0
                            ? Math.min(...internetData.map((obj) => obj.value))
                            : 0,
                        max:
                          internetData && internetData.length !== 0
                            ? Math.max(...internetData.map((obj) => obj.value))
                            : 0,
                        show: false,
                        inRange: {
                          colorLightness: [0.2, 1],
                        },
                      },
                    },
                  },
                }}
              />
            </div>

            <div className="col-12 col-lg-6">
              <DynamicCard
                fullScreen={true}
                height={600}
                cityFilter={cityFilter}
                data={secmenSiyasiDurumData}
                item={{
                  title: "SECMEN SIYASI DURUMU ANALIZI",
                  options: {
                    options: {
                      series: [
                        {
                          data: secmenSiyasiDurumData,
                          name: null,
                          type: "pie",
                          label: {
                            textStyle: {
                              fontSize: 15,
                            },
                          },
                          radius: "50%",
                          itemStyle: {
                            color: context?.chartColor,
                          },
                        },
                      ],
                      tooltip: {
                        trigger: "item",
                        formatter: function (params) {
                          return (
                            params.data.name + "<br /> %" + params.data.value
                          );
                        },
                      },
                      darkMode: true,
                      legend: {
                        show: true,
                        orient: "horizontal",
                        left: "center",
                        itemGap: 20,
                        textStyle: {
                          color: "#fff",
                        },
                      },
                      visualMap: {
                        min:
                          secmenSiyasiDurumData &&
                          secmenSiyasiDurumData.length !== 0
                            ? Math.min(
                                ...secmenSiyasiDurumData.map((obj) => obj.value)
                              )
                            : 0,
                        max:
                          secmenSiyasiDurumData &&
                          secmenSiyasiDurumData.length !== 0
                            ? Math.max(
                                ...secmenSiyasiDurumData.map((obj) => obj.value)
                              )
                            : 0,
                        show: false,
                        inRange: {
                          colorLightness: [0.2, 1],
                        },
                      },
                    },
                  },
                }}
              />
            </div> */}
              </div>
            </div>

            {/* <div className="col-12" style={{ height: "100vh" }}>
        <DynamicCard
          fullScreen={true}
          height="h-full"
          item={{
            title: "SEÇİM / PARTİ OY DEĞİŞİM SÜRECİ",
          }}
          chart={
            <EchartRace
              id={16}
              title="SEÇİM / PARTİ OY DEĞİŞİM SÜRECİ"
              path="choice-analysis"
              city={cityFilter?.label}
              district={districtFilter?.label}
              neighbourhood={neighbourhoodFilter?.label}
              graphic={13}
            />
          }
        />
      </div> */}

            <div className="col-12">
              {cityFilter && districtFilter ? (
                <div className="row g-4">
                  <div
                    className="col-12 col-xxxl-6"
                    style={{ height: "100vh" }}
                  >
                    <DynamicCard
                      fullScreen={true}
                      height="h-full"
                      item={{
                        title: context?.t?.ilce_bazinda_alinan_goc,
                      }}
                      events={false}
                      chart={
                        <ChartSankeyReverse
                          city={cityFilter.label}
                          district={districtFilter}
                          graphic={1}
                        />
                      }
                    />
                  </div>
                  <div
                    className="col-12 col-xxxl-6"
                    style={{ height: "100vh" }}
                  >
                    <DynamicCard
                      fullScreen={true}
                      height="h-full"
                      item={{
                        title: context?.t?.ilce_bazinda_verilen_goc,
                      }}
                      events={false}
                      chart={
                        <ChartSankey
                          city={cityFilter.label}
                          district={districtFilter}
                          graphic={2}
                        />
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="row" style={{ height: 500 }}>
                  <div className="col-6 h-100">
                    <Card className="h-100">
                      <CardBody>
                        <div className="d-flex flex-column h-100">
                          <h6 className="border-bottom pb-3 mb-3">
                            {context?.t?.ilce_bazinda_alinan_goc}
                          </h6>

                          <span className="flex-1 d-flex align-items-center justify-content-center text-center">
                            {context?.t?.il_ve_ilce_secmelisiniz}
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-6 h-100">
                    <Card className="h-100">
                      <CardBody>
                        <div className="d-flex flex-column h-100">
                          <h6 className="border-bottom pb-3 mb-3">
                            {context?.t?.ilce_bazinda_verilen_goc}
                          </h6>

                          <span className="flex-1 d-flex align-items-center justify-content-center text-center">
                            {context?.t?.il_ve_ilce_secmelisiniz}
                          </span>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              )}
            </div>

            <div className="col-12">
              <h3 className="text-center mt-4 pt-4">
                {context?.t?.universite_ve_universite_ogrenci_sayilari}
              </h3>
            </div>

            <div className="col-12">
              <div className="row g-4 mb-5">
                <DinamikPage
                  removeGrid={true}
                  lastItem={6}
                  height={600}
                  filterPath={`${
                    cityFilter ? `?where[]=SehIr:${cityFilter.label}` : ""
                  }${
                    districtFilter
                      ? `${cityFilter ? "&" : "?"}where[]=IlCe:${
                          districtFilter.label
                        }`
                      : ""
                  }`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
