import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PageFilter from "../../components/PageFilter";
import apiClient from "../../ApiClient";
import DatePicker, { registerLocale } from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";
import { AppSettings } from "../../config/app-settings";
import toastMessage from "../../components/ToastMessage";
import bireyselJson from "../../json/bireysel.json";
import { CSVLink } from "react-csv";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import AddReport from "../strateji-haritasi/addReport";
import { Card, CardBody } from "../../components/card/card";

import tr from "date-fns/locale/tr";
import PersonsSurveyTable from "./PersonsSurveyTable";
registerLocale("tr", tr);

const $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs5");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.colVis.min.js");
require("datatables.net-buttons/js/buttons.flash.min.js");
require("datatables.net-buttons/js/buttons.html5.min.js");
require("datatables.net-buttons/js/buttons.print.min.js");
require("datatables.net-buttons-bs5");
require("datatables.net-responsive");
require("datatables.net-responsive-bs5");
require("datatables.net-fixedcolumns");
require("datatables.net-fixedcolumns-bs5");

export default function IletisimYonetimi() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [hoveredTableRow, setHoveredTableRow] = useState(null);
  const [hoveredFilterTableRow, setHoveredFilterTableRow] = useState(null);

  // Get BirthDay
  const [tableHead, setTableHead] = useState([
    { key: "Id", label: "#" },
    { key: "Islemler", label: "İşlemler" },
    { key: "TC", label: "TC" },
    { key: "Ad_Soyad", label: "İsim Soyisim", width: 200 },
    { key: "cinsiyet", label: "Cinsiyet", width: 70 },
    { key: "Dogum_Tarihi", label: "Doğum Tarihi" },
    // { key: "Secmen_Yasi", label: "Yaş Grubu" },
    { key: "Yas", label: "Yaş" },
    { key: "Telefon_NO", label: "Telefon" },
    // { key: "Secmen_Profili", label: "Seçmen Profili" },
    { key: "Memleket_Il", label: "Memleket İl" },
    { key: "Memleket_Ilce", label: "Memleket İlçe" },
    { key: "Adres_Il", label: "İl" },
    { key: "Adres_Ilce", label: "İlçe" },
    { key: "Adres_Mahalle", label: "Mahalle", width: 200 },
    { key: "Adres_Sokak", label: "Sokak", width: 200 },
    { key: "building", label: "Bina No" },
    { key: "independent_section", label: "Daire" },
    { key: "aciklama_1", label: "Açıklama 1" },
    { key: "aciklama_2", label: "Açıklama 2" },
    { key: "aciklama_3", label: "Açıklama 3" },
    { key: "aciklama_3", label: "Açıklama 4" },
    { key: "aciklama_3", label: "Açıklama 5" },
    { key: "aciklama_3", label: "Açıklama 6" },
    { key: "aciklama_3", label: "Açıklama 7" },
    { key: "aciklama_3", label: "Açıklama 8" },
    { key: "aciklama_3", label: "Açıklama 9" },
    { key: "aciklama_3", label: "Açıklama 10" },
    { key: "aciklama_3", label: "Açıklama 11" },
    { key: "aciklama_3", label: "Açıklama 12" },
    { key: "aciklama_3", label: "Açıklama 13" },
    { key: "aciklama_3", label: "Açıklama 14" },
    { key: "aciklama_3", label: "Açıklama 15" },
    // {
    //   key: "Tahmini_1_Derece_Ailede_Uyelik",
    //   label: "Tahmini Ailede 1. Derece Üye",
    // },
    // {
    //   key: "Tahmini_2_Derece_Ailede_Uyelik",
    //   label: "Tahmini Ailede 2. Derece Üye",
    // },
    // { key: "Sandik_Sonucu_1", label: "1. Sandık Seçim Sonucu" },
    // { key: "Sandik_Sonucu_2", label: "2. Sandık Seçim Sonucu" },
    // { key: "Sandik_Sonucu_3", label: "3. Sandık Seçim Sonucu" },
    // { key: "Mahalle_Sonucu_1", label: "1. Mahalle Seçim Sonucu" },
    // { key: "Mahalle_Sonucu_2", label: "2. Mahalle Seçim Sonucu" },
    // { key: "Mahalle_Sonucu_3", label: "3. Mahalle Seçim Sonucu" },
    // { key: "Memleket_Sonucu_1", label: "1. Memleket Seçim Sonucu" },
    // { key: "Memleket_Sonucu_2", label: "2. Memleket Seçim Sonucu" },
    // { key: "Memleket_Sonucu_3", label: "3. Memleket Seçim Sonucu" },
  ]);
  const [months, setMonths] = useState([
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ]);

  useEffect(() => {
    setTableHead([
      { key: "Id", label: "#" },
      { key: "Islemler", label: context?.t?.transactions },
      { key: "TC", label: context?.t?.tc },
      { key: "Ad_Soyad", label: context?.t?.isim_soyisim, width: 200 },
      { key: "cinsiyet", label: context?.t?.gender, width: 70 },
      { key: "Dogum_Tarihi", label: context?.t?.dogum_tarihi },
      { key: "Yas", label: context?.t?.age },
      { key: "Telefon_NO", label: context?.t?.phone },
      { key: "Memleket_Il", label: context?.t?.memleket_il },
      { key: "Memleket_Ilce", label: context?.t?.memleket_ilce },
      { key: "Adres_Il", label: context?.t?.city },
      { key: "Adres_Ilce", label: context?.t?.district },
      { key: "Adres_Mahalle", label: context?.t?.neighborhood, width: 200 },
      { key: "Adres_Sokak", label: context?.t?.street, width: 200 },
      { key: "building", label: context?.t?.building },
      { key: "independent_section", label: context?.t?.apartment },
      { key: "aciklama_1", label: context?.t?.description + " 1" },
      { key: "aciklama_2", label: context?.t?.description + " 2" },
      { key: "aciklama_3", label: context?.t?.description + " 3" },
      { key: "aciklama_3", label: context?.t?.description + " 4" },
      { key: "aciklama_3", label: context?.t?.description + " 5" },
      { key: "aciklama_3", label: context?.t?.description + " 6" },
      { key: "aciklama_3", label: context?.t?.description + " 7" },
      { key: "aciklama_3", label: context?.t?.description + " 8" },
      { key: "aciklama_3", label: context?.t?.description + " 9" },
      { key: "aciklama_3", label: context?.t?.description + " 10" },
      { key: "aciklama_3", label: context?.t?.description + " 11" },
      { key: "aciklama_3", label: context?.t?.description + " 12" },
      { key: "aciklama_3", label: context?.t?.description + " 13" },
      { key: "aciklama_3", label: context?.t?.description + " 14" },
      { key: "aciklama_3", label: context?.t?.description + " 15" },
    ]);
    setMonths([
      context?.t?.ocak,
      context?.t?.subat,
      context?.t?.mart,
      context?.t?.nisan,
      context?.t?.mayis,
      context?.t?.haziran,
      context?.t?.temmuz,
      context?.t?.agustos,
      context?.t?.eylul,
      context?.t?.ekim,
      context?.t?.kasim,
      context?.t?.aralik,
    ]);
  }, [context, context?.t]);

  function formatDate(dateStr) {
    const [year, month, day] = dateStr.split("-").map(Number);
    return `${day} ${months[month - 1]} ${year}`;
  }

  // Filter
  const [cityFilter, setCityFilter] = useState(null);
  const [districtFilter, setDistrictFilter] = useState(null);
  const [neighbourhoodFilter, setNeighbourhoodFilter] = useState(null);
  const [streetFilter, setStreetFilter] = useState(null);

  // Data
  const [fetchOption, setFetchOption] = useState(null);

  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [fileColumns, setFileColumns] = useState(null);

  const [aciklamaValue, setAciklamaValue] = useState("");
  const [aciklamaSelectValue, setAciklamaSelectValue] = useState(null);
  const [tableCinsiyet, setTableCinsiyet] = useState(null);
  const [tableStartDate, setTableStartDate] = useState(null);
  const [tableEndDate, setTableEndDate] = useState(null);

  const [filterData, setFilterData] = useState([null]);

  const [memleketIlData, setMemleketIlData] = useState([]);
  const [memleketIlceData, setMemleketIlceData] = useState([]);

  const [memleketIlSelectValue, setMemleketIlSelectValue] = useState(null);
  const [memleketIlceSelectValue, setMemleketIlceSelectValue] = useState(null);

  const [showFilterTable, setShowFilterTable] = useState(true);
  const [showNewFilterTable, setShowNewFilterTable] = useState(false);
  const [showPersonTable, setShowPersonTable] = useState(false);
  const [showMuhtarliklarTable, setShowMuhtarliklarTable] = useState(false);
  const [showDerneklerTable, setShowDerneklerTable] = useState(false);
  const [showSendikalarTable, setShowSendikalarTable] = useState(false);

  // Get Filter
  const [filtersData, setFiltersData] = useState(null);
  const [filtersSelectData, setFiltersSelectData] = useState(null);
  const [filterSelectValue, setFilterSelectValue] = useState(null);

  async function fetchGetFilters() {
    setFiltersData(null);
    setFiltersSelectData(null);

    try {
      const response = await apiClient.get(`/page/71`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setFiltersData(response.data.graphics);
        setFiltersSelectData(
          response.data.graphics.map((item) => {
            return {
              value: item.id,
              label: item.title,
            };
          })
        );
      }
    } catch (error) {
      setFiltersData([]);
      setFiltersSelectData([]);
      console.error("Get Page", error);
    }
  }

  useEffect(() => {
    fetchGetFilters();
  }, []);

  // Create Filter
  const [filterName, setFilterName] = useState("");

  async function handleCreateFilter() {
    if (!fetchOption) {
      toastMessage(context?.t?.filtre_yapmalisiniz, "error");
      return;
    }

    if (filterName === "") {
      toastMessage(context?.t?.filtre_adi_girmelisiniz, "error");
      return;
    }

    const newOption = fetchOption;
    newOption.page_id = 71;
    newOption.title = filterName;
    newOption.customer = context?.me?.customer?.id;

    try {
      const response = await apiClient.customPost(`/query`, newOption, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setFilterName("");
        toastMessage(context?.t?.filtre_eklendi, "success");
        fetchGetFilters();
      }
    } catch (error) {
      setFilterName("");

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  // Get Filter
  const [filterTable, setFilterTable] = useState(null);
  const [filterTablePage, setFilterTablePage] = useState(1);

  async function handleGetFilter() {
    setFilterTable(null);

    if (
      filterSelectValue &&
      filterSelectValue.value &&
      filtersData &&
      filtersData.length !== 0
    ) {
      const filterOptions =
        filterSelectValue &&
        filterSelectValue.value &&
        filtersData.find((item) => item.id === filterSelectValue.value);

      filterOptions.customer = filterOptions.customer_id;
      filterOptions.paginate = true;

      if (filterOptions.payload) {
        filterOptions.sql = filterOptions.payload;
        delete filterOptions.payload;
      }

      try {
        const response = await apiClient.customPost(
          `/query/preview?page=${filterTablePage}`,
          filterOptions,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setFilterTable(response);
      } catch (error) {
        setFilterTable(null);
        console.error(error);
      }
    }
  }

  useEffect(() => {
    handleGetFilter();
  }, [filterSelectValue, filtersData, filterTablePage]);

  // Delete Filter
  const [filterDeleteLoader, setFilterDeleteLoader] = useState(false);

  async function handleFilterDelete() {
    if (filterSelectValue) {
      setFilterDeleteLoader(true);

      try {
        const response = await apiClient.delete(
          `/query/${filterSelectValue.value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 204) {
          toastMessage(context?.t?.tablo_silindi, "success");

          setFilterDeleteLoader(false);

          setFilterTable(null);
          setFilterSelectValue(null);
          setFilterTablePage(1);

          fetchGetFilters();
        }
      } catch (error) {
        setFilterDeleteLoader(false);
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  // Get File Columns
  async function getFileColumns(name) {
    setFileColumns(null);

    try {
      const response = await apiClient.get(`/columns/${name}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response) {
        setFileColumns(response.data.columns);
      }
    } catch (error) {
      setFileColumns([]);
      console.error("Get File Types Error", error);
    }
  }

  useEffect(() => {
    if (context?.me) {
      getFileColumns(`bireysel-data-customer-${context?.me?.customer?.id}`);
    }
  }, [context]);

  // Get Data
  async function fetchData(
    page,
    select = null,
    condition,
    filter = [null],
    otherFilter = [null]
  ) {
    if (cityFilter && districtFilter) {
      let memleketIlConditions = [null];

      if (select === "Memleket Il" && condition) {
        memleketIlConditions = condition.map((item, index) => {
          return {
            key: select,
            value: item.label,
            operator: "=",
            type: index === 0 ? "and" : "or",
          };
        });
      } else {
        memleketIlConditions = [null];
      }

      if (!select) {
        setData(null);
      }

      if (select === "Memleket Il" && !condition) {
        setMemleketIlData(null);
      }

      if (select && condition) {
        setMemleketIlceData(null);
      }

      const dataColumn = fileColumns.map((item) => ({
        key: item,
        value: "select",
      }));

      const option = {
        chart: 1,
        page_id: 23,
        title: "-",
        type: `bireysel-data-customer-${context?.me?.customer?.id}`,
        description: "",
        customer: null,
        status: true,
        order: 1,
        sql: {
          column: select
            ? [
                select === "Memleket Il" && condition
                  ? {
                      key: "Memleket Il",
                      value: "select",
                    }
                  : null,
                {
                  key:
                    select === "Memleket Il" && condition
                      ? "Memleket Ilce"
                      : select,
                  value: "select",
                },
              ].filter((item) => item !== null)
            : dataColumn,
          condition: [
            cityFilter
              ? {
                  key: "Adres Il",
                  value: context
                    .removeTurkishCharacters(cityFilter.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                }
              : null,
            districtFilter
              ? {
                  key: "Adres Ilce",
                  value: context
                    .removeTurkishCharacters(districtFilter.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                }
              : null,
            neighbourhoodFilter
              ? {
                  key: "Adres Mahalle",
                  value: context
                    .removeTurkishCharacters(neighbourhoodFilter.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                }
              : null,
            streetFilter
              ? {
                  key: "Adres Sokak",
                  value: context
                    .removeTurkishCharacters(streetFilter.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                }
              : null,
            ...otherFilter,
            ...memleketIlConditions,
            select !== "Memleket Il" && condition
              ? {
                  key: select,
                  value: condition,
                  operator: "=",
                  type: "and",
                }
              : null,
            ...filter,
          ].filter((item) => item !== null),
          group: [],
        },
      };

      if (!select) {
        option.paginate = true;
      }

      setFetchOption(option);

      try {
        const response = await apiClient.customPost(
          `/query/preview?page=${page}`,
          option,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response) {
          if (select === "Memleket Il" && !condition) {
            const sortedResponse = response.sort((a, b) => {
              if (a["memleket il"] < b["memleket il"]) {
                return -1;
              }
              if (a["memleket il"] > b["memleket il"]) {
                return 1;
              }
              return 0;
            });

            setMemleketIlData(
              sortedResponse.map((element) => {
                return {
                  value: element["memleket il"],
                  label: element["memleket il"],
                };
              })
            );
          }

          if (select && condition) {
            const sortedResponse = response.sort((a, b) => {
              if (a["memleket ilce"] < b["memleket ilce"]) {
                return -1;
              }
              if (a["memleket ilce"] > b["memleket ilce"]) {
                return 1;
              }
              return 0;
            });

            setMemleketIlceData(
              sortedResponse.map((element) => {
                return {
                  value: element["memleket ilce"],
                  label: element["memleket ilce"],
                  parent: element["memleket il"],
                };
              })
            );
          }

          if (!select) {
            if (response.length !== 0) {
              setData(response);
            } else {
              setData({ data: [] });
            }
          }
        }
      } catch (error) {
        setData({ data: [] });
        setMemleketIlData([]);
        console.error(error);
      }

      //--

      handleMuhtarlar();
      handleDernekler();
      handleSendikalar();
    }
  }

  useEffect(() => {
    if (fileColumns && cityFilter && districtFilter) {
      fetchData(1, null);
      fetchData(1, "Memleket Il");
    }
  }, [
    fileColumns,
    cityFilter,
    districtFilter,
    neighbourhoodFilter,
    streetFilter,
  ]);

  // Pagination
  const [tableFilterData, setTableFilterData] = useState([null]);

  function handlePagination(value) {
    setCurrentPage(value);
    fetchData(value, null, null, filterData, tableFilterData);
  }

  function handleTableFilter() {
    if (
      aciklamaSelectValue ||
      aciklamaValue ||
      memleketIlSelectValue ||
      memleketIlceSelectValue ||
      tableCinsiyet ||
      tableStartDate ||
      tableEndDate
    ) {
      setCurrentPage(1);

      const convertIlData = (data) => {
        return data
          ? data.map((item, index) => ({
              key: "Memleket Il",
              value: item.label,
              operator: "=",
              type: "and", // Varsayılan olarak 'and' ayarlandı
            }))
          : null;
      };

      const convertIlceData = (data) => {
        const ilceMap = new Map();

        data &&
          data.forEach((item) => {
            if (!ilceMap.has(item.parent)) {
              ilceMap.set(item.parent, []);
            }
            ilceMap.get(item.parent).push({
              key: "Memleket Ilce",
              value: item.label,
              operator: "=",
              type: "and", // Varsayılan olarak 'and' ayarlandı
            });
          });

        return data ? ilceMap : null;
      };

      const combinedArray = [];

      // İl ve ilçe verilerini dönüştür
      const ilOptions = convertIlData(memleketIlSelectValue);
      const ilceMap = convertIlceData(memleketIlceSelectValue);

      // İl ve ilçe verilerinin uzunluklarını kontrol et
      const hasSingleIl = memleketIlSelectValue && ilOptions.length === 1;
      const hasSingleIlce =
        memleketIlceSelectValue &&
        Array.from(ilceMap.values()).every((ilceList) => ilceList.length === 1);

      // İl verilerini ekle
      if (memleketIlSelectValue) {
        ilOptions.forEach((il, ilIndex) => {
          combinedArray.push(il);

          // İle bağlı ilçeleri ekle
          if (ilceMap && ilceMap.has(il.value)) {
            const ilceOptions = ilceMap.get(il.value);

            ilceOptions.forEach((ilce, ilceIndex) => {
              // Eğer her iki veride de sadece bir tane varsa, 'type' 'and' olur
              if (hasSingleIl && hasSingleIlce) {
                ilce.type = "and";
              } else {
                ilce.type = ilceIndex === ilceOptions.length - 1 ? "or" : "and";
              }
              combinedArray.push(ilce);
            });
          }
        });
      }

      // İl verilerini son olarak kontrol et ve 'or' tipi ekleyin
      if (memleketIlSelectValue && ilOptions.length > 1) {
        combinedArray.forEach((item, index) => {
          if (item.key === "Memleket Il" && index < combinedArray.length - 1) {
            item.type = "or";
          }
        });
      }

      const otherFilterArray = [
        aciklamaSelectValue && aciklamaSelectValue.value
          ? {
              key: aciklamaSelectValue.value,
              value: aciklamaValue,
              operator: "like",
              type: "and",
            }
          : null,
        tableCinsiyet && tableCinsiyet.value
          ? {
              key: "Cinsiyet",
              value: context
                .removeTurkishCharacters(tableCinsiyet.value)
                .toUpperCase(),
              operator: "=",
              type: "and",
            }
          : null,
        tableStartDate
          ? {
              key: "Dogum Tarihi",
              value: moment(tableStartDate).format("YYYY-MM-DD"),
              operator: tableEndDate ? ">=" : "=",
              type: "and",
            }
          : null,
        tableEndDate
          ? {
              key: "Dogum Tarihi",
              value: moment(tableEndDate).format("YYYY-MM-DD"),
              operator: "<=",
              type: "and",
            }
          : null,
      ].filter((item) => item !== null);

      setFilterData(combinedArray);
      setTableFilterData(otherFilterArray);

      fetchData(1, null, null, combinedArray, otherFilterArray);
    }
  }

  function handleTableFilterReset() {
    setAciklamaSelectValue(null);
    setAciklamaValue("");

    setMemleketIlceSelectValue(null);

    setMemleketIlSelectValue(null);
    setMemleketIlceData(null);
    setTimeout(() => {
      setMemleketIlceData([]);
    }, 300);

    setTableCinsiyet(null);
    setCurrentPage(1);
    setFilterData([null]);
    setTableFilterData([null]);
    setTableStartDate(null);
    setTableEndDate(null);

    fetchData(1, null, null, [null], [null]);
  }

  // Handle Filter
  function handleFilter() {
    const convertIlData = (data) => {
      return data
        ? data.map((item, index) => ({
            key: "Memleket Il",
            value: item.label,
            operator: "=",
            type: "and", // Varsayılan olarak 'and' ayarlandı
          }))
        : null;
    };

    const convertIlceData = (data) => {
      const ilceMap = new Map();

      data.forEach((item) => {
        if (!ilceMap.has(item.parent)) {
          ilceMap.set(item.parent, []);
        }
        ilceMap.get(item.parent).push({
          key: "Memleket Ilce",
          value: item.label,
          operator: "=",
          type: "and", // Varsayılan olarak 'and' ayarlandı
        });
      });

      return data ? ilceMap : null;
    };

    const combinedArray = [];

    // İl ve ilçe verilerini dönüştür
    const ilOptions = convertIlData(memleketIlSelectValue);
    const ilceMap = convertIlceData(memleketIlceSelectValue);

    // İl ve ilçe verilerinin uzunluklarını kontrol et
    const hasSingleIl = ilOptions.length === 1;
    const hasSingleIlce = Array.from(ilceMap.values()).every(
      (ilceList) => ilceList.length === 1
    );

    // İl verilerini ekle
    ilOptions.forEach((il, ilIndex) => {
      combinedArray.push(il);

      // İle bağlı ilçeleri ekle
      if (ilceMap && ilceMap.has(il.value)) {
        const ilceOptions = ilceMap.get(il.value);

        ilceOptions.forEach((ilce, ilceIndex) => {
          // Eğer her iki veride de sadece bir tane varsa, 'type' 'and' olur
          if (hasSingleIl && hasSingleIlce) {
            ilce.type = "and";
          } else {
            ilce.type = ilceIndex === ilceOptions.length - 1 ? "or" : "and";
          }
          combinedArray.push(ilce);
        });
      }
    });

    // İl verilerini son olarak kontrol et ve 'or' tipi ekleyin
    if (ilOptions.length > 1) {
      combinedArray.forEach((item, index) => {
        if (item.key === "Memleket Il" && index < combinedArray.length - 1) {
          item.type = "or";
        }
      });
    }

    setFilterData(combinedArray);

    fetchData(1, null, null, combinedArray, tableFilterData);
  }

  //--

  // Atanmış Anketleri Getir
  const [assigmentSelect, setAssigmentSelect] = useState(null);
  const [tcSurvey, setTCSurvey] = useState(null);

  const [assigmentData, setAssigmentData] = useState([]);

  async function handleGetReports() {
    setAssigmentData(null);

    try {
      const response = await apiClient.get(`/street-report`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.data.length !== 0) {
        const formattedData = response.data.map((item) => ({
          value: item.id,
          label: item.name,
          ...item,
        }));

        setAssigmentData(formattedData);
      } else {
        setAssigmentData([]);
      }
    } catch (error) {
      setAssigmentData(null);

      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error?.response?.data?.message, "error");
      }
    }
  }

  useEffect(() => {
    if (context?.me) {
      handleGetReports();
    }
  }, [context]);

  // Anket Show
  const [surveyData, setSurveyData] = useState(null);

  async function handleGetShowReport(id) {
    try {
      const response = await apiClient.get(`/street-report/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setSurveyData(response.data);
    } catch (error) {
      setSurveyData(null);
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (assigmentSelect) {
      handleGetShowReport(assigmentSelect);
    }
  }, [assigmentSelect]);

  // Muhtarlar
  const [muhtarlikData, setMuhtarlikData] = useState(false);

  const handleMuhtarlar = async () => {
    if (context && context?.me && context?.me?.role?.id !== 6) {
      setMuhtarlikData(null);

      const conditionData = [
        {
          key: "Il adı",
          value: cityFilter.label,
          operator: "=",
          type: "and",
        },
        {
          key: "IlCe Adı",
          value: districtFilter.label,
          operator: "=",
          type: "and",
        },
        neighbourhoodFilter
          ? {
              key: "Mahalle Adı",
              value: neighbourhoodFilter.label,
              operator: "=",
              type: "and",
            }
          : null,
      ].filter((item) => item !== null);

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 24,
            title: "-",
            type: "muhtarlıklar",
            description: "",
            status: true,
            customer: null,
            order: 1,
            sql: {
              column: [
                {
                  key: "Muhtar Adı",
                  value: "select",
                },
                {
                  key: "MuhtarTelefonu",
                  value: "select",
                },
                {
                  key: "Muhtarlık Adı",
                  value: "select",
                },
                {
                  key: "Il adı",
                  value: "select",
                },
                {
                  key: "IlCe Adı",
                  value: "select",
                },
                {
                  key: "Mahalle Adı",
                  value: "select",
                },
                {
                  key: "Adres",
                  value: "select",
                },
                {
                  key: "Longitude",
                  value: "select",
                },
                {
                  key: "Latitude",
                  value: "select",
                },
              ],
              condition: conditionData,
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setMuhtarlikData(response);
      } catch (error) {
        setMuhtarlikData([]);
        console.error(error);
      }
    }
  };

  // Dernekler
  const [derneklerData, setDerneklerData] = useState(false);

  const handleDernekler = async () => {
    if (context && context?.me && context?.me?.role?.id !== 6) {
      setDerneklerData(null);

      const conditionData = [
        {
          key: "Sehir",
          value: cityFilter.label,
          operator: "=",
          type: "and",
        },
        {
          key: "Ilce",
          value: districtFilter.label,
          operator: "=",
          type: "and",
        },
        neighbourhoodFilter
          ? {
              key: "Mahalle",
              value: neighbourhoodFilter.label,
              operator: "=",
              type: "and",
            }
          : null,
      ].filter((item) => item !== null);

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 24,
            title: "-",
            type: "dernekler",
            description: "",
            status: true,
            customer: null,
            order: 1,
            sql: {
              column: [
                {
                  key: "Sehir",
                  value: "select",
                },
                {
                  key: "Ilce",
                  value: "select",
                },
                {
                  key: "Mahalle",
                  value: "select",
                },
                {
                  key: "Kurum-Adres",
                  value: "select",
                },
                {
                  key: "Faaliyet-Alanı",
                  value: "select",
                },
                {
                  key: "Kurum-Adi",
                  value: "select",
                },
                {
                  key: "Telefon",
                  value: "select",
                },
                {
                  key: "Detayli-Faaliyet-Alani",
                  value: "select",
                },
              ],
              condition: conditionData,
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setDerneklerData(response);
      } catch (error) {
        setDerneklerData([]);
        console.error(error);
      }
    }
  };

  // Sendikalar
  const [sendikalarData, setSendikalarData] = useState(false);

  const handleSendikalar = async () => {
    if (context && context?.me && context?.me?.role?.id !== 6) {
      setSendikalarData(null);

      const conditionData = [
        {
          key: "Il",
          value: cityFilter.label,
          operator: "=",
          type: "and",
        },
        {
          key: "Ilce",
          value: districtFilter.label,
          operator: "=",
          type: "and",
        },
        neighbourhoodFilter
          ? {
              key: "Mahalle",
              value: neighbourhoodFilter.label,
              operator: "=",
              type: "and",
            }
          : null,
      ].filter((item) => item !== null);

      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 24,
            title: "-",
            type: "sendikalar",
            description: "",
            status: true,
            customer: null,
            order: 1,
            sql: {
              column: [
                {
                  key: "Sayı",
                  value: "select",
                },
                {
                  key: "Il",
                  value: "select",
                },
                {
                  key: "Ilce",
                  value: "select",
                },
                {
                  key: "Mahalle",
                  value: "select",
                },
                {
                  key: "Sendika-Adresi",
                  value: "select",
                },
                {
                  key: "Sendika-is-kolu",
                  value: "select",
                },
                {
                  key: "Sendika-adi",
                  value: "select",
                },
              ],
              condition: conditionData,
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setSendikalarData(response);
      } catch (error) {
        setSendikalarData([]);
        console.error(error);
      }
    }
  };

  // Atanmış Anketler
  const [atanmisAnketlerData, setAtanmisAnketlerData] = useState([]);

  async function handleAtanmisAnketler(tc) {
    if (tc === "") {
      toastMessage(context?.t?.tc_bulunamadi, "error");
      return;
    }

    setAtanmisAnketlerData([]);

    try {
      const response = await apiClient.get(`/citizen/assignment?tc=${tc}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setAtanmisAnketlerData(response.data);
      }
    } catch (error) {
      setAtanmisAnketlerData([]);
      console.error(error);
    }
  }

  // Anket Ata
  const [selectedTC, setSelectedTC] = useState("");

  const [anketAtaSelectValue, setAnketAtaSelectValue] = useState(null);
  const [anketAtaLoader, setAnketAtaLoader] = useState(false);

  async function handleAnketAta() {
    if (selectedTC === "") {
      toastMessage(context?.t?.tc_bulunamadi, "error");
      return;
    }

    if (!anketAtaSelectValue) {
      toastMessage(context?.t?.anket_secmelisiniz, "error");
      return;
    }

    setAnketAtaLoader(true);

    try {
      const response = await apiClient.customPost(
        `/citizen/assignment`,
        {
          tc: selectedTC,
          report_id: anketAtaSelectValue.value,
          customer_id: context?.me?.customer?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        toastMessage(context?.t?.anket_atandi, "success");

        setTimeout(() => {
          document.getElementById("kisiAnketAtaModalCloseButton").click();
          setShowPersonTable(false);
          setAnketAtaLoader(false);
        }, 1000);
      }
    } catch (error) {
      setAnketAtaLoader(false);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  // Query Download CSV
  async function handleQueryDownload(data) {
    if (data) {
      try {
        const response = await apiClient.get(`/query/export/${data.value}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response) {
          const link = document.createElement("a");
          link.href = response.data.url;
          link.setAttribute("download", "table.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();

          toastMessage(context?.t?.rapor_basariyla_olusturuldu, "success");
        }
      } catch (error) {
        console.error("Get Page", error);
      }
    }
  }

  return (
    <>
      <div className="row gap-4 mb-5 pb-5">
        {context?.me?.role?.id !== 6 && (
          <>
            <PageFilter
              setCityFilter={setCityFilter}
              setDistrictFilter={setDistrictFilter}
              setNeighbourhoodFilter={setNeighbourhoodFilter}
              setStreetFilter={setStreetFilter}
            />
            <div className="col-12">
              <hr />
            </div>
          </>
        )}

        {context?.me?.role?.id !== 6 && (
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="row align-items-center justify-content-between">
                  <div className="col w-auto flex-none d-flex align-items-center gap-2">
                    <h5 className="mb-0">{context?.t?.filtre_tablosu}</h5>
                    <button
                      type="button"
                      className="btn btn-outline-theme"
                      onClick={() => setShowFilterTable(!showFilterTable)}
                    >
                      {showFilterTable ? context?.t?.hide : context?.t?.show}
                    </button>
                  </div>

                  <div className="col flex-none w-auto">
                    <div className="row g-2">
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={context?.t?.filtre_adi_giriniz}
                          value={filterName}
                          onChange={(e) => setFilterName(e.target.value)}
                        />
                      </div>

                      <div className="col w-auto flex-none">
                        <button
                          type="button"
                          className="btn btn-outline-theme w-100"
                          onClick={() => handleCreateFilter()}
                        >
                          {context?.t?.filtre_olustur}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {showFilterTable &&
                (cityFilter && districtFilter ? (
                  data ? (
                    <div className="col-12 mt-4">
                      {context?.me?.role?.id !== 6 && (
                        <div className="row align-items-end g-3 mb-3">
                          <div className="col border-end">
                            <div className="d-flex gap-1">
                              <div className="flex-1">
                                <span className="d-block mb-1">
                                  {context?.t?.description}
                                </span>
                                <div className="d-flex gap-1">
                                  <div className="flex-1">
                                    <Select
                                      options={[
                                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                                        13, 14, 15,
                                      ].map((item) => {
                                        return {
                                          value: "Aciklama " + item,
                                          label:
                                            context?.t?.description +
                                            " " +
                                            item,
                                        };
                                      })}
                                      classNamePrefix="react-select"
                                      noOptionsMessage={() =>
                                        context?.t?.no_option
                                      }
                                      placeholder=""
                                      value={aciklamaSelectValue}
                                      onChange={(e) => {
                                        setAciklamaSelectValue(e);
                                      }}
                                    />
                                  </div>

                                  <div className="flex-1">
                                    <input
                                      placeholder={context?.t?.description}
                                      className="form-control"
                                      value={aciklamaValue}
                                      onChange={(e) => {
                                        setAciklamaValue(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            <div className="d-flex gap-1">
                              <div className="flex-1">
                                {memleketIlData ? (
                                  <div>
                                    <span className="d-block mb-1">
                                      {context?.t?.memleket_il}
                                    </span>
                                    <div className="d-flex gap-1">
                                      <div className="flex-1">
                                        <Select
                                          options={memleketIlData}
                                          classNamePrefix="react-select"
                                          noOptionsMessage={() =>
                                            context?.t?.no_option
                                          }
                                          isMulti
                                          placeholder=""
                                          value={memleketIlSelectValue}
                                          onChange={(e) => {
                                            fetchData(1, "Memleket Il", e);
                                            setMemleketIlSelectValue(e);
                                            setMemleketIlceSelectValue(null);
                                          }}
                                        />
                                      </div>

                                      {memleketIlSelectValue && (
                                        <button
                                          onClick={() => {
                                            setMemleketIlSelectValue(null);
                                            setMemleketIlceData(null);
                                            setTimeout(() => {
                                              setMemleketIlceData([]);
                                            }, 300);
                                            setMemleketIlceSelectValue(null);
                                          }}
                                          className="btn btn-danger btn-sm"
                                          style={{ borderRadius: 4 }}
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="spinner-border text-theme"></div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col">
                            {memleketIlceData ? (
                              <div>
                                <span className="d-block mb-1">
                                  {context?.t?.memleket_ilce}
                                </span>
                                <div className="d-flex gap-1">
                                  <div className="flex-1">
                                    <Select
                                      options={memleketIlceData}
                                      isMulti
                                      classNamePrefix="react-select"
                                      placeholder=""
                                      noOptionsMessage={() =>
                                        context?.t?.no_option
                                      }
                                      value={memleketIlceSelectValue}
                                      onChange={(e) =>
                                        setMemleketIlceSelectValue(e)
                                      }
                                    />
                                  </div>

                                  {memleketIlceSelectValue && (
                                    <button
                                      onClick={() => {
                                        setMemleketIlceSelectValue(null);
                                      }}
                                      className="btn btn-danger btn-sm"
                                      style={{ borderRadius: 4 }}
                                    >
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="spinner-border text-theme"></div>
                            )}
                          </div>

                          <div className="col">
                            <span className="d-block mb-1">
                              {context?.t?.gender}
                            </span>
                            <Select
                              options={[
                                { value: null, label: context?.t?.all },
                                { value: "E", label: context?.t?.male },
                                { value: "K", label: context?.t?.female },
                              ]}
                              placeholder=""
                              classNamePrefix="react-select"
                              noOptionsMessage={() => context?.t?.no_option}
                              value={tableCinsiyet}
                              onChange={(e) => setTableCinsiyet(e)}
                            />
                          </div>

                          <div className="col">
                            <span className="d-block mb-1">
                              {context?.t?.dogum_tarihi}
                            </span>
                            <div className="row g-2">
                              <div className="col">
                                <DatePicker
                                  className="form-control"
                                  locale="tr"
                                  selected={tableStartDate}
                                  onChange={(date) => setTableStartDate(date)}
                                  selectsStart
                                  startDate={tableStartDate}
                                  endDate={tableEndDate}
                                  showMonthDropdown
                                  showYearDropdown
                                  isClearable
                                />
                              </div>
                              <div className="col">
                                <DatePicker
                                  className="form-control"
                                  locale="tr"
                                  selected={tableEndDate}
                                  onChange={(date) => setTableEndDate(date)}
                                  selectsEnd
                                  startDate={tableStartDate}
                                  endDate={tableEndDate}
                                  minDate={tableStartDate}
                                  showMonthDropdown
                                  isClearable
                                  showYearDropdown
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col flex-none w-auto border-start">
                            <button
                              className="btn btn-outline-theme me-2"
                              onClick={() => {
                                handleTableFilter();
                              }}
                            >
                              {context?.t?.getir}
                            </button>

                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                handleTableFilterReset();
                              }}
                            >
                              {context?.t?.clear}
                            </button>
                          </div>
                        </div>
                      )}

                      {data.data.length !== 0 ? (
                        <>
                          <div className="dataTables_wrapper dt-bootstrap5">
                            <div className="dataTables_scroll">
                              <div
                                className="dataTables_scrollBody table-responsive"
                                style={{ height: "calc(100vh - 500px)" }}
                              >
                                <table className="table text-nowrap w-100 dataTable hide-data">
                                  <thead>
                                    <tr>
                                      {tableHead
                                        .filter(
                                          (item) => item.key !== "Islemler",
                                          context?.me?.role?.id === 6 ||
                                            context?.me?.role?.id === 4 ||
                                            context?.me?.role?.id === 1 ||
                                            context?.me?.role?.id === 2
                                        )
                                        .map((item, index) => (
                                          <th
                                            className="text-start p-3"
                                            key={index}
                                            style={{
                                              minWidth: item.width || "auto",
                                            }}
                                          >
                                            {item.label}
                                          </th>
                                        ))}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {data.data.map((item, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          onMouseEnter={() =>
                                            setHoveredTableRow(index)
                                          }
                                          onMouseLeave={() =>
                                            setHoveredTableRow(null)
                                          }
                                        >
                                          <td
                                            style={{ minWidth: 80 }}
                                            className="p-3"
                                          >
                                            {index + 1}
                                          </td>
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? context.me?.customer?.settings
                                                  ?.primary
                                                ? item?.tc ||
                                                  context?.t?.unknown
                                                : "--"
                                              : "********"}
                                          </td>
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? context.me?.customer?.settings
                                                  ?.primary
                                                ? item["ad soyad"] ||
                                                  context?.t?.unknown
                                                : "--"
                                              : "********"}
                                          </td>
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? context.me?.customer?.settings
                                                  ?.primary
                                                ? item["cinsiyet"] ||
                                                  context?.t?.unknown
                                                : "--"
                                              : "********"}
                                          </td>
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? context.me?.customer?.settings
                                                  ?.primary
                                                ? item["dogum tarihi"]
                                                  ? formatDate(
                                                      item["dogum tarihi"]
                                                    )
                                                  : context?.t?.unknown
                                                : "--"
                                              : "********"}
                                          </td>
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? context.me?.customer?.settings
                                                  ?.primary
                                                ? item["dogum tarihi"]
                                                  ? new Date().getFullYear() -
                                                    item["dogum tarihi"].split(
                                                      "-"
                                                    )[0]
                                                  : context?.t?.unknown
                                                : "--"
                                              : "********"}
                                          </td>
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? context.me?.customer?.settings
                                                  ?.primary
                                                ? item["telefon no"]
                                                  ? context.phoneFormat(
                                                      item["telefon no"]
                                                    )
                                                  : context?.t?.unknown
                                                : "--"
                                              : "********"}
                                          </td>
                                          {/* <td style={{ minWidth: 150 }} className="p-3">
                                {item["secmen profili"] || context?.t?.unknown}
                              </td> */}
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? item["memleket il"] ||
                                                context?.t?.unknown
                                              : "********"}
                                          </td>
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? item["memleket ilce"] ||
                                                context?.t?.unknown
                                              : "********"}
                                          </td>
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? item["adres il"] ||
                                                context?.t?.unknown
                                              : "********"}
                                          </td>
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? item["adres ilce"] ||
                                                context?.t?.unknown
                                              : "********"}
                                          </td>
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? item["adres mahalle"] ||
                                                context?.t?.unknown
                                              : "********"}
                                          </td>
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? item["adres sokak"] ||
                                                context?.t?.unknown
                                              : "********"}
                                          </td>
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? item["bina no"] ||
                                                context?.t?.unknown
                                              : "********"}
                                          </td>
                                          <td
                                            style={{ minWidth: 150 }}
                                            className="p-3"
                                          >
                                            {hoveredTableRow === index
                                              ? item["daire"] ||
                                                context?.t?.unknown
                                              : "********"}
                                          </td>
                                          {[
                                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
                                            12, 13, 14, 15,
                                          ].map((desc, descIndex) => (
                                            <td
                                              key={descIndex}
                                              style={{ minWidth: 150 }}
                                              className="p-3"
                                            >
                                              {hoveredTableRow === index
                                                ? item["aciklama " + desc] ||
                                                  context?.t?.unknown
                                                : "********"}
                                            </td>
                                          ))}
                                          {/* <td
                                style={{ minWidth: 250 }}
                                className="text-center p-3"
                              >
                                {item["tahmini 1. derece ailede uyelik"] ||
                                  context?.t?.unknown}
                              </td>
                              <td
                                style={{ minWidth: 250 }}
                                className="text-center p-3"
                              >
                                {item["tahmini 2. derece ailede uyelik"] ||
                                  context?.t?.unknown}
                              </td> */}
                                          {/* <td
                                style={{ minWidth: 200 }}
                                className="text-center p-3"
                              >
                                {item["sandık sonucu 1"] || context?.t?.unknown}
                              </td>
                              <td
                                style={{ minWidth: 200 }}
                                className="text-center p-3"
                              >
                                {item["sandık sonucu 2"] || context?.t?.unknown}
                              </td>
                              <td
                                style={{ minWidth: 200 }}
                                className="text-center p-3"
                              >
                                {item["sandık sonucu 3"] || context?.t?.unknown}
                              </td>

                              <td
                                style={{ minWidth: 220 }}
                                className="text-center p-3"
                              >
                                {item["mahalle sonucu 1"] || context?.t?.unknown}
                              </td>
                              <td
                                style={{ minWidth: 220 }}
                                className="text-center p-3"
                              >
                                {item["mahalle sonucu 2"] || context?.t?.unknown}
                              </td>
                              <td
                                style={{ minWidth: 220 }}
                                className="text-center p-3"
                              >
                                {item["mahalle sonucu 3"] || context?.t?.unknown}
                              </td>

                              <td
                                style={{ minWidth: 220 }}
                                className="text-center p-3"
                              >
                                {item["memleket sonucu 1"] || context?.t?.unknown}
                              </td>
                              <td
                                style={{ minWidth: 220 }}
                                className="text-center p-3"
                              >
                                {item["memleket sonucu 2"] || context?.t?.unknown}
                              </td>
                              <td
                                style={{ minWidth: 220 }}
                                className="text-center p-3"
                              >
                                {item["memleket sonucu 3"] || context?.t?.unknown}
                              </td> */}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div className="row align-items-center mt-3">
                              <div className="mb-0 col-md-12">
                                <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center">
                                  <ul className="pagination">
                                    <li
                                      className={`paginate_button page-item ${
                                        !data.prev_page_url && "disabled"
                                      }`}
                                    >
                                      <span
                                        onClick={() =>
                                          data.prev_page_url &&
                                          handlePagination(
                                            data.prev_page_url.split(
                                              "?page="
                                            )[1]
                                          )
                                        }
                                        className="page-link"
                                      >
                                        {context?.t?.prev}
                                      </span>
                                    </li>

                                    {data.links.map(
                                      (item, index) =>
                                        !isNaN(parseInt(item.label)) && (
                                          <li
                                            key={index}
                                            className={`paginate_button page-item ${
                                              item.active ? "active" : ""
                                            }`}
                                          >
                                            <span
                                              onClick={() =>
                                                handlePagination(item.label)
                                              }
                                              className="page-link"
                                            >
                                              {item.label}
                                            </span>
                                          </li>
                                        )
                                    )}

                                    <li
                                      className={`paginate_button page-item ${
                                        !data.next_page_url && "disabled"
                                      }`}
                                    >
                                      <span
                                        onClick={() =>
                                          data.next_page_url &&
                                          handlePagination(
                                            data.next_page_url.split(
                                              "?page="
                                            )[1]
                                          )
                                        }
                                        className="page-link"
                                      >
                                        {context?.t?.next}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <span className="d-block">
                          {context?.t?.no_data_available}
                        </span>
                      )}
                    </div>
                  ) : (
                    <div className="spinner-border text-theme mt-4"></div>
                  )
                ) : (
                  <span className="d-block mt-4">
                    {context?.t?.il_ve_ilce_filtrelemeniz_gerekiyor}
                  </span>
                ))}
            </div>
          </div>
        )}

        {context?.me?.role?.id !== 6 && (
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                <div className="border-top pt-4 d-flex align-items-center gap-2">
                  {filterSelectValue && (
                    <div className="d-flex align-items-center gap-2">
                      <h5 className="mb-0">
                        {filterSelectValue.label.toUpperCase() + " TABLOSU"}
                      </h5>

                      <button
                        type="button"
                        className="btn btn-outline-theme"
                        onClick={() =>
                          setShowNewFilterTable(!showNewFilterTable)
                        }
                      >
                        {showNewFilterTable
                          ? context?.t?.hide
                          : context?.t?.show}
                      </button>
                    </div>
                  )}

                  {filterSelectValue && (
                    <div className="flex-1 d-flex align-items-center gap-2 mx-2 px-3 border-start border-end">
                      <div className="col w-auto flex-none">
                        <span className="text-white fw-bold">0/500</span>
                      </div>

                      <div className="col">
                        <Select
                          options={[]}
                          classNamePrefix="react-select"
                          placeholder={context?.t?.anket_secin}
                          noOptionsMessage={() => context?.t?.no_option}
                        />
                      </div>

                      <div className="col">
                        <input
                          type="number"
                          min={0}
                          max={500}
                          placeholder={context?.t?.kac_kisi}
                          className="form-control"
                        />
                      </div>

                      <div className="col">
                        <Select
                          options={[]}
                          classNamePrefix="react-select"
                          placeholder={context?.t?.kullanici_secin}
                          noOptionsMessage={() => context?.t?.no_option}
                        />
                      </div>

                      <div className="col w-auto flex-none">
                        <button type="button" className="btn btn-outline-theme">
                          {context?.t?.send}
                        </button>
                      </div>
                    </div>
                  )}

                  <div
                    className={`d-flex align-items-center gap-2 ${
                      filterSelectValue ? "justify-content-end" : ""
                    }`}
                  >
                    {!filterSelectValue && (
                      <h5 className="mb-0">
                        {context?.t?.olusturulmus_tablo_secin}
                      </h5>
                    )}
                    <div className="w-100" style={{ maxWidth: 200 }}>
                      {filtersSelectData ? (
                        <Select
                          options={filtersSelectData}
                          classNamePrefix="react-select"
                          placeholder={context?.t?.tablo_secin}
                          noOptionsMessage={() => context?.t?.no_option}
                          value={filterSelectValue}
                          onChange={(e) => {
                            setFilterSelectValue(e);
                            setFilterTablePage(1);
                            setShowFilterTable(false);
                            setShowNewFilterTable(true);
                          }}
                        />
                      ) : (
                        <div className="spinner-border text-theme"></div>
                      )}
                    </div>

                    {filterSelectValue && (
                      <>
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          style={{ whiteSpace: "nowrap" }}
                          onClick={() => handleQueryDownload(filterSelectValue)}
                        >
                          {context?.t?.tabloyu_indir}
                        </button>

                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => handleFilterDelete()}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {context?.t?.tabloyu_sil}
                          {filterDeleteLoader && (
                            <div className="spinner-border spinner-border-sm ms-2"></div>
                          )}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {showNewFilterTable &&
                (filterTable ? (
                  <div className="col-12">
                    {filterTable && filterTable.data.length !== 0 ? (
                      <>
                        <div className="dataTables_wrapper dt-bootstrap5 mt-4">
                          <div className="dataTables_scroll">
                            <div
                              className="dataTables_scrollBody table-responsive"
                              style={{ height: "calc(100vh - 500px)" }}
                            >
                              <table className="table text-nowrap w-100 dataTable hide-data">
                                <thead>
                                  <tr>
                                    {tableHead.map((item, index) => (
                                      <th
                                        className="text-start p-3"
                                        key={index}
                                        style={{
                                          minWidth: item.width || "auto",
                                        }}
                                      >
                                        {item.label}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>

                                <tbody>
                                  {filterTable.data.map((item, index) => (
                                    <tr
                                      key={index}
                                      onMouseEnter={() =>
                                        setHoveredFilterTableRow(index)
                                      }
                                      onMouseLeave={() =>
                                        setHoveredFilterTableRow(null)
                                      }
                                    >
                                      <td
                                        style={{ minWidth: 80 }}
                                        className="p-3"
                                      >
                                        {index + 1}
                                      </td>
                                      <td
                                        style={{ minWidth: 80 }}
                                        className="p-3"
                                      >
                                        {(context?.me?.role?.id === 1 ||
                                          context?.me?.role?.id === 2 ||
                                          context?.me?.role?.id === 4) && (
                                          <div className="">
                                            <button
                                              type="button"
                                              className="btn btn-outline-theme btn-sm me-1"
                                              data-bs-toggle="modal"
                                              data-bs-target="#kisiyeAtanmisAnketler"
                                              onClick={() =>
                                                handleAtanmisAnketler(item.tc)
                                              }
                                            >
                                              {context?.t?.anketler}
                                            </button>

                                            <button
                                              type="button"
                                              className="btn btn-outline-theme btn-sm"
                                              data-bs-toggle="modal"
                                              data-bs-target="#kisiAnketAtaModal"
                                              onClick={() =>
                                                setSelectedTC(item.tc)
                                              }
                                            >
                                              {context?.t?.anket_ata}
                                            </button>
                                          </div>
                                        )}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? context.me?.customer?.settings
                                              ?.primary
                                            ? item?.tc || context?.t?.unknown
                                            : "--"
                                          : "********"}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? context.me?.customer?.settings
                                              ?.primary
                                            ? item["ad soyad"] ||
                                              context?.t?.unknown
                                            : "--"
                                          : "********"}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? context.me?.customer?.settings
                                              ?.primary
                                            ? item["cinsiyet"] ||
                                              context?.t?.unknown
                                            : "--"
                                          : "********"}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? context.me?.customer?.settings
                                              ?.primary
                                            ? item["dogum tarihi"]
                                              ? formatDate(item["dogum tarihi"])
                                              : context?.t?.unknown
                                            : "--"
                                          : "********"}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? context.me?.customer?.settings
                                              ?.primary
                                            ? item["dogum tarihi"]
                                              ? new Date().getFullYear() -
                                                item["dogum tarihi"].split(
                                                  "-"
                                                )[0]
                                              : context?.t?.unknown
                                            : "--"
                                          : "********"}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? context.me?.customer?.settings
                                              ?.primary
                                            ? item["telefon no"]
                                              ? context.phoneFormat(
                                                  item["telefon no"]
                                                )
                                              : context?.t?.unknown
                                            : "--"
                                          : "********"}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? item["memleket il"] ||
                                            context?.t?.unknown
                                          : "********"}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? item["memleket ilce"] ||
                                            context?.t?.unknown
                                          : "********"}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? item["adres il"] ||
                                            context?.t?.unknown
                                          : "********"}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? item["adres ilce"] ||
                                            context?.t?.unknown
                                          : "********"}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? item["adres mahalle"] ||
                                            context?.t?.unknown
                                          : "********"}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? item["adres sokak"] ||
                                            context?.t?.unknown
                                          : "********"}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? item["bina no"] ||
                                            context?.t?.unknown
                                          : "********"}
                                      </td>
                                      <td
                                        style={{ minWidth: 150 }}
                                        className="p-3"
                                      >
                                        {hoveredFilterTableRow === index
                                          ? item["daire"] || context?.t?.unknown
                                          : "********"}
                                      </td>
                                      {[
                                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                                        13, 14, 15,
                                      ].map((desc, descIndex) => (
                                        <td
                                          key={descIndex}
                                          style={{ minWidth: 150 }}
                                          className="p-3"
                                        >
                                          {hoveredFilterTableRow === index
                                            ? item["aciklama " + desc] ||
                                              context?.t?.unknown
                                            : "********"}
                                        </td>
                                      ))}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="row align-items-center mt-3">
                            <div className="mb-0 col-md-12">
                              <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center">
                                <ul className="pagination">
                                  <li
                                    className={`paginate_button page-item ${
                                      !filterTable.prev_page_url && "disabled"
                                    }`}
                                  >
                                    <span
                                      onClick={() =>
                                        filterTable.prev_page_url &&
                                        setFilterTablePage(
                                          filterTable.prev_page_url.split(
                                            "?page="
                                          )[1]
                                        )
                                      }
                                      className="page-link"
                                    >
                                      {context?.t?.prev}
                                    </span>
                                  </li>

                                  {filterTable.links.map(
                                    (item, index) =>
                                      !isNaN(parseInt(item.label)) && (
                                        <li
                                          key={index}
                                          className={`paginate_button page-item ${
                                            item.active ? "active" : ""
                                          }`}
                                        >
                                          <span
                                            onClick={() =>
                                              setFilterTablePage(item.label)
                                            }
                                            className="page-link"
                                          >
                                            {item.label}
                                          </span>
                                        </li>
                                      )
                                  )}

                                  <li
                                    className={`paginate_button page-item ${
                                      !filterTable.next_page_url && "disabled"
                                    }`}
                                  >
                                    <span
                                      onClick={() =>
                                        filterTable.next_page_url &&
                                        setFilterTablePage(
                                          filterTable.next_page_url.split(
                                            "?page="
                                          )[1]
                                        )
                                      }
                                      className="page-link"
                                    >
                                      {context?.t?.next}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <span className="d-block mt-4">
                        {context?.t?.no_data_available}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className="spinner-border text-theme mt-4"></div>
                ))}
            </div>
          </div>
        )}

        {(context?.me?.role?.id === 1 ||
          context?.me?.role?.id === 2 ||
          context?.me?.role?.id === 4 ||
          context?.me?.role?.id === 6) && (
          <div className="col-12">
            <div
              className={context?.me?.role?.id !== 6 ? "pt-4 border-top" : ""}
            >
              <div className="d-flex align-items-center gap-2">
                <h5 className="mb-0">
                  {context?.me?.role?.id === 6
                    ? context?.t?.yapilacak_anketler
                    : context?.t?.kisilere_atanmış_anketler}
                </h5>

                {context?.me?.role?.id !== 6 && (
                  <button
                    type="button"
                    className="btn btn-outline-theme"
                    onClick={() => setShowPersonTable(!showPersonTable)}
                  >
                    {showPersonTable ? context?.t?.hide : context?.t?.show}
                  </button>
                )}
              </div>

              {context?.me?.role?.id === 6 ? (
                <PersonsSurveyTable
                  assigmentSelect={assigmentSelect}
                  setAssigmentSelect={setAssigmentSelect}
                  setTCSurvey={setTCSurvey}
                />
              ) : (
                showPersonTable && <PersonsSurveyTable />
              )}
            </div>
          </div>
        )}

        {context?.me?.role?.id !== 6 && (
          <div className="col-12">
            <div className="pt-4 border-top">
              <div className="d-flex align-items-center gap-2">
                <h5 className="mb-0">{context?.t?.muhtarliklar}</h5>

                <button
                  type="button"
                  className="btn btn-outline-theme"
                  onClick={() =>
                    setShowMuhtarliklarTable(!showMuhtarliklarTable)
                  }
                >
                  {showMuhtarliklarTable ? context?.t?.hide : context?.t?.show}
                </button>
              </div>

              {showMuhtarliklarTable &&
                (cityFilter && districtFilter ? (
                  muhtarlikData ? (
                    muhtarlikData.length !== 0 ? (
                      <div className="dataTables_wrapper dt-bootstrap5 mt-4">
                        <div className="dataTables_scroll">
                          <div
                            className="dataTables_scrollBody table-responsive"
                            style={{ height: "calc(100vh - 500px)" }}
                          >
                            <table className="table text-nowrap w-100 dataTable">
                              <thead>
                                <tr>
                                  <th className="px-1 text-center">
                                    {context?.t?.city}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.district}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.neighborhood}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.muhtarlik_adi}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.muhtar_adi}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.muhtar_telefon}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.address}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.location}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {muhtarlikData.map((item, index) => (
                                  <tr key={index}>
                                    <td className="py-3 text-center">
                                      {item["il adı"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["ilce adı"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["mahalle adı"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["muhtarlık adı"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["muhtar adı"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["muhtartelefonu"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["adres"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["latitude"] && item["longitude"] ? (
                                        <a
                                          href={`https://www.google.com/maps?q=${item[
                                            "latitude"
                                          ].replace(",", ".")},${item[
                                            "longitude"
                                          ].replace(",", ".")}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {context?.t?.view}
                                        </a>
                                      ) : (
                                        context?.t?.unknown
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span className="d-block mt-4">
                        {context?.t?.no_data_available}
                      </span>
                    )
                  ) : (
                    <div className="spinner-border text-theme mt-4"></div>
                  )
                ) : (
                  <span className="d-block mt-4">
                    {context?.t?.il_ve_ilce_filtrelemeniz_gerekiyor}
                  </span>
                ))}
            </div>
          </div>
        )}

        {context?.me?.role?.id !== 6 && (
          <div className="col-12">
            <div className="pt-4 border-top">
              <div className="d-flex align-items-center gap-2">
                <h5 className="mb-0">{context?.t?.dernekler}</h5>

                <button
                  type="button"
                  className="btn btn-outline-theme"
                  onClick={() => setShowDerneklerTable(!showDerneklerTable)}
                >
                  {showDerneklerTable ? context?.t?.hide : context?.t?.show}
                </button>
              </div>

              {showDerneklerTable &&
                (cityFilter && districtFilter ? (
                  derneklerData ? (
                    derneklerData.length !== 0 ? (
                      <div className="dataTables_wrapper dt-bootstrap5 mt-4">
                        <div className="dataTables_scroll">
                          <div
                            className="dataTables_scrollBody table-responsive"
                            style={{ maxHeight: 500 }}
                          >
                            <table className="table text-nowrap w-100 dataTable mb-0">
                              <thead>
                                <tr>
                                  <th className="px-1 text-center">
                                    {context?.t?.city}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.district}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.neighborhood}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.kurum_adi}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.phone}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.faaliyet_alani}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.detayli_faaliyet_alani}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.kurum_adres}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {derneklerData.map((item, index) => (
                                  <tr key={index}>
                                    <td className="py-3 text-center">
                                      {item["sehir"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["ilce"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["mahalle"]}
                                    </td>
                                    <td className="py-3 text-start border-start">
                                      {item["kurum-adi"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["telefon"]}
                                    </td>
                                    <td className="py-3 text-start border-start">
                                      {item["faaliyet-alanı"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["detayli-faaliyet-alani"]}
                                    </td>
                                    <td className="py-3 text-start border-start">
                                      {item["kurum-adres"]}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span className="d-block mt-4">
                        {context?.t?.no_data_available}
                      </span>
                    )
                  ) : (
                    <div className="spinner-border text-theme mt-4"></div>
                  )
                ) : (
                  <span className="d-block mt-4">
                    {context?.t?.il_ve_ilce_filtrelemeniz_gerekiyor}
                  </span>
                ))}
            </div>
          </div>
        )}

        {context?.me?.role?.id !== 6 && (
          <div className="col-12">
            <div className="pt-4 border-top">
              <div className="d-flex align-items-center gap-2">
                <h5 className="mb-0">{context?.t?.sendikalar}</h5>

                <button
                  type="button"
                  className="btn btn-outline-theme"
                  onClick={() => setShowSendikalarTable(!showSendikalarTable)}
                >
                  {showSendikalarTable ? context?.t?.hide : context?.t?.show}
                </button>
              </div>

              {showSendikalarTable &&
                (cityFilter && districtFilter ? (
                  sendikalarData ? (
                    sendikalarData.length !== 0 ? (
                      <div className="dataTables_wrapper dt-bootstrap5 mt-4">
                        <div className="dataTables_scroll">
                          <div
                            className="dataTables_scrollBody table-responsive"
                            style={{ maxHeight: 500 }}
                          >
                            <table className="table text-nowrap w-100 dataTable mb-0">
                              <thead>
                                <tr>
                                  <th className="px-1 text-center">
                                    {context?.t?.city}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.district}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.neighborhood}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.sendika_adi}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.is_kolu}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.address}
                                  </th>
                                  <th className="px-1 text-center">
                                    {context?.t?.integer}
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {sendikalarData.map((item, index) => (
                                  <tr key={index}>
                                    <td className="py-3 text-center">
                                      {item["il"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["ilce"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["mahalle"]}
                                    </td>
                                    <td className="py-3 text-start border-start">
                                      {item["sendika-adresi"]}
                                    </td>
                                    <td className="py-3 text-start border-start">
                                      {item["sendika-is-kolu"]}
                                    </td>
                                    <td className="py-3 text-start border-start">
                                      {item["sendika-adi"]}
                                    </td>
                                    <td className="py-3 text-center border-start">
                                      {item["sayı"]}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <span className="d-block mt-4">
                        {context?.t?.no_data_available}
                      </span>
                    )
                  ) : (
                    <div className="spinner-border text-theme mt-4"></div>
                  )
                ) : (
                  <span className="d-block mt-4">
                    {context?.t?.il_ve_ilce_filtrelemeniz_gerekiyor}
                  </span>
                ))}
            </div>
          </div>
        )}
      </div>

      {context?.me?.role?.id !== 6 && (
        <div className="modal modal-cover fade" id="kisiyeAtanmisAnketler">
          <div
            className="modal-dialog py-4 px-sm-4"
            style={{
              maxWidth: 500,
            }}
          >
            <div
              className="modal-content rounded"
              style={{ background: "#141b24" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">{context?.t?.atanmis_anketler}</h3>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div className="modal-body">
                <ul className="ps-0">
                  {atanmisAnketlerData ? (
                    atanmisAnketlerData.length !== 0 ? (
                      atanmisAnketlerData.map((item, index) => (
                        <li
                          key={index}
                          className="d-flex justify-content-between"
                        >
                          <span className="d-block">
                            {index + 1}. {item?.report?.name}
                          </span>
                          <span className="d-block">
                            {item.status ? (
                              <span className="text-theme">
                                {context?.t?.yapildi}
                              </span>
                            ) : (
                              <span className="text-danger">
                                {context?.t?.yapilmadi}
                              </span>
                            )}
                          </span>
                        </li>
                      ))
                    ) : (
                      <span>{context?.t?.atanmis_bir_anket_yok}</span>
                    )
                  ) : (
                    <div className="spinner-border text-theme"></div>
                  )}
                </ul>
              </div>

              <div className="modal-footer d-flex">
                <button className="col btn btn-dark" data-bs-dismiss="modal">
                  {context?.t?.cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {context?.me?.role?.id !== 6 && (
        <div className="modal modal-cover fade" id="kisiAnketAtaModal">
          <div
            className="modal-dialog py-4 px-sm-4"
            style={{
              maxWidth: 500,
            }}
          >
            <div
              className="modal-content rounded"
              style={{ background: "#141b24" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">{context?.t?.kisiye_anket_ata}</h3>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="kisiAnketAtaModalCloseButton"
                ></button>
              </div>

              <div className="modal-body">
                <div>
                  <span className="d-block mb-2">
                    {context?.t?.anket_secin}
                  </span>
                  {assigmentData ? (
                    <Select
                      options={assigmentData}
                      placeholder={context?.t?.anket_secin}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => context?.t?.no_option}
                      value={anketAtaSelectValue}
                      onChange={(e) => setAnketAtaSelectValue(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
              </div>

              <div className="modal-footer d-flex">
                <button className="col btn btn-dark" data-bs-dismiss="modal">
                  {context?.t?.cancel}
                </button>
                <button
                  className="col btn btn-outline-theme"
                  onClick={() => handleAnketAta()}
                  disabled={anketAtaLoader}
                >
                  {context?.t?.anket_ata}
                  {anketAtaLoader && (
                    <div className="spinner-border spinner-border-sm ms-1"></div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <AddReport
        surveyInnerPage={true}
        assigmentSelect={assigmentSelect}
        surveyData={surveyData}
        tcSurvey={tcSurvey}
      />
    </>
  );
}
