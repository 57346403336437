import hemsehrilikGocJSON from "../../json/hemsehrilik_ve_goc.json";
import React, { useState, useRef, useContext, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import apiClient from "../../ApiClient";
import toastMessage from "../ToastMessage";
import { useNavigate } from "react-router-dom";
import { AppSettings } from "../../config/app-settings";

import Select from "react-select";
import { ToastContainer } from "react-toastify";

const ChartSankeyReverse = ({ city, district, graphic }) => {
  const chartRef = useRef(null);
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const selectData = [
    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "30", label: "30" },
    { value: "40", label: "40" },
    { value: "50", label: "50" },
    { value: "60", label: "60" },
    { value: "70", label: "70" },
    { value: "80", label: "80" },
    { value: "90", label: "90" },
    { value: "100", label: "100" },
  ];

  // GET Data
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const [data, setData] = useState([]);
  const [links, setLinks] = useState([]);
  const [years, setYears] = useState([]);
  const [loader, setLoader] = useState(true);
  const [changeChart, setChangeChart] = useState();
  const [humanCount, setHumanCount] = useState({ value: "10", label: "10" });

  function handleSelect(e) {
    setHumanCount(e);
  }

  async function getData() {
    setData(null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type: "hemsehri_goc",
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "Tarih",
                value: "select",
              },
              {
                key: "Alt-Kategori-2",
                value: "select",
              },
              {
                key: "Parametre",
                value: "select",
              },
              {
                key: "Deger",
                value: "sum",
              },
            ],
            condition: [
              {
                key: "Alt-Kategori-1",
                value: "ALDIGI GOC DURUMU",
                operator: "=",
                type: "and",
              },
              city
                ? {
                    key: "Sehir",
                    value: context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Ilce",
                    value: context
                      .removeTurkishCharacters(district.label)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const filteredResults = [
        {
          name: district.label,
          itemStyle: {
            color: getRandomColor(),
            borderColor: getRandomColor(),
          },
        },
      ];
      const outputData = [];

      //--

      if (response.length !== 0) {
        const newYears = response.reduce((acc, current) => {
          const year = current.tarih.split("-")[0];

          const existingYearIndex = acc.findIndex((item) => item.year === year);
          if (existingYearIndex !== -1) {
          } else {
            acc.push({ year });
          }

          return acc;
        }, []);

        setYears(newYears);
        setChangeChart(newYears[newYears.length - 1].year);

        //--

        for (const item of response) {
          if (item.tarih.includes(changeChart + "-")) {
            if (item["deger_sum"] >= parseInt(humanCount.value)) {
              const newItem = {
                name: item["alt-kategori-2"],
                itemStyle: {
                  color: getRandomColor(),
                  borderColor: getRandomColor(),
                },
              };
              const newItemParametre = {
                name: item["parametre"],
                itemStyle: {
                  color: getRandomColor(),
                  borderColor: getRandomColor(),
                },
              };

              filteredResults.push(newItem);
              filteredResults.push(newItemParametre);

              //--

              const targetItem = {
                target: district.label,
                source: item["alt-kategori-2"],
                value: item["deger_sum"],
              };

              outputData.push(targetItem);

              if (item["parametre"] !== district.label) {
                const sourceItem = {
                  target: item["alt-kategori-2"],
                  source: item["parametre"],
                  value: item["deger_sum"],
                };

                outputData.push(sourceItem);
              }
            }
          }
        }

        const uniqueArray = Array.from(
          new Set(filteredResults.map((item) => item.name))
        ).map((name) => filteredResults.find((item) => item.name === name));

        setData(uniqueArray);
        setLinks(outputData);
      } else {
        setData([]);
        setLinks([]);
        setYears([]);
      }
    } catch (error) {
      console.error("İlçe Göç Error", error);
      setData([]);
    }
  }

  useEffect(() => {
    getData();
  }, [changeChart, district, humanCount]);

  return (
    <div className="position-relative h-100">
      {data ? (
        data.length !== 0 ? (
          <>
            <div
              className="position-absolute text-end end-0"
              style={{ top: 0, zIndex: 1 }}
            >
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center me-2">
                  <div style={{ width: 80 }} className="me-2">
                    <Select
                      options={selectData}
                      value={humanCount}
                      classNamePrefix="react-select"
                      onChange={handleSelect}
                    />
                  </div>

                  <p className="mb-0" style={{ minWidth: 150 }}>
                    {context?.t?.kisi_ve_uzeri_goc_eden_birey_sayisi}
                  </p>
                </div>

                <button className="btn btn-lg btn-theme fw-bold pe-none">
                  <span className="h5">{context?.t?.alinan_goc}</span>
                </button>
              </div>

              <div className="d-flex justify-content-end gap-2 mt-2">
                {years.map((item, index) => (
                  <button
                    key={index}
                    className="btn btn-theme fw-bold"
                    style={{ opacity: changeChart === item.year ? 1 : 0.5 }}
                    onClick={() => setChangeChart(item.year)}
                  >
                    {item.year}
                  </button>
                ))}
              </div>
            </div>

            <ReactECharts
              option={{
                darkMode: true,
                series: [
                  {
                    type: "sankey",
                    right: 50.0,
                    top: 20.0,
                    left: 150.0,
                    bottom: 25.0,
                    data: data,
                    links: links,
                    lineStyle: {
                      color: "source",
                      curveness: 0.5,
                    },
                    emphasis: {
                      focus: "adjacency",
                    },
                    itemStyle: {
                      color: "#3cd2a5",
                      borderColor: "#ffffff",
                    },
                    label: {
                      color: "#ffffff",
                      fontFamily: "Arial",
                      fontSize: 10,
                      position: "left",
                    },
                  },
                ],
                tooltip: {
                  trigger: "item",
                },
              }}
              ref={chartRef}
              className="w-100 h-100 pb-5 mt-5 pt-5"
            />
          </>
        ) : (
          <span className="d-block text-center">
            {context?.t?.no_data_available}
          </span>
        )
      ) : (
        <div className="spinner-border text-theme"></div>
      )}
    </div>
  );
};

export default ChartSankeyReverse;
